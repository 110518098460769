import { Pipe, PipeTransform } from '@angular/core';
import {ContactDomainObject} from '../../../model/domain/contact.domain-object';

@Pipe({
  name: 'contactTitle'
})
export class ContactTitlePipe implements PipeTransform {

  transform(value: ContactDomainObject, index: number, ...args: any[]): any {
    if (value.name) {
        return value.name;
    }
    if (value.email) {
        return value.email;
    }
    return `Kontakt ${index + 1}`;
  }

}
