import {Tour} from './Tour';

export class InvoiceItem {
    id: number;
    label: string;
    quantity: number;
    unit: string;
    unitCost: number;
    tour: Tour;
    vat: number;
}
