import {AfterViewInit, Component, Inject} from '@angular/core';
import {DragDropPlanComponent} from '../drag-drop-plan/drag-drop-plan.component';

@Component({
  selector: 'ui-drag-drop-plan-timeline',
  templateUrl: './drag-drop-plan-timeline.component.html',
  styleUrls: ['./drag-drop-plan-timeline.component.css']
})
export class DragDropPlanTimelineComponent implements AfterViewInit {

  planLeftSpace = 0;
  planRightSpace = 0;

  constructor(@Inject(DragDropPlanComponent) public parent: DragDropPlanComponent) {
  }

  ngAfterViewInit() {
    this.planLeftSpace = this.parent.planLeft.nativeElement.offsetWidth;
    this.planRightSpace = this.parent.planRight.nativeElement.offsetWidth;
  }

}
