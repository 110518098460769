import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class DialogDeleteTourBundle extends AbstractBypassBundle {
    tourId: number;
}

@Component({
    selector: 'app-dialogdeletetour',
    templateUrl: './dialogdeletetour.component.html',
    styleUrls: ['./dialogdeletetour.component.scss']
})
export class DialogDeleteTourComponent extends BypassFormComponent<DialogDeleteTourBundle> implements OnInit {

    constructor(protected injector: Injector,
                public dialogRef: MatDialogRef<DialogDeleteTourComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new DialogDeleteTourBundle());
    }

    ngOnInit() {
    }

    submit() {
        if (!confirm('Unwiderruflich löschen?')) {
            return;
        }
        this.data = new DialogDeleteTourBundle();
        this.data.tourId = this.input.tour.id;
        this.bpSubmit();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }

}
