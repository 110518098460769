import {Component, Injector} from '@angular/core';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';

export class MasterdataEditorContainerBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    businessPartner: BusinessPartnerDomainObject;

    constructor(id: number) {
        super();
        this.businessPartnerId = id;
    }
}

@Component({
    selector: 'app-masterdata-editor-container',
    templateUrl: './masterdata-editor-container.component.html',
    styleUrls: ['./masterdata-editor-container.component.scss']
})
export class MasterdataEditorContainerComponent extends BypassDataComponent<MasterdataEditorContainerBundle> {

    private bpId;
    private bp$ = new BehaviorSubject<BusinessPartnerDomainObject | null>(null);
    bp = this.bp$.asObservable();

    constructor(protected injector: Injector, private activatedRoute: ActivatedRoute) {
        super(injector);
        this.activatedRoute.paramMap.pipe(take(1)).subscribe(pm => {
            this.bpId = +pm.get('id');
            this.bpSetup(new MasterdataEditorContainerBundle(this.bpId));
        });
    }

    protected bpOnMessageReceived(): void {
        this.bp$.next(this.data.businessPartner);
    }

}
