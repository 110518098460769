import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export class DialogCancelTourBundle extends AbstractBypassBundle {
    tourId: number;
}

@Component({
    selector: 'app-dialog-cancel-tour',
    templateUrl: './dialog-cancel-tour.component.html',
    styleUrls: ['./dialog-cancel-tour.component.scss']
})
export class DialogCancelTourComponent extends BypassFormComponent<DialogCancelTourBundle> implements OnInit {

    tourId: number = undefined;


    constructor(protected injector: Injector,
                public dialog: MatDialog,
                public dialogRef: MatDialogRef<DialogCancelTourComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new DialogCancelTourBundle());
    }

    ngOnInit(): void {
        this.tourId = this.input.tour.id;
    }

    submit(): void {
        this.data = Object.assign(new DialogCancelTourBundle(), {tourId: this.tourId});
        this.bpSubmit();
        this.close();
    }

    close(): void {
        this.dialogRef.close();
    }

}
