import {Pipe, PipeTransform} from '@angular/core';
import {TourStatisticsCarrier} from '../model/tour-statistics.model';

@Pipe({
    name: 'carrierTours'
})
export class CarrierToursPipe implements PipeTransform {

    transform(value: TourStatisticsCarrier): number {
        return value.calendarWeeks.map(cw => cw.tours.length).reduce((a, b) => a + b, 0);
    }

}
