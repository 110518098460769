import {Injectable, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MatNativeDateModule, NativeDateAdapter} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CommonModule} from '@angular/common';
import {FormFieldDataComponent} from './form-field-data/form-field-data.component';
import {MatSelectModule} from '@angular/material/select';
import {UiFormFieldNumberComponent} from './components/ui-form-field-number/ui-form-field-number.component';
import {UiFormFieldSelectComponent} from './components/ui-form-field-select/ui-form-field-select.component';
import {UiFormFieldDateComponent} from './components/ui-form-field-date/ui-form-field-date.component';
import {UiFormFieldStringComponent} from './components/ui-form-field-string/ui-form-field-string.component';
import {UiFormFieldRadioGroupComponent} from './components/ui-form-field-radio-group/ui-form-field-radio-group.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FlexModule} from '@angular/flex-layout';
import {UiFormSliderToggleComponent} from './components/ui-form-field-slider-toggle/ui-form-slider-toggle.component';
import {UiFormFieldPasswordComponent} from './components/ui-form-field-password/ui-form-field-password.component';
import {UiFormFieldEmailComponent} from './components/ui-form-field-email/ui-form-field-email.component';
import {UiFormFieldTimeComponent} from './components/ui-form-field-time/ui-form-field-time.component';
import {FilterActiveErrorsPipe} from './components/filter-active-errors.pipe';
import {UiFormFieldCheckboxComponent} from './components/ui-form-field-checkbox/ui-form-field-checkbox.component';
import {UiFormFieldTextareaComponent} from './components/ui-form-field-textarea/ui-form-field-textarea.component';
import {UiFormFieldAutoCompleteComponent} from './components/ui-form-field-autocomplete/ui-form-field-autocomplete.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {UiFormFieldColorPickerComponent} from './components/ui-form-field-color-picker/ui-form-field-color-picker.component';
import {ColorPickerModule} from 'ngx-color-picker';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

@NgModule({
  declarations: [
    FormFieldDataComponent,
    UiFormFieldStringComponent,
    UiFormFieldNumberComponent,
    UiFormFieldDateComponent,
    UiFormFieldSelectComponent,
    UiFormFieldCheckboxComponent,
    UiFormFieldRadioGroupComponent,
    UiFormSliderToggleComponent,
    UiFormFieldPasswordComponent,
    UiFormFieldEmailComponent,
    UiFormFieldTimeComponent,
    FilterActiveErrorsPipe,
    UiFormFieldTextareaComponent,
    UiFormFieldAutoCompleteComponent,
    UiFormFieldColorPickerComponent
  ],
  imports: [
    // Material
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatAutocompleteModule,

    // Flex
    FlexModule,

    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ColorPickerModule
  ],
  exports: [
    UiFormFieldStringComponent,
    UiFormFieldNumberComponent,
    UiFormFieldDateComponent,
    UiFormFieldSelectComponent,
    UiFormFieldCheckboxComponent,
    UiFormFieldRadioGroupComponent,
    UiFormSliderToggleComponent,
    UiFormFieldPasswordComponent,
    UiFormFieldEmailComponent,
    UiFormFieldTimeComponent,
    UiFormFieldTextareaComponent,
    UiFormFieldAutoCompleteComponent,
    UiFormFieldColorPickerComponent
  ],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter}
  ]
})
export class BypassFormsModule {
}
