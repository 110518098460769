import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        if (value === null || value === undefined) {
            return ' — ';
        }
        return DurationPipe.numberToDuration(value);
    }

    static numberToDuration(n: number): string {
        const s = n + '';
        const spl = s.split('.');
        const hours = +spl[0];
        const minutes = +('0.' + (spl[1] || '0')) * 60.0;
        const r = hours.toString().padStart(2, '0') + ':' + Math.round(minutes).toString().padStart(2, '0');
        return r;
    }

    static durationToNumber(d: string): number {
        const spl = d.split(':');
        const hours = +spl[0];
        const minutes = +spl[1];
        return hours + (minutes / 60.0);
    }

}
