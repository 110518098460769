<form>
  <mat-form-field [style.width.%]="100">
    <input
        #inputField
        matInput
        [(ngModel)]="inputModel"
        name="input"
        placeholder="{{placeholder}}"
        [matAutocomplete]="auto"
        (focus)="onChange()"
        (keyup)="onType($event.keyCode)"
        [required]="required"/>
  </mat-form-field>
  <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption
      (optionSelected)="selectOption($event.option.value)">
    <mat-option *ngFor="let option of data.suggestions" [value]="option"
                (click)="selectOption(option)">
      <lib-bypass-identicon *ngIf="showIdenticon"
                            [type]="entityType.toLowerCase()"
                            [identifier]="option.id"
                            [size]="16"></lib-bypass-identicon>
      {{entityToStringFn(option)}}
    </mat-option>
    <mat-option
      *ngIf="data.suggestions !== undefined && getValueLength(data.suggestions) === 0 && getValueLength(inputField.value) > 0 && suggestionSelected === false && enableEntityCreation">
      <button [style.width.%]="100" mat-button (click)="entityCreateFn(inputField.value)">
        '{{inputField.value}}' als neuen Eintrag anlegen
      </button>
    </mat-option>
    <mat-option
      *ngIf="data.suggestions !== undefined && getValueLength(data.suggestions) === 0 && getValueLength(inputField.value) > 0
                && suggestionSelected === false && !enableEntityCreation">
      keine Einträge gefunden
    </mat-option>
  </mat-autocomplete>
</form>
