import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'ui-form-field-color-picker',
  templateUrl: './ui-form-field-color-picker.component.html',
  styleUrls: ['./ui-form-field-color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiFormFieldColorPickerComponent),
      multi: true
    }
  ]
})
export class UiFormFieldColorPickerComponent implements ControlValueAccessor {

  disabled: boolean;
  control = new FormControl();
  color;

  onChange: any = (value) => {
  }
  onTouch: any = () => {
  }

  constructor() {
  }

  writeValue(value: any) {
    this.color = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.onTouch(this.color);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
