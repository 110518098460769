import {ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {PageSettings} from '../../../../model/old/PageSettings';
import {PriceDomainObject} from '../../../../model/domain/price.domain-object';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {combineLatest, merge, Subject} from 'rxjs';
import {debounceTime, startWith, takeUntil} from 'rxjs/operators';
import {DispoPricesDialogComponent} from '../../dialogs/dispo-prices-dialog/dispo-prices-dialog.component';
import {FormControl, FormGroup} from '@angular/forms';

export interface Option {
    id: number;
    name: string;
    shortName?: string;
}

export class DispoPricesPageBundle extends AbstractBypassBundle {
    pageSettings: PageSettings;
    total: number;
    entries: any[];
    filter: {
        active?: boolean;
        fromDateRange?: {
            start?: Date;
            end?: Date;
        };
        endDateRange?: {
            start?: Date;
            end?: Date;
        };
        customer?: number[];
        loadingLocation?: number[];
        unloadingLocation?: number[];
        material?: number[];
        carrier?: number[];
        priceType?: string;
        priceEkRange?: {
            start?: number;
            end?: number;
        };
        priceFrRange: {
            start?: number;
            end?: number;
        };
        priceVkRange?: {
            start?: number;
            end?: number;
        };
        comment?: string;
    };
    filterOptions: {
        customers: Option[],
        loadingLocations: Option[],
        unloadingLocations: Option[],
        materials: Option[],
        carriers: Option[],
    };
}

@Component({
    selector: 'app-dispo-prices-page',
    templateUrl: './dispo-prices-page.component.html',
    styleUrls: ['./dispo-prices-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispoPricesPageComponent extends BypassFormComponent<DispoPricesPageBundle> implements OnInit, OnDestroy {

    filterFormGroup = new FormGroup({
        active: new FormControl(true),
        fromDateRange: new FormGroup({
            start: new FormControl(),
            end: new FormControl(),
        }),
        endDateRange: new FormGroup({
            start: new FormControl(),
            end: new FormControl(),
        }),
        customer: new FormControl(),
        loadingLocation: new FormControl(),
        unloadingLocation: new FormControl(),
        material: new FormControl(),
        carrier: new FormControl(),
        priceType: new FormControl(),
        priceEkRange: new FormGroup({
            start: new FormControl(),
            end: new FormControl(),
        }),
        priceFrRange: new FormGroup({
            start: new FormControl(),
            end: new FormControl(),
        }),
        priceVkRange: new FormGroup({
            start: new FormControl(),
            end: new FormControl(),
        }),
        comment: new FormControl(),
    });

    paginatorPageSizeOptions = [10, 25, 50, 75];
    paginatorPageSize = this.paginatorPageSizeOptions[3];

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    displayedColumns = [
        'active',
        'fromDate',
        'toDate',
        'customer',
        'loadingLocation',
        'unloadingLocation',
        'material',
        'carrier',
        'commissionType',
        'priceType',
        'priceEk',
        'priceFr',
        'priceVk',
        'comment',
    ];
    dataSource = new MatTableDataSource([]);

    loadData$ = new Subject<void>();
    destroyed$ = new Subject<void>();

    constructor(protected injector: Injector, private dialog: MatDialog) {
        super(injector);
        this.bpSetup(new DispoPricesPageBundle());
    }

    ngOnInit() {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.paginatorPageSize;

        this.sort.sortChange.pipe(
            takeUntil(this.destroyed$),
        ).subscribe(() => this.loadData$.next());

        this.filterFormGroup.valueChanges.pipe(
            takeUntil(this.destroyed$),
        ).subscribe(() => this.loadData$.next());

        this.loadData$.pipe(
            takeUntil(this.destroyed$),
            debounceTime(800),
        ).subscribe(() => this.loadData());

        this.loadData$.next();
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    bpOnMessageReceived() {
        this.dataSource.data = this.data.entries;
        this.paginator.length = this.data.total;
    }

    onRowClick(price: PriceDomainObject) {
        this.dialog.open(DispoPricesDialogComponent, {data: {price}, width: '1000px'});
    }

    createPrice() {
        this.dialog.open(DispoPricesDialogComponent, {width: '1000px'});
    }

    private loadData() {
        this.data = new DispoPricesPageBundle();
        this.data.entries = [];

        let pageIndex = 0;
        if (this.paginator.pageIndex !== undefined) {
            pageIndex = this.paginator.pageIndex;
        }
        let pageSize = this.paginatorPageSize;
        if (this.paginator.pageSize !== undefined) {
            pageSize = this.paginator.pageSize;
        }

        this.data.pageSettings = new PageSettings(
            'Price',
            pageIndex,
            pageSize,
            this.sort.active,
            this.sort.direction,
            '',
        );
        this.data.filter = this.filterFormGroup.value;
        this.bpSubmit();
    }

    reset() {
        this.sort.sort({id: null, start: null, disableClear: false});
        this.data.pageSettings.sortField = null;
        this.data.pageSettings.sortDirection = null;
        this.filterFormGroup.reset();
    }

}
