import {Component, OnInit} from '@angular/core';
import {DatatableCellBase} from '@byteways/bypass-datatable/lib/datatable-cell-base.interface';

@Component({
    selector: 'app-accountancy-invoice-table2-hover-text',
    templateUrl: './accountancy-invoice-table2-hover-text.component.html',
    styleUrls: ['./accountancy-invoice-table2-hover-text.component.scss']
})
export class AccountancyInvoiceTable2HoverTextComponent implements DatatableCellBase<string> {
    data: string;
}
