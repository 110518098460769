import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './views/admin/admin.component';
import {DocumentsComponent} from './views/documents/documents.component';
import {RentalComponent} from './views/rental/rental.component';
import {SettingsComponent} from './views/settings/settings.component';
import {OffersComponent} from './views/offers/offers.component';
import {HelpComponent} from './views/help/help.component';
import {DevicesComponent} from './views/devices/devices.component';
import {DispoBookContainerComponent} from './views/disposition/dispobookcontainer/dispobookcontainer.component';
import {LoginPageComponent} from './views/login-page/login-page.component';
import {AccountancyInvoiceBookComponent} from './views/accountancy/accountancy-invoice-book/accountancy-invoice-book.component';
import {PresetTableComponent} from './views/disposition/preset-table/preset-table.component';
import {AccountancyTodosComponent} from './views/accountancy/accountancy-todos/accountancy-todos.component';
import {AccountancyCreditsComponent} from './views/accountancy/accountancy-credits/accountancy-credits.component';
import {AccountancyReportComponent} from './views/accountancy/accountancy-report/accountancy-report.component';
import {AccountancyTaxesComponent} from './views/accountancy/accountancy-taxes/accountancy-taxes.component';
import {AccountancyTaxesTaxGroupsComponent} from './views/accountancy/accountancy-taxes-tax-groups/accountancy-taxes-tax-groups.component';
import {AccountancyTaxesMaterialsComponent} from './views/accountancy/accountancy-taxes-materials/accountancy-taxes-materials.component';
import {RegisterEmployeeComponent} from './views/register-employee/register-employee.component';
import {BypassAdminGuard, BypassAuthGuard} from '@byteways/bypass-core';
import {
    AccountancyEditorContainerComponent
} from './modules/accountancy-editor/components/editor/accountancy-editor-container/accountancy-editor-container.component';
import {
    AccountancyEditorSelectComponent
} from './modules/accountancy-editor/components/editor/accountancy-editor-select/accountancy-editor-select.component';
import {
    MasterdataEditorContainerComponent
} from './modules/masterdata/components/editor/masterdata-editor-container/masterdata-editor-container.component';
import {MasterdataTableComponent} from './modules/masterdata/components/table/masterdata-table/masterdata-table.component';
import {DispoPlanComponent} from './modules/dispo-plan/components/dispo-plan/dispo-plan.component';
import {UserProfileComponent} from './modules/user-profile/user-profile/user-profile.component';
import {MaterialTableComponent} from './modules/materials/components/material-table/material-table.component';
import {DispoStatsComponent} from './modules/dispo-stats/components/dispo-stats/dispo-stats.component';
import {DashboardContainerComponent} from './views/dashboard/dashboard-container/dashboard-container.component';
import {
    AccountancyDatevExportComponent
} from './modules/accountancy-datev-export/accountancy-datev-export/accountancy-datev-export.component';
import {DispoPricesPageComponent} from './modules/dispo-prices/pages/dispo-prices-page/dispo-prices-page.component';

const routes: Routes = [
    {path: '', redirectTo: '/dashboard', pathMatch: 'full'},

    {path: 'dashboard', component: DashboardContainerComponent, canActivate: [BypassAuthGuard]},
    {path: 'devices', component: DevicesComponent, canActivate: [BypassAuthGuard]},
    {path: 'documents', component: DocumentsComponent, canActivate: [BypassAuthGuard]},
    {path: 'help', component: HelpComponent, canActivate: [BypassAuthGuard]},

    {path: 'masterdata', component: MasterdataTableComponent, canActivate: [BypassAuthGuard]},
    {path: 'masterdata/:id', component: MasterdataEditorContainerComponent, canActivate: [BypassAuthGuard]},
    {path: 'statistics', component: DispoStatsComponent, canActivate: [BypassAuthGuard]},

    {
        path: 'accountancy',
        children: [
            {path: '', pathMatch: 'full', redirectTo: 'invoicing'},
            {path: 'book', component: AccountancyInvoiceBookComponent, canActivate: [BypassAuthGuard]},
            {path: 'credits', component: AccountancyCreditsComponent, canActivate: [BypassAuthGuard]},
            // {
            //     path: 'credits/incoming',
            //     canActivate: [BypassAuthGuard],
            //     children: [
            //         {path: 'new/:businessPartnerId', component: AccountancyInvoicingNewIncomingCreditComponent, canActivate: [BypassAuthGuard]},
            //         {path: 'edit/:incomingCreditId', component: AccountancyInvoicingNewIncomingCreditComponent, canActivate: [BypassAuthGuard]}
            //     ]
            // },
            // {path: 'credits/outgoing', component: AccountancyOutgoingCreditComponent, canActivate: [BypassAuthGuard]},
            {path: 'todos', component: AccountancyTodosComponent, canActivate: [BypassAuthGuard]},
            {path: 'report', component: AccountancyReportComponent, canActivate: [BypassAuthGuard]},
            {path: 'datev', component: AccountancyDatevExportComponent, canActivate: [BypassAuthGuard]},
            {
                path: 'taxes',
                canActivate: [BypassAuthGuard],
                children: [
                    {path: '', pathMatch: 'full', redirectTo: 'taxgroups'},
                    {path: 'overview', component: AccountancyTaxesComponent, canActivate: [BypassAuthGuard]},
                    {path: 'taxgroups', component: AccountancyTaxesTaxGroupsComponent, canActivate: [BypassAuthGuard]},
                    {path: 'materials', component: AccountancyTaxesMaterialsComponent, canActivate: [BypassAuthGuard]}
                ]
            },
            {
                path: 'editor',
                canActivate: [BypassAuthGuard],
                children: [
                    {
                        path: '',
                        redirectTo: 'select',
                        pathMatch: 'full'
                    },
                    {
                        path: 'select',
                        canActivate: [BypassAuthGuard],
                        component: AccountancyEditorSelectComponent
                    },
                    {
                        path: ':editorType/:businessPartnerId',
                        canActivate: [BypassAuthGuard],
                        children: [
                            {path: 'new', component: AccountancyEditorContainerComponent, data: {mode: 'new'}},
                            {path: 'edit/:id', component: AccountancyEditorContainerComponent, data: {mode: 'edit'}}
                        ]
                    }
                ]
            }

        ]
    },

    {path: 'offers', component: OffersComponent, canActivate: [BypassAuthGuard]},
    {path: 'rental', component: RentalComponent, canActivate: [BypassAuthGuard]},
    {path: 'settings', component: SettingsComponent, canActivate: [BypassAuthGuard]},

    {
        path: 'disposition',
        children: [
            {path: '', pathMatch: 'full', redirectTo: 'book'},
            {path: 'materials', component: MaterialTableComponent, canActivate: [BypassAuthGuard]},
            {path: 'prices', component: DispoPricesPageComponent, canActivate: [BypassAuthGuard]},
            {path: 'presets', component: PresetTableComponent, canActivate: [BypassAuthGuard]},
            {path: 'book', component: DispoBookContainerComponent, canActivate: [BypassAuthGuard]},
            {path: 'plan', component: DispoPlanComponent, canActivate: [BypassAuthGuard]}
        ]
    },

    {path: 'admin', component: AdminComponent, canActivate: [BypassAuthGuard, BypassAdminGuard]},

    {path: 'login', component: LoginPageComponent},

    {path: 'register', component: RegisterEmployeeComponent, canActivate: [BypassAuthGuard]},
    {path: 'profile', component: UserProfileComponent, canActivate: [BypassAuthGuard]},

    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}


const myPromise: Promise<number> = Promise.resolve(42);
void myPromise.then().catch();

