import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaderResponse, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {BypassBaseComponent, BypassDataService} from '@byteways/bypass-core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-register-employee',
    templateUrl: './register-employee.component.html',
    styleUrls: ['./register-employee.component.scss']
})
export class RegisterEmployeeComponent extends BypassBaseComponent implements OnInit {

    registerForm = new FormGroup({
        login: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required]),
        sign: new FormControl('1', [Validators.required]),
    });

    submitted = false;

    constructor(protected injector: Injector, private http: HttpClient, private router: Router) {
        super(injector);
    }

    ngOnInit() {
    }

    submit() {
        if (this.submitted) {
            return;
        }
        this.submitted = true;

        const httpRequestBody = Object.assign({}, this.registerForm.value);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.http.post('user/register', httpRequestBody, httpOptions).subscribe(e => {
            this.router.navigateByUrl('/');
        });
    }

}
