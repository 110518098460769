import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DispoPricesPageComponent} from './pages/dispo-prices-page/dispo-prices-page.component';
import {FeaturesModule} from '../../features/features.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {BypassCoreModule} from '@byteways/bypass-core';
import {BypassSearchableInputModule} from '@byteways/bypass-searchable-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import {SharedModule} from '../shared/shared.module';
import { DispoPricesDialogComponent } from './dialogs/dispo-prices-dialog/dispo-prices-dialog.component';
import {NgSelectModule} from '@ng-select/ng-select';


@NgModule({
    declarations: [
        DispoPricesPageComponent,
        DispoPricesDialogComponent
    ],
    imports: [
        CommonModule,
        BypassCoreModule,
        BypassSearchableInputModule,
        BypassIdenticonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FeaturesModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatListModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatSortModule,
        MatDialogModule,
        SharedModule,
        NgSelectModule,
    ],
    entryComponents: [DispoPricesDialogComponent]
})
export class DispoPricesModule {
}
