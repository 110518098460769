import {Customer} from './Customer';
import {DateRange} from './DateRange';
import {Material} from './Material';
import {TourSchedule} from './TourSchedule';
import {Location} from './Location';

export class Tour {
    id: number;
    tourNumber: string;
    customer: Customer;
    targetDate: DateRange;
    material: Material;
    quantity: string;
    loadingLocation: Location;
    unloadingLocation: Location;
    vehicle: string;
    commissionType: string;
    priceEk: number;
    priceFr: number;
    priceVk: number;
    priceType: string;
    comment: string;
    message: string;
    status: string;
    schedule: TourSchedule;
    createdDate: Date;
    duration: number;
    distance: number;
    orderNumber?: string;
    subcontractorInvoiceNumber?: string;
}
