import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {SelectionListSideComponent} from './selection-list/selection-list-side/selection-list-side.component';
import {SelectionListComponent} from './selection-list/selection-list.component';
import {SelectionListItemBaseComponent} from './selection-list/selection-list-item-base/selection-list-item-base.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SelectionListComponent,
    SelectionListSideComponent,
    SelectionListItemBaseComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,

        // Material
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatTooltipModule,
    ],
  exports: [
    SelectionListComponent,
    SelectionListItemBaseComponent
  ],
  entryComponents: [
    SelectionListItemBaseComponent
  ]
})
export class UiSelectionListModule {
}
