/* tslint:disable:member-ordering */
import {
  AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter,
  HostListener, Input, OnInit, Output, TemplateRef, ViewChild
} from '@angular/core';
import {ItemBeyondPlan, PlanItem, PlanItemComponent, RowTitle, RowTitleComponent} from '../../model/model';
import {PlanItemService} from '../../services/plan-item.service';
import {DragDropPlanConfig} from '../../model/DragDropPlanConfig';
import {DragDropPlanPreviewConfig} from '../../model/DragDropPlanPreviewConfig';
import {BehaviorSubject} from 'rxjs';

/**
 * d&d plan implementation.
 */
@Component({
  selector: 'ui-drag-drop-plan',
  templateUrl: './drag-drop-plan.component.html',
  styleUrls: ['./drag-drop-plan.component.scss']
})
export class DragDropPlanComponent implements OnInit, AfterContentInit, AfterViewInit {

  // =======================================================================================================================================
  // Inputs

  /**
   * Template for row title cells in the d&d plan.
   */
  @Input() scrollElementId: string;

  /**
   * Template for row title cells in the d&d plan.
   */
  @Input() rowTitleTemplate: TemplateRef<RowTitleComponent>;

  /**
   * Template for a plan item in the d&d plan.
   */
  @Input() planItemTemplate: TemplateRef<PlanItemComponent>;

  /**
   * Template for an item in the list of unplanned items.
   */
  @Input() unplannedItemTemplate: TemplateRef<PlanItemComponent>;

  /**
   * Row titles in the d&d plan.
   * @param rows Row configuration.
   */
  @Input() set rows(rows: RowTitle[]) {
    this.rowTitles = [...rows];
    this.buildGrid();
  }

  /**
   * Items in the d&d plan (both planned & unplanned).
   * @param items Item configuration.
   */
  @Input() set items(items: PlanItem[]) {
    this.updateItems(items);
    this.setStepPixelSize();
  }

  private localRowHeight = 30;

  @Input() set rowHeight(rowHeight: number) {
    this.localRowHeight = rowHeight;
  }

  get rowHeight(): number {
    return this.localRowHeight;
  }

  // =======================================================================================================================================
  // Outputs

  /**
   * Event emitter for resizing an item.
   */
  @Output() resizingEvent = new EventEmitter<{ success: boolean, item: PlanItem }>();

  /**
   * Event emitter for a resized item.
   */
  @Output() resizedEvent = new EventEmitter<{ success: boolean, item: PlanItem }>();

  /**
   * Event emitter for hovering on a plan item.
   */
  @Output() hoverEvent = new EventEmitter<PlanItem>();

  /**
   * Event emitter for d&d start.
   */
  @Output() dragStartedEvent = new EventEmitter<PlanItem>();

  /**
   * Event emitter for d&d in progress.
   */
  @Output() draggingEvent = new EventEmitter<PlanItem>();

  /**
   * Event emitter for d&d end.
   */
  @Output() dragEndEvent = new EventEmitter<PlanItem>();

  /**
   * Event emitter for d&d from planned to unplanned.
   */
  @Output() plannedToUnplannedEvent = new EventEmitter<PlanItem>();

  /**
   * Event emitter for d&d from unplanned to planned.
   */
  @Output() unplannedToPlannedEvent = new EventEmitter<PlanItem>();

  // =======================================================================================================================================
  // View children

  /**
   * Plan element view child.
   */
  @ViewChild('plan', {static: true}) plan: ElementRef;

  /**
   * Grid element view child.
   */
  @ViewChild('grid', {static: true}) grid: ElementRef;

  /**
   * Unplanned element view child.
   */
  @ViewChild('unplanned', {static: false}) unplanned: ElementRef;

  /**
   * Section before DragDropPlan
   */
  @ViewChild('planLeft', {static: false}) planLeft: ElementRef;

  /**
   * Section after DragDropPlan
   */
  @ViewChild('planRight', {static: false}) planRight: ElementRef;

  // =======================================================================================================================================
  // Host listeners

  /**
   * Host listener for resizing.
   */
  @HostListener('window:resize')
  onResize() {
    this.setStepPixelSize();
    if (this.currentResizeItem) {
      this.currentResizeItem = Object.assign(new PlanItem(), this.currentResizeItem);
    }
  }

  // =======================================================================================================================================
  // Lifecycle callbacks

  constructor(public planItemService: PlanItemService) {
  }

  ngOnInit() {
    window.addEventListener('scroll', () => {
      if (this.scrollElementId) {
        this.scrollDifference = document.getElementById(this.scrollElementId).scrollTop;
      } else {
        this.scrollDifference = document.documentElement.scrollTop;
      }
    }, true);
  }

  ngAfterViewInit() {
    this.setStepPixelSize();
  }

  ngAfterContentInit(): void {
    this.setGridStyleAttributes();
  }

  // =======================================================================================================================================
  // Grid

  /**
   * Grid index array. // TODO: documentation
   */
  gridIndices = [];

  /**
   * Row titles array for rendering row title templates of the d&d plan.
   */
  rowTitles: RowTitle[] = [];

  /**
   * Pixel size of an individual grid cell.
   */
  gridStepSize: number;

  /**
   * Scroll difference. // TODO: documentation
   */
  scrollDifference = 0;

  /**
   * Build the grid based on the array of row titles.
   */
  buildGrid() {
    this.gridIndices = [];
    for (let z = DragDropPlanConfig.PLAN_START; z < (DragDropPlanConfig.PLAN_END * this.rowTitles.length); z++) {
      this.gridIndices.push(z);
    }
  }

  /**
   * Apply grid style to the grid native element.
   * TODO: direkt im template machen
   */
  setGridStyleAttributes(): void {
    let columns = ' ';
    for (let i = DragDropPlanConfig.PLAN_START; i < DragDropPlanConfig.PLAN_END; i++) {
      columns = columns + ' auto';
    }
    this.grid.nativeElement.setAttribute('style', `grid-template-columns:${columns}`);
  }

  /**
   * Calculate grid step size from the native element
   */
  setStepPixelSize() {
    this.gridStepSize = ((this.grid.nativeElement.offsetWidth) / DragDropPlanConfig.PLAN_END);
  }

  // =======================================================================================================================================
  // PlanItem collections & logic
  /**
   * Internal collection of unplanned items.
   */
  unplannedItems: PlanItem[] = [];

  /**
   * Internal collection of planned items.
   */
  plannedItems: PlanItem[] = [];

  /**
   * Updates the internal unplanned items and planned items collection from a single array of plan items.
   * @param items All plan items.
   */
  updateItems(items: PlanItem[]) {
    this.unplannedItems = [];
    this.plannedItems = [];
    items.forEach((item: PlanItem) => {
      if (this.planItemService.isItemUnplanned(item, (this.rowTitles.length - 1))) {
        this.unplannedItems.push(item);
      } else {
        this.plannedItems.push(item);
      }
    });

    this.plannedItems.forEach((item: PlanItem) => {
      this.highlightItem(item, false);
    });
  }

  onItemDragEnd(item: PlanItem) {
    this.currentResizeItem.next(item);
    this.hidePreview.next(true);
  }

  /**
   * Highlights a plan item based on the deadline status.
   * @param item The plan item.
   * @param showDeadline or hide it
   */
  highlightItem(item: PlanItem, showDeadline = true) {
    item.currentColor = this.planItemService.isInDeadline(item) ? item.color : item.warnColor;
  }

  onItemHover(item: PlanItem) {
    this.deadlineItem.next(item);
    this.hideDeadline.next(false);
    this.currentResizeItem.next(item);
  }

  /**
   * Removes an item from the unplanned array and puts it into the planned array
   * @param item The Plan Item
   */
  pushItemToPlanned(item: PlanItem) {
    this.plannedItems.push(item);
    const index = this.unplannedItems.indexOf(item);
    if (index !== -1) {
      this.unplannedItems.splice(index, 1);
    }
    this.hidePreview.next(true);
  }

  /**
   * Removes an item from the planned array and puts it into the unplanned array
   * @param item The Plan Item
   */
  pushItemToUnplanned(item: PlanItem) {
    this.unplannedItems.push(item);
    const index = this.plannedItems.indexOf(item);
    if (index !== -1) {
      this.plannedItems.splice(index, 1);
    }
    this.hidePreview.next(true);
  }

  // =======================================================================================================================================
  // DragDrop

  /**
   * Calculates the row by a pixel position
   * @param position in pixel
   */
  getRowByPosition(position: number): number {
    return Math.floor(position / this.rowHeight) * this.rowHeight;
  }

  /**
   * The current Item for showing the deadline
   */
  deadlineItem = new BehaviorSubject<PlanItem>(null);

  /**
   * If this is false the daedline will be visible until it is set to false
   */
  useTimeoutForDeadline = true;

  /**
   * Show or hide the deadline
   */
  hideDeadline = new BehaviorSubject<boolean>(true);

  /**
   * Creates the config for the deadline component
   * @param item PlanItem
   * @param useTimeout or hide after 2,5 Seconds
   */
  showDeadline(item: PlanItem, useTimeout: boolean) {
    this.useTimeoutForDeadline = useTimeout;
    this.deadlineItem.next(item);
    this.hideDeadline.next(false);
  }

  // =======================================================================================================================================
  // Resize

  /**
   * Internal current resize item.
   */
  currentResizeItem = new BehaviorSubject<PlanItem>(null);

  // =======================================================================================================================================
  // Preview

  /**
   * Configuration to show the preview of a dragging Item
   */
  previewConfig: DragDropPlanPreviewConfig;

  /**
   * Show or hide preview
   */
  hidePreview = new BehaviorSubject(true);

  /**
   * Sets new config for DragDropPreviewComponent
   * @param previewConfig Preview of dragging item
   */
  updatePreview(previewConfig: DragDropPlanPreviewConfig) {
    this.previewConfig = previewConfig;
  }

  // =======================================================================================================================================
  // Beyond Plan visualisation

  itemsBeyondEnd: ItemBeyondPlan[] = [];
  itemsBeyondStart: ItemBeyondPlan[] = [];

  checkIfItemIsBeyondPlan(item: PlanItem) {
    const itemNotFound = !this.plannedItems.find(i => i.id === item.id);

    const beyondStartItem = this.itemsBeyondStart.find(i => i.planItemId === item.id);

    if (itemNotFound || (item.position.column >= 0 && beyondStartItem)) {
      this.itemsBeyondStart.splice(this.itemsBeyondStart.indexOf(beyondStartItem), 1);
    } else if (item.position.column < 0 && beyondStartItem) {
      beyondStartItem.row = item.position.row;
      beyondStartItem.stepsBeyond = item.position.column;
      beyondStartItem.planItemCurrentColor = item.currentColor;
    } else if (item.position.column < 0) {
      this.itemsBeyondStart.push(new ItemBeyondPlan(item.position.row, item.id, item.currentColor, item.position.column));
    }

    const itemEnd = this.planItemService.getItemEnd(item);

    const beyondEndItem = this.itemsBeyondEnd.find(i => i.planItemId === item.id);

    if (itemNotFound || (itemEnd <= DragDropPlanConfig.PLAN_END && beyondEndItem)) {
      this.itemsBeyondEnd.splice(this.itemsBeyondEnd.indexOf(beyondEndItem), 1);
    } else if (itemEnd > DragDropPlanConfig.PLAN_END && beyondEndItem) {
      beyondEndItem.row = item.position.row;
      beyondEndItem.stepsBeyond = (itemEnd - DragDropPlanConfig.PLAN_END);
      beyondEndItem.planItemCurrentColor = item.currentColor;
    } else if (itemEnd > DragDropPlanConfig.PLAN_END) {
      this.itemsBeyondEnd.push(new ItemBeyondPlan(item.position.row, item.id, item.currentColor, (itemEnd - 96)));
    }
  }

  findItemBeyondStartByRow(row: number) {
    return this.itemsBeyondStart.find(i => i.row === row);
  }

  findItemBeyondEndByRow(row: number) {
    return this.itemsBeyondEnd.find(i => i.row === row);
  }

}
