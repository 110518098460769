import {AbstractControl} from '@angular/forms';

export class BypassFormValidatorMail {

  public static mail(customErrorMessageFunction?: () => string) {

    const defaultErrorMessageFunction = () => {
      return `Dies ist keine E-Mail-Adresse`;
    };

    const errorFunction = () => {
      const error = {};
      const errorMessage: any = customErrorMessageFunction ? customErrorMessageFunction() : defaultErrorMessageFunction();
      error[errorMessage] = errorMessage;
      return error;
    };

    const validateFunction = (value: string) => {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return value.match(mailformat);
    };

    return (control: AbstractControl): any => {
      if (document.readyState === 'complete') {
        control.markAsUntouched();
      }
      const value = control.value || '';
      return validateFunction(value) ? null : errorFunction();
    };
  }

}
