import {Pipe, PipeTransform} from '@angular/core';
import {DateRange} from '../model/old/DateRange';

@Pipe({
    name: 'dateRangeFormat'
})
export class DateRangeFormatPipe implements PipeTransform {

    private options = {weekday: 'short', year: '2-digit', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};
    private locale = 'de-DE';

    private formatDate(date: Date) {
        const d = new Date(Date.parse(`${date}`)); // FIXME: seltsamer Workaround für Date problem
        return d.toLocaleDateString(this.locale, this.options);
    }

    private isSameDay(dateRange: DateRange): boolean {
        const d1 = new Date(Date.parse(`${dateRange.fromDate}`)); // s.o.
        const d2 = new Date(Date.parse(`${dateRange.toDate}`)); // s.o.
        return dateRange.useRange
            && (d1.getFullYear() === d2.getFullYear())
            && (d1.getMonth() === d2.getMonth())
            && (d1.getDate() === d2.getDate());
    }

    private formatSameDay(dateRange: DateRange) {
        const d1 = new Date(Date.parse(`${dateRange.fromDate}`)); // s.o.
        const d2 = new Date(Date.parse(`${dateRange.toDate}`)); // s.o.
        const dateSplit = d1.toLocaleDateString(this.locale, this.options).split(',');
        const date = `${dateSplit[0]}, ${dateSplit[1]}`;
        const time1 = d1.toLocaleTimeString('de').slice(0, -3);
        const time2 = d2.toLocaleTimeString('de').slice(0, -3);
        return `${date}, ${time1} - ${time2}`;
    }

    transform(value: DateRange, args?: any): string {
        if (value === undefined || value === null) {
            return 'test';
        }
        if (!value.useRange) {
            return this.formatDate(value.fromDate);
        } else if (value.useRange && this.isSameDay(value)) {
            return this.formatSameDay(value);
        } else {
            return `${this.formatDate(value.fromDate)} - ${this.formatDate(value.toDate)}`;
        }
    }

}
