export class AccountancyReport {

    invoices: number;
    invoicesOpen: number;
    invoicesClosed: number;
    invoicesCancelled: number;
    invoicesCorrected: number;
    invoicesSent: number;
    netto: number;
    brutto: number;
    ust: number;
    accounts: Map<string, number>

}
