import { Component, OnInit } from '@angular/core';
import {DatatableCellBase} from '@byteways/bypass-datatable/lib/datatable-cell-base.interface';

@Component({
  selector: 'app-accountancy-invoice-table2-date-short',
  templateUrl: './accountancy-invoice-table2-date-short.component.html',
  styleUrls: ['./accountancy-invoice-table2-date-short.component.scss']
})
export class AccountancyInvoiceTable2DateShortComponent implements DatatableCellBase<Date> {
    data: Date;
}
