import {AbstractControl} from '@angular/forms';

export class BypassFormValidatorLength {

  public static minLength(minLength: number, customErrorMessageFunction?: (currentLength: number, minLength: number) => {}) {

    const defaultErrorMessageFunction = (currentLength: number, min: number) => {
      return `Aktuelle Länge: ${currentLength}. Mindestlänge: ${min}.`;
    };

    const errorFunction = (currentLength: number, min: number) => {
      const error = {};

      const errorMessage: any = customErrorMessageFunction ? customErrorMessageFunction(currentLength, min) :
        defaultErrorMessageFunction(currentLength, min);

      error[errorMessage] = errorMessage;
      return error;
    };

    const validateFunction = (value: string, min: number) => {
      return value && value.length >= min;
    };

    return (control: AbstractControl): any => {
      if (document.readyState !== 'complete') {
        control.markAsUntouched();
      }
      const value = control.value || '';
      return validateFunction(value, minLength) ? null : errorFunction(value.length, minLength);
    };
  }

  public static maxLength(maxLength: number, customErrorMessageFunction?: (currentLength: number, maxLength: number) => {}) {

    const defaultErrorMessageFunction = (currentLength: number, max: number) => {
      return '';
    };

    const errorFunction = (currentLength: number, max: number) => {
      const error = {};
      const errorMessage: any = customErrorMessageFunction ? customErrorMessageFunction(currentLength, max)
        : defaultErrorMessageFunction(currentLength, max);
      error[errorMessage] = errorMessage;
      return error;
    };

    const validateFunction = (value: string, max: number) => {
      return !value || value.length <= max;
    };

    return (control: AbstractControl): any => {
      const value = control.value || '';
      return validateFunction(value, maxLength) ? null : errorFunction(value.length, maxLength);
    };
  }

}
