import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SelectionListSearchOption} from '../selection-list-search-option';
import {SelectionListDisplayAdapter} from '../selection-list-display-adapter';

@Component({
  selector: 'ui-selection-list-side',
  templateUrl: './selection-list-side.component.html',
  styleUrls: ['./selection-list-side.component.scss']
})
export class SelectionListSideComponent<ItemType> implements OnInit {

  @Input() title: string;

  private _items: ItemType[] = [];

  @Input() set items(value: ItemType[]) {
    this._items = value;
    this.onItemDataChanged(this.filterControl.value);
  }

  get items(): ItemType[] {
    return this._items;
  }

  @Input() selectedItems: ItemType[] = [];

  @Input() itemsSortComparator: (itemA: ItemType, itemB: ItemType) => number = ((itemA, itemB) => 0);

  @Input() dataShowComponent: any;

  itemsFiltered: ItemType[] = [];

  /**
   * @itemFilterStrings
   * Strings, nach dem der FilterInput sucht. Diese müssen von jedem
   * eingebundenen Component als Output zurückgegeben werden.
   * Vorteil: Jedes List-Item kann vorgeben, nach welchen String der Filter
   * den Component finden soll
   */
  itemFilterStrings: SelectionListSearchOption<ItemType>[] = [];

  filterControl = new FormControl('');

  @Output() sideItemClicked = new EventEmitter<any>();

  /**
   * @viewContainerRefs
   * Alle Templates in die der vordefinierte Component gerendert wird
   */
  @ViewChildren('container', {read: ViewContainerRef}) viewContainerRefs: QueryList<ViewContainerRef>;

  selectAllItemsState = false;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.filterControl.valueChanges.subscribe((value) => {
      this.onItemDataChanged(value);
    });
  }

  onItemDataChanged(value: string) {
    this.filterItems(value);
    // create components using component factory resolver for view children
    // use setTimeout to run this function after this angular rendering cycle
    setTimeout(() => this.createItemComponents());
  }

  filterItems(filterValue: string) {
    if (!filterValue) {
      // no filter: show original items
      this.itemsFiltered = [...this.items]; // copy item array
    } else {
      // show filtered items
      this.itemsFiltered = [];
      const objects = this.itemFilterStrings.filter(item => item.searchString.includes(filterValue));
      for (const obj of objects) {
        const objectToFind = this.items.find(item => item === obj.obj);
        if (this.itemsFiltered.indexOf(objectToFind) === -1) {
          this.itemsFiltered.push(objectToFind);
        }
      }
    }
    this.itemsFiltered = this.itemsFiltered.sort(this.itemsSortComparator);
  }

  itemClicked(item: any, i: number, select?: boolean) {
    const view: ViewContainerRef = this.viewContainerRefs.find((template, index) => index === i);
    const clickedElement: HTMLElement = view.element.nativeElement.previousElementSibling.firstElementChild;

    if (select === true) {
      clickedElement.classList.add('selected');
    } else if (select === false) {
      clickedElement.classList.remove('selected');
    } else if (!clickedElement.classList || !clickedElement.classList.contains('selected')) {
      clickedElement.classList.add('selected');
    } else if (clickedElement.classList) {
      clickedElement.classList.remove('selected');
    }

    const shouldSelectButNotSelected = (select === true && this.selectedItems.indexOf(item) === -1);
    const shouldNotSelectButSelected = (select === false && this.selectedItems.indexOf(item) !== -1);

    if (select === undefined || shouldSelectButNotSelected || shouldNotSelectButSelected) {
      this.sideItemClicked.emit(item);
    }
  }

  private allItemsSelected(): boolean {
    return this.selectedItems.length === this.items.length;
  }

  private noItemsSelected(): boolean {
    return this.selectedItems.length === 0;
  }

  /**
   * TODO: was passier hier
   */
  createItemComponents() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.dataShowComponent);

    for (let i = 0; i < this.itemsFiltered.length; i++) {
      const view: ViewContainerRef = this.viewContainerRefs.find((template, index) => index === i);
      view.clear();
      const ref: any = view.createComponent(factory);

      const displayComponent: SelectionListDisplayAdapter<ItemType> = ref.instance;

      // Gibt den vom Component erstellten String zurück, über den der Filter das Item suchen kann
      displayComponent.searchStringCreated.subscribe(item => {
        if (this.itemFilterStrings.indexOf(item) === -1) {
          this.itemFilterStrings.push(item);
        }
      });

      // Das Objekt, dass vom Component angezeigt werden soll
      displayComponent.data = this.itemsFiltered[i];

      ref.changeDetectorRef.detectChanges();
    }
  }

  selectAll() {
    if (this.allItemsSelected()) {
      this.selectAllItemsState = false;
    } else if (this.noItemsSelected()) {
      this.selectAllItemsState = true;
    } else {
      this.selectAllItemsState = this.selectAllItemsState !== true;
    }

    this.items.forEach((item, i) => {
      this.itemClicked(item, i, this.selectAllItemsState);
    });
  }

}
