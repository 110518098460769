export class RowTitle {
  id: number;
  title: string;

  constructor(id?: number, title?: string) {
    this.id = id;
    this.title = title;
  }
}

export class PlanItemPosition {
  column: number;
  row: number;

  constructor(column?: number, row?: number) {
    this.column = column;
    this.row = row;
  }
}

export class PlanItemColor {
  red: number;
  green: number;
  blue: number;

  constructor(red?: number, green?: number, blue?: number) {
    this.red = red;
    this.green = green;
    this.blue = blue;
  }
}

export class PlanItemDeadline {
  start: number;
  end: number;

  constructor(start?: number, end?: number) {
    this.start = start;
    this.end = end;
  }
}

export class PlanItem {
  id: number;
  length: number;
  position: PlanItemPosition;
  title: any;
  currentColor: PlanItemColor;
  color: PlanItemColor;
  warnColor: PlanItemColor = new PlanItemColor(255, 0, 0);
  deadline: PlanItemDeadline;
  onRightClick: (item: PlanItem, event: MouseEvent) => void;
  visible = true;

  constructor(
    id?: number,
    length?: number,
    title?: any,
    color?: PlanItemColor,
    warnColor?: PlanItemColor,
    position?: PlanItemPosition,
    onRightClick?: (item: PlanItem, mouseEvent: MouseEvent) => void,
    deadline?: PlanItemDeadline
  ) {
    this.id = id;
    this.length = length;
    this.position = position;
    this.title = title;
    this.onRightClick = onRightClick;
    this.color = color;
    this.currentColor = color;
    this.warnColor = warnColor;
    this.deadline = deadline;
  }
}

export class ItemBeyondPlan {
  row: number;
  planItemId: number;
  planItemCurrentColor: PlanItemColor;
  stepsBeyond: number;

  constructor(row: number, planItemId: number, planItemCurrentColor: PlanItemColor, stepsBeyond: number) {
    this.row = row;
    this.planItemId = planItemId;
    this.planItemCurrentColor = planItemCurrentColor;
    this.stepsBeyond = stepsBeyond;
  }
}


export interface PlanItemComponent {
  planItem: PlanItem;
}

export interface RowTitleComponent {
  rowTitle: RowTitle;
}
