import {Component, Input, OnInit} from '@angular/core';
import {TourStatisticsCarrierWeekly} from '../../model/tour-statistics.model';

@Component({
    selector: 'app-dispo-stats-weekly-table',
    templateUrl: './dispo-stats-weekly-table.component.html',
    styleUrls: ['./dispo-stats-weekly-table.component.scss']
})
export class DispoStatsWeeklyTableComponent implements OnInit {


    @Input() weeklyStats: TourStatisticsCarrierWeekly | null;

    showColumns: string[] = ['tour', 'loadingLocation', 'unloadingLocation', 'customer', 'quantity', 'fr', 'sum'];

    constructor() {
    }

    ngOnInit() {
    }

}
