import {Pipe, PipeTransform} from '@angular/core';
import {TourStatistics} from '../model/tour-statistics.model';

@Pipe({
    name: 'statsCarrierOptions'
})
export class StatsCarrierOptionsPipe implements PipeTransform {

    transform(value: TourStatistics, showSubcontractors: boolean = true): { carrierId: number; carrierName: string; own: boolean; }[] {
        return value
            .carriers
            .filter(c => showSubcontractors ? true : c.own === true)
            .map(c => {
                return {carrierId: c.carrierId, carrierName: c.carrierName, own: c.own};
            });

    }

}
