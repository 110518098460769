import {Component, Inject} from '@angular/core';
import {PlanItemService} from '../../services/plan-item.service';
import {DragDropPlanComponent} from '../drag-drop-plan/drag-drop-plan.component';
import {DragDropPlanItemBasicComponent} from '../drag-drop-plan-item-basic/drag-drop-plan-item-basic.component';
import {PlanItem} from '../../model/model';

@Component({
  selector: 'ui-drag-drop-plan-item',
  templateUrl: './drag-drop-plan-item.component.html',
  styleUrls: ['./drag-drop-plan-item.component.scss']
})
export class DragDropPlanItemComponent extends DragDropPlanItemBasicComponent {

  constructor(@Inject(DragDropPlanComponent) public parent: DragDropPlanComponent, public planItemService: PlanItemService) {
    super(parent, planItemService);
  }

  /**
   * Creates a translated style string for the item
   * @param item PlanItem
   */
  getPlanItemTransform(item: PlanItem): string {
    if (!item.position) {
      return '';
    }
    const x = item.position.column * this.parent.gridStepSize;
    const y = item.position.row * this.parent.rowHeight;
    if (isNaN(x) || isNaN(y)) {
      return '';
    }
    return `translate3d(${x}px, ${y}px, 0px)`;
  }

  /**
   * Callback for drag start.
   * @param item The plan item.
   * @param event The drag event.
   */
  onItemDragStart(item: PlanItem, event: MouseEvent) {
    this.parent.setStepPixelSize();

    // Die Position im Plan, an der das Element zum ersten mal ein Drag Event ausgelöst hat
    this.currentDragPosition = {
      x: this.planItemService.getPixelPositionFromItemPosition(item.position.column, this.parent.gridStepSize),
      y: ((item.position.row * this.parent.rowHeight) - this.parent.scrollDifference)
    };

    // Ein Ghost Element wird erstellt und das alte versteckt, damit sie nicht überlappen
    clearTimeout(this.invisibleTimeout);
    this.invisibleTimeout = setTimeout(() => {
      item.visible = false;
    }, 10);

    // Differenz zwischen offsetLeft und left ab Start de Plans
    this.xDifference = (event.clientX - (this.planItemService.getPixelPositionFromItemPosition(item.position.column, this.parent.gridStepSize) || 0));

    // Die vertikale Position an dem das Element geklickt wurde
    const verticalClickedPosition = (event.y - this.parent.plan.nativeElement.offsetTop);

    // Pixelposition auf die auf dem Item geklickt wurde
    const positionClickedOnItem = ((verticalClickedPosition + this.parent.scrollDifference)
      - (this.parent.getRowByPosition(verticalClickedPosition) / this.parent.rowHeight * this.parent.rowHeight));

    // Differenz zur vertikalen Mitte des Items
    this.yDifference = ((this.parent.rowHeight / 2) - positionClickedOnItem);

    this.parent.showDeadline(item, false);
    this.parent.currentResizeItem.next(item);
    this.parent.dragStartedEvent.emit(item);
  }

  /**
   * Callback for drag.
   * @param item The plan item.
   * @param event The drag event.
   */
  onItemDrag(item: PlanItem, event: MouseEvent) {
    const preview = this.createItemPreview(item, event);
    this.parent.updatePreview(preview);

    if (this.isMouseOverUnplannedBox(event)) {
      this.parent.hidePreview.next(true);
      this.parent.unplanned.nativeElement.style.background = 'rgba(0, 255, 0, 0.1)';
    } else {
      this.parent.hidePreview.next(false);
      this.parent.unplanned.nativeElement.style.background = 'initial';
    }

    const deadlineDragItem = new PlanItem();
    deadlineDragItem.id = item.id;
    deadlineDragItem.currentColor = item.currentColor;
    deadlineDragItem.position = this.getItemPosition(item, event);
    deadlineDragItem.length = item.length;
    deadlineDragItem.deadline = item.deadline;

    this.parent.showDeadline(deadlineDragItem, false);
    this.parent.checkIfItemIsBeyondPlan(deadlineDragItem);
    this.parent.draggingEvent.emit(item);
  }

  /**
   * Callback for drag end.
   * @param item The plan item.
   * @param event The drag event.
   */
  onItemDragEnd(item: PlanItem, event: MouseEvent) {
    if (this.isLeftButtonMouseClick(event)) {
      if (this.isMouseOverUnplannedBox(event)) {
        this.parent.pushItemToUnplanned(item);
        this.parent.plannedToUnplannedEvent.emit(item);
      } else {
        item.position = this.getItemPosition(item, event);
      }
      item.visible = true;
      item.currentColor = this.isInDeadline(item) ? item.color : item.warnColor;

      this.parent.unplanned.nativeElement.style.background = 'initial';
      this.parent.dragEndEvent.emit(item);
      this.parent.checkIfItemIsBeyondPlan(item);
      this.parent.showDeadline(item, true);
      this.parent.hidePreview.next(true);
    }
  }

  getItemPosition(item: PlanItem, event: MouseEvent) {
    const position = this.planItemService.getItemDragPosition(item, event, this.parent, this.currentDragPosition, this.xDifference, this.yDifference);
    return {
      column: this.planItemService.getItemPositionFromPixelPosition(position.x, this.parent.gridStepSize),
      row: ((position.y + this.parent.scrollDifference) / this.parent.rowHeight)
    };
  }

  onMouseOver(item: PlanItem) {
    this.parent.onItemHover(item);
    this.parent.hoverEvent.emit(item);
  }

}
