import {Component} from '@angular/core';
import {Material} from '../../../../model/old/Material';
import {SelectionListDisplayAdapter} from '@byteways/ui-selection-list';
import {MaterialDomainObject} from '../../../../model/domain/material.domain-object';

@Component({
    selector: 'app-material-display',
    templateUrl: './material-display.component.html',
    styleUrls: ['./material-display.component.scss']
})
export class MaterialDisplayComponent extends SelectionListDisplayAdapter<MaterialDomainObject> {
    dataToString(value: MaterialDomainObject): string {
        return value.name;
    }
}

