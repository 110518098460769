import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {BusinessPartner} from '../../../model/old/BusinessPartner';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-accountancy-invoicing-select-customer',
    templateUrl: './accountancy-invoicing-select-customer.component.html',
    styleUrls: ['./accountancy-invoicing-select-customer.component.scss']
})
export class AccountancyInvoicingSelectCustomerComponent implements OnInit {

    form = new FormGroup({
        businessPartner: new FormControl(undefined, [Validators.required]),
        type: new FormControl('invoice', [Validators.required])
    });

    constructor(protected injector: Injector, protected dialog: MatDialog, public router: Router) {
    }

    getHeader(): string {
        if (this.form.value.type === 'invoice')
            return 'Rechnung';
        else
            return 'Eingangsgutschrift';
    }

    ngOnInit() {
    }

    businessPartnerToString(data: BusinessPartner) {
        return `${data.name} ${data.nameAdd} (Debitor: ${data.debitorNumber})`;
    }

    onBusinessPartnerSelect(businessPartner: BusinessPartner) {
        if (this.form.valid) {
            if (this.form.value.type === 'invoice') {
                this.router.navigateByUrl(`/accountancy/invoicing/new/${businessPartner.id}`);
            } else {
                this.router.navigateByUrl(`/accountancy/credits/incoming/new/${businessPartner.id}`);
            }
        }
    }

}
