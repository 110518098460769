import { EventEmitter, Input, Output, Directive } from '@angular/core';
import {SelectionListSearchOption} from './selection-list-search-option';

/**
 * Base class for display components. Extend this class and implement <code>dataToString</code>, so that the implementation component can
 * be used as a display component for the selection list.
 *
 * Also, don't forget to add 2 css classes to the component:
 * - hover: Will be added to the component when it is hovered
 * - selected: Will be added to the component when it is selected
 */
@Directive()
export abstract class SelectionListDisplayAdapter<T> {

  /**
   * The components value.
   */
  private _data: T;

  /**
   * Setter function for the components value.
   * @param value The new value;
   */
  @Input() set data(value: T) {
    this._data = value;
    const searchOption: SelectionListSearchOption<T> = {obj: this._data, searchString: this.dataToString(this._data)};
    this.searchStringCreated.emit(searchOption);
  }

  /**
   * Getter function for the components value.
   */
  get data(): T {
    return this._data;
  }

  /**
   * Output that is emitted every time the data input changes. The search string will be created using the
   * <code>dataToString</code> function.
   */
  @Output() searchStringCreated = new EventEmitter<SelectionListSearchOption<T>>();

  /**
   * Transform the components value to a string representation, which is used for full text search in the selection list component.
   * @param value The component value
   */
  abstract dataToString(value: T): string;

}
