import {Component, Inject} from '@angular/core';
import {MasterdataFormBuilder} from '../../../../services/masterdata-form-builder.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-dialog-manage-location',
    templateUrl: './dialog-manage-location.component.html',
    styleUrls: ['./dialog-manage-location.component.scss']
})
export class DialogManageLocationComponent {

    formGroup: FormGroup;

    countryTransformValue = (country) => {
        return country;
    };

    countryTransformWrite = (country, options) => {
        if (country) {
            return options.find(o => o.isoCode === country.isoCode);
        }
    };

    constructor(
        public dialogRef: MatDialogRef<DialogManageLocationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private masterdataFormBuilder: MasterdataFormBuilder
    ) {
        this.formGroup = this.masterdataFormBuilder.createLocationFormGroup(data);
    }

    save() {
        if (this.formGroup.valid) {
            this.dialogRef.close(this.formGroup.value);
        }
    }

}
