import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Invoice} from '../../../model/old/Invoice';
import {BusinessPartner} from '../../../model/old/BusinessPartner';
import {Location} from '@angular/common';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InvoiceItem} from '../../../model/old/InvoiceItem';
import {Account} from '../../../model/old/Account';
import {Tour} from '../../../model/old/Tour';
import {AddressDomainObject} from '../../../model/domain/address.domain-object';
import {BankAccount} from '../../../model/old/BankAccount';
import {PaymentTarget} from '../../../model/old/PaymentTarget';

@Component({
    selector: 'app-accountancy-invoicing-editor',
    templateUrl: './accountancy-invoicing-editor.component.html',
    styleUrls: ['./accountancy-invoicing-editor.component.scss']
})
export class AccountancyInvoicingEditorComponent implements OnInit {

    initForm = false;

    commissionType: string;

    _businessPartner: BusinessPartner;

    ngOnInit(): void {
        this.invoiceFormInvoiceItems.valueChanges.subscribe(invoiceItems => {
            this.commissionType = undefined;
            for (const item of invoiceItems) {
                if (item.tour) {
                    this.commissionType = item.tour.commissionType;
                }
            }
            this.commissionTypeOutput.emit(this.commissionType);
            if (!this.initForm) {
                setTimeout(() => this.recalculateAccounts());
            }
        });
    }

    get hasManualPositions(): boolean {
        const items = this.invoiceFormInvoiceItems.value;
        for (const item of items) {
            if (!item.tour) {
                return true;
            }
        }
        return false;
    }

    get formValid(): boolean {
        // TODO/FIXME: selbstgebauter validator

        const invoice = this.invoiceForm.value;

        // mindestens 1 invoice item
        if (invoice.invoiceItems.length === 0) {
            return false;
        }
        // invoice items mit tour müssen quantity und unit haben
        for (const item of invoice.invoiceItems) {
            if (item.tour) {
                if (!item.unit || item.unitCost === undefined || item.unitCost === null
                    || item.quantity === undefined || item.quantity === null) {
                    return false;
                }
            }
        }

        for (const item of invoice.invoiceItems) {
            const invoiceItem = item as InvoiceItem;
            const hasQuantity = invoiceItem.quantity !== null && invoiceItem.quantity !== undefined;
            const hasUnit = invoiceItem.unit !== null && invoiceItem.unit !== undefined;
            const hasUnitCost = invoiceItem.unitCost !== null && invoiceItem.unitCost !== undefined;
            const hasVat = invoiceItem.vat !== null && invoiceItem.vat !== undefined;
            if (hasQuantity && (!hasUnitCost || !hasUnit)) {
                return false;
            }
            if (hasQuantity && hasUnit && hasUnitCost && !hasVat) {
                return false;
            }
            if (hasUnit && (!hasUnitCost || !hasQuantity)) {
                return false;
            }
            if (hasUnitCost && (!hasUnit || !hasQuantity)) {
                return false;
            }
        }

        // mindestens 1 account
        if (invoice.accounts.length === 0) {
            return false;
        }
        // accounts dürfen nicht leer sein
        for (const account of invoice.accounts) {
            if (!account.accountId || (account.sum === null || account.sum === undefined)) {
                return false;
            }
        }

        return this.invoiceForm.valid;
    }

    @Input() set businessPartner(businessPartner: BusinessPartner) {
        this._businessPartner = businessPartner;
        if (businessPartner) {
            if (this.businessPartner.addresses.length === 1) {
                this.invoiceForm.patchValue({
                    invoiceAddress: this.businessPartner.addresses[0]
                });
            }
            if (this.businessPartner.bankAccounts.length === 1) {
                this.invoiceForm.patchValue({
                    invoiceBankAccount: this.businessPartner.bankAccounts[0]
                });
            }
        }
    }

    get businessPartner(): BusinessPartner {
        return this._businessPartner;
    }

    @Output() invoiceOutput: EventEmitter<Invoice> = new EventEmitter<Invoice>();

    @Output() commissionTypeOutput: EventEmitter<string> = new EventEmitter<string>();

    @Output() tourRemoved: EventEmitter<Tour> = new EventEmitter<Tour>();

    @Output() tourSelected: EventEmitter<Tour> = new EventEmitter<Tour>();

    invoiceForm = this.fb.group({
        id: this.fb.control(undefined),
        invoiceDate: this.fb.control(new Date(), [Validators.required]),
        bookingDate: this.fb.control(new Date(), [Validators.required]),
        invoiceAddress: this.fb.control(undefined, [Validators.required]),
        invoiceBankAccount: this.fb.control(undefined, [Validators.required]),
        accounts: this.fb.control([], [Validators.required]),
        paymentTargets: this.fb.control([], [Validators.required]),
        invoiceItems: this.fb.array([]),
        headerText: this.fb.control(''),
        footerText: this.fb.control(''),
        incomingInvoiceNumber: this.fb.control('')
    });

    get invoiceFormInvoiceItems(): FormArray {
        return this.invoiceForm.get('invoiceItems') as FormArray;
    }

    constructor(public location: Location, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    }

    onSubmit() {

        if (this.formValid) {
            const iin = this.invoiceForm.value.incomingInvoiceNumber;
            if (iin === '' || iin === undefined || iin === null) {
                if (!confirm('Rechnung ohne Eingangsrechnungsnummer erstellen?')) {
                    return;
                }
            }

            const invoice = Object.assign(
                new Invoice(undefined),
                this.invoiceForm.value,
                {
                    businessPartner: this.businessPartner,
                    type: 'INVOICE',
                }
            );
            this.invoiceOutput.emit(invoice);
        }
    }

    editInvoice(invoice: Invoice) {
        if (!invoice) {
            return;
        }

        setTimeout(() => {
            this.initForm = true;
            this.invoiceForm.reset();
            const invoiceItems = invoice.invoiceItems.map(item => Object.assign(new InvoiceItem(), item));
            for (const item of invoiceItems) {
                if (item.tour) {
                    this.commissionType = item.tour.commissionType;
                    this.commissionTypeOutput.emit(this.commissionType);
                    this.tourSelected.emit(item.tour);
                }
                this.invoiceFormInvoiceItems.push(this.createInvoiceItemControlForExistingItem(item));
            }
            this.invoiceForm.patchValue({
                id: invoice.id,
                accounts: invoice.accounts.map(acc => Object.assign(new Account(), acc)),
                bookingDate: new Date(invoice.bookingDate),
                footerText: invoice.footerText,
                headerText: invoice.headerText,
                incomingInvoiceNumber: invoice.incomingInvoiceNumber,
                invoiceAddress: Object.assign(new AddressDomainObject(), invoice.invoiceAddress),
                invoiceBankAccount: Object.assign(new BankAccount(), invoice.invoiceBankAccount),
                invoiceDate: new Date(invoice.invoiceDate),
                paymentTargets: invoice.paymentTargets.map(target => Object.assign(new PaymentTarget(), target))
            });
            setTimeout(() => this.initForm = false);
        }, 500);
    }

    recalculateAccounts() {
        const acc8401Handel = Object.assign(new Account(), {
            accountId: '8401',
            sum: (this.getTotalVK() - this.getTotalEK()).toFixed(2)
        });
        const acc8401Fracht = Object.assign(new Account(), {
            accountId: '8401',
            sum: this.getTotalVK().toFixed(2)
        });
        const acc8410Handel = Object.assign(new Account(), {
            accountId: '8410',
            sum: (this.getTotalEK()).toFixed(2)
        });
        const acc8410Franko = Object.assign(new Account(), {
            accountId: '8410',
            sum: this.getTotalVK().toFixed(2)
        });
        const acc1876 = Object.assign(new Account(), {
            accountId: '1876',
            sum: (this.getTotalVK() * 0.19).toFixed(2)
        });
        const accTotal = Object.assign(new Account(), {
            accountId: 'Gesamt',
            sum: (this.getTotalVK() * 1.19).toFixed(2)
        });
        switch (this.commissionType) {
            case 'HANDEL':
                this.invoiceForm.get('accounts').setValue([acc8410Handel, acc8401Handel, acc1876, accTotal]);
                break;
            case 'FRACHT':
                this.invoiceForm.get('accounts').setValue([acc8401Fracht, acc1876]);
                break;
            case 'FRANKO':
                this.invoiceForm.get('accounts').setValue([acc8410Franko, acc1876]);
                break;
            default:
                this.invoiceForm.get('accounts').setValue([accTotal]);
        }
        this.cdr.detectChanges();
    }

    getTotalVK() {
        return this.invoiceForm.value.invoiceItems
            .filter(i => i.unitCost !== undefined)
            .map((i) => {
                const quantity = i.quantity || 1;
                return i.unitCost * quantity;
            })
            .reduce((a, b) => a + b, 0);
    }

    getTotalEK() {
        return this.invoiceForm.value.invoiceItems
            .filter(i => i.tour && i.tour.priceEk !== undefined && i.tour.priceEk !== null)
            .map((i) => {
                const quantity = i.quantity || 1;
                if (i.tour.priceType.toLowerCase() === 'flat') {
                    return i.tour.priceEk;
                } else {
                    return i.tour.priceEk * quantity;
                }
            })
            .reduce((a, b) => a + b, 0);
    }

    swapInvoiceItems(indexA: number, indexB: number) {
        const val = Object.assign([], this.invoiceFormInvoiceItems.getRawValue());
        const itemA = val[indexA];
        val[indexA] = val[indexB];
        val[indexB] = itemA;
        this.invoiceFormInvoiceItems.setValue(val);
    }

    addTourToInvoice(tour: Tour) {
        setTimeout(() => {
            this.invoiceFormInvoiceItems.push(this.createInvoiceItemControl(tour));
            this.tourSelected.emit(tour);
        });
    }


    addManualPositionToInvoice() {
        setTimeout(() => this.invoiceFormInvoiceItems.push(this.createInvoiceItemControl()));
    }

    removeInvoiceItem(index: number) {
        const item = this.invoiceFormInvoiceItems.getRawValue()[index];
        this.invoiceFormInvoiceItems.removeAt(index);

        if (item.tour !== undefined && item.tour !== null) {
            this.tourRemoved.emit(item.tour);
        }
    }

    private createInvoiceItemControl(tour: Tour | null = null): FormGroup {
        let unitCost = null;
        if (tour !== null) {
            if (tour.schedule.customPriceVk !== null && tour.schedule.customPriceVk !== undefined) {
                unitCost = tour.schedule.customPriceVk;
            } else {
                unitCost = tour.priceVk;
            }
        }

        const unit = tour !== null ? 'Tonnen' : null;
        return this.fb.group({
            label: [null],
            unit: [unit],
            unitCost: [unitCost],
            quantity: [null],
            tour: [tour],
            vat: [null]
        });
    }

    private createInvoiceItemControlForExistingItem(item: InvoiceItem) {
        return this.fb.group({
            label: [item.label],
            unit: [item.unit],
            unitCost: [item.unitCost],
            quantity: [item.quantity],
            tour: [item.tour],
            vat: [null]
        });
    }

}
