import {CreateNewTourComponent} from './createnewtour/createnewtour.component';
import {CreateTourContainerComponent} from './createtourcontainer/createtourcontainer.component';
import {CreateTourFromPresetComponent} from './createtourfrompreset/createtourfrompreset.component';
import {DispoBookComponent} from './dispobook/dispobook.component';
import {DispoPlanComponent} from './dispoplan/dispoplan.component';
import {DispoBookContainerComponent} from './dispobookcontainer/dispobookcontainer.component';
import {FeaturesModule} from '../../features/features.module';
import {TourTableComponent} from './tourtable/tourtable.component';
import {DialogPlanTourComponent} from './dialogplantour/dialogplantour.component';
import {DialogDeleteTourComponent} from './dialogdeletetour/dialogdeletetour.component';
import {DialogDuplicateTourComponent} from './dialogduplicatetour/dialogduplicatetour.component';
import {DialogTourDetailsComponent} from './dialogtourdetails/dialogtourdetails.component';
import {DialogGeneratePresetComponent} from './dialoggeneratepreset/dialoggeneratepreset.component';
import {DialogEditTourComponent} from './dialogedittour/dialogedittour.component';
import {DispoPlanSubcontractorsComponent} from './dispoplansubcontractors/dispoplansubcontractors.component';
import {DispoPlanOwnCarriersComponent} from './dispoplanowncarriers/dispoplanowncarriers.component';
import {DialogSendTourComponent} from './dialogsendtour/dialogsendtour.component';
import {CreateMultiComponent} from './createmulti/createmulti.component';
import {CarrierrowComponent} from './dispoplanowncarriers/carrierrow/carrierrow.component';
import {DialogtouractionsComponent} from './dialogtouractions/dialogtouractions.component';
import {DialogShowCarrierCommissionComponent} from './dialog-show-carrier-commission/dialog-show-carrier-commission.component';
import { DialogCancelTourComponent } from './dialog-cancel-tour/dialog-cancel-tour.component';
import { PresetTableComponent } from './preset-table/preset-table.component';
import {AbbrevPipe} from '../../features/abbrev.pipe';
import { PresetDialogComponent } from './preset-dialog/preset-dialog.component';
import { DialogTourAddDowntimeComponent } from './dialog-tour-add-downtime/dialog-tour-add-downtime.component';
import { PresetDeleteComponent } from './preset-delete/preset-delete.component';
import { DialogReverseScheduleComponent } from './dialog-reverse-schedule/dialog-reverse-schedule.component';
import {DateFormatPipe} from '../../features/date-format.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BypassCoreModule} from '@byteways/bypass-core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AngularSplitModule} from 'angular-split';
import {ContextMenuModule} from 'ngx-contextmenu';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import { BypassIdenticonModule } from '@byteways/bypass-identicon';
import {BypassSearchableInputModule} from '@byteways/bypass-searchable-input';
import {MatDialogModule} from '@angular/material/dialog';
import {SharedModule} from '../../modules/shared/shared.module';
import {
    CreateNewTourPriceWidgetComponent
} from './createnewtour-price-widget/createnewtour-price-widget.component';
import { CreateTourFromPresetPriceWidgetComponent } from './createtourfrompreset-price-widget/createtourfrompreset-price-widget.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSortModule} from '@angular/material/sort';
import { DialogSetSubcontractorInvoiceNumberComponent } from './dialog-set-subcontractor-invoice-number/dialog-set-subcontractor-invoice-number.component';
import { DialogSendMailsComponent } from './dialog-send-mails/dialog-send-mails.component';
import {CreateTourFromPreset2Component} from './createtourfrompreset2/createtourfrompreset2.component';
import { PlanTourPriceWidgetPipe } from './dialogplantour/plan-tour-price-widget.pipe';
import {CreateNewTour2Component} from './createnewtour2/createnewtour.component';

@NgModule({
    imports: [
        CommonModule,
        BypassCoreModule,
        BypassSearchableInputModule,
        BypassIdenticonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FeaturesModule,
        AngularSplitModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatListModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatTabsModule,
        BrowserAnimationsModule,
        ContextMenuModule.forRoot(),
        MatSortModule,
        MatDialogModule,
        SharedModule
    ],
    declarations: [
        CreateNewTourComponent,
        CreateTourContainerComponent,
        CreateTourFromPresetComponent,
        DispoBookComponent,
        DispoPlanComponent,
        DispoBookContainerComponent,
        TourTableComponent,
        DialogPlanTourComponent,
        DialogDeleteTourComponent,
        DialogDuplicateTourComponent,
        DialogTourDetailsComponent,
        DialogGeneratePresetComponent,
        DialogEditTourComponent,
        DispoPlanSubcontractorsComponent,
        DispoPlanOwnCarriersComponent,
        DialogSendTourComponent,
        CreateMultiComponent,
        CarrierrowComponent,
        DialogtouractionsComponent,
        DialogShowCarrierCommissionComponent,
        DialogCancelTourComponent,
        PresetTableComponent,
        PresetDialogComponent,
        DialogTourAddDowntimeComponent,
        PresetDeleteComponent,
        DialogReverseScheduleComponent,
        CreateNewTourPriceWidgetComponent,
        CreateTourFromPresetPriceWidgetComponent,
        DialogSetSubcontractorInvoiceNumberComponent,
        DialogSendMailsComponent,
        PlanTourPriceWidgetPipe,
        CreateTourFromPreset2Component,
        CreateNewTour2Component
    ],
    exports: [
        CreateNewTourComponent,
        CreateTourContainerComponent,
        CreateTourFromPresetComponent,
        DispoBookComponent,
        DispoPlanComponent,
        DispoBookContainerComponent
    ],
    entryComponents: [
        DialogDeleteTourComponent,
        DialogPlanTourComponent,
        DialogDuplicateTourComponent,
        DialogTourDetailsComponent,
        DialogGeneratePresetComponent,
        DialogEditTourComponent,
        DialogSendTourComponent,
        DialogtouractionsComponent,
        DialogShowCarrierCommissionComponent,
        DialogCancelTourComponent,
        PresetDialogComponent,
        DialogTourAddDowntimeComponent,
        PresetDeleteComponent,
        DialogReverseScheduleComponent,
        CreateTourFromPreset2Component,
    ]
})
export class DispositionModule {
}
