import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BypassCoreModule} from '@byteways/bypass-core';
import {BypassDatatableModule} from '@byteways/bypass-datatable';
import {UiSelectionListModule} from '@byteways/ui-selection-list';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FeaturesModule} from '../../features/features.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragulaModule} from 'ng2-dragula';
import {AccountancyInvoicingContainerComponent} from './accountancy-invoicing-container/accountancy-invoicing-container.component';
import {AccountancyInvoicingSelectCustomerComponent} from './accountancy-invoicing-select-customer/accountancy-invoicing-select-customer.component';
import {AccountancyInvoicingNewInvoiceComponent} from './accountancy-invoicing-new-invoice/accountancy-invoicing-new-invoice.component';
import {AccountancyInvoicingEditorComponent} from './accountancy-invoicing-editor/accountancy-invoicing-editor.component';
import {AccountancyInvoiceBookComponent} from './accountancy-invoice-book/accountancy-invoice-book.component';
import {AccountancyInvoicingEditorDiscountsComponent} from './accountancy-invoicing-editor-discounts/accountancy-invoicing-editor-discounts.component';
import {AccountancyInvoiceTableComponent} from './accountancy-invoice-table/accountancy-invoice-table.component';
import {ContextMenuModule} from 'ngx-contextmenu';
import {AccountancyInvoicingNewInvoiceDialogComponent} from './accountancy-invoicing-new-invoice-dialog/accountancy-invoicing-new-invoice-dialog.component';
import {AccountancyInvoicingEditorPaymentTargetsComponent} from './accountancy-invoicing-editor-payment-targets/accountancy-invoicing-editor-payment-targets.component';
import {AccountancyInvoicingEditorAccountsComponent} from './accountancy-invoicing-editor-accounts/accountancy-invoicing-editor-accounts.component';
import {AccountancyDialogFinalizeInvoiceComponent} from './accountancy-dialog-finalize-invoice/accountancy-dialog-finalize-invoice.component';
import {AccountancyDialogDeleteInvoiceComponent} from './accountancy-dialog-delete-invoice/accountancy-dialog-delete-invoice.component';
import {AccountancyDialogEditInvoiceComponent} from './accountancy-dialog-edit-invoice/accountancy-dialog-edit-invoice.component';
import {AccountancyDialogShowInvoiceComponent} from './accountancy-dialog-show-invoice/accountancy-dialog-show-invoice.component';
import {AccountancyDialogCancelInvoiceComponent} from './accountancy-dialog-cancel-invoice/accountancy-dialog-cancel-invoice.component';
import {AccountancyDialogSendInvoiceComponent} from './accountancy-dialog-send-invoice/accountancy-dialog-send-invoice.component';
import {AccountancyTodosComponent} from './accountancy-todos/accountancy-todos.component';
import {AccountancyCreditsComponent} from './accountancy-credits/accountancy-credits.component';
import {AccountancyIncomingCreditComponent} from './accountancy-incoming-credit/accountancy-incoming-credit.component';
import {AccountancyOutgoingCreditComponent} from './accountancy-outgoing-credit/accountancy-outgoing-credit.component';
import {AccountancyInvoicingToursListComponent} from './accountancy-invoicing-tours-list/accountancy-invoicing-tours-list.component';
import {AccountancyInvoicingToursListItemComponent} from './accountancy-invoicing-tours-list-item/accountancy-invoicing-tours-list-item.component';
import {AccountancyInvoicingToursListItemTourDisplayComponent} from './accountancy-invoicing-tours-list-item-tour-display/accountancy-invoicing-tours-list-item-tour-display.component';
import {AccountancyInvoicingAddManualPositionComponent} from './accountancy-invoicing-add-manual-position/accountancy-invoicing-add-manual-position.component';
import {AccountancyExportComponent} from './accountancy-export/accountancy-export.component';
import {AccountancyReportComponent} from './accountancy-report/accountancy-report.component';
import {AccountancyReportDisplayComponent} from './accountancy-report-display/accountancy-report-display.component';
import {AccountancyInvoicingInvoiceItemComponent} from './accountancy-invoicing-invoice-item/accountancy-invoicing-invoice-item.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {AccountancyInvoicingEditInvoiceComponent} from './accountancy-invoicing-edit-invoice/accountancy-invoicing-edit-invoice.component';
import {AccountancyInvoicingEditorAddressComponent} from './accountancy-invoicing-editor-address/accountancy-invoicing-editor-address.component';
import {AccountancyInvoicingEditorBankaccountComponent} from './accountancy-invoicing-editor-bankaccount/accountancy-invoicing-editor-bankaccount.component';
import {AccountancyIncomingCreditTourDataComponent} from './accountancy-incoming-credit-tour-data/accountancy-incoming-credit-tour-data.component';
import {AccountancyInvoiceTable2Component} from './accountancy-invoice-table2/accountancy-invoice-table2.component';
import {AccountancyInvoiceTable2DateComponent} from './accountancy-invoice-table2/accountancy-invoice-table2-date/accountancy-invoice-table2-date.component';
import {AccountancyInvoiceTable2StatusComponent} from './accountancy-invoice-table2/accountancy-invoice-table2-status/accountancy-invoice-table2-status.component';
import {AccountancyInvoiceTable2BusinessPartnerComponent} from './accountancy-invoice-table2/accountancy-invoice-table2-business-partner/accountancy-invoice-table2-business-partner.component';
import {AccountancyInvoiceTable2HoverTextComponent} from './accountancy-invoice-table2/accountancy-invoice-table2-hover-text/accountancy-invoice-table2-hover-text.component';
import {AccountancyInvoiceTable2DateShortComponent} from './accountancy-invoice-table2/accountancy-invoice-table2-date-short/accountancy-invoice-table2-date-short.component';
import {AccountancyInvoiceTable2PriceComponent} from './accountancy-invoice-table2/accountancy-invoice-table2-price/accountancy-invoice-table2-price.component';
import {AccountancyTaxesComponent} from './accountancy-taxes/accountancy-taxes.component';
import {AccountancyTaxesTaxGroupsComponent} from './accountancy-taxes-tax-groups/accountancy-taxes-tax-groups.component';
import {AccountancyTaxesMaterialsComponent} from './accountancy-taxes-materials/accountancy-taxes-materials.component';
import {AccountancyTaxesTaxGroupDialogCreateComponent} from './accountancy-taxes-tax-group-dialog-create/accountancy-taxes-tax-group-dialog-create.component';
import {AccountancyTaxesCountrySelectorComponent} from './accountancy-taxes-country-selector/accountancy-taxes-country-selector.component';
import {AccountancyTaxesTaxGroupDialogDeleteComponent} from './accountancy-taxes-tax-group-dialog-delete/accountancy-taxes-tax-group-dialog-delete.component';
import {AccountancyTaxesTaxGroupDialogUpdateComponent} from './accountancy-taxes-tax-group-dialog-update/accountancy-taxes-tax-group-dialog-update.component';
import {AccountancyTaxesTaxGroupDialogDetailsComponent} from './accountancy-taxes-tax-group-dialog-details/accountancy-taxes-tax-group-dialog-details.component';
import {AccountancyTaxesTaxGroupFormComponent} from './accountancy-taxes-tax-group-form/accountancy-taxes-tax-group-form.component';
import {BypassSearchableInputModule} from '@byteways/bypass-searchable-input';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import {MaterialDisplayComponent} from './accountancy-taxes-material-selection-list/material-display/material-display.component';
import {AccountancyTaxesMaterialSelectionListComponent} from './accountancy-taxes-material-selection-list/accountancy-taxes-material-selection-list.component';
import {AccountancyInvoicingMaterialTaxInfoComponent} from './accountancy-invoicing-material-tax-info/accountancy-invoicing-material-tax-info.component';
import {AccountancyInvoicingInvoiceItemTaxInfoComponent} from './accountancy-invoicing-invoice-item-tax-info/accountancy-invoicing-invoice-item-tax-info.component';
import {AccountancyInvoicingNewIncomingCreditComponent} from './accountancy-invoicing-new-incoming-credit/accountancy-invoicing-new-incoming-credit.component';
import {AccountancyInvoicingEditIncomingCreditComponent} from './accountancy-invoicing-edit-incoming-credit/accountancy-invoicing-edit-incoming-credit.component';
import {MatRadioModule} from '@angular/material/radio';
import {AccountancyDialogCorrectInvoiceComponent} from './accountancy-dialog-correct-invoice/accountancy-dialog-correct-invoice.component';
import {AccountancyEditorModule} from '../../modules/accountancy-editor/accountancy-editor.module';
import {SharedModule} from '../../modules/shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';

@NgModule({
    imports: [
        CommonModule,
        BypassCoreModule,
        BypassDatatableModule,
        BypassSearchableInputModule,
        BypassIdenticonModule,
        FlexLayoutModule,
        FeaturesModule,
        RouterModule,
        FormsModule,
        ScrollingModule,
        ReactiveFormsModule,
        ContextMenuModule,
        UiSelectionListModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatListModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatCardModule,
        DragulaModule.forRoot(),
        MatSortModule,
        MatDialogModule,
        MatRadioModule,
        AccountancyEditorModule,
        SharedModule
    ],
    declarations: [
        AccountancyInvoicingContainerComponent,
        AccountancyInvoicingSelectCustomerComponent,
        AccountancyInvoicingNewInvoiceComponent,
        AccountancyInvoicingEditorComponent,
        AccountancyInvoicingEditorDiscountsComponent,
        AccountancyInvoicingNewInvoiceDialogComponent,
        AccountancyInvoiceTableComponent,
        AccountancyInvoiceBookComponent,
        AccountancyInvoicingEditorPaymentTargetsComponent,
        AccountancyInvoicingEditorAccountsComponent,
        AccountancyDialogFinalizeInvoiceComponent,
        AccountancyDialogDeleteInvoiceComponent,
        AccountancyDialogEditInvoiceComponent,
        AccountancyDialogShowInvoiceComponent,
        AccountancyDialogCancelInvoiceComponent,
        AccountancyDialogSendInvoiceComponent,
        AccountancyTodosComponent,
        AccountancyCreditsComponent,
        AccountancyIncomingCreditComponent,
        AccountancyOutgoingCreditComponent,
        AccountancyInvoicingToursListComponent,
        AccountancyInvoicingToursListItemComponent,
        AccountancyInvoicingToursListItemTourDisplayComponent,
        AccountancyInvoicingAddManualPositionComponent,
        AccountancyExportComponent,
        AccountancyReportComponent,
        AccountancyReportDisplayComponent,
        AccountancyInvoicingInvoiceItemComponent,
        AccountancyInvoicingEditInvoiceComponent,
        AccountancyInvoicingEditorAddressComponent,
        AccountancyInvoicingEditorBankaccountComponent,
        AccountancyIncomingCreditTourDataComponent,
        AccountancyInvoiceTable2Component,
        AccountancyInvoiceTable2DateComponent,
        AccountancyInvoiceTable2StatusComponent,
        AccountancyInvoiceTable2BusinessPartnerComponent,
        AccountancyInvoiceTable2HoverTextComponent,
        AccountancyInvoiceTable2DateShortComponent,
        AccountancyInvoiceTable2PriceComponent,
        AccountancyTaxesComponent,
        AccountancyTaxesTaxGroupsComponent,
        AccountancyTaxesMaterialsComponent,
        AccountancyTaxesTaxGroupDialogCreateComponent,
        AccountancyTaxesCountrySelectorComponent,
        AccountancyTaxesTaxGroupDialogDeleteComponent,
        AccountancyTaxesTaxGroupDialogUpdateComponent,
        AccountancyTaxesTaxGroupDialogDetailsComponent,
        AccountancyTaxesTaxGroupFormComponent,
        MaterialDisplayComponent,
        AccountancyTaxesMaterialSelectionListComponent,
        AccountancyInvoicingMaterialTaxInfoComponent,
        AccountancyInvoicingInvoiceItemTaxInfoComponent,
        AccountancyInvoicingInvoiceItemTaxInfoComponent,
        AccountancyInvoicingNewIncomingCreditComponent,
        AccountancyInvoicingEditIncomingCreditComponent,
        AccountancyDialogCorrectInvoiceComponent
    ],
    exports: [],
    entryComponents: [
        AccountancyInvoicingNewInvoiceDialogComponent,
        AccountancyDialogDeleteInvoiceComponent,
        AccountancyDialogEditInvoiceComponent,
        AccountancyDialogFinalizeInvoiceComponent,
        AccountancyDialogShowInvoiceComponent,
        AccountancyDialogCancelInvoiceComponent,
        AccountancyDialogSendInvoiceComponent,
        AccountancyInvoiceTable2DateComponent,
        AccountancyInvoiceTable2DateShortComponent,
        AccountancyInvoiceTable2BusinessPartnerComponent,
        AccountancyInvoiceTable2HoverTextComponent,
        AccountancyInvoiceTable2StatusComponent,
        AccountancyInvoiceTable2PriceComponent,
        AccountancyTaxesTaxGroupDialogCreateComponent,
        AccountancyTaxesTaxGroupDialogUpdateComponent,
        AccountancyTaxesTaxGroupDialogDeleteComponent,
        AccountancyTaxesTaxGroupDialogDetailsComponent,
        MaterialDisplayComponent,
        AccountancyDialogCorrectInvoiceComponent
    ]
})
export class AccountancyModule {
}
