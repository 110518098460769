import {Component, OnInit, ViewChild} from '@angular/core';
import {Preset} from '../../../model/old/Preset';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateRangeComponent} from '../../../features/daterange/daterange.component';
import {DateRange} from '../../../model/old/DateRange';
import {BypassSearchableInputComponent} from '@byteways/bypass-searchable-input';

@Component({
    selector: 'app-createmulti',
    templateUrl: './createmulti.component.html',
    styleUrls: ['./createmulti.component.scss']
})
export class CreateMultiComponent implements OnInit {

    @ViewChild('preset', {static: true}) presetInput: BypassSearchableInputComponent;
    @ViewChild('targetDate', {static: true}) targetDateInput: DateRangeComponent;

    createForm = new FormGroup({
        preset: new FormControl(undefined, [Validators.required]),
        quantity: new FormControl(undefined, [Validators.required]),
        targetDate: new FormControl(new DateRange(), [Validators.required]),
        comment: new FormControl(undefined)
    });

    constructor() {
    }

    ngOnInit() {
    }


    presetToString(data: Preset) {
        return data.name;
    }

}
