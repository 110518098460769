import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {DateRange} from '../../../../../model/old/DateRange';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';

export class TourDisplay {
    tourId: number;
    tourNumber: string;
    targetDate: DateRange;
    tourScheduleCarrierId: number;
    tourScheduleCarrierName: string;
    tourLoadingLocationId: number;
    tourLoadingLocationName: string;
    tourUnloadingLocationId: number;
    tourUnloadingLocationName: string;
    quantity: string;
    materialId: number;
    materialName: string;
    commissionType: string;
    priceType: string;
    priceEk: number;
    priceFr: number;
    priceVk: number;
    scheduleCustomPriceEk: number;
    scheduleCustomPriceFr: number;
    scheduleCustomPriceVk: number;
    comment: string;
    message: string;
    orderNumber: string;
}

export class InvoiceTourDisplayBundle extends AbstractBypassBundle {
    displayId: number;
    tourId: number;
    tourDisplay: TourDisplay;
}

@Component({
    selector: 'app-invoice-tour-display',
    templateUrl: './invoice-tour-display.component.html',
    styleUrls: ['./invoice-tour-display.component.scss']
})
export class InvoiceTourDisplayComponent extends BypassDataComponent<InvoiceTourDisplayBundle> {

    static displayCounter = 0;

    private displayId = InvoiceTourDisplayComponent.displayCounter++;
    _tourId: number;

    @Input() set tourId(tourId: number) {
        this._tourId = tourId;
        const bundle = Object.assign(new InvoiceTourDisplayBundle(), {
            displayId: this.displayId,
            tourId: this._tourId
        });
        this.bpSetup(bundle);
    }

    _tourDisplay: TourDisplay;
    @Input() set tourDisplay(tourDisplay: TourDisplay) {
        this._tourDisplay = tourDisplay;
    }

    get tourDisplay(): TourDisplay {
        return this._tourDisplay;
    }

    @Input() short = false;

    @Output() tourLoaded: EventEmitter<TourDisplay> = new EventEmitter<TourDisplay>();

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected bpFilterIncomingData(data: InvoiceTourDisplayBundle): boolean {
        return data.displayId === this.displayId;
    }

    protected bpOnMessageReceived(): void {
        if (this.data.tourDisplay) {
            this.tourDisplay = this.data.tourDisplay;
            this.tourLoaded.emit(this.tourDisplay);
        }
    }

    hasPrice() {
        const hasPrice = this.tourDisplay.priceVk !== null && this.tourDisplay.priceVk !== undefined;
        const hasSpecialPrice = this.tourDisplay.scheduleCustomPriceVk !== null && this.tourDisplay.scheduleCustomPriceVk !== undefined;
        return hasPrice || hasSpecialPrice;
    }

}
