import {Component, Input, OnInit} from '@angular/core';
import {Carrier} from '../../model/old/Carrier';

@Component({
    selector: 'app-carrier-display',
    templateUrl: './carrier-display.component.html',
    styleUrls: ['./carrier-display.component.scss']
})
export class CarrierDisplayComponent implements OnInit {

    @Input() carrier: Carrier;
    @Input() disableTooltip = false;

    constructor() {
    }

    ngOnInit() {
    }

    display(): string {
        return this.carrier.shortName;
    }

    tooltip(): string {
        return `${this.carrier.name} | ${this.carrier.own ? 'eigene Flotte' : 'Subunternehmer'}`;
    }

}
