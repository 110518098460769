import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ContactDomainObject} from '../../../../../../model/domain/contact.domain-object';

@Component({
  selector: 'app-dialog-confirm-contact-delete',
  templateUrl: './dialog-confirm-contact-delete.component.html',
  styleUrls: ['./dialog-confirm-contact-delete.component.scss']
})
export class DialogConfirmContactDeleteComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public contact: ContactDomainObject) {
    }

}
