<ng-template #rowTitleTemplateDefault let-row="row">
  <span class="row-title">{{row.title}}</span>
</ng-template>

<div fxLayout="row">

  <div fxLayout="column" class="wrapper">

    <ui-drag-drop-plan-timeline></ui-drag-drop-plan-timeline>

    <div>

      <ui-drag-drop-plan-deadline></ui-drag-drop-plan-deadline>

      <!-- plan container -->
      <div fxLayout="row">

        <div #planLeft fxLayout="row">

          <!-- row titles column  -->
          <div fxLayout="column">
            <div class="row" *ngFor="let row of rowTitles" [style.height]="rowHeight + 'px'">
              <ng-template
                [ngTemplateOutlet]="rowTitleTemplate || rowTitleTemplateDefault"
                [ngTemplateOutletContext]="{row: row}">
              </ng-template>
            </div>
          </div>

          <div class="beyondWrapper" fxLayout="column">

            <div class="beyond beyondStart mat-elevation-z2"
                 *ngFor="let row of rowTitles; let i = index"
                 [style.visibility]="findItemBeyondStartByRow(i) ? 'initial' : 'hidden'"
                 [style.background]="planItemService.planItemColorToRgbaString(findItemBeyondStartByRow(i)?.planItemCurrentColor, 0.5)"
                 [style.height]="rowHeight + 'px'">
              <span>{{(findItemBeyondStartByRow(i)?.stepsBeyond / 4) | numberToHoursMinutes}}</span>
            </div>

          </div>

        </div>

        <div #plan class="boundary">

          <ui-drag-drop-plan-resize></ui-drag-drop-plan-resize>

          <ui-drag-drop-plan-item *ngFor="let item of plannedItems" [item]="item"></ui-drag-drop-plan-item>

          <ui-drag-drop-preview [config]="previewConfig"></ui-drag-drop-preview>

          <!-- grid elements -->
          <div #grid class="grid-container">
            <div class="grid-item" *ngFor="let i of gridIndices" [style.height]="rowHeight + 'px'"></div>
          </div>

        </div>

        <div #planRight class="beyondWrapper" fxLayout="column">

          <div class="beyond beyondEnd mat-elevation-z2"
               *ngFor="let row of rowTitles; let i = index"
               [style.visibility]="findItemBeyondEndByRow(i) ? 'initial' : 'hidden'"
               [style.background]="planItemService.planItemColorToRgbaString(findItemBeyondEndByRow(i)?.planItemCurrentColor, 0.5)"
               [style.height]="rowHeight + 'px'">
            <span>{{(findItemBeyondEndByRow(i)?.stepsBeyond / 4) | numberToHoursMinutes}}</span>
          </div>

        </div>

      </div>

    </div>

  </div>

  <!-- list of unplanned items -->
  <div class="unplanned-wrapper mat-elevation-z4" #unplanned>
    <p *ngIf="unplannedItems.length > 0">Verfügbar</p>
    <p *ngIf="unplannedItems.length === 0">Keine Elemente verfügbar</p>
    <ui-drag-drop-plan-item-unplanned
      *ngFor="let item of unplannedItems"
      [item]="item">
    </ui-drag-drop-plan-item-unplanned>
  </div>

</div>
