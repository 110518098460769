import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AddressDomainObject} from '../../../model/domain/address.domain-object';
import {BusinessPartner} from '../../../model/old/BusinessPartner';

@Component({
    selector: 'app-accountancy-invoicing-editor-address',
    templateUrl: './accountancy-invoicing-editor-address.component.html',
    styleUrls: ['./accountancy-invoicing-editor-address.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AccountancyInvoicingEditorAddressComponent)
        }
    ]
})
export class AccountancyInvoicingEditorAddressComponent implements OnInit, ControlValueAccessor {

    @Input() set businessPartner(bp: BusinessPartner) {
        for (const address of bp.addresses) {
            this.addresses.push(address);
        }
    }

    addresses: AddressDomainObject[] = [];

    value: AddressDomainObject;

    onChange: (_: any) => {};

    constructor() {
    }

    ngOnInit() {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
        if (obj) {
            if (this.addresses.indexOf(obj) === -1) {
                this.addresses.push(obj);
            }
        }
        if (this.onChange) {
            this.onChange(this.value);
        }
    }


}
