import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

export class AccountancyDialogSendInvoiceBundle extends AbstractBypassBundle {
    invoiceId: number;
    emails?: string[];
    emailOptions?: {
        email: string;
        selectedByDefault: boolean;
    }[];
}

@Component({
    selector: 'app-accountancy-dialog-send-invoice',
    templateUrl: './accountancy-dialog-send-invoice.component.html',
    styleUrls: ['./accountancy-dialog-send-invoice.component.scss']
})
export class AccountancyDialogSendInvoiceComponent extends BypassFormComponent<AccountancyDialogSendInvoiceBundle> implements OnInit {

    form: FormGroup = new FormGroup({
        emails: new FormArray([this.createEmailFormControl()], [Validators.minLength(1)]),
        files: new FormArray([]),
    });

    submitted = false;

    get emailsFormArray(): FormArray {
        return this.form.get('emails') as FormArray;
    }

    get filesFormArray(): FormArray {
        return this.form.get('files') as FormArray;
    }

    constructor(
        protected injector: Injector, public dialog: MatDialog,
        public dialogRef: MatDialogRef<AccountancyDialogSendInvoiceComponent>,
        private readonly http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public input: any) {
        super(injector);
    }

    protected bpOnMessageReceived() {
        if (this.data.emailOptions) {
            const selectedEmailOptions = this.data.emailOptions.filter(e => e.selectedByDefault);
            if (selectedEmailOptions.length > 0) {
                this.emailsFormArray.clear();
                for (const option of selectedEmailOptions) {
                    const ctrl = this.createEmailFormControl();
                    ctrl.setValue(option.email);
                    this.emailsFormArray.push(ctrl);
                }
            }
        }
        // if (this.data.emailOptions && this.data.emailOptions?.length > 0) {
        //     this.form.patchValue({
        //         email: this.data.emailOptions[0].email
        //     });
        // }
    }

    fileChange(event, index: number) {
        const fileList: FileList = event.target.files;
        if (fileList.length === 0) {
            this.filesFormArray.at(index).setValue(null);
            return;
        } else if (fileList.length > 1) {
            throw new Error();
        }
        this.filesFormArray.at(index).setValue({
            file: fileList[0],
            name: fileList[0].name
        });
    }

    ngOnInit(): void {
        const bundle = new AccountancyDialogSendInvoiceBundle();
        bundle.invoiceId = this.input.invoice.id;
        this.bpSetup(bundle);
    }

    createEmailFormControl(): FormControl {
        return new FormControl(null, [Validators.required, Validators.email]);
    }

    submit() {
        if (this.submitted) {
            return;
        }
        this.submitted = true;
        const fd = new FormData();
        fd.append('invoiceId', this.input.invoice.id);
        for (const email of this.form.value.emails) {
            fd.append('emails', email);
        }
        for (const file of this.form.value.files.filter(f => f !== null)) {
            fd.append('files', file.file, file.name);
        }

        this.http.post('/api/send/invoice', fd, {
            reportProgress: true,
            observe: 'events'
        }).subscribe(
            response => {
                console.log('response', response);
                this.close();
            },
            error => {
                alert('Beim Versenden der Emails ist ein Fehler aufgetreten');
                console.error(error);
                this.close();
            }
        );
    }

    close() {
        this.dialogRef.close();
    }


    createFileFormControl(): FormControl {
        return new FormControl(undefined, []);
    }

    addFile() {
        this.filesFormArray.push(this.createFileFormControl());
    }

}
