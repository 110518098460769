import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResizingService {

  constructor() {
  }

  createStyleAttributes(event: any, rowHeight: number) {
    return {
      left: `${event.rectangle.left}px`,
      width: `${event.rectangle.width}px`,
      top: `${event.rectangle.top}px`,
      height: `${rowHeight}px`
    };
  }

}
