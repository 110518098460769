import {Pipe, PipeTransform} from '@angular/core';
import {TourStatistics} from '../model/tour-statistics.model';

@Pipe({
    name: 'totalTours'
})
export class TotalToursPipe implements PipeTransform {

    transform(value: TourStatistics): number {
        const sum = (a, b) => a + b;
        return value.carriers.map(c => c.calendarWeeks.map(cw => cw.tours.length).reduce(sum, 0)).reduce(sum, 0);
    }

}
