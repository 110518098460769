<div
  #resizable
  class="rectangle"
  (mouseover)="resizable.style.visibility = 'collapse'"
  [ngStyle]="resizeItemStyle"
  mwlResizable
  [enableGhostResize]="true"
  (resizing)="onItemResize($event)"
  (resizeEnd)="onItemResizeEnd()">

  <!-- left resize handle -->
  <div
    *ngIf="parent.currentResizeItem"
    class="resize-handle-left"
    mwlResizeHandle
    (mousedown)="grabLeft = true"
    [resizeEdges]="{ left: true }">
  </div>

  <!-- right resize handle -->
  <div
    *ngIf="parent.currentResizeItem"
    class="resize-handle-right"
    mwlResizeHandle
    (mousedown)="grabLeft = false"
    [resizeEdges]="{ right: true }">
  </div>

</div>
