import {Component} from '@angular/core';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';

@Component({
  selector: 'ui-form-field-string',
  templateUrl: '../ui-form-field-basic-input.html',
  styleUrls: ['../ui-form-field-basic-input.scss']
})
export class UiFormFieldStringComponent extends FormFieldDataComponent {
}
