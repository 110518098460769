import {EditorTypeValue} from './editor-type.value';

export enum EditorModeValue {
    NEW = 'NEW', EDIT = 'EDIT'
}

export class EditorModeValueParser {
    public static parse(value: string): EditorModeValue {
        switch (value.toUpperCase()) {
            case 'NEW':
                return EditorModeValue.NEW;
            case 'EDIT':
                return EditorModeValue.EDIT;
            default:
                console.error(`Could not parse value '${value}' (unknown editor mode)`);
                return null;
        }
    }
}
