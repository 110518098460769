import {NgModule} from '@angular/core';
import {DatatableComponent} from './datatable.component';
import {DatatableCellDirective} from './datatable-cell.directive';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {DatatableCellActionsComponent} from './datatable-cell-actions.component';
import {DatatableCellChipsComponent} from './datatable-cell-chips.component';
import {BypassCoreModule} from '@byteways/bypass-core';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';

@NgModule({
  declarations: [
    DatatableComponent,
    DatatableCellDirective,
    DatatableCellActionsComponent,
    DatatableCellChipsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BypassCoreModule,
    BypassIdenticonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    MatPaginatorModule,
    MatIconModule
  ],
  exports: [
    DatatableComponent,
    DatatableCellDirective,
    DatatableCellActionsComponent,
    DatatableCellChipsComponent
  ],
  entryComponents: [
    DatatableCellActionsComponent,
    DatatableCellChipsComponent
  ]
})
export class BypassDatatableModule {
}
