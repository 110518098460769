import {BankAccount} from './BankAccount';
import {Contact} from './Contact';
import {AddressDomainObject} from '../domain/address.domain-object';
import {Customer} from './Customer';
import {Carrier} from './Carrier';
import {Location} from './Location';
import {BusinessPartnerGroup} from './BusinessPartnerGroup';

export class BusinessPartner {
    id: number;
    name: string;
    nameAdd: string;
    shortName: string;
    addresses: AddressDomainObject[];
    bankAccounts: BankAccount[];
    contacts: Contact[];
    customer: Customer;
    carriers: Carrier[];
    locations: Location[];
    comment: string;
    creditorNumber: string;
    debitorNumber: string;
    businessPartnerGroup: BusinessPartnerGroup | null;
}
