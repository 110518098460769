import {finalize, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, Subscription, Subject} from 'rxjs';
import {AbstractBypassBundle, IBypassDataService, User} from './bypass.interfaces';
import {RxStompService, StompService, StompState} from '@stomp/ng2-stompjs';
import {Message} from '@stomp/stompjs';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {RxStompState} from '@stomp/rx-stomp';

@Injectable({
  providedIn: 'root'
})
export class BypassDataService implements IBypassDataService {

  // TODO Paremeter per config im hauptprojekt steuerbar machen
  // Websocket configuration paramete
  protected baseUrlApp = '/app'; // channel base url for sending messages to server
  protected baseUrlTopic = '/topic'; // channel base url for receiving broadcasts
  protected baseUrlQueue = '/user/queue'; // channel base url for receiving user specific messages
  protected loginSendUrl = `${this.baseUrlApp}/auth/login`;
  protected loginSendSessionUrl = `${this.baseUrlApp}/auth/login/session`;
  protected loginReceiveUrl = `${this.baseUrlQueue}/auth/login`;
  protected logoutSendUrl = `${this.baseUrlApp}/auth/logout`;
  protected fileUploadUrl = '/fileupload';

  // Websocket connection
  private websocketStateSubscription: Subscription;
  private websocketConnected = false;
  private websocketConnectListeners: Array<() => void> = [];
  private websocketDisconnectListeners: Array<() => void> = [];

  // HTTP authentication state
  private user: User = undefined;

  /**
   * Create a new data service and subscribe to websocket state changes
   * @param stompService
   * @param http
   * @param router
   */
  constructor(private stompService: RxStompService, private http: HttpClient, private router: Router) {
    this.subscribeToWebsocketStateChanges();
  }

  // =====================================================================================================================================
  // interface methods

  send<T>(bundle: AbstractBypassBundle, operator: string) {
    this.sendWebSocketMessage<T>(bundle, operator);
  }

  observe<T>(bundle: AbstractBypassBundle): Observable<T> {
    return this.observeWebSocket(bundle);
  }

  onConnect(callback: () => void) {
    this.websocketConnectListeners.push(callback);
  }

  onDisconnect(callback: () => void) {
    this.websocketDisconnectListeners.push(callback);
  }

  login(login: string, password: string, callback: (isAuthenticated) => void) {
    this.stompService.deactivate();
    this.doHttpLogin(login, password, callback);
  }

  sessionLogin(callback: (isAuthenticated: boolean) => void) {
    this.stompService.deactivate();
    this.doHttpLoginFromSession(callback);
  }

  logout(callback: () => void) {
    this.doHttpLogout(callback);
  }

  getUser(): string {
    if (this.isLoggedIn()) {
      return this.user.login;
    } else {
      return undefined;
    }
  }

  getUserId(): number {
    if (this.isLoggedIn()) {
      return this.user.id;
    } else {
      return undefined;
    }
  }

  hasSession(): boolean {
    return JSON.parse(localStorage.getItem('session') || 'false');
  }

  isLoggedIn(): boolean {
    return this.hasSession() && this.user !== undefined && this.user !== null;
  }

  hasRole(role: string): boolean {
    if (this.isLoggedIn()) {
      return this.user.roles.map(r => r.name).indexOf(role) > -1;
    } else {
      return false;
    }
  }

  getRoles(): string[] {
    if (this.isLoggedIn()) {
      return this.user.roles.map(role => role.name);
    } else {
      return [];
    }
  }

  isAdmin(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    } else {
      return this.getRoles().some(role => role === 'ADMIN');
    }
  }

  isSupervisor(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    } else {
      return this.getRoles().some(role => role === 'SUPERVISOR');
    }
  }

  // =====================================================================================================================================
  // websocket implementation

  /**
   * Call the onConnect and onDisconnect callbacks when the websocket state changes
   */
  private subscribeToWebsocketStateChanges() {
    this.websocketStateSubscription = this.stompService.connectionState$.subscribe((statusCode: number) => {
      const status = RxStompState[statusCode];
      console.log(`Websocket connection status: ${status}`);
      this.websocketConnected = (status === 'OPEN');
      if (this.websocketConnected) {
        this.callWebsocketConnectListeners();
      } else {
        this.callWebsocketDisconnectListeners();
      }
    });
  }

  private unsubscribeFromWebsocketStateChanges() {
    if (this.websocketStateSubscription !== undefined && this.websocketStateSubscription !== null) {
      this.websocketStateSubscription.unsubscribe();
    }
  }


  /**
   * Implementation of websocket communication. This method sends data to the backend via STOMP.
   * @param bundle data type
   * @param data actual data
   * @param operator operator for backend
   */
  private sendWebSocketMessage<T>(bundle: AbstractBypassBundle, operator: string) {
    const type = BypassDataService.bundleConstructorToType(bundle);
    const topic = `${this.baseUrlApp}/data/${type}/${operator}`;

    // copy data
    // TODO: probably not necessary
    const data = {};
    Object.keys(bundle).forEach(key => {
      data[key] = bundle[key];
    });
    this.stompService.publish({
      destination: topic,
      body: JSON.stringify(data)
    });
  }

  /**
   * Implementation of websocket communication. This method receives data via STOMP.
   * @param con data type
   * @returns observable
   */
  private observeWebSocket<T>(bundle: AbstractBypassBundle): Observable<T> {
    const type = BypassDataService.bundleConstructorToType(bundle);
    const topic = `${this.baseUrlQueue}/data/${type}`;

    const subscription = this.stompService
      .watch(topic)
      .pipe(
        map((message: Message) => {
          return JSON.parse(message.body) as T;
        })
      );

    // request data from backend by sending the "read" operator
    this.sendWebSocketMessage<T>(bundle, 'read');

    return subscription;
  }

  // =====================================================================================================================================
  // HTTP login

  private doHttpLogin(login: string, password: string, callback: (isAuthenticated: boolean) => void, fromSession = false) {
    const headers = new HttpHeaders({
      authorization: `Basic ${btoa(`${login}:${password}`)}`
    });

    this.sendHttpLoginRequest(headers, callback);
  }

  private doHttpLoginFromSession(callback: (isAuthenticated: boolean) => void) {
    const headers = new HttpHeaders({});
    // TODO: workaround für session login probleme
    this.sendHttpLoginRequest(headers, callback, false);
    this.sendHttpLoginRequest(headers, callback, true);
  }

  private doHttpLogout(callback: () => void) {
    this.http.post(BypassDataService.getHttpHost() + '/logout', {}).pipe(finalize(() => {
      localStorage.removeItem('session');
      if (callback) {
        callback();
      }
      this.reconnectStomp();
    })).subscribe();
  }

  private sendHttpLoginRequest(headers: HttpHeaders, callback: (isAuthenticated: boolean) => void, reconnectStomp: boolean = true) {
    this.user = undefined;
    localStorage.removeItem('session');
    this.http.get(BypassDataService.getHttpHost() + '/user', {headers: headers}).subscribe(
      (response?: User) => {
        let isAuthenticated = false;
        if (response != null) {
          isAuthenticated = !!response['login'];
        }
        if (isAuthenticated) {
          localStorage.setItem('session', 'true');
          this.user = response;

          if (reconnectStomp) {
            this.reconnectStomp();
          }
        }

        return callback && callback(isAuthenticated);
      },
      () => {
        this.user = undefined;
        localStorage.removeItem('session');
        return callback && callback(false);
      }
    );
  }

  // =====================================================================================================================================
  // file upload


  uploadFiles<T>(files: Array<File>): { [key: string]: { progress: Observable<number>, data: Observable<number> } } {
    // this will be the our resulting map
    const status = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', this.fileUploadUrl, formData, {
        reportProgress: true
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      const fileUploadResult = new Subject<{ resultCode: number, uploadedFile: T }>();
      this.http.request(req).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {

            // calculate the progress percentage
            const percentDone = Math.round(100 * event.loaded / event.total);

            // pass the percentage into the progress-stream
            progress.next(percentDone);
          } else if (event.type === HttpEventType.Response) {
            // Close the progress-stream if we get an answer form the API
            // The upload is complete
            fileUploadResult.next(
              {
                resultCode: 200,
                uploadedFile: event.body as T
              }
            );
            progress.complete();

          }
        },
        error => {
          switch (error.status) {
            case 409: {
              fileUploadResult.next(
                {
                  resultCode: 409,
                  uploadedFile: undefined
                }
              );
              break;
            }
            default: {
              fileUploadResult.next(
                {
                  resultCode: 500,
                  uploadedFile: undefined
                }
              );
              break;
            }
          }
          console.error('error: ' + error);
        }
      );

      // Save every progress-observable in a map of all observables
      // TODO das FileUploadResult auslagern und Fehlercodes einbauen
      status[file.name] = {
        progress: progress.asObservable(),
        fileUploadResult: fileUploadResult.asObservable()
      };
    });

    // return the map of progress.observables
    return status;
  }


  // =====================================================================================================================================
  // helpers

  /**
   * Get the type name from a bundle constructor via naming convention.
   * @param bundle data type
   * @returns name of type
   */
  private static bundleConstructorToType<T>(bundle: AbstractBypassBundle): string {
    // TODO test with minimized code
    return bundle.constructor.name.replace('Bundle', '');
  }

  /**
   * Calls all disconnect listeners.
   */
  private callWebsocketDisconnectListeners() {
    for (const listener of this.websocketDisconnectListeners) {
      listener();
    }
  }

  /**
   * Calls all connect listeners.
   */
  private callWebsocketConnectListeners() {
    for (const listener of this.websocketConnectListeners) {
      listener();
    }
  }

  private reconnectStomp() {
    // TODO when session is already authenticated
    // websocket data is sent after initial connect
    // sometimes this reconnect is too fast and causes an error
    // task: try to find out how to prevent reconnect when already authenticated
    // Check out websocket state before

    const ref = this;
    setTimeout(function () {
      ref.stompService.activate();
      // ref.stompService.initAndConnect();
    }, 750);
  }

  // =====================================================================================================================================
  // Development Helpers
  // TODO: workaround, bis vernünftige lokale Testumgebung steht

  public static getHttpHost(): string {
    if (BypassDataService.isLocalDeploy()) {
      return `http://localhost:8080`;
    }

    let port = location.port;
    let protocol = 'https';
    if (BypassDataService.isLocalDev()) {
      port = '8080';
      protocol = 'http';
    }

    return `${protocol}://${location.hostname}:${port}`;
  }

  public static getWebsocketHost(): string {
    if (BypassDataService.isLocalDeploy()) {
      return `ws://localhost:8080/socket`;
    }

    let port = location.port;
    let protocol = 'wss';
    if (BypassDataService.isLocalDev()) {
      port = '8080';
      protocol = 'ws';
    }

    return `${protocol}://${location.hostname}:${port}/socket`;
  }

  public static isLocalDeploy(): boolean { // TODO
    return location.hostname === 'localhost' && (location.port === '4200' || location.port === '8080');
  }

  public static isLocalDev(): boolean { // TODO
    return location.port === '4200';
  }

}
