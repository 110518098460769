import {Pipe, PipeTransform} from '@angular/core';
import {TourItem} from '../model/TourItem';

@Pipe({
    name: 'tourTooltip',
    pure: false
})
export class TourTooltipPipe implements PipeTransform {

    transform(tour: TourItem): string {
        let tooltip = `Tour #${tour.tour.tourNumber}\n\n${tour.tour.materialName}\nvon ${tour.tour.loadingLocationShortName}\nnach ${tour.tour.unloadingLocationShortName}`;
        if (!this.isTourPlannedInDeadline(tour)) {
            tooltip += '\n\nAnlieferdatum passt nicht zu Bestellung!';
        }
        return tooltip;
    }

    isTourPlannedInDeadline(item: TourItem): boolean {
        if (!item.deadline) {
            return true;
        }

        const itemEnd = item.x + item.cols;
        const deadlineStart = item.deadline.x;
        const deadlineEnd = deadlineStart + item.deadline.cols;
        return itemEnd >= deadlineStart && itemEnd <= deadlineEnd;
    }

}
