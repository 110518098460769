import {Component} from '@angular/core';
import {SelectionListDisplayAdapter} from '@byteways/ui-selection-list';
import {BusinessPartner} from '../../../../../model/old/BusinessPartner';

@Component({
    selector: 'app-business-partner-display',
    templateUrl: './business-partner-display.component.html',
    styleUrls: ['./business-partner-display.component.scss']
})
export class BusinessPartnerDisplayComponent extends SelectionListDisplayAdapter<BusinessPartner> {
    dataToString(value: BusinessPartner): string {
        return `${value.name} ${value.nameAdd} ${value.debitorNumber} ${value.creditorNumber} ${value.shortName}`;
    }
}
