import {AddressDomainObject} from '../domain/address.domain-object';

export class Location {
    id: number;
    name: string;
    shortName: string;
    address: AddressDomainObject;
    isLoadingLocation: boolean;
    isUnloadingLocation: boolean;
    comment: string;
}
