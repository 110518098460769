import {Component, Input, OnInit} from '@angular/core';
import {TourStatistics, TourStatisticsCarrier} from '../../model/tour-statistics.model';
import {Observable, pipe} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-dispo-stats-total-table',
    templateUrl: './dispo-stats-total-table.component.html',
    styleUrls: ['./dispo-stats-total-table.component.scss']
})
export class DispoStatsTotalTableComponent implements OnInit {

    _stats: TourStatistics | null = null;

    @Input() set stats(stats: TourStatistics | null) {
        this._stats = stats;
    }

    get stats(): TourStatistics | null {
        return this._stats;
    }

    // showColumns: string[] = ['carrierName', 'carrierOwn', 'carrierTours', 'carrierQuantity', 'carrierSum', 'carrierEuroPerTour', 'carrierEuroPerTon', 'carrierKm'];
    showColumns: string[] = ['carrierName', 'carrierOwn', 'carrierTours', 'carrierQuantity', 'carrierSum', 'carrierEuroPerTour', 'carrierEuroPerTon'];

    constructor() {
    }

    ngOnInit() {

    }

}
