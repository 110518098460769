import {AfterContentInit, Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {DragDropPlanComponent} from '../drag-drop-plan/drag-drop-plan.component';
import {PlanItem} from '../../model/model';
import {PlanItemService} from '../../services/plan-item.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {DragDropPlanConfig} from '../../model/DragDropPlanConfig';

@Component({
  selector: 'ui-drag-drop-plan-deadline',
  templateUrl: './drag-drop-plan-deadline.component.html',
  styleUrls: ['./drag-drop-plan-deadline.component.scss']
})
export class DragDropPlanDeadlineComponent implements AfterContentInit {

  @ViewChild('deadline') deadline: ElementRef;

  deadlineTimeout;

  constructor(@Inject(DragDropPlanComponent) public parent: DragDropPlanComponent, private planItemService: PlanItemService) {
  }

  ngAfterContentInit() {
    this.subscribeItem();
    this.subscribeHiding();
  }

  subscribeItem() {
    this.parent.deadlineItem.subscribe((item) => {
      if (item && item.deadline && this.deadline) {
        this.setDeadlineStart(item);
        this.setDeadlineWidth(item);
        const color = this.planItemService.isInDeadline(item) ?
          DragDropPlanConfig.DEADLINE_COLOR_VALID : DragDropPlanConfig.DEADLINE_COLOR_INVALID;
        this.setDeadlineBackgroundColor(color);
      }
    });
  }

  subscribeHiding() {
    this.parent.hideDeadline.subscribe((hide) => {
      if (hide && this.deadline) {
        this.hideDeadline();
      } else if (this.deadline) {
        this.showDeadline();
      }
    });
  }

  setDeadlineBackgroundColor(color: string) {
    this.deadline.nativeElement.style.background = color;
  }

  setDeadlineWidth(item: PlanItem) {
    if (!this.deadline) {
      return;
    }

    const deadline = item.deadline;
    if (deadline.end && deadline.start) {
      this.deadline.nativeElement.style.width = ((deadline.end - deadline.start) * this.parent.gridStepSize) + 'px';
    } else {
      this.deadline.nativeElement.style.width = '0px';
    }
  }

  setDeadlineStart(item: PlanItem) {
    const deadline = item.deadline;
    if (!deadline) {
      return;
    }
    this.deadline.nativeElement.style.left = ((deadline.start * this.parent.gridStepSize) + this.parent.plan.nativeElement.offsetLeft) + 'px';
  }

  hideDeadline() {
    this.deadline.nativeElement.style.visibility = 'hidden';
  }

  showDeadline() {
    if (this.deadlineTimeout) {
      clearTimeout(this.deadlineTimeout);
    }

    this.deadline.nativeElement.style.visibility = 'visible';

    if (this.parent.useTimeoutForDeadline) {
      this.deadlineTimeout = setTimeout(() => {
        this.hideDeadline();
      }, 2500);
    }
  }

  setDeadlineBackGround(color) {
    this.deadline.nativeElement.style.background = color;
  }

}
