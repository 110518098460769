import {Component} from '@angular/core';

@Component({
  selector: 'ui-loading-overlay-default',
  templateUrl: './ui-loading-overlay-default.component.html',
  styleUrls: ['./ui-loading-overlay-default.component.scss']
})
export class UiLoadingOverlayDefaultComponent {

  constructor() {
  }

  getSpinnerSize(el) {
    const width = el.offsetWidth;
    const height = el.offsetHeight;

    if (width > 300 && height > 300) {
      return 100;
    } else if (width < height) {
      return Math.floor(width / 3);
    } else {
      return Math.floor(height / 3);
    }
  }

}
