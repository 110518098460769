import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Tour} from '../../../model/old/Tour';
import {TourPriceAndQuantity} from '../accountancy-incoming-credit/accountancy-incoming-credit.component';

@Component({
    selector: 'app-accountancy-incoming-credit-tour-data',
    templateUrl: './accountancy-incoming-credit-tour-data.component.html',
    styleUrls: ['./accountancy-incoming-credit-tour-data.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AccountancyIncomingCreditTourDataComponent)
        }
    ]
})
export class AccountancyIncomingCreditTourDataComponent implements ControlValueAccessor {

    _tours: Tour[] = [];

    @Input() set tours(tours: Tour[]) {
        this._tours = tours;
        this.value = {};
        if (tours) {
            for (const tour of tours) {
                this.value[tour.id] = new TourPriceAndQuantity();
                if (tour.schedule.customPriceVk !== undefined && tour.schedule.customPriceVk !== null) {
                    this.value[tour.id].price = tour.schedule.customPriceEk;
                } else {
                    this.value[tour.id].price = tour.priceVk;
                }
            }
        }
    }

    get tours(): Tour[] {
        return this._tours;
    }

    value: { [id: number]: TourPriceAndQuantity } = {};

    onChange: (_: any) => {};

    constructor() {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onInputChange() {
        for (const tourId of Object.keys(this.value)) {
            if (this.value[tourId] === undefined || this.value[tourId] === null) {
                this.onChange(undefined);
                return;
            }
        }
        this.onChange(this.value);
    }


}
