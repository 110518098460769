import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BypassFormsModule} from '@byteways/bypass-forms';
import {AccountancyFormBuilder} from './services/accountancy-form-builder.service';
import {InvoiceFormComponent} from './components/forms/invoice-form/invoice-form.component';
import {InvoiceItemFormComponent} from './components/forms/invoice-item-form/invoice-item-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';
import {SharedModule} from '../shared/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {InvoiceEditorComponent} from './components/editor/invoice-editor/invoice-editor.component';
import {IncomingCreditEditorComponent} from './components/editor/incoming-credit-editor/incoming-credit-editor.component';
import {AccountancyEditorSelectComponent} from './components/editor/accountancy-editor-select/accountancy-editor-select.component';
import {AccountancyEditorContainerComponent} from './components/editor/accountancy-editor-container/accountancy-editor-container.component';
import {FeaturesModule} from '../../features/features.module';
import {BypassSearchableInputModule} from '@byteways/bypass-searchable-input';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {InvoiceItemControlsComponent} from './components/partials/invoice-item-controls/invoice-item-controls.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {InvoiceTourDisplayComponent} from './components/partials/invoice-tour-display/invoice-tour-display.component';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import {InvoiceTourSelectionComponent} from './components/partials/invoice-tour-selection/invoice-tour-selection.component';
import {InvoiceTourSelectionItemComponent} from './components/partials/invoice-tour-selection-item/invoice-tour-selection-item.component';
import {InvoiceItemTaxInfoComponent} from './components/partials/invoice-item-tax-info/invoice-item-tax-info.component';
import {InvoiceTourTaxInfoComponent} from './components/partials/invoice-tour-tax-info/invoice-tour-tax-info.component';
import {InvoiceSumTableComponent} from './components/partials/invoice-sum-table/invoice-sum-table.component';
import {IncomingCreditFormComponent} from './components/forms/incoming-credit-form/incoming-credit-form.component';
import {AccountsFormComponent} from './components/forms/accounts-form/accounts-form.component';
import {MatMenuModule} from '@angular/material/menu';


@NgModule({
    declarations: [
        InvoiceFormComponent,
        InvoiceItemFormComponent,
        InvoiceEditorComponent,
        IncomingCreditEditorComponent,
        AccountancyEditorSelectComponent,
        AccountancyEditorContainerComponent,
        InvoiceItemControlsComponent,
        InvoiceTourDisplayComponent,
        InvoiceTourSelectionComponent,
        InvoiceTourSelectionItemComponent,
        InvoiceItemTaxInfoComponent,
        InvoiceTourTaxInfoComponent,
        InvoiceSumTableComponent,
        IncomingCreditFormComponent,
        AccountsFormComponent,
    ],
    imports: [
        CommonModule,
        BypassFormsModule,
        ReactiveFormsModule,
        FlexModule,
        SharedModule,
        FormsModule,
        MatButtonModule,
        FeaturesModule,
        BypassSearchableInputModule,
        BypassIdenticonModule,
        MatRadioModule,
        MatDividerModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
        MatCheckboxModule,
        MatMenuModule
    ],
    exports: [
        InvoiceEditorComponent,
        IncomingCreditEditorComponent,
        AccountancyEditorContainerComponent,
        AccountancyEditorSelectComponent,
        AccountsFormComponent
    ],
    providers: [
        AccountancyFormBuilder
    ],
    entryComponents: []
})
export class AccountancyEditorModule {
}
