import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Tour} from '../../../model/old/Tour';

@Component({
    selector: 'app-accountancy-invoicing-tours-list-item-tour-display',
    templateUrl: './accountancy-invoicing-tours-list-item-tour-display.component.html',
    styleUrls: ['./accountancy-invoicing-tours-list-item-tour-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountancyInvoicingToursListItemTourDisplayComponent implements OnInit {

    @Input() tour: Tour;

    constructor() {
    }

    ngOnInit() {
    }

}
