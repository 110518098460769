import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euroPerTon'
})
export class EuroPerTonPipe implements PipeTransform {

    transform(euro: number, tons: number): number {
        if (tons === 0) {
            return 0;
        }
        return euro / tons;
    }

}
