import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


export class AccountancyTaxesTaxGroupDialogDeleteBundle extends AbstractBypassBundle {
    taxGroupId: number;
}

export class TaxGroupDeleteData {
    taxGroupId: number;
}

@Component({
    selector: 'app-accountancy-taxes-tax-group-dialog-delete',
    templateUrl: './accountancy-taxes-tax-group-dialog-delete.component.html',
    styleUrls: ['./accountancy-taxes-tax-group-dialog-delete.component.scss']
})
export class AccountancyTaxesTaxGroupDialogDeleteComponent extends BypassFormComponent<AccountancyTaxesTaxGroupDialogDeleteBundle> {

    constructor(
        protected injector: Injector,
        private dialogRef: MatDialogRef<AccountancyTaxesTaxGroupDialogDeleteComponent>,
        @Inject(MAT_DIALOG_DATA) private input: TaxGroupDeleteData
    ) {
        super(injector);
        this.bpSetup(new AccountancyTaxesTaxGroupDialogDeleteBundle());
    }

    close() {
        this.dialogRef.close();
    }

    delete() {
        this.data = Object.assign(new AccountancyTaxesTaxGroupDialogDeleteBundle(), {
            taxGroupId: this.input.taxGroupId
        });
        this.bpSubmit();
        this.close();
    }

}
