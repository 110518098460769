import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {MaterialDisplayComponent} from './material-display/material-display.component';
import {Material} from '../../../model/old/Material';
import {FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {BypassControlValueAccessor} from '../../../../../submodules/bypass-libraries/projects/byteways/bypass-forms/src/lib/bypass-control-value-accessor';
import {MaterialDomainObject} from '../../../model/domain/material.domain-object';


@Component({
    selector: 'app-accountancy-taxes-material-selection-list',
    templateUrl: './accountancy-taxes-material-selection-list.component.html',
    styleUrls: ['./accountancy-taxes-material-selection-list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AccountancyTaxesMaterialSelectionListComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => AccountancyTaxesMaterialSelectionListComponent)
        }
    ]
})
export class AccountancyTaxesMaterialSelectionListComponent extends BypassControlValueAccessor<number[]> implements OnInit {

    control = new FormControl([], [Validators.required]);

    @Input() materialOptions: MaterialDomainObject[] = [];

    materialComparator: (materialA: MaterialDomainObject, materialB: MaterialDomainObject) => number = ((materialA, materialB) => materialA.name < materialB.name ? -1 : 1);

    ngOnInit(): void {
        this.control.valueChanges.subscribe(value => {
            this.notifyChange(value);
        });
    }

    getMaterialDisplayComponent(): any {
        return MaterialDisplayComponent;
    }

    selectionListMapFunction = (items: Material[]) => {
        return items.map(item => item.id);
    };

    selectionListFilterFunction = (item: Material, state: number[]) => {
        return state.indexOf(item.id) !== -1;
    };

    writeValue(obj: number[] | undefined | null): void {
        if (!obj) {
            this.control.reset();
            return;
        }
        this.control.setValue(obj);
    }

}
