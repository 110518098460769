import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BypassAuthGuard} from './bypass-auth.guard';


/**
 * When this guard is active, only users with a role "ADMIN" can access the protected route. Beware! This can easily be manipulated, so
 * make sure you don't forget to validate the users role in the backend as well.
 */
@Injectable({
  providedIn: 'root'
})
export class BypassAdminGuard extends BypassAuthGuard implements CanActivate {

  constructor(router: Router, injector: Injector) {
    super(router, injector);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    return super.canActivate(route, state) && this.dataService.isAdmin();
  }

}
