import {Pipe, PipeTransform} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Pipe({
    name: 'planTourPriceWidget'
})
export class PlanTourPriceWidgetPipe implements PipeTransform {

    transform(priceForm: FormGroup, data): FormGroup {
        console.log("transform", priceForm.value, data);
        return new FormGroup({
            targetDate: new FormControl(data.dateRange),
            customer: new FormControl(data.customer),
            loadingLocation: new FormControl(data.loadingLocation),
            unloadingLocation: new FormControl(data.unloadingLocation),
            material: new FormControl(data.material),
            carrier: new FormControl(priceForm.value.carrier),
        });
    }

}
