import {Component, Injector, OnInit} from '@angular/core';
import {BypassBaseComponent} from '@byteways/bypass-core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent extends BypassBaseComponent implements OnInit {

    // flags toDate show form state
    submitted = false;
    successful = false;
    hasResponse = false;
    loggedIn = false;
    connected = false;

    // rememberMe = true;

    // definition of form and validators
    loginForm = new FormGroup({
        login: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    });

    callback = (isAuthenticated: boolean) => {
        this.hasResponse = true;
        this.successful = isAuthenticated;
        if (isAuthenticated) {
            this.router.navigate(['/']);
        } else if (this.connected) {
            this.loginForm.reset();
        }
    };

    constructor(protected injector: Injector, private router: Router) {
        super(injector);
    }

    ngOnInit() {
        // particlesJS.load('particles-js', 'assets/particles.json', null);

        // if (this.cookieService.get('RememberMe') !== null) {
        //     const login = this.cookieService.get('RememberMeLogin');
        //     const password = this.cookieService.get('RememberMePassword');
        //     this.loginForm.patchValue({
        //         login: login,
        //         password: password
        //     });
        // }

        this.dataService.onConnect(() => {
            this.connected = true;
        });
        this.dataService.onDisconnect(() => {
            this.connected = false;
        });
        this.loggedIn = this.dataService.isLoggedIn();
    }

    onSubmit() {
        // if form is valid
        if (this.loginForm.valid) {

            // if (this.rememberMe) {
            //     this.cookieService.put('RememberMe', 'true');
            //     this.cookieService.put('RememberMeLogin', this.loginForm.value.login);
            //     this.cookieService.put('RememberMePassword', this.loginForm.value.password);
            // } else {
            //     this.cookieService.remove('RememberMe');
            //     this.cookieService.remove('RememberMeLogin');
            //     this.cookieService.remove('RememberMePassword');
            // }

            this.submitted = true;
            this.hasResponse = false;

            this.dataService.login(
                this.loginForm.value.login,
                this.loginForm.value.password,
                this.callback
            );
        }
    }


}
