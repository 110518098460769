import {Component, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {Invoice} from '../../../model/old/Invoice';
import {ActivatedRoute} from '@angular/router';

export class AccountancyInvoicingEditInvoiceBundle extends AbstractBypassBundle {
    invoiceId: number;
    invoice: Invoice;
}

@Component({
    selector: 'app-accountancy-invoicing-edit-invoice',
    templateUrl: './accountancy-invoicing-edit-invoice.component.html',
    styleUrls: ['./accountancy-invoicing-edit-invoice.component.scss']
})
export class AccountancyInvoicingEditInvoiceComponent extends BypassFormComponent<AccountancyInvoicingEditInvoiceBundle> implements OnInit {

    constructor(protected injector: Injector, private route: ActivatedRoute) {
        super(injector);
    }

    invoice: Invoice|undefined = undefined;

    ngOnInit() {
        this.route.paramMap.subscribe(p => {
            const invoiceIdRaw = p.get('invoiceId');
            const invoiceIdParsed = parseInt(invoiceIdRaw, 10);

            const bundle = Object.assign(new AccountancyInvoicingEditInvoiceBundle(), {
                invoiceId: invoiceIdParsed
            });
            this.bpSetup(bundle);
            this.bpSetLoadingUntilMessageReceived();
        });
    }

    protected bpOnMessageReceived(): void {
        this.invoice = this.data.invoice;
    }

}
