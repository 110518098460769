import {Component, Injector, OnInit} from '@angular/core';
import {BypassDataComponent, BypassFormComponent} from '@byteways/bypass-core';
import {FormControl, FormGroup} from '@angular/forms';

export class BusinessPartnerToursCount {
    businessPartnerDisplayName: string;
    businessPartnerId: number;
    billableToursCount: number;
}

export class AccountancyTodosBundle {
    toursCount: BusinessPartnerToursCount[];
    useDateRange: boolean;
    fromDate: Date;
    toDate: Date;
}

@Component({
    selector: 'app-accountancy-todos',
    templateUrl: './accountancy-todos.component.html',
    styleUrls: ['./accountancy-todos.component.scss']

})
export class AccountancyTodosComponent extends BypassFormComponent<AccountancyTodosBundle> implements OnInit {

    dateForm = new FormGroup({
        useDateRange: new FormControl(false),
        fromDate: new FormControl(new Date()),
        toDate: new FormControl(new Date())
    });

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new AccountancyTodosBundle());
        this.loadData(null);
    }

    ngOnInit() {
        this.dateForm.valueChanges.subscribe(val => {
            this.loadData(val);
        });
    }

    loadData(formValue) {
        const bundle = new AccountancyTodosBundle();
        if (formValue) {
            bundle.useDateRange = formValue.useDateRange;
            bundle.fromDate = formValue.fromDate;
            bundle.toDate = formValue.toDate;
            bundle.fromDate.setHours(0, 0, 0, 0);
            bundle.toDate.setHours(23, 59, 59, 999);
        } else {
            bundle.useDateRange = false;
        }
        this.data = bundle;
        this.bpSubmit();
    }

}
