import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export class AccountancyDialogCancelInvoiceBundle extends AbstractBypassBundle {
    invoiceId: number;
    reason: string;
    bookingDate: Date;
}

@Component({
    selector: 'app-accountancy-dialog-cancel-invoice',
    templateUrl: './accountancy-dialog-cancel-invoice.component.html',
    styleUrls: ['./accountancy-dialog-cancel-invoice.component.scss']
})
export class AccountancyDialogCancelInvoiceComponent extends BypassFormComponent<AccountancyDialogCancelInvoiceBundle> implements OnInit {

    submitted = false;

    cancelInvoiceForm = new FormGroup({
        reason: new FormControl(undefined),
        bookingDate: new FormControl(Date(), [Validators.required])
    });

    constructor(
        protected injector: Injector,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AccountancyDialogCancelInvoiceComponent>,
        @Inject(MAT_DIALOG_DATA) public input: any) {
        super(injector);
    }

    ngOnInit(): void {
        this.bpSetup(new AccountancyDialogCancelInvoiceBundle());
    }

    submit() {
        if (!this.submitted && this.cancelInvoiceForm.valid) {
            this.submitted = true;
            this.data = Object.assign(new AccountancyDialogCancelInvoiceBundle(), {
                invoiceId: this.input.invoice.id,
                reason: this.cancelInvoiceForm.value.reason,
                bookingDate: this.cancelInvoiceForm.value.bookingDate
            });
            this.bpSubmit();
            this.close();
        }
    }

    close() {
        this.dialogRef.close();
    }

}
