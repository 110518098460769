import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventsTableComponent} from './events-table/events-table.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FeaturesModule} from '../../features/features.module';
import {DashboardContainerComponent} from './dashboard-container/dashboard-container.component';
import {RouterModule} from '@angular/router';
import {BypassCoreModule} from '@byteways/bypass-core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
    imports: [
        CommonModule,
        BypassCoreModule,
        FlexLayoutModule,
        FeaturesModule,
        MatPaginatorModule,
        RouterModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatListModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatSortModule,
    ],
    declarations: [
        DashboardContainerComponent,
        EventsTableComponent,
    ],
    exports: [
        DashboardContainerComponent
    ]
})
export class DashboardModule {
}
