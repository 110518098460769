import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TourDisplay} from '../invoice-tour-display/invoice-tour-display.component';

@Component({
    selector: 'app-invoice-tour-selection-item',
    templateUrl: './invoice-tour-selection-item.component.html',
    styleUrls: ['./invoice-tour-selection-item.component.scss']
})
export class InvoiceTourSelectionItemComponent implements OnInit {

    @Input() disabled = false;
    @Input() tourDisplay: TourDisplay;
    @Output() tourClicked = new EventEmitter<TourDisplay>();

    constructor() {
    }

    ngOnInit() {
    }

    hasPrice() {
        return this.tourDisplay.priceVk !== null && this.tourDisplay.priceVk !== undefined;
    }

}
