import {Component, Input, OnInit} from '@angular/core';
import {BusinessPartner} from '../../model/old/BusinessPartner';

@Component({
  selector: 'app-business-partner-display',
  templateUrl: './business-partner-display.component.html',
  styleUrls: ['./business-partner-display.component.scss']
})
export class BusinessPartnerDisplayComponent implements OnInit {

    @Input() businessPartner: BusinessPartner;

    constructor() {
    }

    ngOnInit() {
    }

    businessPartnerDisplay(): string {
        return this.businessPartner.shortName;
    }

    businessPartnerTooltip(): string {
        return `${this.businessPartner.name} ${this.businessPartner.nameAdd}`;
    }

}

