import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {DomSanitizer} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export class DialogShowCarrierCommissionBundle extends AbstractBypassBundle {
    tourId: number;
    file: string;
}

@Component({
    selector: 'app-dialog-show-carrier-commission',
    templateUrl: './dialog-show-carrier-commission.component.html',
    styleUrls: ['./dialog-show-carrier-commission.component.scss']
})
export class DialogShowCarrierCommissionComponent extends BypassFormComponent<DialogShowCarrierCommissionBundle> implements OnInit {

    isLoading = true;
    successful = false;
    unsuccessfulTries = 0;
    maxTries = 5;

    constructor(protected injector: Injector,
                public dialog: MatDialog,
                public dialogRef: MatDialogRef<DialogShowCarrierCommissionComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any,
                public sanitizer: DomSanitizer
    ) {
        super(injector);
        this.bpSetup(new DialogShowCarrierCommissionBundle());
    }

    ngOnInit() {
        this.tryRead();
    }

    tryRead() {
        this.data.tourId = this.input.tourId;
        this.bpSendOperator('read');
    }

    bpOnMessageReceived() {
        // on incoming data:
        if (this.data.file !== undefined && this.data.file !== null) {
            // is present? stop loading
            this.isLoading = false;
            this.successful = true;
        } else {
            // otherwise retry
            this.unsuccessfulTries++;
            if (this.unsuccessfulTries <= this.maxTries) {
                setTimeout(() => {
                    this.tryRead();
                }, 2000);
            } else {
                this.isLoading = false;
                this.successful = false;
            }
        }
    }

    refresh() {
        this.isLoading = true;
        this.successful = false;
        this.unsuccessfulTries = 0;
        this.tryRead();
    }

    close() {
        this.dialogRef.close();
    }

    getFileContent() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64 ${encodeURIComponent(this.data.file)}`);
    }


}
