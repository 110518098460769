import {Component, Input, TemplateRef} from '@angular/core';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';

@Component({
  selector: 'ui-form-field-autocomplete',
  templateUrl: './ui-form-field-autocomplete.component.html',
  styleUrls: ['./ui-form-field-autocomplete.component.scss']
})
export class UiFormFieldAutoCompleteComponent extends FormFieldDataComponent {
  @Input() options: any[];
  @Input() template: TemplateRef<any>;
}
