import {Component, Input, OnInit} from '@angular/core';
import {Tour} from '../../model/old/Tour';

@Component({
    selector: 'app-tour-display',
    templateUrl: './tour-display.component.html',
    styleUrls: ['./tour-display.component.scss']
})
export class TourDisplayComponent implements OnInit {

    @Input() tour: Tour;
    @Input() hideCustomer: false;

    constructor() {
    }

    ngOnInit() {
    }

}
