import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {BypassDataService} from '../bypass-data.service';
import {Observable, defer} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';


/**
 * When this guard is active, only logged in users can activate the protected route.
 */
@Injectable({
  providedIn: 'root'
})
export class BypassAuthGuard implements CanActivate {

  /**
   * Injected data service
   */
  protected dataService: BypassDataService = this.injector.get(BypassDataService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    // not logged in: redirect to login

    if (!this.dataService.isLoggedIn()) {
      return defer(() => this.trySessionLogin()
        .then((isAuthenticated: boolean) => {
          if (isAuthenticated) {
            if (this.checkRoles(route)) {
              return this.reject();
            }
            return true;
          }
          this.reject();
        })
      );
    } else {
      if (this.checkRoles(route)) {
        return of(false);
      } else {
        return of(true);
      }
    }

  }

  private checkRoles(route: ActivatedRouteSnapshot): boolean {
    const requiredRoles = route.data['roles'] as Array<string>;
    return requiredRoles !== undefined
      && requiredRoles.length > 0
      && !requiredRoles.some(role => this.dataService.hasRole(role));
  }

  private trySessionLogin(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dataService.sessionLogin((isAuthenticated: boolean) => {
        resolve(isAuthenticated);
      });
    });
  }

  /**
   * Helper function that will be called by the "canActivate" function.
   * Navigates the user to the login page (by convention). Then it returns false.
   * @return false
   */
  private reject(): boolean {
    this.router.navigate(['login']);
    return false;
  }

  /**
   * Constructor
   */
  constructor(public router: Router, private injector: Injector) {

  }

}
