import {Component, Input, OnInit} from '@angular/core';
import {AccountancyReport} from '../../../model/old/AccountancyReport';

@Component({
    selector: 'app-accountancy-report-display',
    templateUrl: './accountancy-report-display.component.html',
    styleUrls: ['./accountancy-report-display.component.scss']
})
export class AccountancyReportDisplayComponent implements OnInit {

    @Input() report: AccountancyReport;

    constructor() {
    }

    ngOnInit() {
    }

    getAccountKeys(accounts: Map<string, number>): string[] {
        return Object.keys(accounts);
    }

    getAccountSum(accounts: Map<string, number>): number {
        const keys = this.getAccountKeys(accounts);
        let sum = 0.0;
        for (let i = 0; i < keys.length; i++) {
            sum += accounts[keys[i]];
        }
        return sum;
    }

}
