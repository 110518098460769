import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {BankAccountDomainObject} from '../../../../../model/domain/bank-account.domain-object';
import {MatDialog} from '@angular/material/dialog';
import {DialogManageBankAccountComponent} from './dialog-manage-bank-account/dialog-manage-bank-account.component';
import {DialogConfirmAccountancyBankAccountDeleteComponent} from './dialog-confirm-accountancy-bank-account-delete/dialog-confirm-accountancy-bank-account-delete.component';

export class MasterdataAccountancyEditorBankAccountsBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    bankAccounts: BankAccountDomainObject[];
}

@Component({
    selector: 'app-masterdata-accountancy-editor-bank-accounts',
    templateUrl: './masterdata-accountancy-editor-bank-accounts.component.html',
    styleUrls: ['./masterdata-accountancy-editor-bank-accounts.component.scss']
})
export class MasterdataAccountancyEditorBankAccountsComponent extends BypassFormComponent<MasterdataAccountancyEditorBankAccountsBundle> {

    tableColumns = ['icon', 'bank', 'ustId', 'ktNr', 'iban', 'bic', 'blz', 'actions'];

    @Output() saved = new EventEmitter();

    businessPartnerId: number;

    bankAccounts = [];

    @Input() set businessPartner(bp: BusinessPartnerDomainObject) {
        this.businessPartnerId = bp.id;
        if (this.businessPartnerId) {
            const bundle = Object.assign(new MasterdataAccountancyEditorBankAccountsBundle(), {
                businessPartnerId: this.businessPartnerId
            });
            this.bpSetup(bundle);
        }
    }

    constructor(private dialog: MatDialog, protected injector: Injector) {
        super(injector);
        this.disableAutoLoadingOverlay();
    }

    protected bpOnMessageReceived(): void {
        console.log('Data', this.data);
        this.bankAccounts = this.data.bankAccounts;
    }

    addBankAccount() {
        this.dialog.open(DialogManageBankAccountComponent, {
            minWidth: '50%'
        }).afterClosed().subscribe(location => {
            if (location) {
                this.bankAccounts.push(location);
                this.submit();
            }
        });
    }

    editBankAccount(index) {
        this.dialog.open(DialogManageBankAccountComponent, {
            data: this.bankAccounts[index],
            minWidth: '50%'
        }).afterClosed().subscribe(editedLocation => {
            if (editedLocation) {
                this.bankAccounts[index] = editedLocation;
                this.submit();
            }
        });
    }

    deleteBankAccount(index: number) {
        this.dialog.open(DialogConfirmAccountancyBankAccountDeleteComponent, {
            data: this.bankAccounts[index]
        }).afterClosed().subscribe((response: boolean) => {
            if (response) {
                this.bankAccounts.splice(index, 1);
                this.submit();
            }
        });
    }

    private submit() {
        this.data = Object.assign(new MasterdataAccountancyEditorBankAccountsBundle(), {
            businessPartnerId: this.businessPartnerId,
            bankAccounts: this.bankAccounts
        });
        this.bpSubmit();
        this.saved.emit();
    }

}
