import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';

export class BypassSearchableInputBundle extends AbstractBypassBundle {
  entityType: string; // name of entity class, e.g. "Customer"
  suggestions: any[]; // array to hold suggestions from search server
  input: string; // text input
  inputId: number; // unique ID of this input component
  filter: {}; // dictionary for server-side filtering of result list. eg.: { own: true } filters for values with attribute "own" = true
}

// noinspection SpellCheckingInspection
/**
 *
 */
@Component({
  selector: 'lib-bypass-searchableinput',
  templateUrl: 'bypass-searchable-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BypassSearchableInputComponent),
      multi: true
    }
  ]
})
export class BypassSearchableInputComponent extends BypassDataComponent<BypassSearchableInputBundle>
  implements OnInit, ControlValueAccessor {

  private static currentInputId = 0;

  // element inputs
  @Input() entityType: string;
  @Input() entityDisplayName = '';
  @Input() required = false;
  @Input() entityToStringFn: (a: any) => string;
  @Input() entityCreateFn: (s: string) => void;
  @Input() entityCreateComponent: any;
  @Input() enableEntityCreation = true;
  @Input() showIdenticon = true;
  @Input() filter = {}; // Dictionary mit Param => Wert, zB. für Spediteur: {own: true}, damit nur eigene Spediteure angezeigt werden.

  // internal state
  inputId: number;
  inputModel: string;
  value: any;
  placeholder = this.entityDisplayName;
  suggestionSelected = false;
  inputSendTimer = undefined;
  inputSendDelay = 300;
  private propagateChange = (_: any) => {
    // tslint:disable-next-line:semicolon
  };

  // =====================================================================================================================================

  constructor(protected injector: Injector, public dialog: MatDialog) {
    super(injector);
    this.inputId = BypassSearchableInputComponent.currentInputId++;
    this.disableAutoLoadingOverlay();
    this.bpSetup(new BypassSearchableInputBundle());

    if (this.entityToStringFn === undefined) {
      this.entityToStringFn = (entity: any) => JSON.stringify(entity);
    }
    if (this.entityCreateFn === undefined && this.entityCreateComponent !== undefined) {
      this.entityCreateFn = () => {
        this.dialog.open(this.entityCreateComponent, {width: '1000px'});
      };
    }
  }

  // =====================================================================================================================================
  // Control Value Accessor

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
      this.data = Object.assign(obj, new BypassSearchableInputBundle());
      this.inputModel = this.entityToStringFn(this.data);
      this.propagateChange(this.value);
    }
  }

  // =====================================================================================================================================
  // Component Lifecycle

  ngOnInit(): void {
    this.placeholder = this.entityDisplayName;
  }

  // =====================================================================================================================================
  // Bypass Lifecycle

  bpFilterIncomingData(data: BypassSearchableInputBundle) {
    return data.inputId === this.inputId;
  }

  // =====================================================================================================================================
  // Component Logic

  onType(keyCode) {
    if (keyCode >= 37 && keyCode <= 40) {
      // arrow keys
      return;
    } else if (keyCode === 13) {
      // return key
      return;
    } else if (keyCode === 27) {
      // escape key
      return;
    }
    this.suggestionSelected = false;
    if (this.inputSendTimer !== undefined) {
      clearTimeout(this.inputSendTimer);
    }
    this.inputSendTimer = setTimeout(() => {
      this.onChange();
    }, this.inputSendDelay);
  }

  onChange() {
    if (!this.suggestionSelected) {
      this.getAutoCompleteOptions();
    }
  }

  getAutoCompleteOptions() {
    // Reset current value
    this.value = undefined;
    this.propagateChange(this.value);
    // Get new suggestions
    this.data = Object.assign(new BypassSearchableInputBundle(), {
      inputId: this.inputId,
      input: this.inputModel,
      suggestions: [],
      entityType: this.entityType,
      filter: this.filter
    });
    this.bpSendOperator('autocomplete');
  }

  selectOption(selected: any) {
    // Suggestion was clicked
    this.suggestionSelected = true;
    this.value = selected;
    this.inputModel = this.entityToStringFn(this.value);
    this.propagateChange(this.value);
  }

  public reset() {
    this.value = undefined;
    this.propagateChange(this.value);
    this.inputModel = '';
    this.suggestionSelected = false;
    this.data = Object.assign(new BypassSearchableInputBundle(), {
      inputId: this.inputId,
      input: this.inputModel,
      suggestions: [],
      entityType: this.entityType,
      filter: this.filter
    });
  }

  getValueLength(value): number {
    if (value === undefined || value === null) {
      return null;
    }

    return value.length;
  }

}
