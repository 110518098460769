import {Pipe, PipeTransform} from '@angular/core';
import {BankAccountDomainObject} from '../../../model/domain/bank-account.domain-object';

@Pipe({
    name: 'bankAccountTitle'
})
export class BankAccountTitlePipe implements PipeTransform {


    transform(value: BankAccountDomainObject, index: number, ...args: any[]): any {
        if (value.ustId !== null) {
            return `Ust.Id. ${value.ustId}`;
        }
        if (value.iban !== null && value.bic !== null) {
            return `IBAN ${value.iban} | BIC ${value.bic}`;
        }
        return `Bankverbindung ${index + 1}`;
    }

}
