import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BypassControlValueAccessor} from '@byteways/bypass-forms';

@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputNumberComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => InputNumberComponent)
        }
    ]
})
export class InputNumberComponent extends BypassControlValueAccessor<number> {

    @Input() placeholder = '';
    @Input() required = false;
    @Input() disableInput = false;
    @Input() min = undefined;
    @Input() max = undefined;

    numberModel: number | null = null;

    writeValue(obj: number | undefined | null): void {
        this.numberModel = obj || null;
    }

}
