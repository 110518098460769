import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'ui-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectionListComponent),
      multi: true
    }
  ]
})
/**
 * TODO: was ist selection list, wie benutzt man diese klasse
 */
export class SelectionListComponent<ItemType, FormControlStateType> implements ControlValueAccessor {

  @Input() leftSideTitle: string;
  @Input() rightSideTitle: string;

  /**
   * Alle Items, die in den Listen zu finden sein sollen
   */
  _allItems: ItemType[] = [];

  @Input() set allItems(value: ItemType[]) {
    this._allItems = value;
    this.splitItems();
  }

  get allItems(): ItemType[] {
    return this._allItems;
  }

  /**
   * @FormControlState = Objekte, welche initial selected sein sollen. Diese weden durch den ControlValueAccessor,
   * aus dem Wrapper, in die writeValue Methode geschrieben.
   *
   * Iteriert durch die *FormControlState Objekte und rendert diese, falls der filter auf das Objekt zutrifft,
   * auf die Seite rechte Seite
   */
  @Input() selectedItemsFilterFunction: (item: ItemType, formControlState: FormControlStateType) => boolean;

  /**
   * This function will transform the selected items each time this components value is queried (for example, when this component is used
   * as a control value accessor in a reactive angular form.
   * For example, a map function (items: Material[]) => items.map(i => i.id) would return an array of the selected items ids as the
   * current value of this control value accessor. This is also an example for the most common way to use this map function, which is to
   * map a list of entities to only a list of ids.
   */
  @Input() selectedItemsMapFunction: (items: ItemType[]) => any;

  @Input() itemsSortComparator: (itemA: ItemType, itemB: ItemType) => number = ((itemA, itemB) => 0);

  /**
   * Component, der die Daten in einem List Item anzeigt
   */
  @Input() dataShowComponent: Component;

  leftSideItems: ItemType[] = [];
  rightSideItems: ItemType[] = [];

  /**
   * Elemente die ausgewählt, jedoch noch nicht durch die Buttons in die andere Liste eingetragen wurden.
   */
  selectedFromLeft: ItemType[] = [];
  selectedFromRight: ItemType[] = [];

  /**
   * Array of onChangeListener functions. These will be called after each value change with the new value as an argument.
   */
  private onChangeListeners: ((items: ItemType[]) => {})[] = [];

  /**
   * Array of onTouchedListener functions. These will be called whenever the control is touched by the user (eg. the first ui event that
   * triggers some sort of change inside this component.
   */
  private onTouchedListeners: (() => {})[] = [];

  private formControlState: FormControlStateType | undefined | null = null;

  constructor() {
  }

  leftSideItemClicked(item: ItemType) {
    const index = this.selectedFromLeft.indexOf(item);
    if (index === -1) {
      this.selectedFromLeft.push(item);
    } else {
      this.selectedFromLeft.splice(index, 1);
    }
    this.selectedFromLeft = [...this.selectedFromLeft];
  }

  rightSideItemClicked(item: ItemType) {
    const index = this.selectedFromRight.indexOf(item);
    if (index === -1) {
      this.selectedFromRight.push(item);
    } else {
      this.selectedFromRight.splice(index, 1);
    }
    this.selectedFromRight = [...this.selectedFromRight];
  }

  pushFromLeftToRight() {
    for (const item of this.selectedFromLeft) {
      this.rightSideItems = [...this.rightSideItems, item];

      const newArrayForLeftSide = [...this.leftSideItems];
      newArrayForLeftSide.splice(this.leftSideItems.indexOf(item), 1);

      this.leftSideItems = newArrayForLeftSide;
    }
    this.selectedFromLeft = [];
    this.propagateChange();
  }

  pushFromRightToLeft() {
    for (const item of this.selectedFromRight) {
      this.leftSideItems = [...this.leftSideItems, item];

      const newArrayForRightSide = [...this.rightSideItems];
      newArrayForRightSide.splice(this.rightSideItems.indexOf(item), 1);

      this.rightSideItems = newArrayForRightSide;
    }
    this.selectedFromRight = [];
    this.propagateChange();
  }


  splitItems() {
    if (this.selectedItemsFilterFunction) {
      this.rightSideItems = this.allItems.filter(item => this.selectedItemsFilterFunction(item, this.formControlState));
      this.leftSideItems = this.allItems.filter(item => !this.selectedItemsFilterFunction(item, this.formControlState));
    } else {
      this.leftSideItems = this.allItems;
    }
  }

  /**
   * Ab hier ControlValueAccessor Methoden
   */

  writeValue(formControlState: FormControlStateType) {
    this.formControlState = formControlState;
    this.splitItems();
    this.propagateChange();
  }

  registerOnChange(fn: (() => {})) {
    this.onChangeListeners.push(fn);
  }

  registerOnTouched(fn: (() => {})) {
    this.onTouchedListeners.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    // TODO
  }

  private propagateChange() {
    // Current value: right side items
    let valueAfterChange = this.rightSideItems;
    // If possible: use map function to transform result
    if (this.selectedItemsMapFunction) {
      valueAfterChange = this.selectedItemsMapFunction(this.rightSideItems);
    }
    // call on change and on touch listeners
    this.onChangeListeners.forEach(fn => fn(valueAfterChange));
    // TODO: eigentlich nicht die richtige stelle für onTouched
    this.onTouchedListeners.forEach(fn => fn());
  }

}
