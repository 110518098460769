import {Component, Inject, Injector} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export class MasterdataDialogGroupCreateBundle extends AbstractBypassBundle {
    name: string;
}

@Component({
    selector: 'app-masterdata-dialog-create-group',
    templateUrl: './masterdata-dialog-group-create.component.html',
    styleUrls: ['./masterdata-dialog-group-create.component.scss']
})
export class MasterdataDialogGroupCreateComponent extends BypassFormComponent<MasterdataDialogGroupCreateBundle> {

    form = new FormGroup({
        name: new FormControl(undefined, [Validators.required]),
    });

    constructor(protected injector: Injector,
                public dialog: MatDialog,
                public dialogRef: MatDialogRef<MasterdataDialogGroupCreateBundle>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new MasterdataDialogGroupCreateBundle());
    }

    submit() {
        if (this.form.valid) {
            this.data = Object.assign(new MasterdataDialogGroupCreateBundle(), this.form.value);
            this.bpSubmit();
            this.dialogRef.close();
        }
    }

}
