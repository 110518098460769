import {ModuleWithProviders, NgModule} from '@angular/core';
import {BypassCoreComponentsModule} from './core-components/bypass-core-components.module';
import {BypassCoreGuardsModule} from './core-guards/bypass-core-guards.module';
import {InjectableRxStompConfig, RxStompService, rxStompServiceFactory} from '@stomp/ng2-stompjs';
import {BypassDataService} from './bypass-data.service';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {BypassAdminGuard} from './core-guards/bypass-admin.guard';
import {BypassAuthGuard} from './core-guards/bypass-auth.guard';
import {stompConfig} from './config/stomp.config';
import {XhrInterceptor} from './config/xhr.interceptor';
import {UiLoadingOverlayModule} from '@byteways/ui-loading-overlay';

@NgModule({
  declarations: [],
  imports: [
    BypassCoreComponentsModule,
    BypassCoreGuardsModule,
    UiLoadingOverlayModule
  ],
  exports: [
    BypassCoreComponentsModule,
    BypassCoreGuardsModule
  ]
})
export class BypassCoreModule {
  static forRoot(): ModuleWithProviders<BypassCoreModule> {
    return {
      ngModule: BypassCoreModule,
      providers: [
        HttpClient,
        BypassDataService,
        BypassAuthGuard,
        BypassAdminGuard,
        RxStompService,
        {
          provide: InjectableRxStompConfig,
          useValue: stompConfig
        },
        {
          provide: RxStompService,
          useFactory: rxStompServiceFactory,
          deps: [InjectableRxStompConfig]
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: XhrInterceptor,
          multi: true
        }
      ]
    };
  }

  static forChild(): ModuleWithProviders<BypassCoreModule> {
    return {
      ngModule: BypassCoreModule,
      providers: []
    };
  }
}
