import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DateRange} from '../../../model/old/DateRange';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {Carrier} from '../../../model/old/Carrier';
import {Preset} from '../../../model/old/Preset';
import {DateRangeComponent} from '../../../features/daterange/daterange.component';
import {DialogPlanTourComponent} from '../dialogplantour/dialogplantour.component';
import {Tour} from '../../../model/old/Tour';
import {BypassSearchableInputComponent} from '@byteways/bypass-searchable-input';
import {MaterialPriceEmbeddedObject} from '../../../model/domain/material-price.embedded-object';
import {MatDialog} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class CreateTourFromPresetBundle extends AbstractBypassBundle {
    preset: number;
    targetDate: DateRange;
    comment: string;
    message: string;
    quantity: string;
    carrier: number;
    priceFr: number;
    priceVk: number;
    tour: Tour | undefined;
    orderNumber?: string;
}

const CommissionTypes = ['fracht', 'handel'];


@Component({
    selector: 'app-createtourfrompreset',
    templateUrl: './createtourfrompreset.component.html',
    styleUrls: ['./createtourfrompreset.component.scss']
})
export class CreateTourFromPresetComponent extends BypassFormComponent<CreateTourFromPresetBundle> implements OnInit, OnDestroy {

    @ViewChild('preset', {static: true}) presetInput: BypassSearchableInputComponent;
    @ViewChild('targetDate', {static: true}) targetDateInput: DateRangeComponent;
    @ViewChild('carrier', {static: true}) carrierInput: BypassSearchableInputComponent;

    awaitResponse = false;

    showPriceWarning = false;
    currentPrice: MaterialPriceEmbeddedObject | null = null;

    createForm = new FormGroup({
        preset: new FormControl(undefined, [Validators.required]),
        quantity: new FormControl(undefined, [Validators.required]),
        targetDate: new FormControl(new DateRange(), [Validators.required]),
        comment: new FormControl(undefined),
        message: new FormControl(undefined),
        carrier: new FormControl(),
        priceEk: new FormControl({value: undefined, disabled: true}),
        priceFr: new FormControl(),
        priceVk: new FormControl(),
        orderNumber: new FormControl()
    });

    private readonly destroy$ = new Subject();

    constructor(protected injector: Injector, private dialog: MatDialog) {
        super(injector);
        this.bpSetup(new CreateTourFromPresetBundle());
    }

    ngOnInit() {
        this.createForm.get('preset').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(preset => {
            this.createForm.get('quantity').setValue(preset?.quantity);
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    create() {
        this.onFormSubmit();
    }

    createAndSchedule() {
        this.awaitResponse = true;
        this.onFormSubmit();
    }

    protected bpOnMessageReceived(): void {
        if (this.awaitResponse) {
            this.awaitResponse = false;
            if (this.data.tour !== null && this.data.tour !== undefined) {
                this.dialog.open(DialogPlanTourComponent, {data: {tour: this.data.tour}, width: '1000px'});
            }
        }
    }

    onFormSubmit() {
        if (this.createForm.valid) {
            const lastPreset = this.createForm.value.preset;
            let carrierId;
            if (this.createForm.value.carrier !== null) {
                carrierId = this.createForm.value.carrier.id;
            }
            this.data = Object.assign(new CreateTourFromPresetBundle(), {
                preset: this.createForm.value.preset.id,
                targetDate: this.createForm.value.targetDate,
                comment: this.createForm.value.comment,
                message: this.createForm.value.message,
                quantity: this.createForm.value.quantity,
                carrier: carrierId,
                priceFr: this.createForm.value.priceFr,
                priceVk: this.createForm.value.priceVk,
                orderNumber: this.createForm.value.orderNumber
            });
            this.bpSubmit();
            this.reset();
            // this.createForm.controls.preset.setValue(lastPreset); // FIXME: erzeugt bug
        } else {
        }
    }

    carrierToString(data: Carrier) {
        return data.name;
    }

    presetToString(data: any) {
        return `${data.name} | ${data.loadingLocation.shortName} => ${data.unloadingLocation.shortName}`;
    }

    reset() {
        this.presetInput.reset();
        this.presetInput.writeValue(undefined);
        this.targetDateInput.reset();
        // this.carrierInput.reset(); // TODO
        this.createForm.reset();
    }

    getFromDate(): Date | null {
        const f = this.createForm.get('targetDate');
        if (f === undefined || f === null) {
            return null;
        }
        const v = f.value;
        if (v === undefined || v === null) {
            return null;
        }
        const d = v.fromDate;
        if (d === undefined) {
            return null;
        }
        return d;
    }

    getPreset(): Preset | null {
        const p = this.createForm.get('preset').value;
        if (p === undefined) {
            return null;
        }
        return p;
    }

    onPriceFound(price: any) {
        this.createForm.patchValue({
            priceEk: price.priceEk,
            priceFr: price.priceFr,
            priceVk: price.priceVk,
            comment: (() => {
                const original = this.createForm.value.comment ?? '';
                return original.length > 0 ? `${original} ${price.comment ?? ''}`.trim() : price.comment ?? '';
            })()
        });
    }

    displayCommissionTypeAndPrice() {
        const commissionType = this.getCommissionType();
        const ek = this.createForm.value.priceEk;
        const fr = this.createForm.value.priceFr;
        const vk = this.createForm.value.priceVk;
        let tradePrice = 0.0;
        switch (commissionType) {
            case 'handel':
                tradePrice = vk - fr - ek;
                return `Handelsauftrag mit ${tradePrice}€ Handelsnutzen`;
            case 'fracht':
                tradePrice = vk - fr;
                return `Frachtauftrag mit ${tradePrice}€ Handelsnutzen`;
            case 'franko':
                tradePrice = vk - ek;
                return `Frankoauftrag mit ${tradePrice}€ Handelsnutzen`;
            default:
                return `Bitte Preise eingeben, um Auftragstyp zu bestimmen.`;
        }
    }

    getCommissionType(): string {
        if (this.createForm.value.priceEk == null
            && this.createForm.value.priceFr != null && this.createForm.value.priceVk != null) {
            return CommissionTypes[0]; // fracht
        } else if (this.createForm.value.priceEk != null
            && this.createForm.value.priceFr != null && this.createForm.value.priceVk != null) {
            return CommissionTypes[1]; // handel
        } else if (this.createForm.value.priceEk != null
            && this.createForm.value.priceFr == null && this.createForm.value.priceVk != null) {
            return CommissionTypes[2]; // handel/franko
        } else {
            return undefined;
        }
    }

    getTradeValue() {
        const commissionType = this.getCommissionType();
        const ek = this.createForm.value.priceEk;
        const fr = this.createForm.value.priceFr;
        const vk = this.createForm.value.priceVk;
        switch (commissionType) {
            case 'handel':
                return vk - fr - ek;
            case 'fracht':
                return vk - fr;
            case 'franko':
                return vk - ek;
            default:
                return undefined;
        }
    }

}
