import {Component, forwardRef, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {CountryDomainObject} from '../../../model/domain/country.domain-object';
import {CountryOption} from '../../../model/old/CountryOption';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';

export class AccountancyTaxesCountrySelectorBundle extends AbstractBypassBundle {
    countries: CountryOption[];
}

@Component({
    selector: 'app-accountancy-taxes-country-selector',
    templateUrl: './accountancy-taxes-country-selector.component.html',
    styleUrls: ['./accountancy-taxes-country-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AccountancyTaxesCountrySelectorComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => AccountancyTaxesCountrySelectorComponent)
        }
    ]
})
/**
 * TODO doku
 */
export class AccountancyTaxesCountrySelectorComponent extends BypassDataComponent<AccountancyTaxesCountrySelectorBundle> implements ControlValueAccessor, OnInit, Validator {

    control = new FormControl(undefined, [Validators.required]);

    options: CountryOption[] | undefined;

    private onChangeListeners: ((countryId: number) => {})[] = [];
    private onTouchedListeners: (() => {})[] = [];

    ngControl: NgControl;

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new AccountancyTaxesCountrySelectorBundle());
    }

    ngOnInit(): void {
        this.ngControl = this.injector.get(NgControl);

        this.control.valueChanges.subscribe(() => {
            this.onChanged();
        });
    }

    protected bpOnMessageReceived(): void {
        this.options = this.data.countries;
        this.onTouched();
    }

    protected bpFilterIncomingData(data: AccountancyTaxesCountrySelectorBundle): boolean {
        this.onTouched();
        return this.options === undefined;
    }

    onChanged() {
        for (const onChangeListener of this.onChangeListeners) {
            onChangeListener(this.control.value);
        }
    }

    onTouched() {
        for (const onTouchedListener of this.onTouchedListeners) {
            onTouchedListener();
        }
    }

    registerOnChange(fn: (countryId: number) => {}): void {
        this.onChangeListeners.push(fn);
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouchedListeners.push(fn);
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    writeValue(countryId: number): void {
        this.control.setValue(countryId);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.control.valid ? null : {'countrySelectorError': true};
    }

}
