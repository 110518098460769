import {Component, Input, OnInit} from '@angular/core';
import {Tour} from '../../model/old/Tour';
import {DateRange} from '../../model/old/DateRange';


@Component({
    selector: 'app-toursummary',
    templateUrl: './toursummary.component.html',
    styleUrls: ['./toursummary.component.scss']
})
export class ToursummaryComponent implements OnInit {

    @Input() tour: Tour;
    @Input() showPrices = false;

    constructor() {
    }

    ngOnInit() {
    }

    formatDate(date: Date) {
        return new Date(date).toLocaleString('de').slice(0, -3);
    }

    targetDateToString(dateRange: DateRange) {
        if (dateRange.useRange) {
            return `${this.formatDate(dateRange.fromDate)} - ${this.formatDate(dateRange.toDate)}`;
        } else {
            return `${this.formatDate(dateRange.fromDate)}`;
        }
    }

    commissionTypeToString() {
        switch (this.tour.commissionType) {
            case 'FRACHT':
                return 'Fracht';
            case 'HANDEL':
                return 'Handel';
            case 'FRANKO':
                return 'Franko';
            default:
                return '-';
        }
    }

    priceTypeToString() {
        switch (this.tour.priceType) {
            case 'FLAT':
                return 'Pauschal';
            case 'WEIGHT':
                return 'Pro Tonne';
            case 'TIME':
                return 'Pro Stunde';
            default:
                return '-';
        }
    }

    statusToString() {
        switch (this.tour.status) {
            case 'OPEN':
                return 'Offen';
            case 'SCHEDULED':
                return 'Disponiert';
            case 'SENT':
                return 'Versendet';
            case 'DONE':
                return 'Abgerechnet';
        }
    }

    getTradeValue() {
        switch (this.tour.commissionType) {
            case 'HANDEL':
                return this.tour.priceVk - this.tour.priceFr - this.tour.priceEk;
            case 'FRACHT':
                return this.tour.priceVk - this.tour.priceFr;
            case 'FRANKO':
                return this.tour.priceVk - this.tour.priceEk;
            default:
                return undefined;
        }
    }

    formatPrice(price: number): string {
        if (price === undefined || price === null) {
            return '-';
        }
        return `${price.toFixed(2).toString().replace('.', ',')} €`;
    }


}
