import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';

export class InvoiceItemTaxInfoBundle extends AbstractBypassBundle {
    componentId: number;
    taxGroupId: number;
    addressId: number;

    vat: number;
    taxGroupName: string;
}

@Component({
  selector: 'app-invoice-item-tax-info',
  templateUrl: './invoice-item-tax-info.component.html',
  styleUrls: ['./invoice-item-tax-info.component.scss']
})
export class InvoiceItemTaxInfoComponent extends BypassFormComponent<InvoiceItemTaxInfoBundle> implements OnInit {

    static componentId = 0;
    private componentId = InvoiceItemTaxInfoComponent.componentId++;

    private _taxGroupId: number | null;

    @Input() set taxGroupId(value: number) {
        this._taxGroupId = value;
        this.onInputsChange();
    }

    get taxGroupId(): number | null {
        return this._taxGroupId;
    }

    private _addressId: number | null = null;

    @Input() set addressId(value: number) {
        this._addressId = value;
        this.onInputsChange();
    }

    get addressId(): number | null {
        return this._addressId;
    }

    taxGroupName: string | null = null;
    vat: number | null = null;

    @Output() vatChanged = new EventEmitter<number>();

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new InvoiceItemTaxInfoBundle());
    }

    ngOnInit() {
    }

    private onInputsChange() {
        if (this.taxGroupId !== null && this.taxGroupId !== undefined
            && this.addressId !== null && this.addressId !== undefined) {

            this.data = Object.assign(new InvoiceItemTaxInfoBundle(), {
                componentId: this.componentId,
                taxGroupId: this.taxGroupId,
                addressId: this.addressId
            });
            this.bpSubmit();

        }
    }

    protected bpFilterIncomingData(data: InvoiceItemTaxInfoBundle): boolean {
        return data.componentId === this.componentId;
    }

    protected bpOnMessageReceived(): void {
        this.taxGroupName = this.data.taxGroupName;
        this.vat = this.data.vat;
        this.vatChanged.emit(this.vat);

    }

}
