import {Component, forwardRef, Input} from '@angular/core';
import {BypassControlValueAccessor} from '@byteways/bypass-forms';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-input-date-picker',
    templateUrl: './input-date-picker.component.html',
    styleUrls: ['./input-date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputDatePickerComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => InputDatePickerComponent)
        }
    ]
})
export class InputDatePickerComponent extends BypassControlValueAccessor<Date> {

    dateModel = new Date();

    @Input() required = false;
    @Input() placeholder = '';
    @Input() formControlName: string | null = null;

    writeValue(obj: Date | undefined | null): void {
        this.dateModel = obj;
    }

}
