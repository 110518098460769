import {Component, Input} from '@angular/core';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';

/**
 * Component, der eine Checkbox anzeigt
 * @color: Farbe der aktivierten Checkbox (primary | accent | warn | custom)
 *
 * Dieser Component implementiert den ControlValueAccessor und kann mit einer Formcontrol
 * oder [value] genutzt werden
 *
 * Dokumentation zum ControlValueAccessor
 * https://angular.io/api/forms/ControlValueAccessor
 */
@Component({
  selector: 'ui-form-field-checkbox',
  templateUrl: './ui-form-field-checkbox.component.html',
  styleUrls: ['./ui-form-field-checkbox.component.scss']
})
export class UiFormFieldCheckboxComponent extends FormFieldDataComponent {

  @Input() color: any;

}
