import {Component, Input, OnInit} from '@angular/core';
import {DateRange} from '../../model/old/DateRange';

@Component({
    selector: 'app-daterange-display',
    templateUrl: './daterange-display.component.html',
    styleUrls: ['./daterange-display.component.scss']
})
export class DaterangeDisplayComponent implements OnInit {

    @Input() dateRange: DateRange;
    @Input() timeOnly = false;

    private days = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'];

    private format(date: Date) {
        const d = new Date(date);
        let str: string;
        if (this.timeOnly) {
            str = d.toLocaleTimeString('de').slice(0, -3);
        } else {
            str = d.toLocaleString('de').slice(0, -3);
        }
        return `${this.days[d.getDay()]}, ${str}`;
    }

    private formatSameDay(dateRange: DateRange) {
        const time1 = new Date(dateRange.fromDate).toLocaleTimeString('de').slice(0, -3);
        const time2 = new Date(dateRange.toDate).toLocaleTimeString('de').slice(0, -3);
        const date = new Date(dateRange.fromDate).toLocaleString().slice(0, -10);
        return `${this.days[new Date(dateRange.fromDate).getDay()]}, ${date}, ${time1} - ${time2}`;
    }

    private targetDateToString(dateRange: DateRange): string {
        const date1 = new Date(dateRange.fromDate);
        const date2 = new Date(dateRange.toDate);
        if (!dateRange.useRange) {
            return `${this.format(date1)}`;
        } else if (date1.getDate() === date2.getDate()) {
            return this.formatSameDay(dateRange);
        }
        return `${this.format(date1)} - ${this.format(date2)}`;
    }

    constructor() {
    }

    ngOnInit() {
    }

    display() {
        return this.targetDateToString(this.dateRange);
    }


}
