import {AddressDomainObject} from './address.domain-object';
import {AddressEmbeddedObject} from './address.embedded-object';

export class LocationDomainObject {
    id: number|undefined;
    name: string;
    shortName: string;
    address: AddressEmbeddedObject;
    isLoadingLocation: boolean;
    isUnloadingLocation: boolean;
    comment: string;
}
