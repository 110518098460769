import {Component, Input} from '@angular/core';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';

@Component({
  selector: 'ui-form-field-radio-group',
  templateUrl: './ui-form-field-radio-group.component.html',
  styleUrls: ['./ui-form-field-radio-group.component.scss']
})
export class UiFormFieldRadioGroupComponent extends FormFieldDataComponent {

  @Input() options: string[];

}
