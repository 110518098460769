import {AfterContentInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {ResizeEvent} from 'angular-resizable-element';
import {ResizingService} from '../../services/resizing.service';
import {PlanItem} from '../../model/model';
import {DragDropPlanComponent} from '../drag-drop-plan/drag-drop-plan.component';
import {PlanItemService} from '../../services/plan-item.service';

@Component({
  selector: 'ui-drag-drop-plan-resize',
  templateUrl: './drag-drop-plan-resize.component.html',
  styleUrls: ['./drag-drop-plan-resize.component.scss']
})
export class DragDropPlanResizeComponent implements AfterContentInit {

  @ViewChild('resizable') resizable: ElementRef;

  resizeItemStyle: object = {};

  grabLeft = false;

  item: PlanItem;

  constructor(
    @Inject(DragDropPlanComponent) public parent: DragDropPlanComponent,
    private resizingService: ResizingService,
    private planItemService: PlanItemService
  ) {
  }

  ngAfterContentInit() {
    this.parent.currentResizeItem.subscribe((item: PlanItem) => {
      this.item = item;
      this.updateResizeWrapperPosition(this.item);
    });
  }

  /**
   * Updates the wrapper position for the resize element.
   * @param item The plan item.
   */
  updateResizeWrapperPosition(item: PlanItem) {
    if (item && item.position) {
      this.resizable.nativeElement.style.left = this.planItemService.getPixelPositionFromItemPosition(item.position.column, this.parent.gridStepSize) + 'px';
      this.resizable.nativeElement.style.top = ((item.position.row * this.parent.rowHeight)) + 'px';
      this.resizable.nativeElement.style.width = this.planItemService.getPixelPositionFromItemPosition(item.length, this.parent.gridStepSize) + 'px';
      this.resizable.nativeElement.style.height = this.parent.rowHeight + 'px';
    }
  }

  /**
   * Callback for item resize start.
   * @param event The resize event.
   */
  onItemResize(event: ResizeEvent): void {
    if (this.item) {

      this.resizeItemStyle = this.resizingService.createStyleAttributes(event, this.parent.rowHeight);

      const startPosition = (event.rectangle.left - this.parent.plan.nativeElement.offsetLeft);
      const endPosition = (startPosition + event.rectangle.width);

      if (this.grabLeft) {
        const newColumn = Math.round(startPosition / this.parent.gridStepSize);
        this.item.length = (this.item.length + (this.item.position.column - newColumn));
        this.item.position.column = newColumn;
      } else {
        this.item.length = (Math.round(endPosition / this.parent.gridStepSize) - this.item.position.column);
      }

      const isInDeadline = this.planItemService.isInDeadline(this.item);
      this.item.currentColor = isInDeadline ? this.item.color : this.item.warnColor;
      this.parent.resizingEvent.emit({success: isInDeadline, item: this.item});
    }
  }

  /**
   * Callback for item resize end.
   */
  onItemResizeEnd(): void {
    this.updateResizeWrapperPosition(this.item);
    const isInDeadline = this.planItemService.isInDeadline(this.item);
    this.parent.resizedEvent.emit({success: isInDeadline, item: this.parent.currentResizeItem.value});
  }

}
