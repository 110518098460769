import {Pipe, PipeTransform} from '@angular/core';
import {Employee} from '../model/old/Employee';

@Pipe({
    name: 'employeeFormat'
})
export class EmployeeFormatPipe implements PipeTransform {

    transform(value: Employee, args?: any): string {
        if (value === undefined || value === null) {
            return ' — ';
        } else {
            return `${value.firstName} ${value.lastName}`;
        }
    }

}
