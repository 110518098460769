import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {DialogDuplicateTourBundle} from '../dialogduplicatetour/dialogduplicatetour.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class DialogGeneratePresetBundle extends AbstractBypassBundle {
    tourId: number;
    presetName: string;
}

@Component({
    selector: 'app-dialoggeneratepreset',
    templateUrl: './dialoggeneratepreset.component.html',
    styleUrls: ['./dialoggeneratepreset.component.scss']
})
export class DialogGeneratePresetComponent extends BypassFormComponent<DialogGeneratePresetBundle> implements OnInit {

    constructor(protected injector: Injector,
                public dialogRef: MatDialogRef<DialogGeneratePresetComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new DialogGeneratePresetBundle());
    }

    presetName: string;

    ngOnInit() {
    }

    submit() {
        if (this.presetName == null) {
            this.presetName = `${this.input.tour.customer.name}: ${this.input.tour.material.name} von ${this.input.tour.loadingLocation.name} nach ${this.input.tour.unloadingLocation.name}`;
        }
        this.data = new DialogGeneratePresetBundle();
        this.data.tourId = this.input.tour.id;
        this.data.presetName = this.presetName;
        this.bpSubmit();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }

}
