import {Component, Inject, Input, TemplateRef} from '@angular/core';
import {PlanItem, PlanItemComponent} from '../../model/model';
import {DragDropPlanComponent} from '../drag-drop-plan/drag-drop-plan.component';
import {DragDropPlanItemBasicComponent} from '../drag-drop-plan-item-basic/drag-drop-plan-item-basic.component';
import {PlanItemService} from '../../services/plan-item.service';

@Component({
  selector: 'ui-drag-drop-plan-item-unplanned',
  templateUrl: './drag-drop-plan-item-unplanned.component.html',
  styleUrls: ['./drag-drop-plan-item-unplanned.component.scss']
})
export class DragDropPlanItemUnplannedComponent extends DragDropPlanItemBasicComponent {

  /**
   * Template for an item in the list of unplanned items.
   */
  @Input() unplannedItemTemplate: TemplateRef<PlanItemComponent>;

  constructor(@Inject(DragDropPlanComponent) public parent: DragDropPlanComponent, public planItemService: PlanItemService) {
    super(parent, planItemService);
  }

  // =======================================================================================================================================
  // Click callbacks

  /**
   * Callback for right clicking a plan item.
   * @param item The plan item.
   * @param event The mouse event.
   */
  onUnplannedItemClick(item: PlanItem, event: MouseEvent) {
    if (!this.isLeftButtonMouseClick(event)) {
      return;
    }

    this.xDifference = this.parent.plan.nativeElement.offsetLeft;
    this.yDifference = 0;

    const el = this.itemEl.nativeElement;
    el.style.position = 'absolute';
    el.style.width = (item.length * this.parent.gridStepSize) + 'px';
    el.style.left = event.pageX + 'px';
    el.style.top = (event.pageY - (this.parent.rowHeight / 2)) + 'px';
  }

  /**
   * Callback for unplanned item drag start
   */
  onUnplannedItemDragStart(item: PlanItem) {
    this.parent.showDeadline(item, false);
    setTimeout(() => {
      this.itemEl.nativeElement.style.visibility = 'hidden';
    }, 10);
  }

  /**
   * Callback for unplanned item drag.
   * @param item The plan item.
   * @param event The drag event.
   */
  onUnplannedItemDrag(item: PlanItem, event: MouseEvent) {
    if (this.isMouseOverUnplannedBox(event)) {
      this.parent.unplanned.nativeElement.style.background = 'rgba(0, 255, 0, 0.1)';
    } else {
      this.parent.unplanned.nativeElement.style.background = 'initial';
    }
    const preview = this.createItemPreview(item, event);
    this.parent.updatePreview(preview);
  }

  /**
   * Callback for drag end.
   * @param item The plan item.
   * @param event The drag event.
   */
  onUnplannedItemDragEnd(item: PlanItem, event: MouseEvent) {
    if (this.isLeftButtonMouseClick(event)) {

      if (this.isMouseOverUnplannedBox(event)) {
        const styleAttributes = ['visibility', 'position', 'width', 'left', 'top'];
        styleAttributes.forEach((attribute: string) => {
          this.setElementStyleAttribute(attribute, 'initial');
        });
      } else {
        this.parent.pushItemToPlanned(item);

        const position = this.planItemService.getItemDragPosition(item, event, this.parent, this.currentDragPosition, this.xDifference, this.yDifference);
        item.position = {
          column: this.planItemService.getItemPositionFromPixelPosition(position.x, this.parent.gridStepSize) || 0,
          row: ((position.y + this.parent.scrollDifference) / this.parent.rowHeight) || 0
        };

        item.currentColor = this.isInDeadline(item) ? item.color : item.warnColor;
        item.visible = true;

        this.parent.dragEndEvent.emit(item);
        this.parent.unplannedToPlannedEvent.emit(item);
        this.parent.showDeadline(item, true);
      }

    }
  }

  setElementStyleAttribute(attribute: string, value: string) {
    this.itemEl.nativeElement.style[attribute] = value;
  }

  onMouseOver(item: PlanItem) {
    this.parent.showDeadline(item, true);
    this.parent.hoverEvent.emit(item);
  }

}
