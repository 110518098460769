import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {BusinessPartner} from '../../../../../model/old/BusinessPartner';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';

export class MasterdataAccountancyEditorNumbersBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    generateCreditor: boolean;
    generateDebitor: boolean;
}

@Component({
    selector: 'app-masterdata-accountancy-editor-numbers',
    templateUrl: './masterdata-accountancy-editor-numbers.component.html',
    styleUrls: ['./masterdata-accountancy-editor-numbers.component.scss']
})
export class MasterdataAccountancyEditorNumbersComponent extends BypassFormComponent<MasterdataAccountancyEditorNumbersBundle> {

    @Output() saved = new EventEmitter();

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new MasterdataAccountancyEditorNumbersBundle());
    }

    private _businessPartner: BusinessPartnerDomainObject;
    @Input() set businessPartner(bp: BusinessPartnerDomainObject) {
        this._businessPartner = bp;
        this.isCreditor = this.businessPartner.creditorNumber !== undefined && this.businessPartner.creditorNumber !== null && this.businessPartner.creditorNumber !== '';
        this.isDebitor = this.businessPartner.debitorNumber !== undefined && this.businessPartner.debitorNumber !== null && this.businessPartner.debitorNumber !== '';
    }

    get businessPartner(): BusinessPartnerDomainObject {
        return this._businessPartner;
    }

    isCreditor: boolean;
    isDebitor: boolean;

    clickCreditor() {
        if (confirm('Kreditor Nr. erstellen? Dieser Vorgang kann nicht rückgängig gemacht werden!')) {
            this.data = Object.assign(new MasterdataAccountancyEditorNumbersBundle(), {
                businessPartnerId: this.businessPartner.id,
                generateCreditor: true
            });
            this.bpSubmit();
            this.saved.emit();
        }
    }

    clickDebitor() {
        if (confirm('Debitor Nr. erstellen? Dieser Vorgang kann nicht rückgängig gemacht werden!')) {
            this.data = Object.assign(new MasterdataAccountancyEditorNumbersBundle(), {
                businessPartnerId: this.businessPartner.id,
                generateDebitor: true
            });
            this.bpSubmit();
            this.saved.emit();
        }
    }

}
