import {NgModule} from '@angular/core';
import {DragDropPlanComponent} from './components/drag-drop-plan/drag-drop-plan.component';
import {CommonModule} from '@angular/common';
import {FlexModule} from '@angular/flex-layout';
import {ResizableModule} from 'angular-resizable-element';
import {MatListModule} from '@angular/material/list';
import {DragDropPlanTimelineComponent} from './components/drag-drop-plan-timeline/drag-drop-plan-timeline.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import {NumberToHoursMinutesPipe} from './pipes/number-to-hours-minutes.pipe';
import {DragDropPlanItemComponent} from './components/drag-drop-plan-item/drag-drop-plan-item.component';
import {DragDropPlanDeadlineComponent} from './components/drag-drop-plan-deadline/drag-drop-plan-deadline.component';
import {DragDropPlanResizeComponent} from './components/drag-drop-plan-resize/drag-drop-plan-resize.component';
import {DragDropPreviewComponent} from './components/drag-drop-preview/drag-drop-preview.component';
import {DragDropPlanItemUnplannedComponent} from './components/drag-drop-plan-item-unplanned/drag-drop-plan-item-unplanned.component';
import {DragDropPlanItemBasicComponent} from './components/drag-drop-plan-item-basic/drag-drop-plan-item-basic.component';

@NgModule({
  declarations: [
    DragDropPlanComponent,
    NumberToHoursMinutesPipe,
    DragDropPlanItemComponent,
    DragDropPlanDeadlineComponent,
    DragDropPlanResizeComponent,
    DragDropPlanTimelineComponent,
    DragDropPreviewComponent,
    DragDropPlanItemUnplannedComponent,
    DragDropPlanItemBasicComponent
  ],
  imports: [
    CommonModule,
    ResizableModule,
    FlexModule,
    MatListModule,
    MatCardModule,
    MatTooltipModule
  ],
  exports: [
    DragDropPlanComponent
  ],
  providers: []
})
export class UiDragDropPlanModule {
}
