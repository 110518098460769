import {Component, Inject, Injector, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export class DialogTourAddDowntimeBundle extends AbstractBypassBundle {
    tourId: number;
    time: number;
    reason: string;
}

@Component({
    selector: 'app-dialog-tour-add-downtime',
    templateUrl: './dialog-tour-add-downtime.component.html',
    styleUrls: ['./dialog-tour-add-downtime.component.scss']
})
export class DialogTourAddDowntimeComponent extends BypassFormComponent<DialogTourAddDowntimeBundle> implements OnInit {

    form = new FormGroup({
        time: new FormControl(undefined, [Validators.min(0), Validators.required]),
        reason: new FormControl('', [Validators.required])
    });

    constructor(
        protected injector: Injector,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DialogTourAddDowntimeComponent>,
        @Inject(MAT_DIALOG_DATA) public input: any,
        public sanitizer: DomSanitizer
    ) {
        super(injector);
        this.bpSetup(new DialogTourAddDowntimeBundle());
    }

    ngOnInit() {
    }

    submit() {
        if (this.form.valid) {
            this.data = Object.assign(new DialogTourAddDowntimeBundle(), this.form.value, {
                tourId: this.input.tour.id
            });
            this.bpSubmit();
            this.close();
        }
    }

    close() {
        this.dialogRef.close();
    }

}
