import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';

export class InvoiceTourTaxInfoBundle extends AbstractBypassBundle {
    infoId: number;

    tourId: number;
    invoiceAddressId: number;

    taxGroupId: number;
    taxGroupName: string;
    vat: number;
}

@Component({
    selector: 'app-invoice-tour-tax-info',
    templateUrl: './invoice-tour-tax-info.component.html',
    styleUrls: ['./invoice-tour-tax-info.component.scss']
})
export class InvoiceTourTaxInfoComponent extends BypassFormComponent<InvoiceTourTaxInfoBundle> {

    static infoCounter = 0;

    infoId = InvoiceTourTaxInfoComponent.infoCounter++;

    _tourId: number = null;
    @Input() set tourId(id: number) {
        this._tourId = id;
        this.read();
    }

    get tourId(): number {
        return this._tourId;
    }

    _invoiceAddressId: number = null;
    @Input() set invoiceAddressId(id: number) {
        this._invoiceAddressId = id;
        this.read();
    }

    get invoiceAddressId(): number {
        return this._invoiceAddressId;
    }

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new InvoiceTourTaxInfoBundle());
    }

    @Output() vatChanged = new EventEmitter<number>();

    ngOnInit() {
    }

    read() {
        if (this._tourId !== null && this._tourId !== undefined && this._invoiceAddressId !== null && this._invoiceAddressId !== undefined) {
            this.data = Object.assign(new InvoiceTourTaxInfoBundle(), {
                infoId: this.infoId,
                tourId: this._tourId,
                invoiceAddressId: this._invoiceAddressId
            });
            this.bpSetLoadingUntilMessageReceived();
            this.bpSubmit();
        }
    }

    protected bpFilterIncomingData(data: InvoiceTourTaxInfoBundle): boolean {
        return data.infoId === this.infoId;
    }

    protected bpOnMessageReceived(): void {
        if (this.data.vat !== null && this.data.vat !== undefined) {
            this.vatChanged.emit(this.data.vat);
        }
    }

}
