import {Pipe, PipeTransform} from '@angular/core';
import {AddressDomainObject} from '../../../model/domain/address.domain-object';

@Pipe({
    name: 'addressTitle'
})
export class AddressTitlePipe implements PipeTransform {

    transform(value: AddressDomainObject, index: number, ...args: any[]): any {
        if (value.residentName && value.street && value.cityCode && value.city) {
            return `${value.residentName} | ${value.street} | ${value.cityCode} ${value.city}`;
        }
        return `Adresse ${index + 1}`;
    }

}
