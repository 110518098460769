import {Customer} from './Customer';
import {Material} from './Material';
import {Location} from './Location';

export class Preset {
    id: number;
    name: string;
    customer: Customer;
    material: Material;
    loadingLocation: Location;
    unloadingLocation: Location;
    message: string;
    comment: string;
    duration: number;
    distance: number;
    orderNumber: string;
    vehicle: string;
    quantity: string;
}
