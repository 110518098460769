import {Component, ComponentFactoryResolver, Injector, Input, ViewChild} from '@angular/core';
import {DatatableCellBase} from './datatable-cell-base.interface';

export interface DatatableCellChipsDefinition<CellDataType> {
  display: string;
  cellData: CellDataType;
  click: (CellDataType) => {};
}

@Component({
  selector: 'lib-bypass-datatable-cell-chips',
  template: `
        <mat-chip-list>
            <mat-chip
                *ngFor="let item of data"
                (click)="item.click(item.cellData)">
                {{item.display}}
            </mat-chip>
        </mat-chip-list>
    `,
  styles: ['']
})
// @dynamic
export class DatatableCellChipsComponent implements DatatableCellBase<DatatableCellChipsDefinition<string>[]> {
  data: DatatableCellChipsDefinition<string>[];
}
