import {Component, Inject, OnInit} from '@angular/core';
import {Tour} from '../../../model/old/Tour';
import {DialogDeleteTourComponent} from '../dialogdeletetour/dialogdeletetour.component';
import {DialogSendTourComponent} from '../dialogsendtour/dialogsendtour.component';
import {DialogEditTourComponent} from '../dialogedittour/dialogedittour.component';
import {DialogPlanTourComponent} from '../dialogplantour/dialogplantour.component';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-dialogtouractions',
    templateUrl: './dialogtouractions.component.html',
    styleUrls: ['./dialogtouractions.component.scss']
})
export class DialogtouractionsComponent implements OnInit {

    constructor(
        public router: Router,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DialogtouractionsComponent>,
        @Inject(MAT_DIALOG_DATA) public input: any
    ) {
    }

    ngOnInit() {
    }

    canSend() {
        const tour: Tour = this.input.tour;
        return tour.status === 'SCHEDULED';
    }

    canSchedule() {
        const tour: Tour = this.input.tour;
        return tour.status !== 'SENT';
    }

    canDelete() {
        const tour: Tour = this.input.tour;
        return tour.status === 'OPEN';
    }

    canEdit() {
        const tour: Tour = this.input.tour;
        return tour.status === 'OPEN';
    }

    actionSend(tour: Tour) {
        this.dialog.open(DialogSendTourComponent, {data: {tour: tour}, width: '1000px'});
    }

    actionDelete(tour: Tour) {
        this.dialog.open(DialogDeleteTourComponent, {data: {tour: tour}, width: '1000px'});
    }

    actionEditTour(tour: Tour) {
        this.dialog.open(DialogEditTourComponent, {data: {tour: tour}, width: '1000px'});
    }

    actionEditSchedule(tour: Tour) {
        this.dialog.open(DialogPlanTourComponent, {data: {tour: tour}, width: '1000px'});
    }

    close() {
        this.dialogRef.close();
    }

}
