import {Component, Input, OnInit} from '@angular/core';
import {Location} from '../../model/old/Location';

@Component({
    selector: 'app-location-display',
    templateUrl: './location-display.component.html',
    styleUrls: ['./location-display.component.scss']
})
export class LocationDisplayComponent implements OnInit {

    @Input() location: Location;

    @Input() disableTooltip = false;

    @Input() disableText = false;

    constructor() {
    }

    ngOnInit() {
    }

    display(): string {
        if (this.disableText) {
            return '';
        }
        return this.location.shortName;
    }

    tooltip(): string {
        return `${this.location.name} | ${this.location.address.street}, ${this.location.address.city}`;
    }

}
