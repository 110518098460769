import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dispo-plan-load',
  templateUrl: './dispo-plan-load.component.html',
  styleUrls: ['./dispo-plan-load.component.scss']
})
export class DispoPlanLoadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
