import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PageSettings} from '../../../model/old/PageSettings';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {Event} from '../../../model/old/Event';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

export class EventsTableBundle extends AbstractBypassBundle {
    pageSettings: PageSettings;
    entries: Event[];
    total: number;
}

@Component({
    selector: 'app-events-table',
    templateUrl: './events-table.component.html',
    styleUrls: ['./events-table.component.scss']
})
export class EventsTableComponent extends BypassFormComponent<EventsTableBundle> implements OnInit {

    paginatorPageSizeOptions = [10, 20, 50, 100, 250];
    paginatorPageSize = this.paginatorPageSizeOptions[1];
    _filter: string = undefined;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    displayedColumns = ['createdDate', 'user', 'eventType', 'userAction', 'message'];
    dataSource = new MatTableDataSource([]);

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new EventsTableBundle());
    }

    ngOnInit() {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.paginatorPageSize;

        this.sort.active = 'createdDate';
        this.sort.direction = 'desc';

        merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadData())).subscribe();
        this.loadData();
    }

    private loadData() {
        this.data = new EventsTableBundle();
        this.data.entries = [];

        let pageIndex = 0;
        if (this.paginator.pageIndex !== undefined) {
            pageIndex = this.paginator.pageIndex;
        }
        let pageSize = this.paginatorPageSize;
        if (this.paginator.pageSize !== undefined) {
            pageSize = this.paginator.pageSize;
        }

        this.data.pageSettings = new PageSettings(
            'Event',
            pageIndex,
            pageSize,
            this.sort.active,
            this.sort.direction,
            this._filter
        );

        this.bpSubmit();
    }

    bpOnMessageReceived() {
        this.dataSource.data = this.data.entries;
        this.paginator.length = this.data.total;
    }

    applyFilter(filterValue: string) {
        if (filterValue === '') {
            this._filter = undefined;
        } else {
            this._filter = filterValue.toLowerCase();
        }
        this.loadData();
    }

}
