import {AfterViewInit, Component, Inject, Injector, OnInit} from '@angular/core';
import {Carrier} from '../../../model/old/Carrier';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateRange} from '../../../model/old/DateRange';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class DialogPlanTourBundle extends AbstractBypassBundle {
    dateRange: DateRange;
    carrierId: number;
    tourId: number;
    priceEk: number;
    priceFr: number;
    priceVk: number;
}

const CommissionTypes = ['fracht', 'handel', 'franko'];


@Component({
    selector: 'app-dialogplantour',
    templateUrl: './dialogplantour.component.html',
    styleUrls: ['./dialogplantour.component.scss']
})
export class DialogPlanTourComponent extends BypassFormComponent<DialogPlanTourBundle> implements OnInit, AfterViewInit {

    form = new FormGroup({
        customer: new FormControl(),
        loadingLocation: new FormControl(),
        unloadingLocation: new FormControl(),
        material: new FormControl(),



        targetDate: new FormControl(undefined, [Validators.required]),
        carrier: new FormControl(undefined, [Validators.required]),
        priceEk: new FormControl(),
        priceFr: new FormControl(),
        priceVk: new FormControl(),
    });

    inputArrivalTime: string;
    inputArrivalDate: Date = new Date();
    inputEstimation = 0;

    constructor(
        protected injector: Injector,
        public dialogRef: MatDialogRef<DialogPlanTourComponent>,
        @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new DialogPlanTourBundle());
        if (input.tour.priceEk !== null) {
            this.form.controls.priceEk.setValue(input.tour.priceEk);
        } else {
            this.form.controls.priceEk.disable();
        }
        if (input.tour.priceFr !== null) {
            this.form.controls.priceFr.setValue(input.tour.priceFr);
        } else {
            this.form.controls.priceFr.disable();
        }
        if (input.tour.priceVk !== null) {
            this.form.controls.priceVk.setValue(input.tour.priceVk);
        } else {
            this.form.controls.priceVk.disable();
        }

        this.form.controls.targetDate.setValue(input.tour.targetDate);

        // TODO
        this.inputArrivalDate = new Date(input.tour.targetDate.fromDate);
        this.inputArrivalTime = input.tour.targetDate.fromDate;
        let hours = `${this.inputArrivalDate.getHours()}`;
        if (hours.length === 1) {
            hours = `0${hours}`;
        }
        let minutes = `${this.inputArrivalDate.getMinutes()}`;
        if (minutes.length === 1) {
            minutes = `0${minutes}`;
        }
        this.inputArrivalTime = `${hours}:${minutes}`;

        // TODO
        /*if (input.tour.schedule !== undefined && input.tour.schedule !== null) {
            console.log('prefill');
            this.form.controls.dateRange.setValue(input.tour.schedule.dateRange);
            this.form.controls.carrier.setValue(input.tour.schedule.carrier);
            this.form.controls.priceEk.setValue(input.tour.schedule.customPriceEk);
            this.form.controls.priceFr.setValue(input.tour.schedule.customPriceFr);
            this.form.controls.priceVk.setValue(input.tour.schedule.customPriceVk);
        }*/
    }

    submitSchedule() {
        this.submit(false);
    }

    submitScheduleAndSend() {
        this.submit(true);
    }

    submit(scheduleAndSend: boolean) {
        if (this.form.valid) {
            let dateRange;
            if (this.form.value.carrier.own) {
                if (this.inputEstimation === undefined || this.inputEstimation === null) {
                    return;
                }

                dateRange = new DateRange();

                const toDate = new Date(this.inputArrivalDate);
                toDate.setHours(+this.inputArrivalTime.split(':')[0]);
                toDate.setMinutes(+this.inputArrivalTime.split(':')[1]);

                dateRange.fromDate = new Date();
                dateRange.fromDate.setTime(toDate.getTime() - 1000 * 60 * 60 * this.inputEstimation);
                dateRange.useRange = true;
                dateRange.toDate = toDate;
            } else {
                dateRange = this.form.value.targetDate;
            }

            this.data = new DialogPlanTourBundle();
            this.data.dateRange = dateRange;
            this.data.carrierId = this.form.value.carrier.id;
            this.data.tourId = this.input.tour.id;
            this.data.priceEk = this.form.value.priceEk;
            this.data.priceFr = this.form.value.priceFr;
            this.data.priceVk = this.form.value.priceVk;

            if (scheduleAndSend) {
                this.bpSendOperator('scheduleandsend');
            } else {
                this.bpSendOperator('schedule');
            }
            this.close();
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnInit() {
    }

    carrierToString(data: any) {
        return (data as Carrier).name;
    }

    getCommissionType(): string {
        if (this.form.value.priceEk == null
            && this.form.value.priceFr != null && this.form.value.priceVk != null) {
            return CommissionTypes[0]; // fracht
        } else if (this.form.value.priceEk != null
            && this.form.value.priceFr != null && this.form.value.priceVk != null) {
            return CommissionTypes[1]; // handel
        } else if (this.form.value.priceEk != null
            && this.form.value.priceFr == null && this.form.value.priceVk != null) {
            return CommissionTypes[2]; // handel/franko
        } else {
            return undefined;
        }
    }

    getTradeValue() {
        const commissionType = this.getCommissionType();
        const ek = this.form.value.priceEk;
        const fr = this.form.value.priceFr;
        const vk = this.form.value.priceVk;
        switch (commissionType) {
            case 'handel':
                return vk - fr - ek;
            case 'fracht':
                return vk - fr;
            case 'franko':
                return vk - ek;
            default:
                return undefined;
        }
    }

    onPriceFound(price: any) {
        this.form.patchValue({
            priceEk: price?.priceEk ?? null,
            priceFr: price?.priceFr ?? null,
            priceVk: price?.priceVk ?? null,
        });
    }

    ngAfterViewInit(): void {
        this.form.patchValue({
            customer: this.input.tour.customer,
            material: this.input.tour.material,
            loadingLocation: this.input.tour.loadingLocation,
            unloadingLocation: this.input.tour.unloadingLocation,
        });
    }

}
