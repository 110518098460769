<div class="wrapper" fxLayout="row" fxLayoutGap="0.5em">

  <div class="dot" fxFlexAlign="center"></div>

  <div fxLayout="column">
    <span class="header" *ngIf="data.title">{{data.title}}</span>
    <span *ngIf="data.description">{{data.description}}</span>
  </div>

</div>
