import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatisticsPageComponent} from './statistics-page/statistics-page.component';
import {FeaturesModule} from '../../features/features.module';

@NgModule({
    imports: [
        CommonModule,
        FeaturesModule
    ],
    declarations: [
        StatisticsPageComponent
    ]
})
export class StatisticsModule {
}
