import {Pipe, PipeTransform} from '@angular/core';
import {MaterialDomainObject} from '../../../model/domain/material.domain-object';
import {MaterialPriceEmbeddedObject} from '../../../model/domain/material-price.embedded-object';

@Pipe({
    name: 'materialCurrentPrice'
})
export class MaterialCurrentPricePipe implements PipeTransform {

    transform(value: MaterialDomainObject): MaterialPriceEmbeddedObject | null {
        // no prices => no current price
        if (value.materialPrices === null || value.materialPrices === undefined || value.materialPrices.length === 0) {
            return null;
        }

        // find current price
        const sortedPrices = [...value.materialPrices].sort((a, b) => new Date(b.from).getTime() - new Date(a.from).getTime());
        const now = new Date().getTime();
        for (const mp of sortedPrices) {
            if (new Date(mp.from).getTime() <= now) {
                return mp;
            }
        }

        // no current price found
        return null;
    }

}
