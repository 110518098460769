import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'euroPerKm'
})
export class EuroPerKmPipe implements PipeTransform {

    transform(euro: number, km: number): number {
        if (km === 0) {
            return 0;
        }
        return euro / km;
    }

}
