import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DateRange} from '../../../model/old/DateRange';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {Carrier} from '../../../model/old/Carrier';
import {Preset} from '../../../model/old/Preset';
import {DateRangeComponent} from '../../../features/daterange/daterange.component';
import {DialogPlanTourComponent} from '../dialogplantour/dialogplantour.component';
import {Tour} from '../../../model/old/Tour';
import {BypassSearchableInputComponent} from '@byteways/bypass-searchable-input';
import {MaterialPriceEmbeddedObject} from '../../../model/domain/material-price.embedded-object';
import {MatDialog} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class CreateTourFromPreset2Bundle extends AbstractBypassBundle {
    preset: number;
    targetDate: DateRange;
    comment: string;
    message: string;
    quantity: string;
    carrierId: number;
    priceFr: number;
    priceVk: number;
    tour: Tour | undefined;
    orderNumber?: string;
}

const CommissionTypes = ['fracht', 'handel'];


@Component({
    selector: 'app-createtourfrompreset2',
    templateUrl: './createtourfrompreset2.component.html',
    styleUrls: ['./createtourfrompreset2.component.scss']
})
export class CreateTourFromPreset2Component extends BypassFormComponent<CreateTourFromPreset2Bundle> implements OnInit, OnDestroy {

    @ViewChild('preset', {static: true}) presetInput: BypassSearchableInputComponent;
    @ViewChild('targetDate', {static: true}) targetDateInput: DateRangeComponent;
    @ViewChild('carrier', {static: true}) carrierInput: BypassSearchableInputComponent;

    awaitResponse = false;

    showPriceWarning = false;
    currentPrice: MaterialPriceEmbeddedObject | null = null;

    createForm = new FormGroup({
        preset: new FormControl(undefined, [Validators.required]),
        quantity: new FormControl(undefined, [Validators.required]),
        targetDate: new FormControl(new DateRange(), [Validators.required]),
        comment: new FormControl(undefined),
        message: new FormControl(undefined),
        carrier: new FormControl(),
        priceEk: new FormControl({value: undefined, disabled: true}),
        priceFr: new FormControl(),
        priceVk: new FormControl(),
        orderNumber: new FormControl()
    });

    private readonly destroy$ = new Subject();

    constructor(protected injector: Injector, private dialog: MatDialog) {
        super(injector);
        this.bpSetup(new CreateTourFromPreset2Bundle());
        this.disableAutoLoadingOverlay();
    }

    ngOnInit() {
        this.createForm.get('preset').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(preset => {
            this.createForm.get('quantity').setValue(preset?.quantity);
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    create() {
        this.onFormSubmit();
    }

    createAndSchedule() {
        this.awaitResponse = true;
        this.onFormSubmit();
    }

    protected bpOnMessageReceived(): void {
        if (this.awaitResponse) {
            this.awaitResponse = false;
            if (this.data.tour !== null && this.data.tour !== undefined) {
                this.dialog.open(DialogPlanTourComponent, {data: {tour: this.data.tour}, width: '1000px'});
            }
        }
    }

    onFormSubmit() {
        if (this.createForm.valid) {
            const lastPreset = this.createForm.value.preset;
            let carrierId = null;
            if (this.createForm.value.carrier !== null) {
                carrierId = this.createForm.value.carrier.id;
            }
            this.data = Object.assign(new CreateTourFromPreset2Bundle(), {
                preset: this.createForm.value.preset.id,
                targetDate: this.createForm.value.targetDate,
                comment: this.createForm.value.comment,
                message: this.createForm.value.message,
                quantity: this.createForm.value.quantity,
                carrierId: carrierId,
                priceFr: this.createForm.value.priceFr,
                priceVk: this.createForm.value.priceVk,
                orderNumber: this.createForm.value.orderNumber
            });
            console.log(this.data);
            this.bpSubmit();
            this.reset();
            // this.createForm.controls.preset.setValue(lastPreset); // FIXME: erzeugt bug
        } else {
        }
    }

    carrierToString(data: Carrier) {
        return data.name;
    }

    presetToString(data: any) {
        return `${data.name} | ${data.loadingLocation.shortName} => ${data.unloadingLocation.shortName}`;
    }

    reset() {
        this.presetInput.reset();
        this.presetInput.writeValue(undefined);
        this.targetDateInput.reset();
        // this.carrierInput.reset(); // TODO
        this.createForm.reset();
    }

    getFromDate(): Date | null {
        const f = this.createForm.get('targetDate');
        if (f === undefined || f === null) {
            return null;
        }
        const v = f.value;
        if (v === undefined || v === null) {
            return null;
        }
        const d = v.fromDate;
        if (d === undefined) {
            return null;
        }
        return d;
    }

    getPreset(): Preset | null {
        const p = this.createForm.get('preset').value;
        if (p === undefined) {
            return null;
        }
        return p;
    }

    onPriceFound(mp: MaterialPriceEmbeddedObject | null) {
        this.currentPrice = mp;
        this.showPriceWarning = false;
        if (mp !== null) {
            this.showPriceWarning = this.currentPrice.ek !== this.presetInput.value.defaultPriceEk
                || this.currentPrice.fr !== this.presetInput.value.defaultPriceFr
                || this.currentPrice.vk !== this.presetInput.value.defaultPriceVk;
        }
    }

}
