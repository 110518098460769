import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';

@Component({
    selector: 'app-masterdata-accountancy-editor',
    templateUrl: './masterdata-accountancy-editor.component.html',
    styleUrls: ['./masterdata-accountancy-editor.component.scss']
})
export class MasterdataAccountancyEditorComponent implements OnInit {

    @Output() saved = new EventEmitter();
    @Input() businessPartner: BusinessPartnerDomainObject;

    constructor() {
    }

    ngOnInit() {
    }

}
