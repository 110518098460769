import {Pipe, PipeTransform} from '@angular/core';
import {AddressDomainObject} from '../model/domain/address.domain-object';

@Pipe({
    name: 'addressFormat'
})
export class AddressFormatPipe implements PipeTransform {

    transform(address: AddressDomainObject, args?: any): any {
        if (!address) {
            return '-';
        }
        // TODO: country
        return `${address.residentName} | ${address.street} | ${address.cityCode} ${address.city}`;
    }

}
