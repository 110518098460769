import {Pipe, PipeTransform} from '@angular/core';
import {MaterialDomainObject} from '../../../model/domain/material.domain-object';
import {MaterialPriceEmbeddedObject} from '../../../model/domain/material-price.embedded-object';

@Pipe({
    name: 'materialNextPrice'
})
export class MaterialNextPricePipe implements PipeTransform {

    transform(value: MaterialDomainObject): MaterialPriceEmbeddedObject | null {
        // no prices => no next price
        if (value.materialPrices === null || value.materialPrices === undefined || value.materialPrices.length === 0) {
            return null;
        }

        const now = new Date().getTime();

        // one price => find out if its in the future or not
        if (value.materialPrices.length === 1) {
            const mp = value.materialPrices[0];
            if (new Date(mp.from).getTime() > now) {
                return mp;
            } else {
                return null;
            }
        }

        // find next price
        let result = null;
        let i = 0;
        do {
            const mp = value.materialPrices[i++];
            const from = new Date(mp.from).getTime();
            if (from > now) {
                result = mp;
            }
        } while (result === null && i < value.materialPrices.length);

        return result;
    }

}
