import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-accounts-form',
    templateUrl: './accounts-form.component.html',
    styleUrls: ['./accounts-form.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: AccountsFormComponent, multi: true}
    ]
})
export class AccountsFormComponent implements ControlValueAccessor, OnDestroy {

    formArray: FormArray;
    _onChange;
    _onTouched;
    @Input() disabled = false;
    @Input() accountIdSuggestions: string[] = [];
    @Input() disableInputs = false;
    @Input() disableDelete = false;

    private sub: Subscription | null = null;

    constructor() {
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: any): void {
        this.formArray = new FormArray(
            value.map(x => {
                return new FormGroup({
                    accountId: new FormControl(x.accountId, Validators.required),
                    sum: new FormControl(x.sum, Validators.required)
                });
            })
        );
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.sub = this.formArray.valueChanges.subscribe(res => {
            this._onChange(res);
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

}
