import {AfterViewInit, Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output} from '@angular/core';
import {TaxGroupDomainObject} from '../../../model/domain/tax-group.domain-object';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormBuilder,
    FormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {MaterialDisplayComponent} from '../accountancy-taxes-material-selection-list/material-display/material-display.component';
import {Material} from '../../../model/old/Material';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {MaterialDomainObject} from '../../../model/domain/material.domain-object';
import {BehaviorSubject} from 'rxjs';

export class AccountancyTaxesTaxGroupFormBundle extends AbstractBypassBundle {
    formId: number;
    materialOptions: MaterialDomainObject[];
}

@Component({
    selector: 'app-accountancy-taxes-tax-group-form',
    templateUrl: './accountancy-taxes-tax-group-form.component.html',
    styleUrls: ['./accountancy-taxes-tax-group-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AccountancyTaxesTaxGroupFormComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => AccountancyTaxesTaxGroupFormComponent)
        }
    ]
})
export class AccountancyTaxesTaxGroupFormComponent extends BypassDataComponent<AccountancyTaxesTaxGroupFormBundle> implements AfterViewInit, ControlValueAccessor, Validator {

    static formId = 1;

    private onChangeListeners = [];
    private onTouchedListeners = [];

    @Output() submitted: EventEmitter<TaxGroupDomainObject> = new EventEmitter<TaxGroupDomainObject>();

    formGroup = this.fb.group({
        id: this.fb.control(null),
        name: this.fb.control('', [Validators.required]),
        materialIds: this.fb.control([], []),
        taxRules: this.fb.array([], []),
    });

    formId = AccountancyTaxesTaxGroupFormComponent.formId++;
    materialOptions = new BehaviorSubject<MaterialDomainObject[]>([]);

    constructor(protected injector: Injector, private fb: FormBuilder) {
        super(injector);

    }

    protected bpFilterIncomingData(data: AccountancyTaxesTaxGroupFormBundle): boolean {
        return data.formId === this.formId;
    }

    protected bpOnMessageReceived(): void {
        this.materialOptions.next(this.data.materialOptions);
    }

    ngAfterViewInit() {
        // on form value changes
        this.formGroup.valueChanges.subscribe((value) => {
            // call listeners
            for (const listener of this.onChangeListeners) {
                listener(value);
            }
        });

        const bundle = Object.assign(new AccountancyTaxesTaxGroupFormBundle(), {
            formId: this.formId,
            taxGroupId: this.formGroup.value.id
        });
        this.bpSetup(bundle);
    }

    createTaxRuleFormGroup(): FormGroup {
        return this.fb.group({
            countryId: this.fb.control(undefined, [Validators.required]),
            valueAddedTax: this.fb.control(undefined, [Validators.required, Validators.min(0)])
        });
    }

    getTaxRuleFormArray(): FormArray {
        return this.formGroup.get('taxRules') as FormArray;
    }

    addTaxRule() {
        this.getTaxRuleFormArray().push(this.createTaxRuleFormGroup());
    }

    getMaterialFormArray(): FormArray {
        return this.formGroup.get('materialIds') as FormArray;
    }

    registerOnChange(fn: any): void {
        this.onChangeListeners.push(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouchedListeners.push(fn);
    }

    setDisabledState(isDisabled: boolean): void {
        // TODO
    }

    writeValue(obj: any): void {
        if (!obj) {
            this.formGroup.reset();
            return;
        }
        const taxGroup: TaxGroupDomainObject = <TaxGroupDomainObject>obj;
        for (let i = 0; i < taxGroup.taxRules.length; i++) {
            this.getTaxRuleFormArray().push(this.createTaxRuleFormGroup());
        }
        this.formGroup.patchValue(obj);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.formGroup.valid ? null : {'taxGroupError': true};
    }

}
