import {Component, Input, OnInit} from '@angular/core';
import {InvoiceItemEmbeddedObject} from '../../../../../model/domain/invoice-item.embedded-object';

@Component({
    selector: 'app-invoice-sum-table',
    templateUrl: './invoice-sum-table.component.html',
    styleUrls: ['./invoice-sum-table.component.scss']
})
export class InvoiceSumTableComponent implements OnInit {

    @Input() set invoiceItems(items: InvoiceItemEmbeddedObject[]) {
        this.vatMap = {};
        items.forEach(item => {
            const vat = item.vat || 0.0;
            const quantity = item.quantity || 0.0;
            const unitCost = item.unitCost || 0.0;
            this.vatMap[vat] = this.vatMap[vat] || 0.0;
            this.vatMap[vat] += quantity * unitCost;
        });
    }

    vatMap = {};

    get vatKeys(): number[] {
        return Object.keys(this.vatMap).map(v => parseFloat(v)).sort((k1, k2) => k1 - k2);
    }

    get netto(): number {
        return this.vatKeys.map(v => this.vatMap[v]).reduce((a, b) => a + b, 0);
    }

    get brutto(): number {
        return this.vatKeys.map(v => this.vatMap[v] * (1.0 + (v / 100.0))).reduce((a, b) => a + b, 0);
    }

    constructor() {
    }

    ngOnInit() {
    }

}
