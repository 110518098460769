import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {AccountancyReport} from '../../../model/old/AccountancyReport';
import {WindowRefService} from '../../../window-ref.service';

export class AccountancyReportBundle extends AbstractBypassBundle {
    from: Date;
    to: Date;
    report: AccountancyReport;
}


@Component({
    selector: 'app-accountancy-report',
    templateUrl: './accountancy-report.component.html',
    styleUrls: ['./accountancy-report.component.scss']
})
export class AccountancyReportComponent extends BypassFormComponent<AccountancyReportBundle> implements OnInit {

    reportForm = new FormGroup({
        from: new FormControl(undefined, [Validators.required]),
        to: new FormControl(undefined, [Validators.required])
    });

    report: AccountancyReport | undefined = undefined;

    submitted = false;

    constructor(protected injector: Injector, private windowRef: WindowRefService) {
        super(injector);
    }

    ngOnInit() {
        this.bpSetup(new AccountancyReportBundle());
    }

    onSubmit() {
        if (!this.reportForm.valid) {
            return;
        }

        this.submitted = true;
        this.data = Object.assign(new AccountancyReportBundle(), {
            from: this.getFrom(),
            to: this.getTo()
        });
        this.bpSubmit();
        this.bpSetLoadingUntilMessageReceived();
    }

    protected bpOnMessageReceived(): void {
        this.report = this.data.report;
    }

    download() {
        const from = this.formatDate(this.getFrom());
        const to = this.formatDate(this.getTo());

        this.windowRef.open(`/export/rechnungsausgangsbuch.csv?from=${from}&to=${to}`);
    }

    getFrom(): Date {
        const from = new Date(this.reportForm.value.from);
        from.setHours(0, 0, 0);
        return from;
    }

    getTo(): Date {
        const to = new Date(this.reportForm.value.to);
        to.setHours(23, 59, 59);
        return to;
    }

    formatDate(date: Date): string {
        const deDate = new Date(date).toLocaleDateString('de-DE');
        const split = deDate.split('.');
        let year = split[2];
        while (year.length < 4) {
            year = '0' + year;
        }
        let month = split[1];
        while (month.length < 2) {
            month = '0' + month;
        }
        let day = split[0];
        while (day.length < 2) {
            day = '0' + day;
        }
        return `${year}${month}${day}`;
    }

}
