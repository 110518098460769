<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <label>
    <input matInput [formControl]="control" [matAutocomplete]="auto"
           [maxlength]="maxLength" [required]="required" (ngModelChange)="change($event)" />
  </label>
  <mat-autocomplete #auto>
    <mat-option *ngFor="let option of options" [value]="option">
      <ng-template
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{option: option}">
      </ng-template>
    </mat-option>
  </mat-autocomplete>
  <mat-icon class="prefix" matPrefix *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>
  <mat-icon class="suffix" matSuffix *ngIf="suffixIcon">{{suffixIcon}}</mat-icon>
  <mat-hint class="hint">{{hint}}</mat-hint>
  <mat-error *ngFor="let error of control | filterActiveErrors">{{error}}</mat-error>
</mat-form-field>
