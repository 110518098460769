import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, Injector} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PriceDomainObject} from '../../../../model/domain/price.domain-object';
import {Customer} from '../../../../model/old/Customer';
import {Location} from '../../../../model/old/Location';
import {Material} from '../../../../model/old/Material';
import {Carrier} from '../../../../model/old/Carrier';
import {addDays} from 'date-fns';

export class DispoPricesDialogBundle extends AbstractBypassBundle {
    price: PriceDomainObject;
}

@Component({
    selector: 'app-dispo-prices-dialog',
    templateUrl: './dispo-prices-dialog.component.html',
    styleUrls: ['./dispo-prices-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispoPricesDialogComponent extends BypassFormComponent<DispoPricesDialogBundle> implements AfterViewInit {

    submitted = false;

    form = new FormGroup({
        id: new FormControl(null),
        fromDate: new FormControl(null, [Validators.required]),
        toDate: new FormControl(null, [Validators.required]),
        customer: new FormControl(null, [Validators.required]),
        material: new FormControl(null, [Validators.required]),
        loadingLocation: new FormControl(null, [Validators.required]),
        unloadingLocation: new FormControl(null, [Validators.required]),
        carrier: new FormControl(null),
        priceType: new FormControl(null, [Validators.required]),
        priceEk: new FormControl(null),
        priceFr: new FormControl(null),
        priceVk: new FormControl(null, [Validators.required]),
        comment: new FormControl(null),
        active: new FormControl(true, [Validators.required]),
    });

    constructor(protected injector: Injector,
                public dialogRef: MatDialogRef<DispoPricesDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public input: { price?: any }
    ) {
        super(injector);
        this.bpSetup(new DispoPricesDialogBundle());
    }

    ngAfterViewInit() {
        if (this.input?.price) {
            const patch = {
                ...this.input.price,
                fromDate: this.input.price.fromDate ? new Date(this.input.price.fromDate) : null,
                toDate: this.input.price.toDate ? new Date(this.input.price.toDate) : null,
            };
            this.form.patchValue(patch);
        }
    }

    onSubmit() {
        if (this.form.valid) {
            this.submitted = true;
            const bundle = new DispoPricesDialogBundle();
            bundle.price = new PriceDomainObject();
            Object.assign(bundle.price, {
                ...this.form.value,
                materialId: this.form.value.material?.id,
                loadingLocationId: this.form.value.loadingLocation?.id,
                unloadingLocationId: this.form.value.unloadingLocation?.id,
                customerId: this.form.value.customer?.id,
                carrierId: this.form.value.carrier?.id,
            });
            this.data = bundle;
            this.bpSubmit();
            this.close();
        }
    }

    onDuplicate() {
        this.form.patchValue({
            id: null,
            fromDate: addDays(new Date(this.form.value.toDate), 1),
            toDate: null,
        });
        this.form.get('toDate').markAsTouched();
    }

    onDelete() {
        if (confirm('Achtung! Der Preis wird unwiderruflich gelöscht')) {
            this.data = new DispoPricesDialogBundle();
            this.data.price = {
                id: this.input?.price?.id
            } as PriceDomainObject;
            this.bpSendOperator('delete');
            this.close();
        }
    }

    close() {
        this.dialogRef.close();
    }

    customerToString(data: any) {
        const customer = (data as Customer);
        return `[${customer.shortName}] ${customer.name} ${customer.nameAdd}`;
    }

    locationToString(data: Location) {
        return `[${data.shortName}] ${data.name} | ${data.address.city}`;
    }

    materialToString(data: any) {
        return (data as Material).name;
    }

    carrierToString(data: any) {
        return (data as Carrier).name;
    }

}
