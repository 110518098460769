import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {LocationDomainObject} from '../../../../../model/domain/location.domain-object';
import {MatDialog} from '@angular/material/dialog';
import {DialogManageLocationComponent} from './dialog-manage-location/dialog-manage-location.component';
import {DialogConfirmLocationDeleteComponent} from './dialog-confirm-location-delete/dialog-confirm-location-delete.component';

export class MasterdataLocationsEditorBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    locations: LocationDomainObject[];
}

@Component({
    selector: 'app-masterdata-locations-editor',
    templateUrl: './masterdata-locations-editor.component.html',
    styleUrls: ['./masterdata-locations-editor.component.scss']
})
export class MasterdataLocationsEditorComponent extends BypassFormComponent<MasterdataLocationsEditorBundle> {

    tableColumns = ['icon', 'name', 'title', 'actions'];

    @Output() saved = new EventEmitter();

    businessPartnerId: number;

    locations = [];

    countryTransformValue = (country) => {
        return country;
    };
    countryTransformWrite = (country, options) => {
        if (country) {
            return options.find(o => o.isoCode === country.isoCode);
        }
    };

    @Input() set businessPartner(bp: BusinessPartnerDomainObject) {
        this.businessPartnerId = bp.id;
        if (this.businessPartnerId) {
            const bundle = Object.assign(new MasterdataLocationsEditorBundle(), {businessPartnerId: this.businessPartnerId});
            this.bpSetup(bundle);
        }
    }

    constructor(protected injector: Injector, private dialog: MatDialog) {
        super(injector);
        this.disableAutoLoadingOverlay();
    }

    protected bpOnMessageReceived(): void {
        this.locations = this.data.locations.map(l => {
            return {
                id: l.id,
                name: l.name,
                shortName: l.shortName,
                comment: l.comment,
                address: l.address,
                isLoadingLocation: l['loadingLocation'],
                isUnloadingLocation: l['unloadingLocation']
            };
        });
    }

    addLocation() {
        this.dialog.open(DialogManageLocationComponent, {
            minWidth: '50%'
        }).afterClosed().subscribe(location => {
            if (location) {
                this.locations.push(location);
                this.submit();
            }
        });
    }

    editLocation(index) {
        this.dialog.open(DialogManageLocationComponent, {
            data: this.locations[index],
            minWidth: '50%'
        }).afterClosed().subscribe(editedLocation => {
            if (editedLocation) {
                this.locations[index] = editedLocation;
                this.submit();
            }
        });
    }

    deleteLocation(index: number) {
        this.dialog.open(DialogConfirmLocationDeleteComponent, {
            data: this.locations[index]
        }).afterClosed().subscribe((response: boolean) => {
            if (response) {
                this.locations.splice(index, 1);
                this.submit();
            }
        });
    }

    // TODO: Müssen auch bei edit/create alle Daten zum Backend geschickt werden?
    private submit() {
        this.data = Object.assign(new MasterdataLocationsEditorBundle(), {
            businessPartnerId: this.businessPartnerId,
            locations: this.locations
        });
        this.bpSubmit();
        this.saved.emit();
    }

}
