import {Component} from '@angular/core';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';

@Component({
  selector: 'ui-form-field-slider-toggle',
  templateUrl: './ui-form-slider-toggle.component.html',
  styleUrls: ['./ui-form-slider-toggle.component.scss']
})
export class UiFormSliderToggleComponent extends FormFieldDataComponent {
}
