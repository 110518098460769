import {Component, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';

@Component({
  selector: 'ui-form-field-email',
  templateUrl: '../ui-form-field-basic-input.html',
  styleUrls: ['../ui-form-field-basic-input.scss']
})
export class UiFormFieldEmailComponent extends FormFieldDataComponent {

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
    this.type = 'email';
  }

}
