import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class MasterdataFormBuilder {

    constructor(private fb: FormBuilder) {
    }

    public createBusinessPartnerFormGroup(value: any): FormGroup {
        const group = this.fb.group({
            name: this.fb.control(undefined, [Validators.required]),
            nameAdd: this.fb.control(undefined, [Validators.required]),
            shortName: this.fb.control(undefined, [Validators.required]),
            comment: this.fb.control(undefined),
            businessPartnerGroupId: this.fb.control(null)
        });
        group.patchValue(value);
        return group;
    }

    public createAddressesFormArray(addresses: any[]): FormArray {
        const groups = addresses.map(add => this.createAddressFormGroup(add));
        return this.fb.array(groups);
    }

    public createAddressFormGroup(address: any): FormGroup {
        const group = this.fb.group({
            id: this.fb.control(undefined),
            residentName: this.fb.control(undefined, [Validators.required]),
            street: this.fb.control(undefined, [Validators.required]),
            city: this.fb.control(undefined, [Validators.required]),
            cityCode: this.fb.control(undefined, [Validators.required]),
            countryId: this.fb.control(undefined, [Validators.required])
        });
        if (address) {
            group.patchValue(address);
        }
        return group;
    }

    public createContactsFormArray(contacts: any[]): FormArray {
        const groups = contacts.map(con => this.createContactFormGroup(con));
        return this.fb.array(groups);
    }

    public createContactFormGroup(contact: any): FormGroup {
        const group = this.fb.group({
            id: this.fb.control(undefined),
            name: this.fb.control(undefined),
            phone: this.fb.control(undefined),
            mobile: this.fb.control(undefined),
            email: this.fb.control(undefined),
            fax: this.fb.control(undefined),
            comment: this.fb.control(undefined),
            accountancyEmailReceiver: this.fb.control(false),
            dispositionEmailReceiver: this.fb.control(false),
        });
        if (contact) {
            group.patchValue(contact);
        }
        return group;
    }

    public createLocationsFormArray(locations: any[]): FormArray {
        const groups = locations.map(loc => this.createLocationFormGroup(loc));
        return this.fb.array(groups);
    }

    public createLocationFormGroup(location: any): FormGroup {
        const group = this.fb.group({
            id: this.fb.control(undefined),
            name: this.fb.control(undefined, [Validators.required]),
            shortName: this.fb.control(undefined, [Validators.required]),
            address: this.fb.group({
                residentName: this.fb.control(undefined, [Validators.required]),
                street: this.fb.control(undefined, [Validators.required]),
                city: this.fb.control(undefined, [Validators.required]),
                cityCode: this.fb.control(undefined, [Validators.required]),
                country: this.fb.control(undefined, [Validators.required])
            }),
            isLoadingLocation: this.fb.control(false),
            isUnloadingLocation: this.fb.control(false),
            comment: this.fb.control(undefined)
        });
        if (location) {
            group.patchValue(location);
        }
        return group;
    }

    public createCarriersFormArray(carriers: any[]): FormArray {
        const groups = carriers.map(car => this.createCarrierFormGroup(car));
        return this.fb.array(groups);
    }

    public createCarrierFormGroup(carrier: any): FormGroup {
        const group = this.fb.group({
            id: this.fb.control(undefined),
            name: this.fb.control(undefined, [Validators.required]),
            shortName: this.fb.control(undefined, [Validators.required]),
            email: this.fb.control(undefined, [Validators.email]),
            comment: this.fb.control(undefined),
            own: this.fb.control(undefined),
            active: this.fb.control(undefined),
        });
        if (carrier) {
            group.patchValue(carrier);
        }
        return group;
    }

    public createBankAccountsFormArray(bankAccounts: any[]): FormArray {
        const groups = bankAccounts.map(ba => this.createBankAccountFormGroup(ba));
        return this.fb.array(groups);
    }

    public createBankAccountFormGroup(bankAccount: any): FormGroup {
        const group = this.fb.group({
            id: this.fb.control(undefined),
            bank: this.fb.control(undefined),
            ustId: this.fb.control(undefined),
            ktNr: this.fb.control(undefined),
            blz: this.fb.control(undefined),
            iban: this.fb.control(undefined),
            bic: this.fb.control(undefined)
        });
        if (bankAccount) {
            group.patchValue(bankAccount);
        }
        return group;
    }

    public createCustomerFormGroup(customer: any): FormGroup {
        const group = this.fb.group({
            id: this.fb.control(undefined),
            name: this.fb.control(undefined, [Validators.required]),
            nameAdd: this.fb.control(undefined, [Validators.required]),
            shortName: this.fb.control(undefined, [Validators.required]),
            comment: this.fb.control(undefined)
        });
        if (customer) {
            group.patchValue(customer);
        }
        return group;
    }

}
