import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'checkOverflow'
})
export class CheckOverflowPipe implements PipeTransform {

    transform(element: any): any {
        if (element.offsetHeight < element.scrollHeight ||
            element.offsetWidth < element.scrollWidth) {
            return true;
        } else {
            return false;
        }
    }

}
