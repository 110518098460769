import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AdminComponent} from './admin/admin.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FeaturesModule} from '../features/features.module';
import {DocumentsComponent} from './documents/documents.component';
import {RentalComponent} from './rental/rental.component';
import {SettingsComponent} from './settings/settings.component';
import {OffersComponent} from './offers/offers.component';
import {HelpComponent} from './help/help.component';
import {DevicesComponent} from './devices/devices.component';
import {DispositionModule} from './disposition/disposition.module';
import {LoginPageComponent} from './login-page/login-page.component';
import {AccountancyModule} from './accountancy/accountancy.module';
import {StatisticsModule} from './statistics/statistics.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {RegisterEmployeeComponent} from './register-employee/register-employee.component';
import {BypassCoreModule} from '@byteways/bypass-core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    imports: [
        CommonModule,
        BypassCoreModule,
        FormsModule,
        FlexLayoutModule,
        FeaturesModule,
        ReactiveFormsModule,
        AccountancyModule,
        DispositionModule,
        StatisticsModule,
        DashboardModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule
    ],
    exports: [
        DispositionModule,
        AccountancyModule
    ],
    declarations: [
        AdminComponent,
        DocumentsComponent,
        RentalComponent,
        SettingsComponent,
        OffersComponent,
        HelpComponent,
        DevicesComponent,
        LoginPageComponent,
        RegisterEmployeeComponent,
    ]
})
export class ViewsModule {
}
