import {
    AfterContentChecked,
    AfterContentInit,
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Injector,
    Output,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {MaterialPriceEmbeddedObject} from '../../../../model/domain/material-price.embedded-object';

const validateTradeValue: ValidatorFn = (fg: FormGroup) => {
    const purchasePrice = fg.get('ek').value;
    const freightPrice = fg.get('fr').value;
    const sellingPrice = fg.get('vk').value;

    return ((purchasePrice + freightPrice) < sellingPrice) ? null : {tradeValueError: true};
};

const validatePrices: ValidatorFn = (fg: FormGroup) => {
    const ek = fg.get('ek').value;
    const fr = fg.get('fr').value;
    const hasEkOrFr = ek || fr;
    if (!hasEkOrFr) {
        return {invalidPricesError: true};
    }
    return null;
};

@Component({
    selector: 'app-material-price',
    templateUrl: './material-price.component.html',
    styleUrls: ['./material-price.component.scss'],
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MaterialPriceComponent,
            multi: true
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MaterialPriceComponent),
            multi: true
        }
    ]
})
export class MaterialPriceComponent implements ControlValueAccessor, AfterContentInit {

    private _value;

    formGroup = new FormGroup({
        from: new FormControl(null, [Validators.required]),
        ek: new FormControl(null, [Validators.min(0)]),
        fr: new FormControl(null, [Validators.min(0)]),
        vk: new FormControl(null, [Validators.required, Validators.min(0)])
    }, {validators: [validateTradeValue, validatePrices]});

    ngControl: AbstractControl;

    onChange = (value) => {
    };
    onTouched = () => {
    };

    @Output() delete = new EventEmitter();

    constructor(private injector: Injector) {
    }

    ngAfterContentInit() {
        this.ngControl = this.injector.get(NgControl).control;

        this.formGroup.valueChanges.subscribe((val) => {
            this.value = val;

            if (this.formGroup.valid) {
                this.ngControl.setErrors(null);
            } else {
                this.ngControl.setErrors([{invalidError: true}]);
            }

        }); // TODO: subscription canceln?
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
        if (this.onChange) {
            this.onChange(this.value);
        }
    }

    writeValue(materialPrice: MaterialPriceEmbeddedObject): void {
        this._value = materialPrice;

        if (materialPrice) {
            this.formGroup.patchValue({
                from: materialPrice.from !== null ? new Date(materialPrice.from) : null,
                ek: materialPrice.ek,
                fr: materialPrice.fr,
                vk: materialPrice.vk
            });
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    validate({value}: FormControl) {
        return this.formGroup.invalid && {
            invalid: true
        };
    }

}
