import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AccountancyFormBuilder} from '../../../services/accountancy-form-builder.service';
import {TourDisplay} from '../../partials/invoice-tour-display/invoice-tour-display.component';
import {Subscription} from 'rxjs';
import {Account} from '../../../../../model/old/Account';
import {InvoiceItemFormComponent} from '../invoice-item-form/invoice-item-form.component';
import {EditorItemTypeValue} from '../../../model/editor-item-type.value';

@Component({
    selector: 'app-invoice-form',
    templateUrl: './invoice-form.component.html',
    styleUrls: ['./invoice-form.component.scss']
})
export class InvoiceFormComponent implements OnInit, OnDestroy {

    @Input() businessPartnerId: number;
    @Input() invoiceCommissionType: string | null = null;
    @Output() invoiceCommissionTypeUpdated = new EventEmitter<string | null>();
    @Output() tourRemoved = new EventEmitter<number>();
    @Output() submitted = new EventEmitter<any>();
    @ViewChildren('invoiceItemForm') invoiceItemForms: QueryList<InvoiceItemFormComponent>;
    invoiceForm: FormGroup = this.accountancyFormBuilder.createInvoiceFormGroup();
    @Input() automaticAccountCalculation = true;
    @Output() vatWarnings = new EventEmitter<boolean>();

    private sub: Subscription;

    constructor(public accountancyFormBuilder: AccountancyFormBuilder, private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.sub = this.invoiceForm.valueChanges.subscribe(vc => {
            if (this.automaticAccountCalculation) {
                this.calculateAccounts();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    swapInvoiceItems(i: number, j: number) {
        const items = this.accountancyFormBuilder.getInvoiceItems(this.invoiceForm);
        const valI = items.at(i);
        const valJ = items.at(j);
        items.setControl(i, valJ);
        items.setControl(j, valI);
    }

    deleteInvoiceItem(index: number) {
        const items = this.accountancyFormBuilder.getInvoiceItems(this.invoiceForm);
        if (items.at(index).value.tourId !== null) {
            const tourId = items.at(index).value.tourId;
            this.tourRemoved.emit(tourId);
        }
        items.removeAt(index);
        this.onAutomaticAccountCalculationChange(this.automaticAccountCalculation);
    }

    addTour(tourDisplay: TourDisplay) {
        const invoiceItem = this.accountancyFormBuilder.createInvoiceItemFormGroup();
        invoiceItem.patchValue({
            tourId: tourDisplay.tourId,
            discountable: tourDisplay.commissionType !== 'FRACHT'
        });
        this.accountancyFormBuilder.getInvoiceItems(this.invoiceForm).push(invoiceItem);
        this.onAutomaticAccountCalculationChange(this.automaticAccountCalculation);
    }

    onAutomaticAccountCalculationChange(enabled: boolean) {
        if (enabled) {
            this.calculateAccounts();
        }
    }

    addAccount() {
        const control = this.invoiceForm.get('accounts');
        control.setValue([...control.value, {
            accountId: null,
            sum: null
        }]);
    }

    calculateAccounts() {
        const accounts = this.invoiceForm.get('accounts').value;
        accounts.splice(0, accounts.length);
        if (this.accountancyFormBuilder.getInvoiceItems(this.invoiceForm).length === 0) {
            return;
        }
        const acc8401Handel = Object.assign(new Account(), {
            accountId: '8401',
            sum: (this.getTotalVK() - this.getTotalEK()).toFixed(2)
        });
        const acc8401Fracht = Object.assign(new Account(), {
            accountId: '8401',
            sum: this.getTotalVK().toFixed(2)
        });
        const acc8410Handel = Object.assign(new Account(), {
            accountId: '8410',
            sum: (this.getTotalEK()).toFixed(2)
        });
        const acc8410Franko = Object.assign(new Account(), {
            accountId: '8410',
            sum: this.getTotalVK().toFixed(2)
        });
        const acc1876 = Object.assign(new Account(), {
            accountId: '1876',
            sum: (this.getTotalTax()).toFixed(2)
        });
        const acc8411Co2 = Object.assign(new Account(), {
            accountId: '8411',
            sum: (this.getTotalOfType(EditorItemTypeValue.CO2)).toFixed(2)
        });
        const acc8401Shortfall = Object.assign(new Account(), {
            accountId: '8401',
            sum: (this.getTotalOfType(EditorItemTypeValue.SHORTFALL)).toFixed(2)
        });
        const acc8401Energy = Object.assign(new Account(), {
            accountId: '8401',
            sum: (this.getTotalOfType(EditorItemTypeValue.ENERGY)).toFixed(2)
        });
        const acc8410Energy = Object.assign(new Account(), {
            accountId: '8410',
            sum: (this.getTotalOfType(EditorItemTypeValue.ENERGY)).toFixed(2)
        });
        const acc8408Waiting = Object.assign(new Account(), {
            accountId: '8408',
            sum: (this.getTotalOfType(EditorItemTypeValue.WAITING)).toFixed(2)
        });
        const acc8403Pallet = Object.assign(new Account(), {
            accountId: '8403',
            sum: (this.getTotalOfType(EditorItemTypeValue.PALLET)).toFixed(2)
        });

        const newAccounts: Account[] = [];

        switch (this.invoiceCommissionType) {
            case 'HANDEL':
                newAccounts.push(acc8410Handel, acc8401Handel, acc1876);
                if (acc8410Energy.sum > 0) {
                    newAccounts.push(acc8410Energy);
                }
                break;
            case 'FRACHT':
                newAccounts.push(acc8401Fracht, acc1876);
                if (acc8401Energy.sum > 0) {
                    newAccounts.push(acc8401Energy);
                }
                break;
            case 'FRANKO':
                newAccounts.push(acc8410Franko, acc1876);
                if (acc8410Energy.sum > 0) {
                    newAccounts.push(acc8410Energy);
                }
                break;
            default:
                break;
        }

        const totalCo2 = this.getTotalOfType(EditorItemTypeValue.CO2);
        if (totalCo2 > 0) {
            newAccounts.push(acc8411Co2);
        }
        const totalShortfall = this.getTotalOfType(EditorItemTypeValue.SHORTFALL);
        if (totalShortfall > 0) {
            newAccounts.push(acc8401Shortfall);
        }
        const totalWaiting = this.getTotalOfType(EditorItemTypeValue.WAITING);
        if (totalWaiting > 0) {
            newAccounts.push(acc8408Waiting);
        }
        const totalPallet = this.getTotalOfType(EditorItemTypeValue.PALLET);
        if (totalPallet > 0) {
            newAccounts.push(acc8403Pallet);
        }

        // prepare form array. TODO: necessary?
        if (accounts.length !== newAccounts.length) {
            for (let i = 0; i < newAccounts.length; i++) {
                accounts.push(this.accountancyFormBuilder.createAccountFormGroup());
            }
        }
        // set value
        this.invoiceForm.get('accounts').setValue(newAccounts, {emitEvent: false});

        this.cdr.detectChanges();
    }

    getTotalOfType(type: EditorItemTypeValue) {
        return this.invoiceItemForms
            .toArray()
            .map(form => form.invoiceItemForm.value)
            .filter(i => i.unitCost !== null && i.unitCost !== undefined)
            .filter(i => i.quantity !== null && i.quantity !== undefined)
            .filter(i => i.type === type)
            .map(i => (i.quantity * i.unitCost))
            .reduce((a, b) => a + b, 0);
    }

    getTotalVK() {
        return this.invoiceItemForms
            .toArray()
            .map(form => form.invoiceItemForm.value)
            .filter(i => i.unitCost !== null && i.unitCost !== undefined)
            .filter(i => i.quantity !== null && i.quantity !== undefined)
            .filter(i => i.type === EditorItemTypeValue.DEFAULT)
            .map(i => (i.quantity * i.unitCost))
            .reduce((a, b) => a + b, 0);
    }

    getTotalEK() {
        return this.invoiceItemForms
            .toArray()
            .filter(i => i.invoiceItemForm.value.type === EditorItemTypeValue.DEFAULT)
            .map(form => {
                const ek = form.getTourPriceEk() || 0;
                const quantity = form.invoiceItemForm.value.quantity || 0;
                return ek * quantity;
            })
            .reduce((a, b) => a + b, 0);
    }

    getTotalTax() {
        return this.invoiceItemForms
            .toArray()
            .map(form => form.invoiceItemForm.value)
            .filter(i => i.unitCost !== null && i.unitCost !== undefined)
            .filter(i => i.quantity !== null && i.quantity !== undefined)
            .filter(i => i.vat !== null && i.vat !== undefined)
            .map(i => (i.quantity * i.unitCost) * (i.vat / 100.0))
            .reduce((a, b) => a + b, 0);
    }

    updateCommissionType(commissionType: string | null) {
        if (commissionType !== null) {
            this.invoiceCommissionType = commissionType;
        }
        this.invoiceCommissionTypeUpdated.emit(commissionType);
    }


    hasManualPositions(): boolean {
        const items = this.accountancyFormBuilder.getInvoiceItems(this.invoiceForm).controls.map(c => c.value);
        for (const item of items) {
            if (!item.tourId && item.type === EditorItemTypeValue.DEFAULT) {
                return true;
            }
        }
        return false;
    }

    onSubmit(): void {
        this.submitted.emit(this.invoiceForm.value);
    }

}
