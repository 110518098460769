import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Account} from '../../../model/old/Account';

@Component({
    selector: 'app-accountancy-invoicing-editor-accounts',
    templateUrl: './accountancy-invoicing-editor-accounts.component.html',
    styleUrls: ['./accountancy-invoicing-editor-accounts.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AccountancyInvoicingEditorAccountsComponent),
        multi: true
    }]
})
export class AccountancyInvoicingEditorAccountsComponent implements OnInit, ControlValueAccessor {

    manualMode = false;

    private value: Account[] = [];

    set accounts(value: Account[]) {
        this.propagateChange(value);
    }

    get accounts(): Account[] {
        return this.value;
    }

    createForm = new FormGroup({
        accountId: new FormControl(undefined, [Validators.required]),
        sum: new FormControl(undefined, [Validators.required])
    });

    onChange = (_: any) => {
    };
    onTouched = () => {
    };

    constructor() {
    }

    ngOnInit() {
    }

    private propagateChange(value: Account[]) {
        this.value = value;
        if (!this.value) {
            this.value = [];
        }
        if (this.value.length === 0) {
            this.value.push(new Account());
        }
        this.onChange(this.value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.propagateChange(obj);
    }

    addAccount() {
        const next = this.accounts.concat([new Account()]);
        this.propagateChange(next);
    }

    deleteAccount(i: number) {
        const next = this.accounts.slice();
        next.splice(i, 1);
        this.propagateChange(next);
    }

    getBrutto(sum: number): number {
        return Math.round(sum * 119.0) / 100.0;
    }

}
