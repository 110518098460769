import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {TourStatistics} from '../../model/tour-statistics.model';
import {WindowRefService} from '../../../../window-ref.service';
import {Customer} from '../../../../model/old/Customer';
import {BusinessPartnerGroupDisplayComponent} from './business-partner-group-display-component/business-partner-group-display.component';
import {BusinessPartnerDomainObject} from '../../../../model/domain/business-partner.domain-object';
import {BusinessPartnerGroup} from '../../../../model/old/BusinessPartnerGroup';
import {BusinessPartnerDisplayComponent} from './business-partner-display-component/business-partner-display.component';
import { BusinessPartner } from '../../../../model/old/BusinessPartner';

export class DispoStatsBundle extends AbstractBypassBundle {
    from: Date;
    to: Date;
    result: TourStatistics | null;
    businessPartnerGroupOptions: BusinessPartnerGroup[];
    businessPartnerOptions: BusinessPartner[];
}

@Component({
    selector: 'app-dispo-stats',
    templateUrl: './dispo-stats.component.html',
    styleUrls: ['./dispo-stats.component.scss']
})
export class DispoStatsComponent extends BypassFormComponent<DispoStatsBundle> implements OnInit {


    form = this.formBuilder.group({
        from: new FormControl(null, [Validators.required]),
        to: new FormControl(null, [Validators.required]),
        businessPartners: new FormControl(null, []),
        businessPartnerGroups: new FormControl(null, []),
        mode: new FormControl('by_invoice', [Validators.required])
    }, {
        validator: (formGroup: FormGroup) => {
            const startForm = formGroup.get('from');
            const endForm = formGroup.get('to');

            const start = startForm.value as Date;
            const end = endForm.value as Date;


            if (start && end && start.getTime() > end.getTime()) {
                startForm.setErrors({'': ''});
                endForm.setErrors({'': ''});
            } else {
                startForm.setErrors(null);
                endForm.setErrors(null);
            }

            return false;
        }
    });

    submitted = false;

    dispoStatsFieldSelectionParameter;

    businessPartnerGroupOptions: BusinessPartnerGroup[] = [];
    businessPartnerOptions: BusinessPartner[] = [];

    idMapFunction = (items: BusinessPartnerGroup[]) => {
        return items.map(item => item.id);
    }

    nameComparator: (a: {name: string}, b: {name: string}) => number = ((a, b) => a.name < b.name ? -1 : 1);

    getDisplayComponent(): any {
        return BusinessPartnerDisplayComponent;
    }

    getGroupDisplayComponent(): any {
        return BusinessPartnerGroupDisplayComponent;
    }

    constructor(protected injector: Injector, private windowRef: WindowRefService, private formBuilder: FormBuilder) {
        super(injector);
        this.bpSetup(new DispoStatsBundle());
    }

    protected bpOnMessageReceived() {
        this.businessPartnerGroupOptions = this.data.businessPartnerGroupOptions;
        this.businessPartnerOptions = this.data.businessPartnerOptions;
    }

    ngOnInit() {
        const now = new Date();
        const dateFrom = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const dateTo = new Date(now.getFullYear(), now.getMonth(), 0);
        this.form.patchValue({
            from: dateFrom,
            to: dateTo
        });
    }

    onSubmit() {
        this.submitted = true;
        this.data = Object.assign(new DispoStatsBundle(), {
            from: this.getFrom(),
            to: this.getTo(),
            result: null
        });
        this.bpSetLoadingUntilMessageReceived();
        this.bpSubmit();
    }

    getFrom(): Date {
        const from = new Date(this.form.value.from);
        from.setHours(0, 0, 0);
        return from;
    }

    getTo(): Date {
        const to = new Date(this.form.value.to);
        to.setHours(23, 59, 59);
        return to;
    }

    download() {
        const from = this.formatDate(this.getFrom());
        const to = this.formatDate(this.getTo());

        this.windowRef.open(`/export/dispostats.xlsx?from=${from}&to=${to}`);
    }

    downloadTours() {
        const from = this.formatDate(this.getFrom());
        const to = this.formatDate(this.getTo());
        const businessPartnerOptions = this.form.get('businessPartners').value ?? '';
        const businessPartnerGroupOptions = this.form.get('businessPartnerGroups').value ?? '';
        const mode = this.form.get('mode').value;
        this.windowRef.open(`/export/touren.xlsx?from=${from}&to=${to}&fields=${this.dispoStatsFieldSelectionParameter}&businessPartners=${businessPartnerOptions}&businessPartnerGroups=${businessPartnerGroupOptions}&mode=${mode}`);
    }

    formatDate(date: Date): string {
        const deDate = new Date(date).toLocaleDateString('de-DE');
        const split = deDate.split('.');
        let year = split[2];
        while (year.length < 4) {
            year = '0' + year;
        }
        let month = split[1];
        while (month.length < 2) {
            month = '0' + month;
        }
        let day = split[0];
        while (day.length < 2) {
            day = '0' + day;
        }
        return `${year}${month}${day}`;
    }

}
