import {AfterViewInit, Component, EventEmitter, Injector, Input, OnDestroy, Output} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Tour} from '../../../model/old/Tour';

export interface PriceRequest {
    date: Date | null;
    customerId: number | null;
    loadingLocationId: number | null;
    unloadingLocationId: number | null;
    materialId: number | null;
}

export class CreateNewTourPriceWidgetBundle extends AbstractBypassBundle {
    priceRequest: PriceRequest | null;
    prices: any[] | null;
}

@Component({
    selector: 'app-createnewtour-price-widget',
    templateUrl: './createnewtour-price-widget.component.html',
    styleUrls: ['./createnewtour-price-widget.component.scss']
})
export class CreateNewTourPriceWidgetComponent extends BypassFormComponent<CreateNewTourPriceWidgetBundle> implements AfterViewInit, OnDestroy {
    @Input() showHint = true;
    init = false;
    readonly tour$: BehaviorSubject<FormGroup | null> = new BehaviorSubject<FormGroup | null>(null);
    readonly tourValueChanges$: Observable<Tour> = this.tour$.pipe(
        filter(fg => !!fg),
        switchMap(fg => fg.valueChanges),
    );
    readonly priceRequest$: Observable<PriceRequest> = this.tourValueChanges$.pipe(
        debounceTime(300),
        tap(console.log),
        map(tour => {
            return tour ? {
                date: tour.targetDate?.fromDate ?? null,
                customerId: tour.customer?.id ?? tour.preset?.customer?.id,
                loadingLocationId: tour.loadingLocation?.id ?? tour?.preset?.loadingLocation?.id,
                unloadingLocationId: tour.unloadingLocation?.id ?? tour?.preset?.unloadingLocation?.id,
                materialId: tour.material?.id ?? tour?.preset?.material?.id,
                carrierId: tour.schedule?.carrier?.id ?? tour['carrier']?.id ?? null,
            } : null;
        }),
        distinctUntilChanged((a, b) =>
            !((a.date ? new Date(a.date).getTime() : null) !== (b.date ? new Date(b.date).getTime() : null) ||
                a.materialId !== b.materialId ||
                a.customerId !== b.customerId ||
                a.loadingLocationId !== b.loadingLocationId ||
                a.unloadingLocationId !== b.unloadingLocationId ||
                a.carrierId !== b.carrierId
            )
        ),
        filter(priceRequest => !!priceRequest),
    );
    @Output() priceFound = new EventEmitter<any>();
    private readonly destroyed$: Subject<void> = new Subject<void>();


    constructor(protected injector: Injector) {
        super(injector);
        this.disableAutoLoadingOverlay();
        this.bpSetLoadingUntilMessageReceived();
        this.bpSetup(new CreateNewTourPriceWidgetBundle());
    }

    @Input() set tourFormGroup(tourFormGroup: FormGroup) {
        this.tour$.next(tourFormGroup);
        setTimeout(() => tourFormGroup.updateValueAndValidity());
    }

    ngAfterViewInit() {
        this.priceRequest$.pipe(takeUntil(this.destroyed$)).subscribe(pr => {
            this.init = true;
            this.data = new CreateNewTourPriceWidgetBundle();
            this.data.priceRequest = pr;
            this.bpSetLoadingUntilMessageReceived();
            this.bpRead();
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    protected bpOnMessageReceived() {
        super.bpOnMessageReceived();
        this.loading.next(false);
    }

}
