import {Component, OnInit, Injector} from '@angular/core';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';

export class ErrorBundle extends AbstractBypassBundle {
    error: string;
}

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends BypassDataComponent<ErrorBundle> {

    error: ErrorBundle;

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new ErrorBundle());
    }

    protected bpOnMessageReceived(): void {
        this.error = this.data;
    }

    get href(): string | undefined {
        if (!this.error) {
            return undefined;
        }

        const receiver = 'jan-philipp.vogtherr@byteways.de';
        const subject = `Serverfehler`;
        const date = `${new Date().toLocaleDateString('de')} um ${new Date().toLocaleTimeString('de')} Uhr`;
        const body = escape(`Fehlermeldung:\n${this.error.error}\n\nZeitpunkt:\n${date}\n\nWeitere Informationen:\n`);

        return `mailto:${receiver}?subject=${subject}&body=${body}`;
    }

    close() {
        this.error = null;
    }

}
