import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {DateRange} from '../../../model/old/DateRange';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class DialogDuplicateTourBundle extends AbstractBypassBundle {
    tourId: number;
    dateRanges: DateRange[] = [];
}

@Component({
    selector: 'app-dialogduplicatetour',
    templateUrl: './dialogduplicatetour.component.html',
    styleUrls: ['./dialogduplicatetour.component.scss']
})
export class DialogDuplicateTourComponent extends BypassFormComponent<DialogDuplicateTourBundle> implements OnInit {

    dateRanges: DateRange[] = [];

    constructor(protected injector: Injector,
                public dialogRef: MatDialogRef<DialogDuplicateTourComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new DialogDuplicateTourBundle());
    }

    ngOnInit() {
        this.addDateRange();
    }

    submit() {
        this.data = new DialogDuplicateTourBundle();
        this.data.tourId = this.input.tour.id;
        this.data.dateRanges = [...this.dateRanges];
        this.bpSubmit();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }

    addDateRange() {
        this.dateRanges.push(Object.assign(new DateRange(), this.input.tour.targetDate));
    }

    trackByIndex(index: number, obj: any): any {
        return index;
    }

}
