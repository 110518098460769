import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-dispo-plan-daypicker',
    templateUrl: './dispo-plan-daypicker.component.html',
    styleUrls: ['./dispo-plan-daypicker.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DispoPlanDaypickerComponent),
        multi: true
    }]
})
export class DispoPlanDaypickerComponent implements ControlValueAccessor {

    state: Date;
    listeners = [];

    registerOnChange(fn: any): void {
        this.listeners.push(fn);
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.state = obj;
    }

    addDays(n: number): void {
        this.state = new Date(this.state.getTime() + n * 24 * 60 * 60 * 1000);
        this.notify();
    }

    notify() {
        this.listeners.forEach(listener => listener(this.state));
    }

}
