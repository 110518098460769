import {NgModule} from '@angular/core';
import {BypassIdenticonComponent} from './bypass-identicon.component';
import {BypassCoreModule} from '@byteways/bypass-core';


@NgModule({
  declarations: [BypassIdenticonComponent],
  imports: [
    BypassCoreModule
  ],
  exports: [BypassIdenticonComponent]
})
export class BypassIdenticonModule {
}
