import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accountancy-taxes',
  templateUrl: './accountancy-taxes.component.html',
  styleUrls: ['./accountancy-taxes.component.scss']
})
export class AccountancyTaxesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
