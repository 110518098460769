import {Component, Inject, Injector, OnInit} from '@angular/core';
import {Invoice} from '../../../model/old/Invoice';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-accountancy-dialog-show-invoice',
    templateUrl: './accountancy-dialog-show-invoice.component.html',
    styleUrls: ['./accountancy-dialog-show-invoice.component.scss']
})
export class AccountancyDialogShowInvoiceComponent implements OnInit {

    invoice: Invoice = undefined;

    constructor(protected injector: Injector,
                public dialog: MatDialog,
                public dialogRef: MatDialogRef<AccountancyDialogShowInvoiceComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any,
    ) {
        this.invoice = input.invoice;
    }

    ngOnInit() {
    }


    formatDate(date: Date) {
        return new Date(date).toLocaleString('de').slice(0, -3);
    }

    close() {
        this.dialogRef.close();
    }


    formatPrice(price: number): string {
        if (price === undefined || price === null) { return '-'; }
        return `${price.toFixed(2).toString().replace('.', ',')} €`;
    }


}
