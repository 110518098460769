import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ContextMenuComponent} from 'ngx-contextmenu';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {WindowRefService} from '../../../window-ref.service';
import {Invoice} from '../../../model/old/Invoice';
import {PageSettings} from '../../../model/old/PageSettings';
import {Account} from '../../../model/old/Account';
import {AccountancyDialogFinalizeInvoiceComponent} from '../accountancy-dialog-finalize-invoice/accountancy-dialog-finalize-invoice.component';
import {AccountancyDialogShowInvoiceComponent} from '../accountancy-dialog-show-invoice/accountancy-dialog-show-invoice.component';
import {AccountancyDialogDeleteInvoiceComponent} from '../accountancy-dialog-delete-invoice/accountancy-dialog-delete-invoice.component';
import {AccountancyDialogCancelInvoiceComponent} from '../accountancy-dialog-cancel-invoice/accountancy-dialog-cancel-invoice.component';
import {DemoDialogComponent} from '../../../features/demo-dialog/demo-dialog.component';
import {AccountancyDialogSendInvoiceComponent} from '../accountancy-dialog-send-invoice/accountancy-dialog-send-invoice.component';
import {Router} from '@angular/router';
import {AccountancyDialogCorrectInvoiceComponent} from '../accountancy-dialog-correct-invoice/accountancy-dialog-correct-invoice.component';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

export class AccountancyInvoiceTableBundle extends AbstractBypassBundle {
    pageSettings: PageSettings;
    total: number;
    entries: Invoice[];
}

@Component({
    selector: 'app-accountancy-invoice-table',
    templateUrl: './accountancy-invoice-table.component.html',
    styleUrls: ['./accountancy-invoice-table.component.scss']
})
export class AccountancyInvoiceTableComponent extends BypassFormComponent<AccountancyInvoiceTableBundle> implements OnInit {

    paginatorPageSizeOptions = [25, 50, 100, 250, 500];
    paginatorPageSize = this.paginatorPageSizeOptions[1];
    _filter: string = undefined;
    filterTimeout = undefined;
    filterDateModel = undefined;

    @ViewChild(ContextMenuComponent, {static: true}) public basicMenu: ContextMenuComponent;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    displayedColumns = ['status', 'createdDate', 'type', 'invoiceNumber', 'businessPartner', 'bookingDate', 'incomingInvoiceNumber', 'tourNumbers', 'subcontractorInvoiceNumber', 'accounts', 'netto', 'brutto'];
    dataSource = [];

    constructor(
        protected injector: Injector,
        public dialog: MatDialog,
        private windowRef: WindowRefService,
        private router: Router
    ) {
        super(injector);
        this.disableAutoLoadingOverlay();
        this.bpSetup(new AccountancyInvoiceTableBundle());
    }

    ngOnInit() {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.paginatorPageSize;

        this.sort.active = 'createdDate';
        this.sort.direction = 'desc';

        merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadData())).subscribe();
        this.loadData();
    }

    private loadData() {
        this.data = new AccountancyInvoiceTableBundle();
        this.data.entries = [];

        let pageIndex = 0;
        if (this.paginator.pageIndex !== undefined) {
            pageIndex = this.paginator.pageIndex;
        }
        let pageSize = this.paginatorPageSize;
        if (this.paginator.pageSize !== undefined) {
            pageSize = this.paginator.pageSize;
        }

        this.data.pageSettings = new PageSettings(
            'Invoice',
            pageIndex,
            pageSize,
            this.sort.active,
            this.sort.direction,
            this._filter
        );

        this.bpSubmit();
    }

    bpOnMessageReceived() {
        this.dataSource = this.data.entries;
        this.paginator.length = this.data.total;
    }

    applyFilter(filterValue: string) {
        if (filterValue === '') {
            this._filter = undefined;
        } else {
            this._filter = filterValue.toLowerCase();
        }
        if (this.filterTimeout !== undefined) {
            clearTimeout(this.filterTimeout);
        }
        this.filterTimeout = setTimeout(() => {
            this.loadData();
        }, 250);
    }

    onExportClick(filter: string) {
        alert('Basierend auf dem aktuellen Filter werden die Rechnungen als CSV-Datei exportiert.');
        this.windowRef.open(`/export/rechnungsausgangsbuch.csv?filter=` + escape(filter));
    }

    //
    // formatPrice(price: number): string {
    //     return `${price.toFixed(2).toString().replace('.', ',')} €`;
    // }

    onRowClick(invoice: Invoice) {
        // this.showInvoice(invoice);
    }

    todayToFilterInput(): string {
        return this.dateToFilterInput(new Date());
    }

    dateToFilterInput(date: Date): string {
        const y = date.getUTCFullYear().toString();
        const m = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const d = (date.getUTCDate() + 1).toString().padStart(2, '0');
        return `${y}${m}${d}`;
    }

    getInvoiceNumber(invoice: Invoice): string {
        if (invoice.invoiceNumber === undefined || invoice.invoiceNumber === null) {
            return '-';
        } else {
            return invoice.invoiceNumber + '';
        }
    }

    getInvoiceType(invoice: Invoice): string {
        switch (invoice.type) {
            case 'CANCELLATION':
                return 'Stornierung';
            case 'CORRECTION':
                return 'Korrektur';
            case 'INCOMING_CREDIT':
                return 'Gutschrift';
            default:
                return 'Rechnung';
        }
    }

    statusToColor(status: string): string {
        switch (status.toLocaleLowerCase()) {
            case 'open':
                return 'purple';
            case 'closed':
                return 'yellow';
            case 'cancelled':
                return 'red';
            case 'sent':
                return 'green';
            case 'corrected':
                return 'salmon';
            default:
                return 'blue';
        }
    }

    getTourNumbers(invoice: Invoice): string {
        const tourNrs = invoice
            .invoiceItems
            .filter(i => i.tour !== null && i.tour !== undefined)
            .map(i => i.tour.tourNumber)
            .join(', ');
        if (tourNrs.length === 0) {
            return '-';
        }
        return tourNrs;
    }

    showInvoicePDF(invoice: Invoice) {
        this.windowRef.open(`/document/invoice/${invoice.invoiceNumber}`);
    }

    showInvoicePreviewPDF(invoice: Invoice) {
        this.windowRef.open(`/document/invoicepreview/${invoice.id}`);
    }

    showInvoiceDetails(invoice: Invoice) {
        this.dialog.open(AccountancyDialogShowInvoiceComponent, {data: {invoice: invoice}, width: '1000px'});
    }

    lockInvoice(invoice: Invoice) {
        this.dialog.open(AccountancyDialogFinalizeInvoiceComponent, {data: {invoice: invoice}, width: '1000px'});
    }

    editInvoice(invoice: Invoice) {
        this.router.navigateByUrl(`/accountancy/editor/${invoice.type.toLowerCase().replace('_', '')}/${invoice.businessPartner.id}/edit/${invoice.id}`);
    }

    deleteInvoice(invoice: Invoice) {
        this.dialog.open(AccountancyDialogDeleteInvoiceComponent, {data: {invoice: invoice}, width: '1000px'});
    }

    cancelInvoice(invoice: Invoice) {
        this.dialog.open(AccountancyDialogCancelInvoiceComponent, {data: {invoice: invoice}, width: '1000px'});
    }

    correctInvoice(invoice: Invoice) {
        this.dialog.open(AccountancyDialogCorrectInvoiceComponent, {data: {invoice: invoice}, width: '1000px'});
    }

    sendInvoice(invoice: Invoice) {
        this.dialog.open(AccountancyDialogSendInvoiceComponent, {data: {invoice: invoice}, width: '1000px'});
    }

    invoiceClosed(invoice: Invoice) {
        return invoice.status === 'CLOSED';
    }

    invoiceCanBeCancelled(invoice: Invoice) {
        return (invoice.status === 'SENT'
            || invoice.status === 'CLOSED'
            || invoice.status === 'CORRECTED')
            && (invoice.type !== 'CANCELLATION');
    }

    invoiceCanBeCorrected(invoice: Invoice) {
        return (invoice.status === 'SENT'
            || invoice.status === 'CLOSED'
            || invoice.status === 'CORRECTED')
            && invoice.type !== 'CANCELLATION'
            && invoice.type !== 'CORRECTION';
    }

    invoiceSent(invoice: Invoice) {
        return invoice.status === 'SENT';
    }

    invoiceOpen(invoice: Invoice) {
        return invoice.status === 'OPEN';
    }

    invoiceCanEdit(invoice: Invoice) {
        return invoice.status === 'OPEN' && (invoice.type === 'INVOICE' || invoice.type === 'INCOMING_CREDIT');
    }

    invoicePDFAvailable(invoice: Invoice) {
        return invoice.status !== 'OPEN';
    }

    accountsToString(accs: Account[]) {
        return accs.map(a => `${a.accountId}: ${this.formatPrice(a.sum)}`).sort((a, b) => a.localeCompare(b)).join(', ');
    }

    private static localeOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    formatPrice(value: number): string {
        if (value === undefined || value === null) {
            return ' — ';
        }
        return value.toLocaleString(undefined, AccountancyInvoiceTableComponent.localeOptions) + ' €';
    }

    statusToString(invoice: Invoice): string {
        switch (invoice.status) {
            case 'OPEN':
                return 'Offen';
            case 'CLOSED':
                return 'Abgeschlossen';
            case 'CANCELLED':
                return 'Storniert';
            case 'CORRECTED':
                return 'Korrigiert';
            case 'SENT':
                return 'Verschickt';
            default:
                return 'Unbekannt';
        }
    }

    typeToColor(type: string): string {
        switch (type) {
            case 'INVOICE':
                return 'black';
            case 'INCOMING_CREDIT':
                return 'green';
            case 'CANCELLATION':
                return 'red';
            case 'CORRECTION':
                return 'salmon';
            default:
                console.warn('Unknown invoice type: ', type);
                return 'black';
        }
    }

    typeToCurrencyColor(type: string): string {
        switch (type) {
            case 'INVOICE':
                return 'black';
            case 'INCOMING_CREDIT':
                return 'green';
            case 'CANCELLATION':
                return 'red';
            case 'CORRECTION':
                return 'salmon';
            default:
                console.warn('Unknown invoice type: ', type);
                return 'black';
        }
    }


}
