import {Component, Inject, Injector, OnInit} from '@angular/core';
import {BypassFormComponent} from '@byteways/bypass-core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class PresetDeleteBundle {
    presetId: number;
}

@Component({
    selector: 'app-preset-delete',
    templateUrl: './preset-delete.component.html',
    styleUrls: ['./preset-delete.component.scss']
})
export class PresetDeleteComponent extends BypassFormComponent<PresetDeleteBundle> implements OnInit {

    constructor(protected injector: Injector,
                public dialogRef: MatDialogRef<PresetDeleteComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new PresetDeleteBundle());
    }


    ngOnInit() {
    }

    submit() {
        this.data = Object.assign(new PresetDeleteBundle(), {
            presetId: this.input.preset.id
        });
        this.bpSubmit();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }

}
