import {Component, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {TaxGroupDomainObject} from '../../../model/domain/tax-group.domain-object';
import {AccountancyTaxesTaxGroupDialogCreateComponent} from '../accountancy-taxes-tax-group-dialog-create/accountancy-taxes-tax-group-dialog-create.component';
import {AccountancyTaxesTaxGroupDialogDeleteComponent} from '../accountancy-taxes-tax-group-dialog-delete/accountancy-taxes-tax-group-dialog-delete.component';
import {AccountancyTaxesTaxGroupDialogDetailsComponent} from '../accountancy-taxes-tax-group-dialog-details/accountancy-taxes-tax-group-dialog-details.component';
import {AccountancyTaxesTaxGroupDialogUpdateComponent} from '../accountancy-taxes-tax-group-dialog-update/accountancy-taxes-tax-group-dialog-update.component';
import {MatDialog} from '@angular/material/dialog';

export class AccountancyTaxesTaxGroupsBundle extends AbstractBypassBundle {
    taxGroups: TaxGroupDomainObject[];
}

@Component({
    selector: 'app-accountancy-taxes-tax-groups',
    templateUrl: './accountancy-taxes-tax-groups.component.html',
    styleUrls: ['./accountancy-taxes-tax-groups.component.scss']
})
export class AccountancyTaxesTaxGroupsComponent extends BypassDataComponent<AccountancyTaxesTaxGroupsBundle> implements OnInit {

    constructor(protected injector: Injector, private dialog: MatDialog) {
        super(injector);
        this.bpSetup(new AccountancyTaxesTaxGroupsBundle());
    }

    ngOnInit() {
    }

    openDialogCreate() {
        this.dialog.open(AccountancyTaxesTaxGroupDialogCreateComponent, {width: '1000px'});
    }

    openDialogDetails(taxGroup: TaxGroupDomainObject) {
        this.dialog.open(AccountancyTaxesTaxGroupDialogDetailsComponent, {width: '1000px', data: { taxGroup: taxGroup }});
    }

    openDialogUpdate(taxGroup: TaxGroupDomainObject) {
        this.dialog.open(AccountancyTaxesTaxGroupDialogUpdateComponent, {width: '1000px', data: { taxGroup: taxGroup }});
    }

    openDialogDelete(id: number) {
        this.dialog.open(AccountancyTaxesTaxGroupDialogDeleteComponent, {width: '1000px', data: { taxGroupId: id }});
    }

}
