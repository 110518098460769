import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {DateRange} from '../../../../model/old/DateRange';

@Component({
  selector: 'app-dispo-plan-target-date-snackbar',
  templateUrl: './dispo-plan-target-date-snackbar.component.html',
  styleUrls: ['./dispo-plan-target-date-snackbar.component.scss']
})
export class DispoPlanTargetDateSnackbarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: DateRange) { }

  ngOnInit() {
  }

}
