import {InjectableRxStompConfig} from '@stomp/ng2-stompjs';
import {BypassDataService} from '../bypass-data.service';

export const stompConfig: InjectableRxStompConfig = {
  brokerURL: BypassDataService.getWebsocketHost(),
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 5000, // Typical value 20000 - every 20 seconds
  reconnectDelay: 2500,
  splitLargeFrames: true
};
