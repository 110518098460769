import {AfterViewInit, Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {DragDropPlanPreviewConfig} from '../../model/DragDropPlanPreviewConfig';
import {DragDropPlanComponent} from '../drag-drop-plan/drag-drop-plan.component';

@Component({
  selector: 'ui-drag-drop-preview',
  templateUrl: './drag-drop-preview.component.html',
  styleUrls: ['./drag-drop-preview.component.scss']
})
export class DragDropPreviewComponent implements AfterViewInit {

  /**
   * Preview element view child.
   */
  @ViewChild('preview', {static: true}) preview: ElementRef;

  private _config;

  @Input() set config(config: DragDropPlanPreviewConfig) {
    this._config = config;
    if (this.config) {
      this.setConfig(config);
    }
  }

  get config(): DragDropPlanPreviewConfig {
    return this._config;
  }

  constructor(@Inject(DragDropPlanComponent) public parent: DragDropPlanComponent) {
  }

  ngAfterViewInit() {
    this.parent.hidePreview.subscribe((hide: boolean) => {
      this.preview.nativeElement.style.visibility = hide ? 'collapse' : 'visible';
    });
  }

  setConfig(config: DragDropPlanPreviewConfig) {
    const style = `transform: translate3d(${config.x}px, ${config.y}px, 0px); width: ${config.width}px; height: ${config.height}px;`;
    this.preview.nativeElement.setAttribute('style', style);
  }

}
