import {Component, Input, OnInit} from '@angular/core';
import {Preset} from '../../model/old/Preset';
import {Location} from '../../model/old/Location';

@Component({
    selector: 'app-presetsummary',
    templateUrl: './preset-summary.component.html',
    styleUrls: ['./preset-summary.component.scss']
})
export class PresetSummaryComponent {

    @Input() preset: Preset;

}
