import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {TaxGroupDomainObject} from '../../../model/domain/tax-group.domain-object';
import {Component, Inject, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


export class TaxGroupUpdateData {
    taxGroup: TaxGroupDomainObject;
}

export class AccountancyTaxesTaxGroupDialogUpdateBundle extends AbstractBypassBundle {
    taxGroup: TaxGroupDomainObject;
}

@Component({
    selector: 'app-accountancy-taxes-tax-group-dialog-update',
    templateUrl: './accountancy-taxes-tax-group-dialog-update.component.html',
    styleUrls: ['./accountancy-taxes-tax-group-dialog-update.component.scss']
})
export class AccountancyTaxesTaxGroupDialogUpdateComponent extends BypassFormComponent<AccountancyTaxesTaxGroupDialogUpdateBundle> implements OnInit {

    submitted = false;

    taxGroupForm = new FormGroup({
        taxGroup: new FormControl(undefined, [Validators.required])
    });

    constructor(
        protected injector: Injector,
        private dialogRef: MatDialogRef<AccountancyTaxesTaxGroupDialogUpdateComponent>,
        @Inject(MAT_DIALOG_DATA) public input: TaxGroupUpdateData
    ) {
        super(injector);
        this.bpSetup(new AccountancyTaxesTaxGroupDialogUpdateBundle(), false);
    }

    ngOnInit(): void {
        this.taxGroupForm.get('taxGroup').patchValue(this.input.taxGroup);
    }

    close() {
        this.dialogRef.close();
    }

    update() {
        this.submitted = true;
        this.data = Object.assign(new AccountancyTaxesTaxGroupDialogUpdateBundle(), {
            taxGroup: this.taxGroupForm.get('taxGroup').value
        });
        this.bpSubmit();
        this.close();
    }

}
