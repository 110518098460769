import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

    @Input() hideHeader = false;
    @Input() header: string;
    @Input() megaHeader: string;
    @Input() showFullscreenToggle = false;
    @Output() onFullscreenToggle = new EventEmitter<boolean>();

    isFullscreen = false;

    constructor() {
    }

    ngOnInit() {
    }

    toggleFullscreen() {
        this.isFullscreen = !this.isFullscreen;
        this.onFullscreenToggle.emit(this.isFullscreen);
    }

}
