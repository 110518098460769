import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-text-display',
    templateUrl: './text-display.component.html',
    styleUrls: ['./text-display.component.scss']
})
export class TextDisplayComponent implements OnInit {

    @Input() text = '';
    @Input() maxLength = 10;

    constructor() {
    }

    ngOnInit() {
    }

    display() {
        if (this.text === undefined || this.text === null || this.text === '') {
            return '-';
        } else if (this.text.length > this.maxLength) {
            return `${this.text.substring(0, this.maxLength)}...`;
        } else {
            return this.text;
        }
    }

}
