import {Component, forwardRef, Inject, Injector, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {AccountancyDialogCancelInvoiceBundle} from '../accountancy-dialog-cancel-invoice/accountancy-dialog-cancel-invoice.component';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

export class AccountancyTaxesTaxGroupDialogCreateBundle extends AbstractBypassBundle {
    name: string;
}

@Component({
    selector: 'app-accountancy-taxes-tax-group-dialog-create',
    templateUrl: './accountancy-taxes-tax-group-dialog-create.component.html',
    styleUrls: ['./accountancy-taxes-tax-group-dialog-create.component.scss']
})
export class AccountancyTaxesTaxGroupDialogCreateComponent extends BypassFormComponent<AccountancyTaxesTaxGroupDialogCreateBundle> implements OnInit {

    submitted = false;

    taxFormGroup = this.fb.group({
        name: this.fb.control('', [Validators.required]),
        // taxRules: this.fb.array([this.createTaxRuleFormGroup()])
    });

    constructor(
        protected injector: Injector,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AccountancyTaxesTaxGroupDialogCreateComponent>,
        private fb: FormBuilder
    ) {
        super(injector);
        this.bpSetup(new AccountancyTaxesTaxGroupDialogCreateBundle());
        // TODO: read
    }

    ngOnInit(): void {
    }

    getTaxRuleFormArray(): FormArray {
        return this.taxFormGroup.get('taxRules') as FormArray;
    }

    addTaxRule() {
        this.getTaxRuleFormArray().push(this.createTaxRuleFormGroup());
    }

    createTaxRuleFormGroup(): FormGroup {
        return this.fb.group({
            country: this.fb.control(undefined, [Validators.required]),
            vat: this.fb.control(undefined, [Validators.required, Validators.min(0)])
        });
    }

    close() {
        this.dialogRef.close();
    }

    create() {
        if (this.submitted || !this.taxFormGroup.valid) {
            return;
        }
        this.submitted = true;
        this.data = Object.assign(new AccountancyTaxesTaxGroupDialogCreateBundle(), this.taxFormGroup.value);
        this.bpSubmit();
        this.close();
    }

}
