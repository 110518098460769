import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    private optionsLong = {month: 'numeric', year: '2-digit', day: 'numeric', hour: 'numeric', minute: 'numeric'};
    private optionsShort = {month: 'numeric', year: '2-digit', day: 'numeric'};
    private locale = 'de-DE';

    transform(value: Date, args?: string): string | undefined {
        if (value === undefined || value === null) {
            return undefined;
        }

        if (args !== undefined || args === 'short') {
            return new Date(value).toLocaleDateString(this.locale, this.optionsShort);
        } else {
            return new Date(value).toLocaleDateString(this.locale, this.optionsLong);
        }
    }

}
