import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BankAccountDomainObject} from '../../../../../../model/domain/bank-account.domain-object';

@Component({
    selector: 'app-dialog-confirm-accountancy-bank-account-delete',
    templateUrl: './dialog-confirm-accountancy-bank-account-delete.component.html',
    styleUrls: ['./dialog-confirm-accountancy-bank-account-delete.component.scss']
})
export class DialogConfirmAccountancyBankAccountDeleteComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public bankAccount: BankAccountDomainObject) {
    }

}
