import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';

@Component({
    selector: 'app-dispoplan',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['dispoplan.component.scss'],
    templateUrl: 'dispoplan.component.html'
})
export class DispoPlanComponent {

    viewDate: Date = new Date();
    refresh: Subject<any> = new Subject();

    inputDate: Date = new Date();

    onDateChange() {
        this.viewDate = this.inputDate;
    }

    constructor() {
    }

}
