import {Carrier} from './Carrier';
import {DateRange} from './DateRange';

export class TourSchedule {
    carrier: Carrier;
    dateRange: DateRange;
    customPriceEk: number;
    customPriceFr: number;
    customPriceVk: number;
}
