export class BusinessPartnerDomainObject {
    id: number | null;
    name: string;
    nameAdd: string;
    shortName: string;
    addressIds: number[];
    bankAccountIds: number[];
    contactIds: number[];
    customerId: number | null;
    carrierIds: number[];
    locationIds: number[];
    comment: string | null;
    creditorNumber: string | null;
    debitorNumber: string | null;
    defaultPaymentTargetIds: number[];
}
