import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ContextMenuComponent} from 'ngx-contextmenu';
import {DialogtouractionsComponent} from '../../dialogtouractions/dialogtouractions.component';
import {Tour} from '../../../../model/old/Tour';
import {MatDialog} from '@angular/material/dialog';

export interface TourDisplay {
    tour: Tour;
    width: number;
    offset: number;
}

@Component({
    selector: 'app-carrierrow',
    templateUrl: './carrierrow.component.html',
    styleUrls: ['./carrierrow.component.scss']
})
export class CarrierrowComponent implements OnInit {

    private _day: Date = new Date();

    private _tours: Tour[];

    @ViewChild(ContextMenuComponent, {static: true}) public basicMenu: ContextMenuComponent;

    @Input() set tours(value: Tour[]) {
        if (value === undefined || value === null) {
            this._tours = value;
        } else {
            this._tours = value.sort((a, b) => {
                // sort by start of schedule date range
                return new Date(a.schedule.dateRange.fromDate).getTime() - new Date(b.schedule.dateRange.fromDate).getTime();
            });
        }
    }

    get tours() {
        return this._tours;
    }

    @Input() set day(date: Date) {
        if (date !== undefined && date !== null) {
            date = new Date(date);
            this._day = new Date();
            this._day.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        }
    }

    get day(): Date {
        return this._day;
    }

    constructor(public dialog: MatDialog) {
    }

    ngOnInit() {
    }

    statusToColor(status: string) {
        switch (status) {
            case 'OPEN':
                return 'red';
            case 'SCHEDULED':
                return 'yellow';
            case 'SENT':
                return 'green';
            default:
                return 'blue';
        }
    }

    getWidth(tour: Tour): number {
        // TODO: dates abschneiden und nur aktuelle tag betrachten
        const from = new Date(tour.schedule.dateRange.fromDate);
        from.setTime(from.getTime() + from.getTimezoneOffset() * 60 * 1000);
        if (from.getTime() < this.day.getTime()) {
            from.setTime(this.day.getTime());
        }

        const to = new Date(tour.schedule.dateRange.toDate);
        const dayEnd = new Date(this.day);
        dayEnd.setTime(dayEnd.getTime() + 1000 * 60 * 60 * 24);
        to.setTime(to.getTime() + to.getTimezoneOffset() * 60 * 1000);
        if (to.getTime() > dayEnd.getTime()) {
            to.setTime(dayEnd.getTime());
        }

        const durationInHours = (to.getTime() - from.getTime()) / 1000 / 60 / 60;
        const width = durationInHours / 24 * 100;

        return width;
    }

    getOffset(tour: Tour, index: number): number {
        const from = new Date(tour.schedule.dateRange.fromDate);
        from.setTime(from.getTime() + from.getTimezoneOffset() * 60 * 1000);
        if (from.getTime() < this.day.getTime()) {
            from.setTime(this.day.getTime());
        }

        let offset;
        if (index === 0) {
            // zeit zwischen tagesbeginn und tourstart
            const dayStartToTourStart = (from.getTime() - this.day.getTime()) / 1000 / 60 / 60;
            offset = dayStartToTourStart / 24 * 100;
        } else {
            // zeit zwischen letztem tour ende und diesem tour start
            const lastTourEnd = new Date(this.tours[index - 1].schedule.dateRange.toDate);
            lastTourEnd.setTime(lastTourEnd.getTime() + lastTourEnd.getTimezoneOffset() * 60 * 1000);
            const lastTourEndToTourStart = (from.getTime() - lastTourEnd.getTime()) / 1000 / 60 / 60;
            offset = lastTourEndToTourStart / 24 * 100;
        }
        return offset;
    }

    getTourDisplays(): TourDisplay[] {
        if (this.tours === undefined || this.tours === null) {
            return [];
        }

        return this.tours.map((tour, index) => {
            return {
                tour: tour,
                width: this.getWidth(tour),
                offset: this.getOffset(tour, index)
            };
        });
    }

    onClick(tourDisplay: TourDisplay) {
        const dialogRef = this.dialog.open(DialogtouractionsComponent, {data: {tour: tourDisplay.tour}, width: '1000px'});
    }

}
