import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {UiLoadingOverlayService} from './ui-loading-overlay.service';
import {UiLoadingOverlayDefaultComponent} from './ui-loading-overlay-default/ui-loading-overlay-default.component';

@Directive({
  selector: '[uiLoadingOverlay]'
})
export class UiLoadingOverlayDirective implements AfterViewInit {

  @Input() uiLoadingOverlay: Observable<boolean>;

  @Input() component = UiLoadingOverlayDefaultComponent;

  @Input() hide: Observable<boolean>;

  private readonly hostElement: HTMLElement;

  constructor(el: ElementRef, private uiLoadingOverlayService: UiLoadingOverlayService) {
    this.hostElement = el.nativeElement;
  }

  ngAfterViewInit() {
    // TODO: Observable aus dem WrapperComponent holen
    if (this.uiLoadingOverlay) {
      this.uiLoadingOverlayService.init(this.hostElement, this.uiLoadingOverlay, this.hide, this.component);
    }
  }

}
