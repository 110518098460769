import {BusinessPartner} from './BusinessPartner';
import {InvoiceItem} from './InvoiceItem';
import {PaymentTarget} from './PaymentTarget';
import {AddressDomainObject} from '../domain/address.domain-object';
import {Account} from './Account';
import {BankAccount} from './BankAccount';
import {InvoiceEditorItem} from './InvoiceEditorItem';

export class InvoiceEditor {
    id: number;
    status: string;
    invoiceNumber: string;
    businessPartnerId: number;
    invoiceDate: Date;
    bookingDate: Date;
    headerText: string;
    footerText: string;
    invoiceItems: InvoiceEditorItem[];
    paymentTargets: PaymentTarget[];
    netto: number;
    ust: number;
    brutto: number;
    accounts: Account[];
    type: string;
    incomingInvoiceNumber: string;
    invoiceAddress: AddressDomainObject;
    invoiceBankAccount: BankAccount;
    createdDate: Date;

    constructor() {
    }

}
