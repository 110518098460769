import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export class AccountancyDialogDeleteInvoiceBundle extends AbstractBypassBundle {
    invoiceId: number;
}

@Component({
    selector: 'app-accountancy-dialog-delete-invoice',
    templateUrl: './accountancy-dialog-delete-invoice.component.html',
    styleUrls: ['./accountancy-dialog-delete-invoice.component.scss']
})
export class AccountancyDialogDeleteInvoiceComponent extends BypassFormComponent<AccountancyDialogDeleteInvoiceBundle> implements OnInit {

    submitted = false;

    constructor(
        protected injector: Injector,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AccountancyDialogDeleteInvoiceComponent>,
        @Inject(MAT_DIALOG_DATA) public input: any) {
        super(injector);
    }

    ngOnInit(): void {
        this.bpSetup(new AccountancyDialogDeleteInvoiceBundle());
    }

    submit() {
        if (this.submitted) {
            return;
        }
        this.submitted = true;
        this.data = Object.assign(new AccountancyDialogDeleteInvoiceBundle(), {
            invoiceId: this.input.invoice.id
        });
        this.bpSubmit();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }

}
