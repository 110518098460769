import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, map, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {DialogTourDetailsComponent} from '../dialogtourdetails/dialogtourdetails.component';
import {DialogSendMailsComponent} from '../dialog-send-mails/dialog-send-mails.component';


@Component({
    selector: 'app-dispobook',
    templateUrl: './dispobook.component.html',
    styleUrls: ['./dispobook.component.scss']
})
export class DispoBookComponent {

    selectedGroupOption = 'total';
    selectedStatus = 'all';
    filterDateModel = undefined;
    filterModel = '';
    filterSubject = new BehaviorSubject<string>('');
    filterDebounced = this.filterSubject.asObservable().pipe(
        map(f => this.appendStatusFilter(f)),
        map(f => this.appendDateFilter(f)),
        debounceTime(1000),
    );

    constructor(
        private dialog: MatDialog
    ) {
    }

    appendStatusFilter(filter: string): string {
        if (this.selectedStatus !== null && this.selectedStatus !== undefined && this.selectedStatus !== 'all') {
            return `${filter} ${this.selectedStatus}`;
        }
        return filter;
    }

    appendDateFilter(filter): string {
        if (this.filterDateModel !== null && this.filterDateModel !== undefined) {
            return `${filter} ${this.dateToFilterInput(this.filterDateModel)}`;
        }
        return filter;
    }

    resetFilter() {
        this.filterModel = '';
        this.selectedStatus = 'all';
        this.filterDateModel = undefined;
        this.filterSubject.next('');
    }

    todayToFilterInput(): string {
        return this.dateToFilterInput(new Date());
    }

    dateToFilterInput(date: Date): string {
        const y = date.getUTCFullYear().toString();
        const m = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const d = (date.getUTCDate() + 1).toString().padStart(2, '0');
        return `${y}${m}${d}`;
    }

    setFilter(filter: string) {
        this.filterModel = filter;
    }

    onFilterModelChange(arg: any) {
        this.filterSubject.next(arg);
    }

    openEmailsDialog(): void {
        this.dialog.open(DialogSendMailsComponent, {data: null, width: '1000px'});
    }

}
