import {Component, Injector, OnInit} from '@angular/core';
import {BypassBaseComponent} from '@byteways/bypass-core';

@Component({
    selector: 'app-connection-status',
    templateUrl: './connection-status.component.html',
    styleUrls: ['./connection-status.component.scss']
})
export class ConnectionStatusComponent extends BypassBaseComponent implements OnInit {

    isConnected = false;

    constructor(protected injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.dataService.onConnect(() => this.isConnected = true);
        this.dataService.onDisconnect(() => this.isConnected = false);
    }

}
