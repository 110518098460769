import {Component, Injector, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EditorTypeValue, EditorTypeValueParser} from '../../../model/editor-type.value';
import {combineLatest} from 'rxjs';
import {EditorModeValue, EditorModeValueParser} from '../../../model/editor-mode.value';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {InvoiceEditorComponent} from '../invoice-editor/invoice-editor.component';
import {IncomingCreditEditorComponent} from '../incoming-credit-editor/incoming-credit-editor.component';
import {AccountancyFormBuilder} from '../../../services/accountancy-form-builder.service';

export class EditorConfiguration {
    businessPartnerId: number;
    documentId: number | null;
    editorType: EditorTypeValue;
    editorMode: EditorModeValue;

    constructor(businessPartnerId: number, documentId: number | null, editorType: EditorTypeValue, editorMode: EditorModeValue) {
        this.businessPartnerId = businessPartnerId;
        this.documentId = documentId;
        this.editorType = editorType;
        this.editorMode = editorMode;
    }
}

export class AccountancyEditorContainerBundle extends AbstractBypassBundle {
    editorConfiguration: EditorConfiguration;
    businessPartner: BusinessPartnerDomainObject;
    document: any;
}

@Component({
    selector: 'app-accountancy-editor-container',
    templateUrl: './accountancy-editor-container.component.html',
    styleUrls: ['./accountancy-editor-container.component.scss']
})
export class AccountancyEditorContainerComponent extends BypassFormComponent<AccountancyEditorContainerBundle> {

    editorConfiguration: EditorConfiguration | null = null;

    @ViewChild('invoiceEditor', {static: false}) invoiceEditor: InvoiceEditorComponent;
    @ViewChild('incomingCreditEditor', {static: false}) incomingCreditEditor: IncomingCreditEditorComponent;

    constructor(protected injector: Injector, private route: ActivatedRoute, private accountancyFormBuilder: AccountancyFormBuilder, private router: Router) {
        super(injector);

        combineLatest([this.route.paramMap, this.route.data]).subscribe(result => {
            const paramMap = result[0];
            const data = result[1];
            this.editorConfiguration = new EditorConfiguration(
                +paramMap.get('businessPartnerId'),
                (+paramMap.get('id') || null),
                EditorTypeValueParser.parse(paramMap.get('editorType')),
                EditorModeValueParser.parse(data['mode'])
            );

            this.bpSetup(Object.assign(new AccountancyEditorContainerBundle(), {
                editorConfiguration: this.editorConfiguration
            }));
        });
    }

    protected bpOnMessageReceived(): void {
        switch (this.editorConfiguration.editorType) {
            case EditorTypeValue.INVOICE:
                switch (this.editorConfiguration.editorMode) {
                    case EditorModeValue.NEW:
                        this.configureNewInvoice();
                        break;
                    case EditorModeValue.EDIT:
                        this.configureEditInvoice();
                        break;
                }
                break;
            case EditorTypeValue.INCOMING_CREDIT:
                switch (this.editorConfiguration.editorMode) {
                    case EditorModeValue.NEW:
                        this.configureNewIncomingCredit();
                        break;
                    case EditorModeValue.EDIT:
                        this.configureEditIncomingCredit();
                        break;
                }
                break;
        }
    }


    private configureNewInvoice() {
        // TODO?
    }

    private configureEditInvoice() {
        // TODO: hier wird der value der invoice form gesetzt mit den invoice date. eigentlich blöd gelöst, sowas sollte in der invoice form passieren. dafür muss die invoice form vermutlich ein controlvalueaccessor?
        const formValue = {
            invoiceDate: new Date(this.data.document.invoiceDate),
            bookingDate: new Date(this.data.document.bookingDate),
            incomingInvoiceNumber: this.data.document.incomingInvoiceNumber,
            invoiceAddressId: this.data.document.invoiceAddressId,
            invoiceBankAccountId: this.data.document.invoiceBankAccountId,
            paymentTargetIds: this.data.document.paymentTargetIds,
            headerText: this.data.document.headerText,
            footerText: this.data.document.footerText,

            // TODO: direkt hier setzen möglich? aktuell workaround siehe unten
            accounts: [...this.data.document.accounts],
            // accounts: this.data.document.accounts,
            invoiceItems: []
            // invoiceItems: this.data.document.invoiceItems
        };
        this.invoiceEditor.invoiceForm.invoiceForm.patchValue(formValue);

        const items = this.accountancyFormBuilder.getInvoiceItems(this.invoiceEditor.invoiceForm.invoiceForm);
        for (let i = 0; i < this.data.document.invoiceItems.length; i++) {
            const fg = this.accountancyFormBuilder.createInvoiceItemFormGroup();
            fg.patchValue(this.data.document.invoiceItems[i]);
            items.push(fg);
        }

        // const accounts = this.accountancyFormBuilder.getAccounts(this.invoiceEditor.invoiceForm.invoiceForm);
        // console.log('get accounts', this.data.document.accounts);
        // this.invoiceEditor.invoiceForm.invoiceForm.patchValue({accounts: this.data.document.accounts});
        // console.log('form value', this.invoiceEditor.invoiceForm.invoiceForm.value);
        // for (let i = 0; i < this.data.document.accounts.length; i++) {
        //     const fg = this.accountancyFormBuilder.createAccountFormGroup();
        //     fg.patchValue(this.data.document.accounts[i]);
        //     accounts.push(fg);
        // }

        // set invoice editor settings
        const tourItems = items.getRawValue().filter(i => i.tourId !== null);
        if (tourItems.length > 0) {
            this.invoiceEditor.disabledTourIds = tourItems.map(i => i.tourId);
            this.invoiceEditor.invoiceCommissionType = tourItems.map(i => i.commissionType)[0];
        } else {
            this.invoiceEditor.disabledTourIds = [];
            this.invoiceEditor.invoiceCommissionType = null;
        }

    }

    private configureNewIncomingCredit() {
        // TODO?
    }

    private configureEditIncomingCredit() {
        // TODO: hier wird der value der invoice form gesetzt mit den invoice date. eigentlich blöd gelöst, sowas sollte in der invoice form passieren. dafür muss die invoice form vermutlich ein controlvalueaccessor?
        const formValue = {
            invoiceDate: new Date(this.data.document.invoiceDate),
            bookingDate: new Date(this.data.document.bookingDate),
            incomingInvoiceNumber: this.data.document.incomingInvoiceNumber,
            invoiceAddressId: this.data.document.invoiceAddressId,
            invoiceBankAccountId: this.data.document.invoiceBankAccountId,
            paymentTargetIds: [],
            headerText: this.data.document.headerText,
            footerText: this.data.document.footerText,

            // TODO: direkt hier setzen möglich? aktuell workaround siehe unten
            // accounts: [],
            accounts: this.data.document.accounts,
            invoiceItems: []
            // invoiceItems: this.data.document.invoiceItems
        };
        this.incomingCreditEditor.incomingCreditForm.incomingCreditForm.patchValue(formValue);

        const items = this.accountancyFormBuilder.getInvoiceItems(this.invoiceEditor.invoiceForm.invoiceForm);
        for (let i = 0; i < this.data.document.invoiceItems.length; i++) {
            const fg = this.accountancyFormBuilder.createInvoiceItemFormGroup();
            fg.patchValue(this.data.document.invoiceItems[i]);
            items.push(fg);
        }

        // const accounts = this.accountancyFormBuilder.getAccounts(this.invoiceEditor.invoiceForm.invoiceForm);
        // for (let i = 0; i < this.data.document.accounts.length; i++) {
        //     const fg = this.accountancyFormBuilder.createAccountFormGroup();
        //     fg.patchValue(this.data.document.accounts[i]);
        //     accounts.push(fg);
        // }

        // set invoice editor settings
        const tourItems = this.data.document.invoiceItems.filter(i => i.tourId !== null);
        if (tourItems.length > 0) {
            this.incomingCreditEditor.disabledTourIds = tourItems.map(i => i.tourId);
        } else {
            this.incomingCreditEditor.disabledTourIds = [];
        }
    }


    getHeader() {
        if (!this.data || !this.data.businessPartner) {
            return 'Lädt ...';
        }
        const bpName = `${this.data.businessPartner.name} ${this.data.businessPartner.nameAdd}`;
        switch (this.editorConfiguration.editorType) {
            case EditorTypeValue.INVOICE:
                switch (this.editorConfiguration.editorMode) {
                    case EditorModeValue.NEW:
                        return `Neue Rechnung für ${bpName}`;
                    case EditorModeValue.EDIT:
                        return `Rechnung bearbeiten für ${bpName}`;
                }
                break;
            case EditorTypeValue.INCOMING_CREDIT:
                switch (this.editorConfiguration.editorMode) {
                    case EditorModeValue.NEW:
                        return `Neue Eingangsgutschrift für ${bpName}`;
                    case EditorModeValue.EDIT:
                        return `Eingangsgutschrift bearbeiten für ${bpName}`;
                }
                break;
        }
    }

    onInvoiceSubmit(data: any) {
        this.data = new AccountancyEditorContainerBundle();
        this.data.editorConfiguration = this.editorConfiguration;
        this.data.document = data;
        this.bpSubmit();
        this.router.navigateByUrl('/accountancy/book').then();
    }

    onIncomingCreditSubmit(data: any) {
        this.data = new AccountancyEditorContainerBundle();
        this.data.editorConfiguration = this.editorConfiguration;
        this.data.document = data;
        this.bpSubmit();
        this.router.navigateByUrl('/accountancy/book').then();
    }

}
