<div [hidden]="!datatableConfiguration">

  <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start center">
    <mat-form-field [style.width]="'100%'">
      <input matInput type="text" placeholder="Filter" name="filter" [formControl]="datatableFilterControl"/>
    </mat-form-field>
    <div>
      <button mat-button color="primary" (click)="datatableFilterControl.setValue('')">Filter zurücksetzen
      </button>
    </div>
    <div *ngIf="datatableConfiguration?.onCreateClick">
      <button mat-flat-button color="primary" (click)="datatableConfiguration.onCreateClick()">+</button>
    </div>
  </div>

  <table
    mat-table
    matSort
    [dataSource]="datatableDataSource"
    class="mat-elevation-z8">

    <ng-container
      *ngFor="let columnDef of (datatableConfiguration?.columns || [])"
      [matColumnDef]="columnDef.identifier">

      <th mat-header-cell mat-sort-header *matHeaderCellDef [disabled]="!columnDef.sortable">
        {{columnDef.title}}
      </th>

      <td mat-cell *matCellDef="let element">

        <!-- dynamic component template -->
        <ng-template
          *ngIf="columnDef.component"
          libDatatableCell
          [datatableCellComponent]="columnDef.component"
          [datatableCellData]="columnDef.accessor(element)">
        </ng-template>

        <!-- default: directly render data using accessor -->
        <span *ngIf="!columnDef.component">{{columnDef.accessor(element)}}</span>

      </td>

    </ng-container>

    <tr class="header-row" mat-header-row *matHeaderRowDef="datatableDisplayedColumns"></tr>
    <tr class="body-row" mat-row *matRowDef="let row; columns: datatableDisplayedColumns;"></tr>
    <!--        <context-menu>-->
    <!--            <ng-template contextMenuItem let-item>achja</ng-template>-->
    <!--        </context-menu>-->

  </table>

  <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="center">
    <div style="padding: 1em 0;">
      <mat-chip-list *ngIf="datatableFilterControl.value">
        <mat-chip color="warn" selected>Filter ist aktiv!</mat-chip>
      </mat-chip-list>
    </div>
    <div fxFlex></div>
    <mat-paginator
      [length]="datatableConfiguration?.total || 0"
      [pageSize]="datatableConfiguration?.searchSettings?.pageSize || 0"
      [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>
  </div>

</div>




<!--<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->
<!--<mat-row *matRowDef="let row; columns: displayedColumns;"-->
<!--         [contextMenu]="basicMenu"-->
<!--         [contextMenuSubject]="row"-->
<!--         (click)="onRowClick(row)"></mat-row>-->
<!--<context-menu>-->
<!--    <ng-template contextMenuItem let-item (execute)="sendInvoice($event.item)" [visible]="invoiceClosed">-->
<!--        Rechnung verschicken-->
<!--    </ng-template>-->
<!--    <ng-template contextMenuItem let-item divider="true" [visible]="invoiceClosed"></ng-template>-->
<!--    <ng-template contextMenuItem let-item (execute)="cancelInvoice($event.item)" [visible]="invoiceCanBeCancelled">-->
<!--        Stornorechnung erstellen-->
<!--    </ng-template>-->
<!--    &lt;!&ndash;<ng-template contextMenuItem let-item (execute)="correctInvoice($event.item)" [visible]="invoiceSent">&ndash;&gt;-->
<!--    &lt;!&ndash;Korrekturrechnung erstellen&ndash;&gt;-->
<!--    &lt;!&ndash;</ng-template>&ndash;&gt;-->
<!--    <ng-template contextMenuItem let-item divider="true" [visible]="invoiceSent"></ng-template>-->
<!--    <ng-template contextMenuItem let-item (execute)="lockInvoice($event.item)" [visible]="invoiceOpen">-->
<!--        Abschließen-->
<!--    </ng-template>-->
<!--    <ng-template contextMenuItem let-item (execute)="editInvoice($event.item)" [visible]="invoiceCanEdit">-->
<!--        Bearbeiten-->
<!--    </ng-template>-->
<!--    <ng-template contextMenuItem let-item (execute)="deleteInvoice($event.item)" [visible]="invoiceOpen">-->
<!--        Löschen-->
<!--    </ng-template>-->
<!--    &lt;!&ndash;<ng-template contextMenuItem let-item (execute)="showInvoiceDetails($event.item)">&ndash;&gt;-->
<!--    &lt;!&ndash;Details anzeigen&ndash;&gt;-->
<!--    &lt;!&ndash;</ng-template>&ndash;&gt;-->
<!--    <ng-template contextMenuItem let-item (execute)="showInvoicePDF($event.item)" [visible]="invoicePDFAvailable">-->
<!--        Rechnung als PDF anzeigen-->
<!--    </ng-template>-->
<!--    <ng-template contextMenuItem let-item divider="true"></ng-template>-->
<!--    <ng-template contextMenuItem let-item (execute)="showInvoicePreviewPDF($event.item)">-->
<!--        Interne Vorschau als PDF anzeigen-->
<!--    </ng-template>-->
<!--</context-menu>-->

