import {Component} from '@angular/core';
import {DatatableCellBase} from './datatable-cell-base.interface';

export interface DatatableCellActionDefinition<CellDataType> {
  actionName: string;
  onAction: (CellDataType) => any;
  buttonIcon: string;
  buttonColor: 'primary' | 'accent' | 'warn';
  enabled: boolean;
}

export interface DatatableCellActionsDefinition<CellDataType> {
  cellData: CellDataType;
  actions: DatatableCellActionDefinition<CellDataType>[];
}

@Component({
  selector: 'lib-datatable-cell-actions',
  template: `
      <div fxLayout="row" fxLayoutGap="1em">
          <button
                  *ngFor="let actionDef of data.actions"
                  mat-icon-button
                  [disabled]="!actionDef.enabled"
                  [color]="actionDef.buttonColor"
                  (click)="onActionClick(actionDef)">
              <mat-icon>{{actionDef.buttonIcon}}</mat-icon>
          </button>
      </div>
  `,
  styles: ['']
})
// @dynamic
export class DatatableCellActionsComponent<CellDataType>
  implements DatatableCellBase<DatatableCellActionsDefinition<CellDataType>> {

  data: DatatableCellActionsDefinition<CellDataType>;

  onActionClick(actionDef: DatatableCellActionDefinition<CellDataType>) {
    if (actionDef && actionDef.onAction) {
      actionDef.onAction(this.data.cellData);
    }
  }
}
