import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-accountancy-credits',
    templateUrl: './accountancy-credits.component.html',
    styleUrls: ['./accountancy-credits.component.scss']
})
export class AccountancyCreditsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
