import { NgModule } from '@angular/core';
import { BypassSearchableInputComponent } from './bypass-searchable-input.component';
import {BypassCoreModule} from '@byteways/bypass-core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import {CommonModule} from '@angular/common';



@NgModule({
  declarations: [BypassSearchableInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    BypassCoreModule.forChild(),
    BypassIdenticonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule
  ],
  exports: [BypassSearchableInputComponent]
})
export class BypassSearchableInputModule { }
