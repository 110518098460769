import {ModuleWithProviders, NgModule} from '@angular/core';
import {UiLoadingOverlayDirective} from './ui-loading-overlay.directive';
import {UiLoadingOverlayService} from './ui-loading-overlay.service';
import {UiLoadingOverlayDefaultComponent} from './ui-loading-overlay-default/ui-loading-overlay-default.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [UiLoadingOverlayDirective, UiLoadingOverlayDefaultComponent],
  providers: [UiLoadingOverlayService],
  exports: [UiLoadingOverlayDirective],
  imports: [
    CommonModule
  ],
  entryComponents: [
    UiLoadingOverlayDefaultComponent
  ]
})
export class UiLoadingOverlayModule {

  static forRoot(component): ModuleWithProviders<UiLoadingOverlayModule> {
    return {
      ngModule: UiLoadingOverlayModule,
      providers: [
        {provide: 'component', useValue: component}
      ]
    };
  }

}
