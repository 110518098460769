<ng-template #unplannedItemTemplateDefault let-item="item">
  <span>{{item.title}}</span>
</ng-template>

<div
  draggable="true"
  #itemEl
  class="unplanned-item"
  (mousedown)="onUnplannedItemClick(item, $event)"
  (mouseup)="onUnplannedItemDragEnd(item, $event)"
  (click)="onUnplannedItemDragEnd(item, $event)"
  (dragstart)="onUnplannedItemDragStart(item)"
  (drag)="onUnplannedItemDrag(item, $event)"
  (dragend)="onUnplannedItemDragEnd(item, $event)"
  (mouseover)="onMouseOver(item)"
  [style.background]="planItemService.planItemColorToRgbaString(item.color)"
  [style.height]="parent.rowHeight + 'px'">

  <ng-template
    [ngTemplateOutlet]="unplannedItemTemplate || unplannedItemTemplateDefault"
    [ngTemplateOutletContext]="{item: item}">
  </ng-template>

</div>
