import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {DateRange} from '../../../model/old/DateRange';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {DateRangeComponent} from '../../../features/daterange/daterange.component';
import {Customer} from '../../../model/old/Customer';
import {Carrier} from '../../../model/old/Carrier';
import {Material} from '../../../model/old/Material';
import {Location} from '../../../model/old/Location';
import {BypassSearchableInputComponent} from '@byteways/bypass-searchable-input';
import {MatDialog} from '@angular/material/dialog';

export class CreateNewTour2Bundle extends AbstractBypassBundle {
    customer: number;
    targetDate: DateRange;
    material: number;
    quantity: string;
    loadingLocation: number;
    unloadingLocation: number;
    commissionType: string;
    priceEk: number;
    priceFr: number;
    priceVk: number;
    priceType: string;
    comment: string;
    carrier: number;
    saveAsPreset: boolean;
    presetName: string;
    vehicle: string;
    duration: number;
    distance: number;
    orderNumber?: string;
}

const CommissionTypes = ['fracht', 'handel', 'franko'];
const PriceTypes = ['flat', 'weight', 'time'];

// TODO: entity creator funktionen für alle entities

@Component({
    selector: 'app-createnewtour2',
    templateUrl: './createnewtour.component.html',
    styleUrls: ['./createnewtour.component.scss']
})
export class CreateNewTour2Component extends BypassFormComponent<CreateNewTour2Bundle> implements OnInit {

    @ViewChild('customer', {static: true}) customerInput: BypassSearchableInputComponent;
    @ViewChild('dateRange', {static: true}) dateRangeInput: DateRangeComponent;
    @ViewChild('material', {static: true}) materialInput: BypassSearchableInputComponent;
    @ViewChild('loadingLocation', {static: true}) loadingLocationInput: BypassSearchableInputComponent;
    @ViewChild('unloadingLocation', {static: true}) unloadingLocationInput: BypassSearchableInputComponent;
    @ViewChild('carrier', {static: true}) carrierInput: BypassSearchableInputComponent;

    createForm = new FormGroup({
        customer: new FormControl(undefined, [Validators.required]),
        targetDate: new FormControl(new DateRange(), [Validators.required]),
        material: new FormControl(undefined, [Validators.required]),
        quantity: new FormControl(undefined, [Validators.required]),
        vehicle: new FormControl('Silo', [Validators.required]),
        loadingLocation: new FormControl(undefined, [Validators.required]),
        unloadingLocation: new FormControl(undefined, [Validators.required]),
        commissionType: new FormControl(null),
        priceEk: new FormControl(),
        priceFr: new FormControl(),
        priceVk: new FormControl(),
        priceType: new FormControl(),
        comment: new FormControl(),
        message: new FormControl(),
        carrier: new FormControl(),
        saveAsPreset: new FormControl(false, [Validators.required]),
        presetName: new FormControl(),
        duration: new FormControl(),
        distance: new FormControl(),
        orderNumber: new FormControl()
    });

    showPrices = !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES');

    constructor(protected injector: Injector, public dialog: MatDialog) {
        super(injector);
        this.bpSetup(new CreateNewTour2Bundle());
        this.disableAutoLoadingOverlay();
    }

    ngOnInit() {
    }

    onFormReset() {
        this.customerInput.reset();
        this.dateRangeInput.reset();
        this.materialInput.reset();
        this.loadingLocationInput.reset();
        this.unloadingLocationInput.reset();
        // this.carrierInput.reset(); // TODO
        this.createForm.reset();
        this.createForm.controls['targetDate'].setValue(new DateRange());
        // this.createForm.controls['commissionType'].setValue(CommissionTypes[0]);
        this.createForm.controls['priceType'].setValue(null);
        this.createForm.controls['saveAsPreset'].setValue(false);
        this.createForm.controls['orderNumber'].setValue(null);
    }

    bpOnMessageReceived() {
        super.bpOnMessageReceived();
        this.onFormReset();
    }

    getCommissionType(): string {
        if (this.createForm.value.priceEk == null
            && this.createForm.value.priceFr != null && this.createForm.value.priceVk != null) {
            return CommissionTypes[0]; // fracht
        } else if (this.createForm.value.priceEk != null
            && this.createForm.value.priceFr != null && this.createForm.value.priceVk != null) {
            return CommissionTypes[1]; // handel
        } else if (this.createForm.value.priceEk != null
            && this.createForm.value.priceFr == null && this.createForm.value.priceVk != null) {
            return CommissionTypes[2]; // handel/franko
        } else {
            return undefined;
        }
    }

    onFormSubmit() {
        if (this.createForm.valid) {
            let optionalCarrier;
            if (this.createForm.value.carrier == null) {
                optionalCarrier = null;
            } else {
                optionalCarrier = this.createForm.value.carrier.id;
            }
            let presetName;
            if (this.createForm.value.presetName == null) {
                presetName = `von ${this.createForm.value.loadingLocation.name} nach ${this.createForm.value.unloadingLocation.name}: ${this.createForm.value.material.name} für ${this.createForm.value.customer.name}`;
            } else {
                presetName = this.createForm.value.presetName;
            }

            // Auftragsart aus Preisen erschließen
            const commissionType = this.getCommissionType();
            if (commissionType === CommissionTypes[1] || commissionType === CommissionTypes[2]) {
                if (this.createForm.value.priceEk > this.createForm.value.priceVk) {
                    alert('Fehler bei der Preiseingabe: EK ist größer als VK');
                }
            }

            this.data = Object.assign(new CreateNewTour2Bundle(), {
                customer: this.createForm.value.customer.id,
                targetDate: this.createForm.value.targetDate,
                material: this.createForm.value.material.id,
                quantity: this.createForm.value.quantity,
                vehicle: this.createForm.value.vehicle,
                loadingLocation: this.createForm.value.loadingLocation.id,
                unloadingLocation: this.createForm.value.unloadingLocation.id,
                commissionType: commissionType,
                priceEk: this.createForm.value.priceEk,
                priceFr: this.createForm.value.priceFr,
                priceVk: this.createForm.value.priceVk,
                priceType: this.createForm.value.priceType,
                comment: this.createForm.value.comment,
                message: this.createForm.value.message,
                carrier: optionalCarrier,
                saveAsPreset: this.createForm.value.saveAsPreset,
                presetName: presetName,
                duration: this.createForm.value.duration,
                distance: this.createForm.value.distance,
                orderNumber: this.createForm.value.orderNumber
            });
            this.bpSetLoadingUntilMessageReceived();
            this.bpSubmit();
            this.onFormReset();
        }
    }

    carrierToString(data: any) {
        return (data as Carrier).name;
    }

    customerToString(data: any) {
        const customer = (data as Customer);
        return customer.name;
    }

    locationToString(data: Location) {
        return `${data.name} | ${data.address.city}`;
    }

    materialToString(data: any) {
        return (data as Material).name;
    }

    displayCommissionTypeAndPrice() {
        const commissionType = this.getCommissionType();
        const ek = this.createForm.value.priceEk;
        const fr = this.createForm.value.priceFr;
        const vk = this.createForm.value.priceVk;
        let tradePrice = 0.0;
        switch (commissionType) {
            case 'handel':
                tradePrice = vk - fr - ek;
                return `Handelsauftrag mit ${tradePrice}€ Handelsnutzen`;
            case 'fracht':
                tradePrice = vk - fr;
                return `Frachtauftrag mit ${tradePrice}€ Handelsnutzen`;
            case 'franko':
                tradePrice = vk - ek;
                return `Frankoauftrag mit ${tradePrice}€ Handelsnutzen`;
            default:
                return `Bitte Preise eingeben, um Auftragstyp zu bestimmen.`;
        }
    }

    getTradeValue() {
        const commissionType = this.getCommissionType();
        const ek = this.createForm.value.priceEk;
        const fr = this.createForm.value.priceFr;
        const vk = this.createForm.value.priceVk;
        switch (commissionType) {
            case 'handel':
                return vk - fr - ek;
            case 'fracht':
                return vk - fr;
            case 'franko':
                return vk - ek;
            default:
                return undefined;
        }
    }

    getFromDate(): Date | null {
        const f = this.createForm.get('dateRange');
        if (f === undefined || f === null) {
            return null;
        }
        const v = f.value;
        if (v === undefined || v === null) {
            return null;
        }
        const d = v.fromDate;
        if (d === undefined) {
            return null;
        }
        return d;
    }

    onPriceFound(price: any) {
        this.createForm.patchValue({
            priceEk: price?.priceEk ?? null,
            priceFr: price?.priceFr ?? null,
            priceVk: price?.priceVk ?? null,
            priceType: price?.priceType?.toLowerCase() ?? null,
            comment: (() => {
                const original = this.createForm.value.comment ?? '';
                return original.length > 0 ? `${original} ${price.comment ?? ''}`.trim() : price.comment ?? '';
            })()
        });
    }

}
