<div fxLayout="row">

  <mat-form-field fxFlex *ngIf="control" [appearance]="appearance" [color]="color">
    <mat-label class="label">{{label}}</mat-label>
    <label>
      <input matInput [formControl]="control" [matDatepicker]="picker"
             [disabled]="disabled" [required]="required" (ngModelChange)="change($event)">
    </label>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-icon class="prefix" matPrefix *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>
    <mat-icon class="suffix" matSuffix *ngIf="suffixIcon">{{suffixIcon}}</mat-icon>
    <mat-hint class="hint">{{hint}}</mat-hint>
    <mat-error *ngFor="let error of (control | filterActiveErrors)">{{error}}</mat-error>
  </mat-form-field>

</div>
