import {Component, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

export class UserProfileBundle extends AbstractBypassBundle {
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    sign: string;
}


@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends BypassFormComponent<UserProfileBundle> implements OnInit {

    userForm = this.fb.group(
        {
            'login': this.fb.control('', [Validators.required]),
            'firstName': this.fb.control('', [Validators.required]),
            'lastName': this.fb.control('', [Validators.required]),
            'email': this.fb.control('', [Validators.required]),
            'phone': this.fb.control('', [Validators.required]),
            'password': this.fb.control('', [Validators.required, Validators.minLength(8)]),
            'password2': this.fb.control('', [Validators.required, Validators.minLength(8)]),
            sign: this.fb.control('1', [Validators.required, Validators.minLength(1)]),
        },
        {
            validator: this.checkPasswords
        }
    );

    constructor(protected injector: Injector, private fb: FormBuilder, private readonly router: Router) {
        super(injector);
        this.bpSetLoadingUntilMessageReceived();
        this.bpSetup(new UserProfileBundle());
    }

    ngOnInit() {
    }

    checkPasswords(form: FormGroup) {
        return form.get('password').value === form.get('password2').value ? null : {notSame: true};
    }

    submit() {
        if (!this.userForm.valid) {
            return;
        }
        this.data = Object.assign(new UserProfileBundle(), {
            login: this.userForm.get('login').value,
            firstName: this.userForm.get('firstName').value,
            lastName: this.userForm.get('lastName').value,
            phone: this.userForm.get('phone').value,
            email: this.userForm.get('email').value,
            password: this.userForm.get('password').value,
            sign: this.userForm.get('sign').value,
        });
        this.bpSetLoadingUntilMessageReceived();
        this.bpSubmit();
        this.router.navigateByUrl('/');
    }

    protected bpOnMessageReceived(): void {
        this.userForm.reset();
        this.userForm.patchValue(this.data);
    }

}
