import {AbstractControl} from '@angular/forms';

export class BypassFormValidatorCount {

  public static min(min: number, customErrorMessageFunction?: (current: number, min: number) => {}) {

    const defaultErrorMessageFunction = (current: number, minValue: number) => {
      return `Wert ${current || 0} ist zu klein. Mindestens ${minValue}.`;
    };

    const errorFunction = (current: number, minValue: number) => {
      const error = {};
      const errorMessage: any = customErrorMessageFunction ? customErrorMessageFunction(current, minValue) : defaultErrorMessageFunction(current, minValue);
      error[errorMessage] = errorMessage;
      return error;
    };

    const validateFunction = (value: number, minValue: number) => {
      return value >= minValue;
    };

    return (control: AbstractControl): any => {
      const value = control.value || '';
      return validateFunction(value, min) ? null : errorFunction(value, min);
    };
  }

  public static max(max: number, customErrorMessageFunction?: (current: number, max: number) => {}) {

    const defaultErrorMessageFunction = (current: number, maxValue: number) => {
      return `Wert ${current || 0} ist zu groß. Maximal ${maxValue}.`;
    };

    const errorFunction = (current: number, maxValue: number) => {
      const error = {};
      const errorMessage: any = customErrorMessageFunction ? customErrorMessageFunction(current, maxValue) : defaultErrorMessageFunction(current, maxValue);
      error[errorMessage] = errorMessage;
      return error;
    };

    const validateFunction = (value: number, maxValue: number) => {
      return value <= maxValue;
    };

    return (control: AbstractControl): any => {
      if (document.readyState !== 'complete') {
        control.markAsUntouched();
      }
      const value = control.value || '';
      return validateFunction(value, max) ? null : errorFunction(value, max);
    };
  }

}
