import {AfterViewInit, Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    private _darkTheme = new BehaviorSubject<boolean>(false);
    private _identiconsEnabled = new BehaviorSubject<boolean>(false);
    public readonly isDarkTheme = this._darkTheme.asObservable();
    public readonly identiconsEnabled = this._identiconsEnabled.asObservable();

    constructor() {
        this.setDarkTheme(ThemeService.getFromLocalStorage('isDarkTheme', false));
        this.setIdenticonsEnabled(ThemeService.getFromLocalStorage('identiconsEnabled', true));
    }

    setDarkTheme(isDarkTheme: boolean): void {
        this._darkTheme.next(isDarkTheme);
        ThemeService.writeToLocalStorage('isDarkTheme', isDarkTheme);
    }

    setIdenticonsEnabled(identiconsEnabled: boolean): void {
        this._identiconsEnabled.next(identiconsEnabled);
        ThemeService.writeToLocalStorage('identiconsEnabled', identiconsEnabled);
    }

    private static getFromLocalStorage<T>(key: string, defaultValue: T): T {
        const value = localStorage.getItem(key);
        if (value === undefined || value === null) {
            return defaultValue;
        }
        return JSON.parse(value) as T;
    }

    private static writeToLocalStorage<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }
}
