import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accountancy-invoicing-edit-incoming-credit',
  templateUrl: './accountancy-invoicing-edit-incoming-credit.component.html',
  styleUrls: ['./accountancy-invoicing-edit-incoming-credit.component.scss']
})
export class AccountancyInvoicingEditIncomingCreditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
