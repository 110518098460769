import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BankAccount} from '../../../model/old/BankAccount';
import {BusinessPartner} from '../../../model/old/BusinessPartner';

@Component({
    selector: 'app-accountancy-invoicing-editor-bankaccount',
    templateUrl: './accountancy-invoicing-editor-bankaccount.component.html',
    styleUrls: ['./accountancy-invoicing-editor-bankaccount.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AccountancyInvoicingEditorBankaccountComponent)
        }
    ]
})
export class AccountancyInvoicingEditorBankaccountComponent implements OnInit, ControlValueAccessor {

    @Input() set businessPartner(bp: BusinessPartner) {
        for (const bankAccount of bp.bankAccounts) {
            this.bankAccounts.push(bankAccount);
        }
    }

    bankAccounts: BankAccount[] = [];

    value: BankAccount;

    onChange: (_: any) => {};

    constructor() {
    }

    ngOnInit() {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.value = obj;
        if (obj) {
            if (this.bankAccounts.indexOf(obj) === -1) {
                this.bankAccounts.push(obj);
            }
        }
        if (this.onChange) {
            this.onChange(this.value);
        }
    }


}
