import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberToHoursMinutes'
})
export class NumberToHoursMinutesPipe implements PipeTransform {

  transform(value: number): string {
    let negative = false;

    let decimalTime = value;
    if (decimalTime < 0) {
      negative = true;
      decimalTime = (decimalTime * (-1));
    }

    decimalTime = decimalTime * 60 * 60;
    let hours = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    let minutes = Math.floor((decimalTime / 60));

    if (negative) {
      hours = (24 - hours);

      if (minutes > 0) {
        hours -= 1;
        minutes = (60 - minutes);
      }
    }

    let hoursString = hours.toString();
    let minutesString = minutes.toString();

    if (hours < 10) {
      hoursString = '0' + hours.toString();
    }

    if (minutes < 10) {
      minutesString = '0' + minutes.toString();
    }

    return `${hoursString}:${minutesString}`;
  }

}
