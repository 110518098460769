import {Component, Inject} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MasterdataFormBuilder} from '../../../../services/masterdata-form-builder.service';

@Component({
  selector: 'app-dialog-manage-carrier',
  templateUrl: './dialog-manage-carrier.component.html',
  styleUrls: ['./dialog-manage-carrier.component.scss']
})
export class DialogManageCarrierComponent {

    formGroup: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<DialogManageCarrierComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private masterdataFormBuilder: MasterdataFormBuilder
    ) {
        this.formGroup = this.masterdataFormBuilder.createCarrierFormGroup(data);
    }

    save() {
        if (this.formGroup.valid) {
            this.dialogRef.close(this.formGroup.value);
        }
    }

}
