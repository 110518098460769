import {AfterViewInit, Component, Input, Optional} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';
import {FormFieldConfig} from '../form-field-config';

@Component({
  selector: 'ui-form-field',
  template: ''
})
export class FormFieldDataComponent implements ControlValueAccessor, AfterViewInit {

  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  get value() {
    return this.val;
  }

  @Input() type: string;

  @Input() label: string;
  @Input() appearance;

  @Input() prefixIcon: string;
  @Input() suffixIcon: string;

  @Input() hint: string;

  @Input() color;

  @Input() required: boolean;

  // TODO: Default Value?
  @Input() autocomplete: boolean;

  @Input() maxLength: string|number;

  // TODO
  config: FormFieldConfig;

  val: any;

  disabled: boolean;

  control = new FormControl();

  onChange: any = (value) => {
  };
  onTouch: any = () => {
  };

  constructor(@Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
      if (this.ngControl.control) {
        this.control = this.ngControl.control as FormControl;
      }
    }
  }

  ngAfterViewInit() {
    if (this.ngControl && this.ngControl.control && this.control !== this.ngControl.control) {
      this.control = this.ngControl.control as FormControl;
    }
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.onTouch(this.value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(value) {
    this.onChange(value);
  }

}
