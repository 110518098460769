import {Component} from '@angular/core';
import {SelectionListDisplayAdapter} from '@byteways/ui-selection-list';
import {BusinessPartnerGroup} from '../../../../../model/old/BusinessPartnerGroup';

@Component({
    selector: 'app-business-partner-group-display',
    templateUrl: './business-partner-group-display.component.html',
    styleUrls: ['./business-partner-group-display.component.scss']
})
export class BusinessPartnerGroupDisplayComponent extends SelectionListDisplayAdapter<BusinessPartnerGroup> {
    dataToString(value: BusinessPartnerGroup): string {
        return value.name;
    }
}
