import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CarrierDomainObject} from '../../../../../../model/domain/carrier.domain-object';

@Component({
    selector: 'app-dialog-confirm-carrier-delete',
    templateUrl: './dialog-confirm-carrier-delete.component.html',
    styleUrls: ['./dialog-confirm-carrier-delete.component.scss']
})
export class DialogConfirmCarrierDeleteComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public carrier: CarrierDomainObject) {
    }

}
