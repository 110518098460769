import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {BypassFormComponent} from '@byteways/bypass-core';
import {MatDialog} from '@angular/material/dialog';
import {Draggable, GridsterComponent, GridsterConfig, GridsterItem, Resizable} from 'angular-gridster2';
import {CdkDragEnd} from '@angular/cdk/drag-drop/drag-events';
import {GridsterItemComponentInterface} from 'angular-gridster2/lib/gridsterItem.interface';
import {startOfDay, subMilliseconds} from 'date-fns';
import {MatMenuTrigger} from '@angular/material/menu';
import {TourItemUtils} from '../../utils/TourItemUtils';
import {DispoPlanTour} from '../../model/DispoPlanTour';
import {DispoPlanTourUpdate} from '../../model/DispoPlanTourUpdate';
import {TourDeadline} from '../../model/TourDeadline';
import {DispoPlanCarrier} from '../../model/DispoPlanCarrier';
import {TourItem} from '../../model/TourItem';
import {DispoPlanBundle} from '../../model/DispoPlanBundle';

@Component({
    selector: 'app-dispo-plan',
    templateUrl: './dispo-plan.component.html',
    styleUrls: ['./dispo-plan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispoPlanComponent extends BypassFormComponent<DispoPlanBundle> {

    // TODO: Beim Touren planen mit eigener Flotte: Dauer ist aktuell sinnlos, was tun?
    // TODO: welche Touren dürfen bearbeitet werden? bzw. kann man draggen disablen?
    // TODO: mindestlänge feststellen für alte Touren, die mit dem Plan gerendert werden (sind scheinbar 0 spalten breit? oder 1?)

    @ViewChild('unplannedEl') unplannedEl?: ElementRef;
    @ViewChild(GridsterComponent) grid?: GridsterComponent;
    @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

    // States
    tourContextMenuPosition = {x: '0', y: '0'};
    selectedDay = startOfDay(new Date());
    initializedTours: number[] = [];
    setupDone = false;
    currentTourIdLoading: number | null = null;
    unplannedItemDragging: DispoPlanTour | null = null;

    // Plan informations
    planned: TourItem[] = [];
    unplanned: DispoPlanTour[] = [];
    carriers: DispoPlanCarrier[] = [];
    deadline;

    private static DEFAULT_TOUR_LENGTH = 8;

    // Grid Options
    options: GridsterConfig = {
        displayGrid: 'always',
        fixedRowHeight: 50,
        minCols: 96,
        maxCols: 96,
        maxItemCols: 96,
        disableScrollHorizontal: true,
        disableScrollVertical: true,
        margin: 0,
        pushItems: false,
        swap: false,
        swapWhileDragging: false,
        allowMultiLayer: true,
        outerMargin: false,
        itemInitCallback: (item: GridsterItem) => {
            if (item.tour?.id) {
                this.initializedTours.push(item.tour.id);
            }
        },
        itemChangeCallback: (item: GridsterItem) => {
            if (item.tour?.id && this.currentTourIdLoading !== item.tour?.id && this.initializedTours.some((tourId: number) => tourId === item.tour.id)) {
                this.currentTourIdLoading = item.tour?.id;

                this.options.draggable.enabled = false;
                this.options.resizable.enabled = false;
                this.options = Object.assign({}, this.options);

                this.updateTour(item);
            }
        },
        draggable: {
            enabled: true,
            start: (item: TourItem) => {
                this.showDeadline(item.deadline);
            },
            stop: () => {
                this.hideDeadline();
            }
        } as Draggable,
        resizable: {
            enabled: true,
            handles: {
                n: false,
                ne: false,
                nw: false,
                s: false,
                se: false,
                sw: false
            },
            start: (item: TourItem, itemComponent: GridsterItemComponentInterface, event: MouseEvent) => {
                this.showDeadline(item.deadline);
            },
            stop: (item: GridsterItem, itemComponent: GridsterItemComponentInterface, event: MouseEvent) => {
                this.hideDeadline();
            }
        } as Resizable,
    };

    constructor(public injector: Injector, public dialog: MatDialog, private cdr: ChangeDetectorRef) {
        super(injector);
        this.disableAutoLoadingOverlay();
        this.bpSetup(new DispoPlanBundle(this.selectedDay));
        this.bpSubmit();
    }

    protected bpOnMessageReceived(): void {
        // console.log('===============================================');
        // console.log(this.data);
        // const unplannedToursDebug = this.data.tours.filter(t => t.schedule === null);
        // const plannedToursDebug = this.data.tours.filter(t => t.schedule !== null);
        // console.table([
        //     {type: 'unplanned', length: unplannedToursDebug.length, data: unplannedToursDebug},
        //     {type: 'planned', length: plannedToursDebug.length, data: plannedToursDebug},
        //     {}
        // ]);
        // console.log('===============================================');

        this.setupDone = true;

        this.carriers = this.data.carriers;

        this.unplanned = this.data.tours
            .filter((tour: DispoPlanTour) => !this.isTourPlanned(tour))
            .sort((a: DispoPlanTour, b: DispoPlanTour) => a.tourNumber < b.tourNumber ? -1 : 1);

        const planned = this.data.tours.filter((tour: DispoPlanTour) => this.isTourPlanned(tour)).map((tour: DispoPlanTour, index: number) => {
            return {
                ...DispoPlanComponent.calculateHorizontalGridPosition(
                    new Date(tour.schedule.dateRange.fromDate),
                    new Date(tour.schedule.dateRange.toDate)
                ),
                ...this.calculateVerticalGridPosition(tour),
                tour,
                layerIndex: index,
                deadline: this.getDeadline(tour)
            } as TourItem;
        });

        const mapItemPositions = (items: TourItem[]) => items.map((item: TourItem) => {
            return {
                // TODO: Brauche ich hier id?
                id: item.tour.id,
                x: item.x,
                y: item.y,
                cols: item.cols,
                title: item.title
            };
        });

        if (JSON.stringify(mapItemPositions(this.planned)) !== JSON.stringify(mapItemPositions(planned))) {
            this.planned = planned;
        }

        this.options.maxRows = this.carriers.length;
        this.options.minRows = this.carriers.length;

        this.currentTourIdLoading = null;
        this.options.draggable.enabled = true;
        this.options.resizable.enabled = true;
        this.options = Object.assign({}, this.options);

        this.cdr.markForCheck();
    }

    private submit(bundlePartial: Partial<DispoPlanBundle>) {
        this.bpSetLoadingUntilMessageReceived();
        this.data = Object.assign(new DispoPlanBundle(this.selectedDay), bundlePartial);
        this.bpSubmit();
    }

    private updateTour(planItem: GridsterItem): void {
        const startHours = Math.floor(planItem.x / 4);
        const startMinutes = ((planItem.x / 4) - startHours) * 60;

        const endHours = Math.floor(((planItem.x + planItem.cols) / 4));
        const endMinutes = (((planItem.x + planItem.cols) / 4) - endHours) * 60;

        const start = new Date(this.selectedDay);
        start.setHours(startHours);
        start.setMinutes(startMinutes);

        const end = new Date(this.selectedDay);
        end.setHours(endHours);
        end.setMinutes(endMinutes);

        const tourId: number = planItem.tour.id;
        const tourUpdate = {
            tourUpdate: {
                tourId: tourId,
                schedule: {
                    carrierId: this.carriers[planItem.y].carrierId,
                    dateRange: {
                        fromDate: start,
                        toDate: subMilliseconds(end, 1),
                        useRange: true
                    }
                },
            } as DispoPlanTourUpdate
        } as Partial<DispoPlanBundle>;
        this.submit(tourUpdate);
    }

    refresh() {
        this.submit({day: this.selectedDay});
    }

    unplanTour(tour: DispoPlanTour): void {
        const tourUpdate = {
            tourUpdate: {
                tourId: tour.id,
                schedule: null,
            } as DispoPlanTourUpdate
        } as Partial<DispoPlanBundle>;
        this.submit(tourUpdate);
    }

    // Deadline

    private getDeadline(tour: DispoPlanTour): TourDeadline | null {
        const dateRange = tour.targetDate;
        if (dateRange) {
            return {
                dragEnabled: false,
                resizeEnabled: false,
                ...DispoPlanComponent.calculateHorizontalGridPosition(
                    new Date(dateRange.fromDate),
                    new Date(dateRange.toDate)
                ),
                y: 0,
                rows: this.carriers.length,
                layerIndex: -1
            } as TourDeadline;
        } else {
            return null;
        }
    }

    private showDeadline(deadline: TourDeadline): void {
        this.deadline = deadline;
    }

    private hideDeadline(): void {
        this.deadline = null;
    }

    // Position calculation

    private static calculateHorizontalGridPosition(start: Date, end: Date): { x: number, cols: number } {
        const x = Math.floor((start.getHours() + start.getMinutes() / 60) * 4);
        const cols = Math.round((end.getHours() + end.getMinutes() / 60) * 4) - x;
        return {x, cols};
    }

    private calculateVerticalGridPosition(tour: DispoPlanTour): { y: number, rows: number } {
        const carrier = this.carriers.find((dispoPlanCarrier: DispoPlanCarrier) => dispoPlanCarrier.carrierId === tour.schedule.carrierId);
        return {
            y: this.carriers.indexOf(carrier),
            rows: 1
        };
    }

    openTourMenu(event: MouseEvent, item: GridsterItem) {
        event.preventDefault();
        this.tourContextMenuPosition.x = event.clientX + 'px';
        this.tourContextMenuPosition.y = event.clientY + 'px';
        this.matMenuTrigger.menuData = {tour: item.tour};
        this.matMenuTrigger.openMenu();
    }

    isTourPlanned(tour: DispoPlanTour): boolean {
        if (!tour.schedule) {
            return false;
        }
        return tour.schedule?.dateRange?.fromDate !== null && tour.schedule?.dateRange?.toDate !== null;
    }

    onDrop(dragEnd: CdkDragEnd, tour: DispoPlanTour): void {
        const html = this.unplannedEl.nativeElement as HTMLElement;
        const pos = dragEnd.source.getFreeDragPosition();
        const xPos = html.offsetLeft + pos.x;
        const yPos = html.offsetTop + pos.y;
        const x: number = Math.floor(xPos / this.grid.curColWidth);
        const y: number = Math.floor(yPos / this.grid.curRowHeight);
        const duration = DispoPlanComponent.getTourDurationCols(tour);

        // Check if the item fit in the grid
        if (x >= 0 && x <= 96 && y >= 0 && y <= (this.carriers.length - 1) && (x + duration) <= 96) {
            const tourItem = {
                x,
                y,
                cols: duration,
                rows: 1,
                tour,
                maxItemRows: 1,
                layerIndex: this.planned.length,
                deadline: this.getDeadline(tour)
            } as TourItem;
            this.planned.push(tourItem);
            this.unplanned.splice(this.unplanned.indexOf(tour), 1);
            this.updateTour(tourItem);
        } else {
            dragEnd.source.reset();
        }

        this.unplannedItemDragging = null;
    }

    private static getTourDurationCols(tour: DispoPlanTour): number {
        return tour.duration ? (tour.duration * 4) : DispoPlanComponent.DEFAULT_TOUR_LENGTH;
    }

    isTourPlannedInDeadline(item: TourItem): boolean {
        return TourItemUtils.isInDeadline(item);
    }
}
