import {Pipe, PipeTransform} from '@angular/core';
import {BankAccount} from '../model/old/BankAccount';

@Pipe({
    name: 'bankAccountFormat'
})
export class BankAccountFormatPipe implements PipeTransform {

    transform(value: BankAccount, args?: any): any {
        if (!value) {
            return '';
        }
        return this.bankAccountToString(value);
    }

    private toStringOrDashIfEmpty(value: any): string {
        if (value !== undefined && value !== null && value !== '') {
            return value;
        } else {
            return '-';
        }
    }

    bankAccountToString(bankAccount: BankAccount): string {
        return `Institut: ${this.toStringOrDashIfEmpty(bankAccount.bank)} | IBAN: ${this.toStringOrDashIfEmpty(bankAccount.iban)} | BIC: ${this.toStringOrDashIfEmpty(bankAccount.bic)} | KtNr: ${this.toStringOrDashIfEmpty(bankAccount.ktNr)} | BLZ: ${bankAccount.blz} | Ust.Id: ${bankAccount.ustId}`;
    }

}
