import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialDialogComponent} from './components/material-dialog/material-dialog.component';
import {MaterialTableComponent} from './components/material-table/material-table.component';
import {BypassCoreModule} from '@byteways/bypass-core';
import {BypassSearchableInputModule} from '@byteways/bypass-searchable-input';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FeaturesModule} from '../../features/features.module';
import {AngularSplitModule} from 'angular-split';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ContextMenuModule} from 'ngx-contextmenu';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import {SharedModule} from '../shared/shared.module';
import { MaterialCurrentPricePipe } from './pipes/material-current-price.pipe';
import { MaterialNextPricePipe } from './pipes/material-next-price.pipe';
import { MaterialPriceComponent } from './components/material-price/material-price.component';


@NgModule({
    declarations: [
        MaterialDialogComponent,
        MaterialTableComponent,
        MaterialCurrentPricePipe,
        MaterialNextPricePipe,
        MaterialPriceComponent,
    ],
    imports: [
        CommonModule,
        BypassCoreModule,
        BypassSearchableInputModule,
        BypassIdenticonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FeaturesModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatListModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        BrowserAnimationsModule,
        ContextMenuModule.forRoot(),
        MatSortModule,
        MatDialogModule,
        SharedModule
    ],
    exports: [
        MaterialDialogComponent,
        MaterialTableComponent
    ],
    entryComponents: [
        MaterialDialogComponent
    ]
})
export class MaterialsModule {
}
