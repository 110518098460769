import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit} from '@angular/core';
import {Tour} from '../../../model/old/Tour';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';

export class DispoPlanSubcontractorsBundle extends AbstractBypassBundle {
    date: Date;
    tours: Tour[];
}

@Component({
    selector: 'app-dispoplansubcontractors',
    templateUrl: './dispoplansubcontractors.component.html',
    styleUrls: ['./dispoplansubcontractors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispoPlanSubcontractorsComponent extends BypassFormComponent<DispoPlanSubcontractorsBundle> implements OnInit {

    private _date: Date;
    tours: Tour[] = [];

    @Input() set date(date: Date) {
        this._date = date;
        this.data = new DispoPlanSubcontractorsBundle();
        this.data.date = this._date;
        this.data.tours = [];
        this.bpSubmit();
    }

    bpOnMessageReceived() {
        this.tours = this.data.tours;
        this.cdr.detectChanges();
    }


    constructor(protected injector: Injector, private cdr: ChangeDetectorRef) {
        super(injector);
        this.bpSetup(new DispoPlanSubcontractorsBundle());
    }

    ngOnInit() {
    }

}
