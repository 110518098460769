import {Component, ElementRef, Injector, OnDestroy} from '@angular/core';
import {AbstractBypassBundle} from '../bypass.bundle';
import {BehaviorSubject, Subscription} from 'rxjs';
import {BypassBaseComponent} from './bypass-base.component';
import {UiLoadingOverlayService} from '@byteways/ui-loading-overlay';

/**
 * TODO: doku
 */
@Component({
  selector: 'lib-data',
  template: ''
})
export class BypassDataComponent<T extends AbstractBypassBundle> extends BypassBaseComponent implements OnDestroy {

  /**
   * internal data object
   *
   * all data operations (CRUD in derived components, for example) will use this variable to store their value
   */
  data: T;

  private _loadingUntilMessageReceived = false;

  public loading = new BehaviorSubject<boolean>(false);

  /**
   * subscription for the data object
   */
  protected dataSubscription: Subscription;

  /**
   * the constructor that is used for dependency injection
   *
   * @param injector - the common angular injector
   */

  private uiLoadingOverlayService;
  private component;
  private hideOverlay = new BehaviorSubject(false);

  constructor(protected injector: Injector) {
    super(injector);
    this.uiLoadingOverlayService = this.injector.get(UiLoadingOverlayService);
    this.uiLoadingOverlayService.init(
      this.injector.get(ElementRef).nativeElement,
      this.loading.asObservable(),
      this.hideOverlay.asObservable(),
      this.component
    );
  }

  /**
   * call this from each derived component constructor
   *
   * this method receives an empty constructor of the data type that the derived component should manage
   * it stores the constructor for later use and creates a new instance of the generic data object
   * afterwards the onSetup() callback will be triggered
   *
   * example usage in a derived classes constructor:
   *
   * constructor(protected injector: Injector) {
   *      super(injector)
   *      this.setup(ExampleDataBundle)
   * }
   *
   * @param bundle - the empty constructor of the data type
   * @param subscribeData - true, if initial data subscription should be created, else false
   */
  protected bpSetup(bundle: T, subscribeData: boolean = true) {
    this.data = bundle;

    this.loading.next(true);

    // start subscription on data object
    // (will be unsubscribed when component is destroyed)
    this.bpBeforeSubscribe();
    if (subscribeData) {
      this.dataSubscription = this.dataService.observe<T>(this.data).subscribe(data => {
        this.loading.next(false);
        if (this.bpFilterIncomingData(data)) {
          this.data = data;
          this.bpOnFilterAccept();
          this.bpOnMessageReceived();
        } else {
          this.bpOnFilterReject(data);
        }
      });
    } else {
      this.loading.next(false);
    }
    // trigger callback
    this.bpOnSetup();
  }

  /**
   * sends the components data bundle to the specified operator
   * @param operator - the operator
   */
  protected bpSendOperator(operator: string) {
    this.dataService.send<T>(this.data, operator);
    this.loading.next(true);
  }

  /**
   * override this for callback when setup is done
   */
  protected bpOnSetup() {
    // override for callback onSetup
  }

  /**
   * override for filtering the data subscription. when result is true, accept data. discard otherwise
   *
   * TODO: unglückliche lösung. components sollten sowas wie subchannels haben können. diese funktion gibt es weil:
   * es gibt 5 ChatComponents, jeder soll einen eigenen Chat managen. Wenn eine Nachricht auf /chat gepublisht wird,
   * bekommen alle components die. also muss gefiltert werden. eigentlich schlecht! dafür sollte ein subchannel da sein
   *
   * @param data - incoming data bundle
   * @return true, if data is accepted, false otherwise
   */
  protected bpFilterIncomingData(data: T): boolean {
    // override for filtering the data subscription. when result is true, accept data. discard otherwise
    return true;
  }

  /**
   * is called each time the data subscriptions filter accepts a bundle
   * override this if needed
   */
  protected bpOnFilterAccept() {
    // override for callback on filter accept
  }

  /**
   * is called each time the data subscriptions filter rejects a bundle
   * override this if needed
   */
  protected bpOnFilterReject(data: T) {
    // override for callback on filter reject
  }

  /**
   * override this for callback when new data arrives
   */
  protected bpOnMessageReceived() {
    // override for callback onRead
  }

  /**
   * override this for callback before data subscription starts
   */
  protected bpBeforeSubscribe() {
    // override for callback before subscribe
  }

  protected bpSetLoadingUntilMessageReceived() {
    this._loadingUntilMessageReceived = true;
    this.loading.next(true);
  }

  /**
   * angular lifecycle callback: on destroy: unsubscribe from data service
   */
  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  /**
   * Sends the Read command
   */
  protected bpRead() {
    this.bpSendOperator('read');
  }

  enableAutoLoadingOverlay() {
    this.hideOverlay.next(false);
  }

  disableAutoLoadingOverlay() {
    this.hideOverlay.next(true);
  }

  setLoadingOverlayComponent(component) {
    this.component = component;
  }


}
