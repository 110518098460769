import {Pipe, PipeTransform} from '@angular/core';
import {TourStatistics, TourStatisticsCarrier} from '../model/tour-statistics.model';

@Pipe({
    name: 'statsForCarrier'
})
export class StatsForCarrierPipe implements PipeTransform {

    transform(value: TourStatistics, carrierId: number): TourStatisticsCarrier {
        return value.carriers.find(c => c.carrierId === carrierId);
    }

}
