import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ui-selection-list-item-base',
  templateUrl: './selection-list-item-base.component.html',
  styleUrls: ['./selection-list-item-base.component.scss']
})
export class SelectionListItemBaseComponent implements OnInit {

  @Input() data: any;

  @Output() searchStringCreated = new EventEmitter<{ object: any, searchString: string }>();

  constructor() {
  }

  ngOnInit() {

    let searchString = '';

    if (this.data.title) {
      searchString += this.data.title;
    }

    if (this.data.description) {
      searchString += this.data.description;
    }

    this.searchStringCreated.emit({object: this.data, searchString: searchString});
  }

}
