export class PriceDomainObject {
    id: number | null;
    fromDate: Date | null;
    toDate: Date | null;
    customerId: number | null;
    materialId: number | null;
    loadingLocationId: number | null;
    unloadingLocationId: number | null;
    commissionType: 'FRACHT' | 'HANDEL' | 'FRANKO' | null;
    priceType: 'FLAT' | 'WEIGHT' | 'TIME' | null;
    priceEk: number | null;
    priceFr: number | null;
    priceVk: number | null;
    comment: string | null;
    active: boolean;
}
