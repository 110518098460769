import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Tour} from '../../../model/old/Tour';
import {DialogTourDetailsComponent} from '../../disposition/dialogtourdetails/dialogtourdetails.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-accountancy-invoicing-tours-list-item',
    templateUrl: './accountancy-invoicing-tours-list-item.component.html',
    styleUrls: ['./accountancy-invoicing-tours-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountancyInvoicingToursListItemComponent {

    @Input() tour: Tour;
    @Output() tourClicked = new EventEmitter<Tour>();

    constructor(private dialog: MatDialog) {

    }

    onDetailsClick() {
        this.dialog.open(DialogTourDetailsComponent, {data: {tour: this.tour}, width: '1000px'});
    }

    hasPrice() {
        return this.tour.priceVk !== null && this.tour.priceVk !== undefined;
    }

}
