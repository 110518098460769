import {AfterViewInit, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {Invoice} from '../../../model/old/Invoice';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountancyInvoicingNewInvoiceDialogComponent} from '../accountancy-invoicing-new-invoice-dialog/accountancy-invoicing-new-invoice-dialog.component';
import {BusinessPartner} from '../../../model/old/BusinessPartner';
import {AccountancyInvoicingEditorComponent} from '../accountancy-invoicing-editor/accountancy-invoicing-editor.component';
import {AccountancyInvoicingToursListComponent} from '../accountancy-invoicing-tours-list/accountancy-invoicing-tours-list.component';
import {InvoiceEditor} from '../../../model/old/InvoiceEditor';
import {InvoiceEditorItem} from '../../../model/old/InvoiceEditorItem';
import {MatDialog} from '@angular/material/dialog';

export class AccountancyInvoicingNewInvoiceBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    businessPartner: BusinessPartner;
    invoice: InvoiceEditor;
}

@Component({
    selector: 'app-accountancy-invoicing-new-invoice',
    templateUrl: './accountancy-invoicing-new-invoice.component.html',
    styleUrls: ['./accountancy-invoicing-new-invoice.component.scss']
})
export class AccountancyInvoicingNewInvoiceComponent extends BypassFormComponent<AccountancyInvoicingNewInvoiceBundle> implements AfterViewInit {

    @Input() editInvoice = undefined;

    formFullscreen = false;
    previewFullscreen = false;

    businessPartner: BusinessPartner = undefined;
    commissionType: string = undefined;

    @ViewChild(AccountancyInvoicingEditorComponent, {static: true}) editor: AccountancyInvoicingEditorComponent;
    @ViewChild(AccountancyInvoicingToursListComponent, {static: true}) toursList: AccountancyInvoicingToursListComponent;

    constructor(
        protected injector: Injector,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {
        super(injector);

    }

    ngAfterViewInit(): void {
        this.route.paramMap.subscribe(p => {
            const businessPartnerIdRaw = p.get('businessPartnerId');
            const businessPartnerIdParsed = parseInt(businessPartnerIdRaw, 10);

            if (businessPartnerIdRaw !== null && businessPartnerIdRaw !== undefined) {
                // new invoice
                const bundle = new AccountancyInvoicingNewInvoiceBundle();
                bundle.businessPartnerId = businessPartnerIdParsed;
                this.bpSetup(bundle);
            } else {
                // edit invoice
                const bundle = new AccountancyInvoicingNewInvoiceBundle();
                bundle.businessPartnerId = this.editInvoice.businessPartner.id;
                this.bpSetup(bundle);
            }
        });
    }

    bpOnMessageReceived() {
        this.businessPartner = this.data.businessPartner;
        setTimeout(() => this.editor.editInvoice(this.editInvoice));
    }

    onInvoice(invoice: Invoice) {
        this.data = new AccountancyInvoicingNewInvoiceBundle();
        this.data.invoice = new InvoiceEditor();
        this.data.invoice.id = invoice.id;
        this.data.invoice.status = 'OPEN';
        this.data.invoice.invoiceNumber = invoice.invoiceNumber;
        this.data.invoice.businessPartnerId = invoice.businessPartner.id;
        this.data.invoice.invoiceDate = invoice.invoiceDate;
        this.data.invoice.bookingDate = invoice.bookingDate;
        this.data.invoice.headerText = invoice.headerText;
        this.data.invoice.footerText = invoice.footerText;
        this.data.invoice.invoiceItems = invoice.invoiceItems.map(i => new InvoiceEditorItem(i));
        this.data.invoice.paymentTargets = invoice.paymentTargets;
        this.data.invoice.netto = invoice.netto;
        this.data.invoice.ust = invoice.ust;
        this.data.invoice.brutto = invoice.brutto;
        this.data.invoice.accounts = invoice.accounts;
        this.data.invoice.type = invoice.type;
        this.data.invoice.incomingInvoiceNumber = invoice.incomingInvoiceNumber;
        this.data.invoice.invoiceAddress = invoice.invoiceAddress;
        this.data.invoice.invoiceBankAccount = invoice.invoiceBankAccount;
        this.data.invoice.createdDate = new Date();

        if (this.businessPartner.debitorNumber === undefined || this.businessPartner.debitorNumber == null) {
            const confirmed = confirm('Für diesen Geschäftspartner wurde noch keine Debitorennummer erstellt. Mit dem Abschicken dieser Rechnung wird eine neue Debitorennummer generiert. Einverstanden?');
            if (!confirmed) {
                return;
            }
        }
        this.bpSubmit();
        this.dialog.open(AccountancyInvoicingNewInvoiceDialogComponent, {
            width: '1000px',
            disableClose: true
        });
        // alert("PDF-Anzeige deaktiviert in Demo-Version");
    }

    getPreviewFullscreenSize() {
        return this.previewFullscreen ? 'grow' : 50;
    }

    onPreviewFullscreenToggle() {
        this.previewFullscreen = !this.previewFullscreen;
        if (this.previewFullscreen) {
            this.formFullscreen = false;
        }
    }

    onFormFullscreenToggle() {
        this.formFullscreen = !this.formFullscreen;
        if (this.formFullscreen) {
            this.previewFullscreen = false;
        }
    }

    onCommissionType(type: string) {
        this.commissionType = type;
    }

}
