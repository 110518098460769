import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BypassAdminGuard} from './bypass-admin.guard';
import {BypassAuthGuard} from './bypass-auth.guard';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [BypassAdminGuard, BypassAuthGuard]
})
export class BypassCoreGuardsModule { }
