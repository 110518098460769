import {BypassFormValidatorCustom} from './bypass-form-validator-custom';
import {BypassFormValidatorCount} from './bypass-form-validator-count';
import {BypassFormValidatorLength} from './bypass-form-validator-length';
import {BypassFormValidatorRequired} from './bypass-form-validator-required';
import {BypassFormValidatorMail} from './bypass-form-validator-mail';
import {BypassFormValidatorPattern} from './bypass-form-validator-pattern';

export class BypassFormValidator {

  public static required = BypassFormValidatorRequired.required;

  public static custom = BypassFormValidatorCustom.custom;

  public static minLength = BypassFormValidatorLength.minLength;
  public static maxLength = BypassFormValidatorLength.maxLength;

  public static min = BypassFormValidatorCount.min;
  public static max = BypassFormValidatorCount.max;

  public static mail = BypassFormValidatorMail.mail;

  public static pattern = BypassFormValidatorPattern.pattern;

}
