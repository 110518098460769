import {Component, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {BypassControlValueAccessor} from '@byteways/bypass-forms';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputTextComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => InputTextComponent)
        }
    ]
})
export class InputTextComponent extends BypassControlValueAccessor<string> {

    @Input() placeholder = '';
    @Input() required = false;

    @Input() set suggestions(value: string[]) {
        this._suggestions = value;
        this.filteredSuggestions.next(this.filter(this.textModel));
    }

    get suggestions(): string[] {
        return this._suggestions;
    }

    @Input() disableInput = false;

    textModel: string | null = null;

    private _suggestions: string[] = [];
    private filteredSuggestions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    filteredSuggestions$: Observable<string[]> = this.filteredSuggestions.asObservable();

    onModelChange(value: string) {
        this.filteredSuggestions.next(this.filter(value));
        this.notifyChange(value);
    }

    private filter(value: string): string[] {
        if (value === undefined || value === null) {
            return this.suggestions;
        }
        const filterValue = value.toLowerCase();
        return this.suggestions.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);
    }

    writeValue(obj: string | undefined | null): void {
        this.textModel = obj || null;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return undefined;
    }

}
