import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euroPerTour'
})
export class EuroPerTourPipe implements PipeTransform {

    transform(euro: number, tours: number): number {
        if (tours === 0) {
            return 0;
        }
        return euro / tours;
    }

}
