import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {AddressDomainObject} from '../../../../../model/domain/address.domain-object';
import {DialogManageAddressComponent} from './dialog-manage-address/dialog-manage-address.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogConfirmAddressDeleteComponent} from './dialog-confirm-address-delete/dialog-confirm-address-delete.component';

export class MasterdataAddressesEditorBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    addresses: AddressDomainObject[];
}

@Component({
    selector: 'app-masterdata-addresses-editor',
    templateUrl: './masterdata-addresses-editor.component.html',
    styleUrls: ['./masterdata-addresses-editor.component.scss']
})
export class MasterdataAddressesEditorComponent extends BypassFormComponent<MasterdataAddressesEditorBundle> {

    tableColumns = [
        'icon',
        'residentName',
        'street',
        'city',
        'cityCode',
        'countryId',
        'actions'
    ];

    @Output() saved = new EventEmitter();

    businessPartnerId: number;

    addresses: AddressDomainObject[] = [];

    @Input() set businessPartner(bp: BusinessPartnerDomainObject) {
        this.businessPartnerId = bp.id;
        if (this.businessPartnerId) {
            const bundle = Object.assign(new MasterdataAddressesEditorBundle(), {businessPartnerId: this.businessPartnerId});
            this.bpSetup(bundle);
        }
    }

    constructor(private dialog: MatDialog, protected injector: Injector) {
        super(injector);
        this.disableAutoLoadingOverlay();
    }

    protected bpOnMessageReceived(): void {
        this.addresses = this.data.addresses;
    }

    addAddress() {
        this.dialog.open(DialogManageAddressComponent, {
            minWidth: '50%'
        }).afterClosed().subscribe((address: AddressDomainObject) => {
            if (address) {
                this.addresses.push(address);
                this.submit();
            }
        });
    }

    editAddress(index) {
        this.dialog.open(DialogManageAddressComponent, {
            data: this.addresses[index],
            minWidth: '50%'
        }).afterClosed().subscribe(editedAddress => {
            if (editedAddress) {
                this.addresses[index] = editedAddress;
                this.submit();
            }
        });
    }

    deleteAddress(index: number) {
        this.dialog.open(DialogConfirmAddressDeleteComponent, {
            data: this.addresses[index]
        }).afterClosed().subscribe((response: boolean) => {
            if (response) {
                this.addresses.splice(index, 1);
                this.submit();
            }
        });
    }

    // TODO: Müssen auch bei edit/create alle Daten zum Backend geschickt werden?
    submit() {
        this.data = Object.assign(new MasterdataAddressesEditorBundle(), {
            businessPartnerId: this.businessPartnerId,
            addresses: this.addresses
        });
        this.bpSubmit();
        this.saved.emit();
    }

}
