import {Component, Input, OnInit} from '@angular/core';
import {TourSchedule} from '../../model/old/TourSchedule';
import {DateRange} from '../../model/old/DateRange';

@Component({
    selector: 'app-schedulesummary',
    templateUrl: './schedulesummary.component.html',
    styleUrls: ['./schedulesummary.component.scss']
})
export class SchedulesummaryComponent implements OnInit {

    @Input() tourSchedule: TourSchedule;
    @Input() showPrices = false;

    constructor() {
    }

    ngOnInit() {
    }

    formatDate(date: Date) {
        return new Date(date).toLocaleString('de').slice(0, -3);
    }

    dateRangeToString(dateRange: DateRange) {
        if (dateRange.useRange) {
            return `${this.formatDate(dateRange.fromDate)} - ${this.formatDate(dateRange.toDate)}`;
        } else {
            return `${this.formatDate(dateRange.fromDate)}`;
        }
    }


    formatPrice(price: number): string {
        if (price === undefined || price === null) { return '-'; }
        return `${price.toFixed(2).toString().replace('.', ',')} €`;
    }

}
