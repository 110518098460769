import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AccountancyFormBuilder} from '../../../modules/accountancy-editor/services/accountancy-form-builder.service';

export class AccountancyDialogCorrectInvoiceBundle extends AbstractBypassBundle {
    invoiceId: number;
    reason: string;
    bookingDate: Date;
    accounts: Account[];
    itemIndices: number[];
}

@Component({
    selector: 'app-accountancy-dialog-correct-invoice',
    templateUrl: './accountancy-dialog-correct-invoice.component.html',
    styleUrls: ['./accountancy-dialog-correct-invoice.component.scss']
})
export class AccountancyDialogCorrectInvoiceComponent extends BypassFormComponent<AccountancyDialogCorrectInvoiceBundle> implements OnInit {

    submitted = false;

    correctInvoiceForm = new FormGroup({
        reason: new FormControl(undefined),
        bookingDate: new FormControl(Date(), [Validators.required]),
        itemIndices: new FormControl([], [Validators.required]),
        accounts: new FormControl([{accountId: null, sum: null}], [Validators.required])
    });

    constructor(
        protected injector: Injector, public dialog: MatDialog,
        public dialogRef: MatDialogRef<AccountancyDialogCorrectInvoiceComponent>,
        public accountancyFormBuilder: AccountancyFormBuilder,
        @Inject(MAT_DIALOG_DATA) public input: any) {
        super(injector);
    }

    ngOnInit(): void {
        this.bpSetup(new AccountancyDialogCorrectInvoiceBundle());
    }

    submit() {
        if (!this.submitted && this.correctInvoiceForm.valid) {
            this.submitted = true;
            this.data = Object.assign(new AccountancyDialogCorrectInvoiceBundle(), {
                invoiceId: this.input.invoice.id,
                reason: this.correctInvoiceForm.value.reason,
                bookingDate: this.correctInvoiceForm.value.bookingDate,
                itemIndices: this.correctInvoiceForm.value.itemIndices,
                accounts: this.correctInvoiceForm.value.accounts
            });
            this.bpSubmit();
            this.close();
        }
    }

    close() {
        this.dialogRef.close();
    }

    getInvoiceSum() {
        return this.input.invoice.invoiceItems
            .map(item => item.unitCost * item.quantity)
            .reduce((a, b) => a + b, 0);
    }

    getInvoiceTax() {
        return this.input.invoice.invoiceItems
            .map(item => item.unitCost * item.quantity * (item.vat / 100.0))
            .reduce((a, b) => a + b, 0);
    }

    getCorrectedSum() {
        return this.correctInvoiceForm.value.itemIndices
            .map(idx => this.input.invoice.invoiceItems[idx])
            .map(item => item.unitCost * item.quantity)
            .reduce((a, b) => a + b, 0);
    }

    getCorrectedTax() {
        return this.correctInvoiceForm.value.itemIndices
            .map(idx => this.input.invoice.invoiceItems[idx])
            .map(item => item.unitCost * item.quantity * (item.vat / 100.0))
            .reduce((a, b) => a + b, 0);
    }

    accountsWarning() {
        for (let i = 0; i < this.correctInvoiceForm.value.accounts.length; i++) {
            const account = this.correctInvoiceForm.value.accounts[i];
            if (account.sum > 0) {
                return true;
            }
        }
        return false;
    }

    addAccount() {
        const control = this.correctInvoiceForm.get('accounts');
        control.setValue([...control.value, {
            accountId: null,
            sum: null
        }]);
    }

    hasNoAccountsOrContainsEmptyAccount(): boolean {
        if (this.correctInvoiceForm.value.accounts.length === 0) {
            return true;
        }
        for (const account of this.correctInvoiceForm.value.accounts) {
            if (account.accountId === null || account.accountId === undefined || account.accountId === '') {
                return true;
            }
            if (account.sum === null || account.sum === undefined) {
                return true;
            }
        }
        return false;
    }

}
