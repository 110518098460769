export class DragDropPlanConfig {

  static PLAN_START = 0;

  static PLAN_END = 96;

  /**
   * Color for items ending in the given deadline
   */
  static DEADLINE_COLOR_VALID = 'rgba(0, 255, 0, 0.3)';

  /**
   * Color for items doesn´t end in the given deadline
   */
  static DEADLINE_COLOR_INVALID = 'rgba(255, 0, 0, 0.3)';

  /**
   * Color for items that are not valid or invalid
   */
  static COLOR_NEUTRAL = 'rgba(196, 196, 196, 0.6)';

}
