import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';

@Component({
    selector: 'app-masterdata-editor',
    templateUrl: './masterdata-editor.component.html',
    styleUrls: ['./masterdata-editor.component.scss']
})
export class MasterdataEditorComponent implements OnInit {

    index = 0;

    MASTERDATA_TAB_GENERAL = 0;
    MASTERDATA_TAB_COMPANY_ADDRESSES = 1;
    MASTERDATA_TAB_CONTACTS = 2;
    MASTERDATA_TAB_ACCOUNTANCY = 3;
    MASTERDATA_TAB_DISPOSITION = 4;
    MASTERDATA_TAB_CARRIERS = 5;
    MASTERDATA_TAB_LOCATIONS = 6;

    @Output() saved = new EventEmitter();

    @Input() businessPartner: BusinessPartnerDomainObject;

    constructor() {
    }

    ngOnInit() {
    }

}
