import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {PageSettings} from '../../../model/old/PageSettings';
import {Preset} from '../../../model/old/Preset';
import {tap} from 'rxjs/operators';
import {merge} from 'rxjs';
import {PresetDialogComponent} from '../preset-dialog/preset-dialog.component';
import {ContextMenuComponent} from 'ngx-contextmenu';
import {PresetDeleteComponent} from '../preset-delete/preset-delete.component';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';

export class PresetTableBundle extends AbstractBypassBundle {
    pageSettings: PageSettings;
    total: number;
    entries: Preset[];
}


@Component({
    selector: 'app-preset-table',
    templateUrl: './preset-table.component.html',
    styleUrls: ['./preset-table.component.scss']
})
export class PresetTableComponent extends BypassFormComponent<PresetTableBundle> implements OnInit {

    paginatorPageSizeOptions = [10, 25, 50, 75];
    paginatorPageSize = this.paginatorPageSizeOptions[3];
    _filter: string = undefined;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    displayedColumns = ['name', 'customer', 'material', 'loadingLocation', 'unloadingLocation', 'quantity',  'duration', 'distance', 'message', 'comment', 'orderNumber'];
    dataSource = new MatTableDataSource([]);

    @ViewChild(ContextMenuComponent, {static: true}) public basicMenu: ContextMenuComponent;

    constructor(protected injector: Injector, private dialog: MatDialog) {
        super(injector);
        this.bpSetup(new PresetTableBundle());
    }

    ngOnInit() {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.paginatorPageSize;

        merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadData())).subscribe();
        this.loadData();
    }

    private loadData() {
        this.data = new PresetTableBundle();
        this.data.entries = [];

        let pageIndex = 0;
        if (this.paginator.pageIndex !== undefined) {
            pageIndex = this.paginator.pageIndex;
        }
        let pageSize = this.paginatorPageSize;
        if (this.paginator.pageSize !== undefined) {
            pageSize = this.paginator.pageSize;
        }

        this.data.pageSettings = new PageSettings(
            'Preset',
            pageIndex,
            pageSize,
            this.sort.active,
            this.sort.direction,
            this._filter
        );

        this.bpSubmit();
    }

    bpOnMessageReceived() {
        this.dataSource.data = this.data.entries;
        this.paginator.length = this.data.total;
    }

    applyFilter(filterValue: string) {
        if (filterValue === '') {
            this._filter = undefined;
        } else {
            this._filter = filterValue.toLowerCase();
        }
        this.loadData();
    }

    onRowClick(row) {
        this.editPreset(row);
    }

    createPreset() {
        this.dialog.open(PresetDialogComponent, {width: '1000px'});
    }

    editPreset(preset: Preset) {
        this.dialog.open(PresetDialogComponent, {data: {preset: preset}, width: '1000px'});
    }

    deletePreset(preset: Preset) {
        this.dialog.open(PresetDeleteComponent, {data: {preset: preset}, width: '1000px'});
    }

}

