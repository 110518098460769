import {Component, Inject, Injector, OnInit} from '@angular/core';
import {BypassFormComponent} from '@byteways/bypass-core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';

export class DialogReverseScheduleBundle {
    tourId: number;
    emails: string[];
}

@Component({
    selector: 'app-dialog-reverse-schedule',
    templateUrl: './dialog-reverse-schedule.component.html',
    styleUrls: ['./dialog-reverse-schedule.component.scss']
})
export class DialogReverseScheduleComponent extends BypassFormComponent<DialogReverseScheduleBundle> implements OnInit {

    sendTourForm = new FormGroup({
        emails: new FormArray([this.createEmailFormControl()]),
    });

    get emailsFormArray(): FormArray {
        return this.sendTourForm.get('emails') as FormArray;
    }

    constructor(protected injector: Injector,
                public dialog: MatDialog,
                public dialogRef: MatDialogRef<DialogReverseScheduleComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(Object.assign(new DialogReverseScheduleBundle(), {
            tourId: this.input.tour.id
        }));
    }

    ngOnInit() {
    }

    protected bpOnMessageReceived() {
        this.emailsFormArray.clear();
        for (const email of this.data.emails) {
            const ctrl = this.createEmailFormControl();
            ctrl.setValue(email);
            this.emailsFormArray.push(ctrl);
        }
    }


    submit() {
        this.data = Object.assign(new DialogReverseScheduleBundle(), {
            tourId: this.input.tour.id,
            emails: this.emailsFormArray.value
        });
        this.bpSubmit();
        this.close();
    }

    close(): void {
        this.dialogRef.close();
    }

    createEmailFormControl(): FormControl {
        return new FormControl(undefined, [Validators.email]);
    }

}

