import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-invoice-item-controls',
    templateUrl: './invoice-item-controls.component.html',
    styleUrls: ['./invoice-item-controls.component.scss']
})
export class InvoiceItemControlsComponent implements OnInit {

    @Input() posNr = 0;
    @Input() enableMoveUp = false;
    @Input() enableMoveDown = false;
    @Input() enableDetails = false;
    @Output() deleted = new EventEmitter();
    @Output() movedUp = new EventEmitter();
    @Output() movedDown = new EventEmitter();
    @Output() detailsClicked = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

}
