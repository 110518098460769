export class PageSettings {
    entityType: string;
    pageIndex: number;
    pageSize: number;
    sortField: string;
    sortDirection: string;
    filter: string;


    constructor(entityType: string, pageIndex: number, pageSize: number, sortField: string, sortDirection: string, filter: string) {
        this.entityType = entityType;
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.sortField = sortField;
        this.sortDirection = sortDirection;
        this.filter = filter;
    }
}
