import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EditorItemValue} from '../model/editor-item.value';
import {EditorItemTypeValue} from '../model/editor-item-type.value';
import {AdditionalCost} from '../components/forms/invoice-item-form/invoice-item-form.component';

@Injectable({
    providedIn: 'root'
})
export class AccountancyFormBuilder {

    constructor(private fb: FormBuilder) {

    }

    private notPresent(field: any): boolean {
        return !this.isPresent(field);
    }

    private isPresent(field): boolean {
        return field !== null && field !== undefined;
    }

    public validateAccounts(invoiceForm: FormGroup): boolean {
        // validate accounts
        // compare total sum of accounts with invoice total
        // TODO: negative preise?
        const accountsTotalSum = invoiceForm.get('accounts').value
            .map(c => c.sum ? +(c.sum) : 0.0)
            .reduce((a, b) => a + b, 0);
        const invoiceTotalPrice = this.getInvoiceItems(invoiceForm)
            .controls
            .map(c => c.value)
            .map(item => {
                const quantity = this.isPresent(item.quantity) ? item.quantity : 0.0;
                const price = this.isPresent(item.unitCost) ? item.unitCost : 0.0;
                const tax = this.isPresent(item.vat) ? item.vat : 0.0;
                const itemTotal = quantity * price * (tax / 100.0 + 1.0);
                return itemTotal;
            })
            .reduce((a, b) => a + b, 0);
        return Math.abs(accountsTotalSum - invoiceTotalPrice) < 0.01;
    }

    public validateInvoiceItems(invoiceForm: FormGroup): boolean {
        // validate invoice items
        const invoiceItems = this.getInvoiceItems(invoiceForm).controls.map(c => c.value);
        for (const i of invoiceItems) {
            // if item has tour: needs quantity, unit, unit cost, vat
            if (i.tourId !== null) {
                if (this.notPresent(i.quantity) || this.notPresent(i.unit) || this.notPresent(i.unitCost) || this.notPresent(i.vat)) {
                    return false;
                }
            }
            // if has item has any of quantity/unit/unitCost/vat, needs all of the others
            if (this.isPresent(i.quantity) || this.isPresent(i.unit) || this.isPresent(i.unitCost) || this.isPresent(i.vat)) {
                if (this.notPresent(i.quantity) || this.notPresent(i.unit) || this.notPresent(i.unitCost) || this.notPresent(i.vat)) {
                    return false;
                }
            }
            const hasPrice = this.isPresent(i.unitCost) && this.isPresent(i.quantity) && this.isPresent(i.vat) && i.quantity > 0;
            if (!hasPrice) {
                return false;
            }
        }
        return true;
    }

    public validateFormContent(invoiceForm: FormGroup): boolean {
        return invoiceForm.valid;
    }

    public validate(invoiceForm: FormGroup): boolean {
        return this.validateAccounts(invoiceForm) && this.validateInvoiceItems(invoiceForm) && this.validateFormContent(invoiceForm);
    }

    // =====================================================================================================================================
    // Form group builders

    public createIncomingCreditFormGroup(): FormGroup {
        return this.fb.group({
            invoiceDate: this.fb.control(new Date(), [Validators.required]),
            bookingDate: this.fb.control(new Date(), [Validators.required]),
            invoiceAddressId: this.fb.control(null, [Validators.required]),
            invoiceBankAccountId: this.fb.control(null, [Validators.required]),
            incomingInvoiceNumber: this.fb.control(null, [Validators.required]), // = eingangsgutschriftsnummer
            paymentTargetIds: this.fb.control([]),
            headerText: this.fb.control(null),
            invoiceItems: this.fb.array([], [Validators.required]),
            footerText: this.fb.control(null),
            accounts: this.fb.control([]),
        });
    }

    public createInvoiceFormGroup(): FormGroup {
        return this.fb.group({
            invoiceDate: this.fb.control(new Date(), [Validators.required]),
            bookingDate: this.fb.control(new Date(), [Validators.required]),
            invoiceAddressId: this.fb.control(null, [Validators.required]),
            invoiceBankAccountId: this.fb.control(null, [Validators.required]),
            incomingInvoiceNumber: this.fb.control(null),
            paymentTargetIds: this.fb.control([]),
            headerText: this.fb.control(null),
            invoiceItems: this.fb.array([], [Validators.required]),
            footerText: this.fb.control(null),
            accounts: this.fb.control([]),
        });
    }

    public createInvoiceItemFormGroup(): FormGroup {
        return this.fb.group({
            label: this.fb.control(null),
            quantity: this.fb.control(null),
            unit: this.fb.control(null),
            unitCost: this.fb.control(null),
            vat: this.fb.control(null),
            tourId: this.fb.control(null),
            discountable: this.fb.control(true),
            type: this.fb.control(EditorItemTypeValue.DEFAULT)
        });
    }

    public createAccountFormGroup(): FormGroup {
        return this.fb.group({
            accountId: this.fb.control(null, [Validators.required]),
            sum: this.fb.control(null, [Validators.required])
        });
    }

    // =====================================================================================================================================
    // Form array tools

    public getInvoiceItems(invoiceForm: FormGroup): FormArray {
        return invoiceForm.get('invoiceItems') as FormArray;
    }

    public addInvoiceItem(invoiceForm: FormGroup, value: EditorItemValue | null = null): void {
        const group = this.createInvoiceItemFormGroup();
        if (value !== null) {
            group.patchValue(value);
        }
        (invoiceForm.get('invoiceItems') as FormArray).push(group);
    }

    public addAdditionalCost(invoiceForm: FormGroup, i: number, cost: AdditionalCost): void {
        const itemForm = this.createInvoiceItemFormGroup();
        let label;
        switch (cost.type) {
            case EditorItemTypeValue.CO2:
                label = 'CO2-Zuschlag';
                break;
            case EditorItemTypeValue.SHORTFALL:
                label = 'Mindermenge';
                break;
            case EditorItemTypeValue.WAITING:
                label = 'Wartezeit';
                break;
            case EditorItemTypeValue.PALLET:
                label = 'Paletten';
                break;
            case EditorItemTypeValue.ENERGY:
                label = 'Energiekostenzuschlag';
                break;
            case EditorItemTypeValue.DEFAULT:
                throw new Error();
            default:
                throw new Error();
        }
        itemForm.patchValue({
            label: `Tour ${cost.tourNumber}: ${label}`,
            quantity: cost.type === EditorItemTypeValue.CO2 ? null : 1,
            unit: (() => {
                switch (cost.type) {
                    case EditorItemTypeValue.CO2:
                    case EditorItemTypeValue.ENERGY:
                        return 'Tonnen';
                    default:
                        return 'Festpreis';
                }
            })(),
            vat: cost.vat,
            discountable: false,
            type: cost.type
        });
        this.getInvoiceItems(invoiceForm).insert(i, itemForm);
    }

}
