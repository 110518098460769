import {Component, Injector, Input, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MaterialDomainObject} from '../../../model/domain/material.domain-object';
import {TaxGroupDomainObject} from '../../../model/domain/tax-group.domain-object';

export class MaterialTaxInfo {
    material: MaterialDomainObject;
    taxGroup: TaxGroupDomainObject;
}

export class AccountancyInvoicingMaterialTaxInfoBundle extends AbstractBypassBundle {
    componentId: number;
    materialId: number;
    invoiceAddressId: number;

    materialTaxInfo: MaterialTaxInfo;
}

@Component({
    selector: 'app-accountancy-invoicing-material-tax-info',
    templateUrl: './accountancy-invoicing-material-tax-info.component.html',
    styleUrls: ['./accountancy-invoicing-material-tax-info.component.scss']
})
export class AccountancyInvoicingMaterialTaxInfoComponent extends BypassFormComponent<AccountancyInvoicingMaterialTaxInfoBundle> implements OnInit {

    static componentId = 0;

    public materialTaxInfo: MaterialTaxInfo = null;

    private _materialId: number = null;
    private _invoiceAddressId: number = null;

    @Input() set materialId(value: number) {
        this._materialId = value;
        this.onInputsChange();
    }

    @Input() set invoiceAddressId(value: number) {
        this._invoiceAddressId = value;
        this.onInputsChange();
    }

    get materialId(): number {
        return this._materialId;
    }

    get invoiceAddressId(): number {
        return this._invoiceAddressId;
    }

    componentId = AccountancyInvoicingMaterialTaxInfoComponent.componentId++;

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new AccountancyInvoicingMaterialTaxInfoBundle());
    }

    ngOnInit() {

    }

    private onInputsChange() {
        if (this._materialId !== null && this._invoiceAddressId !== null
            && this._materialId !== undefined && this._invoiceAddressId !== undefined) {


            const bundle = new AccountancyInvoicingMaterialTaxInfoBundle();
            bundle.componentId = this.componentId;
            bundle.materialId = this._materialId;
            bundle.invoiceAddressId = this._invoiceAddressId;
            this.data = bundle;
            this.bpSubmit();
        }
    }

    protected bpFilterIncomingData(data: AccountancyInvoicingMaterialTaxInfoBundle): boolean {
        return data.componentId === this.componentId;
    }

    protected bpOnMessageReceived(): void {
        this.materialTaxInfo = this.data.materialTaxInfo;
    }

}
