import {Component, Injector, OnInit} from '@angular/core';
import {Material} from '../../../model/old/Material';
import {MaterialDomainObject} from '../../../model/domain/material.domain-object';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';


export class AccountancyTaxesMaterialsBundle extends AbstractBypassBundle {
    materials: MaterialDomainObject[];
}

@Component({
    selector: 'app-accountancy-taxes-materials',
    templateUrl: './accountancy-taxes-materials.component.html',
    styleUrls: ['./accountancy-taxes-materials.component.scss']
})
export class AccountancyTaxesMaterialsComponent extends BypassDataComponent<AccountancyTaxesMaterialsBundle> implements OnInit {

    materialList: MaterialDomainObject[];

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new AccountancyTaxesMaterialsBundle());
        this.bpSetLoadingUntilMessageReceived();
    }

    protected bpOnMessageReceived(): void {
        this.materialList = [...this.data.materials];
        this.materialList.sort((a, b) => a.name < b.name ? -1 : 1);
    }

    ngOnInit() {
    }

}
