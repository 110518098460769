import {Component, Input, Optional} from '@angular/core';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';
import {NgControl} from '@angular/forms';

@Component({
  selector: 'ui-form-field-password',
  templateUrl: './ui-form-field-password.component.html',
  styleUrls: ['./ui-form-field-password.component.scss']
})
export class UiFormFieldPasswordComponent extends FormFieldDataComponent {

  @Input() iconPasswordVisible: boolean;

  showPassword = false;

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
    this.type = 'password';
  }

}
