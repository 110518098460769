import {Component, Injector, OnInit} from '@angular/core';
import {Invoice} from '../../../model/old/Invoice';
import {AccountancyInvoiceTable2DateComponent} from './accountancy-invoice-table2-date/accountancy-invoice-table2-date.component';
import {AccountancyInvoiceTable2DateShortComponent} from './accountancy-invoice-table2-date-short/accountancy-invoice-table2-date-short.component';
import {AccountancyInvoiceTable2PriceComponent} from './accountancy-invoice-table2-price/accountancy-invoice-table2-price.component';
import {AccountancyInvoiceTable2BusinessPartnerComponent} from './accountancy-invoice-table2-business-partner/accountancy-invoice-table2-business-partner.component';
import {AccountancyInvoiceTable2HoverTextComponent} from './accountancy-invoice-table2-hover-text/accountancy-invoice-table2-hover-text.component';
import {AccountancyInvoiceTable2StatusComponent} from './accountancy-invoice-table2-status/accountancy-invoice-table2-status.component';
import {
    DatatableBundle,
    DatatableComponent,
    DatatableConfiguration,
    SearchSettings
} from '@byteways/bypass-datatable';
import {AbstractBypassBundle} from '@byteways/bypass-core';
import {MatDialog} from '@angular/material/dialog';


export class AccountancyInvoiceTable2Bundle extends AbstractBypassBundle implements DatatableBundle<Invoice> {
    searchSettings: SearchSettings = new SearchSettings();
    page: Invoice[] = [];
    total = 0;
}


@Component({
    selector: 'app-accountancy-invoice-table2',
    templateUrl: './accountancy-invoice-table2.component.html',
    styleUrls: ['./accountancy-invoice-table2.component.scss']
})
export class AccountancyInvoiceTable2Component extends DatatableComponent<Invoice, AccountancyInvoiceTable2Bundle> {

    static getInvoiceNumber(invoiceNumber: string | null | undefined): string {
        if (invoiceNumber === undefined || invoiceNumber === null) {
            return '-';
        } else {
            return invoiceNumber;
        }
    }

    static translateInvoiceType(invoiceType: string): string {
        switch (invoiceType) {
            case 'CANCELLATION':
                return 'Stornierung';
            case 'CORRECTION':
                return 'Korrektur';
            case 'INCOMING_CREDIT':
                return 'Gutschrift';
            default:
                return 'Rechnung';
        }
    }

    static formatPrice(price: number): string {
        if (price === undefined || price === null) {
            return ' — ';
        }
        const fixed = price.toFixed(2);
        const decimalPoints = fixed.replace(/\d(?=(\d{3})+\.)/g, '$&.');
        const decimalCut = decimalPoints.lastIndexOf('.');
        return decimalPoints.substr(0, decimalCut)
            + ',' + decimalPoints.substr(decimalCut + 1)
            + ' €';
    }

    constructor(protected injector: Injector, private dialog: MatDialog) {
        super(injector);
        this.bpSetup(new AccountancyInvoiceTable2Bundle(), true);
    }

    protected messageToConfiguration(bundle: DatatableBundle<Invoice>): DatatableConfiguration<Invoice, AccountancyInvoiceTable2Bundle> {
        return {
            page: bundle.page,
            total: bundle.total,
            searchSettings: bundle.searchSettings,
            columns: this.datatableColumnDefinition,
            createBundle: () => new AccountancyInvoiceTable2Bundle(),
            onCreateClick: null
        } as DatatableConfiguration<Invoice, AccountancyInvoiceTable2Bundle>;
    }

    // =====================================================================================================================================

    datatableColumnDefinition = [
        {
            title: 'Status',
            identifier: 'status',
            sortable: true,
            accessor: (invoice: Invoice) => invoice.status,
            component: AccountancyInvoiceTable2StatusComponent
        },
        {
            title: 'Erstellt',
            identifier: 'createdDate',
            sortable: true,
            accessor: (invoice: Invoice) => invoice.createdDate,
            component: AccountancyInvoiceTable2DateComponent
        },
        {
            title: 'Typ',
            identifier: 'type',
            sortable: true,
            accessor: (invoice: Invoice) => AccountancyInvoiceTable2Component.translateInvoiceType(invoice.type),
            component: undefined
        },
        {
            title: 'Nr.',
            identifier: 'invoiceNumber',
            sortable: true,
            accessor: (invoice: Invoice) => AccountancyInvoiceTable2Component.getInvoiceNumber(invoice.invoiceNumber),
            component: undefined
        },
        {
            title: 'Empfänger',
            identifier: 'businessPartner',
            sortable: true,
            accessor: (invoice: Invoice) => invoice.businessPartner,
            component: AccountancyInvoiceTable2BusinessPartnerComponent
        },
        {
            title: 'Gebucht',
            identifier: 'bookingDate',
            sortable: true,
            accessor: (invoice: Invoice) => invoice.bookingDate,
            component: AccountancyInvoiceTable2DateShortComponent
        },
        {
            title: 'Eingangsrechnung',
            identifier: 'incomingInvoiceNumber',
            sortable: false,
            accessor: (invoice: Invoice) => invoice.incomingInvoiceNumber,
            component: AccountancyInvoiceTable2HoverTextComponent
        },
        {
            title: 'Touren',
            identifier: 'tourNumbers',
            sortable: false,
            accessor: (invoice: Invoice) => invoice.invoiceItems.filter(i => !!(i.tour)).map(i => i.tour.tourNumber).join(', '),
            component: AccountancyInvoiceTable2HoverTextComponent
        },
        {
            title: 'Sachkonten',
            identifier: 'accounts',
            sortable: false,
            accessor: (invoice: Invoice) => invoice.accounts.map(a => `${a.accountId}: ${AccountancyInvoiceTable2Component.formatPrice(a.sum)}`).join(', '),
            component: AccountancyInvoiceTable2HoverTextComponent
        },
        {
            title: 'Netto',
            identifier: 'netto',
            sortable: false,
            accessor: (invoice: Invoice) => invoice.netto,
            component: AccountancyInvoiceTable2PriceComponent
        },
        {
            title: 'Brutto',
            identifier: 'brutto',
            sortable: false,
            accessor: (invoice: Invoice) => invoice.brutto,
            component: AccountancyInvoiceTable2PriceComponent
        }
    ];

}
