import {Component, Input, OnInit} from '@angular/core';
import {DispoPlanTour} from '../../model/DispoPlanTour';

@Component({
    selector: 'app-dispo-plan-tour',
    templateUrl: './dispo-plan-tour.component.html',
    styleUrls: ['./dispo-plan-tour.component.scss']
})
export class DispoPlanTourComponent implements OnInit {

    @Input() tour: DispoPlanTour;

    constructor() {
    }

    ngOnInit() {
    }

}
