import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../../model/old/Customer';

@Component({
    selector: 'app-customer-display',
    templateUrl: './customer-display.component.html',
    styleUrls: ['./customer-display.component.scss']
})
export class CustomerDisplayComponent implements OnInit {

    @Input() customer: Customer;

    constructor() {
    }

    ngOnInit() {
    }

    customerDisplay(): string {
        return this.customer.shortName;
    }

    customerTooltip(): string {
        return this.customer.name;
    }

}
