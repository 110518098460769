import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-dialogtourdetails',
    templateUrl: './dialogtourdetails.component.html',
    styleUrls: ['./dialogtourdetails.component.scss']
})
export class DialogTourDetailsComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DialogTourDetailsComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }

}
