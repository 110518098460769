import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TourDisplay} from '../../partials/invoice-tour-display/invoice-tour-display.component';
import {InvoiceFormComponent} from '../../forms/invoice-form/invoice-form.component';
import {InvoiceTourSelectionComponent} from '../../partials/invoice-tour-selection/invoice-tour-selection.component';

@Component({
    selector: 'app-invoice-editor',
    templateUrl: './invoice-editor.component.html',
    styleUrls: ['./invoice-editor.component.scss']
})
export class InvoiceEditorComponent {

    @Input() businessPartnerId: number;
    @Output() submitted = new EventEmitter<any>();
    @ViewChild('invoiceForm', {static: false}) invoiceForm: InvoiceFormComponent;
    disabledTourIds: number[] = [];
    invoiceCommissionType: string | null = null;
    enableVatWarning = false;
    @Input() automaticAccountsCalculation = true;

    addTourToInvoice(tourSelection: InvoiceTourSelectionComponent, invoiceForm: InvoiceFormComponent, tourDisplay: TourDisplay) {
        // TODO: commission type eleganter lösen
        tourSelection.commissionType = tourDisplay.commissionType;
        this.invoiceCommissionType = tourDisplay.commissionType;

        invoiceForm.addTour(tourDisplay);
        this.disabledTourIds.push(tourDisplay.tourId);
    }

    removeTourFromInvoice(tourSelection: InvoiceTourSelectionComponent, invoiceForm: InvoiceFormComponent, tourId: number) {
        this.disabledTourIds = this.disabledTourIds.filter(tid => tid !== tourId);
        if (this.disabledTourIds.length === 0) {
            tourSelection.commissionType = null;
        }
    }

    onSubmitted(event) {
        if (this.enableVatWarning) {
            if (confirm('Achtung!\n\nDie Rechnung enthält möglicherweise Rechnungsposten, für die die Steueränderungen zwischen dem 1. Juli und 31. Dezember 2020 beachtet werden müssen!\n\n"OK" klicken, um zu speichern\n\n"Abbrechen" klicken, um weiter zu bearbeiten')) {
                this.submitted.emit(event);
            }
        } else {
            this.submitted.emit(event);
        }
    }


}
