import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'abbrev'
})
export class AbbrevPipe implements PipeTransform {

    transform(value: string, maxLength: number): string {
        if (value === undefined || value === null) {
            return '-';
        }
        if (value.length <= maxLength) {
            return value;
        } else {
            return `${value.substring(0, maxLength - 4)}...`;
        }
    }

}
