import {TourItem} from '../model/TourItem';

export class TourItemUtils {

    static isInDeadline(item: TourItem): boolean {
        if (!item.deadline) {
            return true;
        }

        const itemEnd = item.x + item.cols;
        const deadlineStart = item.deadline.x;
        const deadlineEnd = deadlineStart + item.deadline.cols;
        return itemEnd >= deadlineStart && itemEnd <= deadlineEnd;
    }

}
