import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {BankAccountDomainObject} from '../../../../model/domain/bank-account.domain-object';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';


export class InputBusinessPartnerBankAccountSelectorBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    bankAccounts: BankAccountDomainObject[];
}

@Component({
    selector: 'app-input-business-partner-bank-account-selector',
    templateUrl: './input-business-partner-bank-account-selector.component.html',
    styleUrls: ['./input-business-partner-bank-account-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputBusinessPartnerBankAccountSelectorComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => InputBusinessPartnerBankAccountSelectorComponent)
        }
    ]
})
export class InputBusinessPartnerBankAccountSelectorComponent extends BypassDataComponent<InputBusinessPartnerBankAccountSelectorBundle> implements ControlValueAccessor, OnInit, Validator {

    @Input() set businessPartnerId(id: number) {
        this.bpSetup(Object.assign(new InputBusinessPartnerBankAccountSelectorBundle(), {
            businessPartnerId: id
        }));
    }

    @Input() placeholder = 'Bankverbindung';
    @Input() required = false;
    @Input() transformValue: (bankAccount: BankAccountDomainObject) => any = (bankAccount: BankAccountDomainObject) => {
        if (bankAccount !== null && bankAccount !== undefined) {
            return bankAccount.id;
        } else {
            return null;
        }
    };
    @Input() transformWrite: (input: any, options: BankAccountDomainObject[]) => BankAccountDomainObject = (input: any, options: BankAccountDomainObject[]) => {
        return options.find(o => o.id === input);
    };

    control = new FormControl(undefined, [Validators.required]);
    options: BankAccountDomainObject[] | undefined;
    private onChangeListeners: ((bankAccount: any) => {})[] = [];
    private onTouchedListeners: (() => {})[] = [];

    private writtenValue: any;

    constructor(protected injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.control.valueChanges.subscribe(() => {
            this.onChanged();
        });
    }

    protected bpOnMessageReceived(): void {
        this.options = this.data.bankAccounts;
        setTimeout(() => this.control.setValue(this.transformWrite(this.writtenValue, this.options)));
        this.onTouched();
    }

    protected bpFilterIncomingData(data: InputBusinessPartnerBankAccountSelectorBundle): boolean {
        this.onTouched();
        return this.options === undefined;
    }

    onChanged() {
        for (const onChangeListener of this.onChangeListeners) {
            onChangeListener(this.transformValue(this.control.value));
        }
    }

    onTouched() {
        for (const onTouchedListener of this.onTouchedListeners) {
            onTouchedListener();
        }
    }

    registerOnChange(fn: (address: any) => {}): void {
        this.onChangeListeners.push(fn);
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouchedListeners.push(fn);
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    writeValue(address: any): void {
        this.writtenValue = address;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.control.valid ? null : {'bankAccount': true};
    }

}
