import {Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ThemeService} from './theme.service';
import {Observable} from 'rxjs';
import {BypassBaseComponent} from '@byteways/bypass-core';
import {NgSelectConfig} from '@ng-select/ng-select';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BypassBaseComponent implements OnInit {

    connected = false;
    isDarkTheme: Observable<boolean>;
    identiconsEnabled: Observable<boolean>;


    constructor(protected injector: Injector, private router: Router, private themeService: ThemeService, private ngSelectConfig: NgSelectConfig) {
        super(injector);
        this.ngSelectConfig.notFoundText = 'Keine Ergebnisse gefunden';
    }

    doLogout() {
        this.dataService.logout(() => {
            // FIXME: hack für hard reload vom component ohne location.reload()
            this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() =>
                this.router.navigate(['/login'])
            );
        });
    }

    ngOnInit() {
        this.dataService.onConnect(() => {
            this.connected = true;
        });
        this.dataService.onDisconnect(() => {
            this.connected = false;
        });

        this.isDarkTheme = this.themeService.isDarkTheme;
        this.identiconsEnabled = this.themeService.identiconsEnabled;
    }

    setDarkTheme(isDarkTheme: boolean) {
        this.themeService.setDarkTheme(isDarkTheme);
    }

    setIdenticonsEnabled(identiconsEnabled: boolean) {
        this.themeService.setIdenticonsEnabled(identiconsEnabled);
    }

}
