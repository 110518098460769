import {Component, Input} from '@angular/core';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';

@Component({
  selector: 'ui-form-field-textarea',
  templateUrl: './ui-form-field-textarea.component.html',
  styleUrls: ['./ui-form-field-textarea.component.scss']
})
export class UiFormFieldTextareaComponent extends FormFieldDataComponent {

  @Input() rows: number;
  @Input() cols: number;

}
