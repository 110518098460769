export class ContactDomainObject {
    id: number|undefined;
    name: string|undefined;
    phone: string|undefined;
    mobile: string|undefined;
    email: string|undefined;
    fax: string|undefined;
    comment: string|undefined;
    accountancyEmailReceiver?: boolean;
}
