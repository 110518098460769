import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BypassFormsModule} from '@byteways/bypass-forms';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';
import {SharedModule} from '../shared/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {FeaturesModule} from '../../features/features.module';
import {BypassSearchableInputModule} from '@byteways/bypass-searchable-input';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DispoStatsComponent} from './components/dispo-stats/dispo-stats.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DispoStatsResultComponent} from './components/dispo-stats-result/dispo-stats-result.component';
import {StatsCarrierOptionsPipe} from './pipes/stats-carrier-options.pipe';
import {MatSelectModule} from '@angular/material/select';
import {StatsForCarrierPipe} from './pipes/stats-for-carrier.pipe';
import {MatTableModule} from '@angular/material/table';
import {DispoStatsWeeklyTableComponent} from './components/dispo-stats-weekly-table/dispo-stats-weekly-table.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {DispoStatsTotalTableComponent} from './components/dispo-stats-total-table/dispo-stats-total-table.component';
import {KmPerEuroPipe} from './pipes/km-per-euro.pipe';
import {EuroPerKmPipe} from './pipes/euro-per-km.pipe';
import {InputToNumberPipe} from './pipes/input-to-number.pipe';
import {CarrierToursPipe} from './pipes/carrier-tours.pipe';
import {EuroPerTourPipe} from './pipes/euro-per-tour.pipe';
import {EuroPerTonPipe} from './pipes/euro-per-ton.pipe';
import {TotalToursPipe} from './pipes/total-tours.pipe';
import {NumOwnCarriersPipe} from './pipes/num-own-carriers.pipe';
import {DispoStatsFieldSelectionComponent} from './components/dispo-stats-field-selection/dispo-stats-field-selection.component';
import {BusinessPartnerGroupDisplayComponent} from './components/dispo-stats/business-partner-group-display-component/business-partner-group-display.component';
import {UiSelectionListModule} from '@byteways/ui-selection-list';
import { BusinessPartnerDisplayComponent } from './components/dispo-stats/business-partner-display-component/business-partner-display.component';


@NgModule({
    declarations: [
        DispoStatsComponent,
        DispoStatsResultComponent,
        StatsCarrierOptionsPipe,
        StatsForCarrierPipe,
        DispoStatsWeeklyTableComponent,
        DispoStatsTotalTableComponent,
        KmPerEuroPipe,
        EuroPerKmPipe,
        InputToNumberPipe,
        CarrierToursPipe,
        EuroPerTourPipe,
        EuroPerTonPipe,
        TotalToursPipe,
        NumOwnCarriersPipe,
        DispoStatsFieldSelectionComponent,
        BusinessPartnerGroupDisplayComponent,
        BusinessPartnerDisplayComponent
    ],
    imports: [
        CommonModule,
        BypassFormsModule,
        ReactiveFormsModule,
        FlexModule,
        SharedModule,
        FormsModule,
        MatButtonModule,
        FeaturesModule,
        BypassSearchableInputModule,
        BypassIdenticonModule,
        MatRadioModule,
        MatDividerModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatExpansionModule,
        UiSelectionListModule
    ]
})
export class DispoStatsModule {
}
