export enum EditorTypeValue {
    INVOICE = 'INVOICE',
    INCOMING_CREDIT = 'INCOMING_CREDIT'
}

export class EditorTypeValueParser {
    public static parse(value: string): EditorTypeValue {
        switch (value.toUpperCase().replace('_', '')) {
            case 'INVOICE':
                return EditorTypeValue.INVOICE;
            case 'INCOMINGCREDIT':
                return EditorTypeValue.INCOMING_CREDIT;
            default:
                console.error(`Could not parse value '${value}' (unknown editor type)`);
                return null;
        }
    }
}
