import {Pipe, PipeTransform} from '@angular/core';
import {TourItem} from '../model/TourItem';

@Pipe({
    name: 'tourItemTimeRange',
    pure: false
})
export class TourItemTimeRangePipe implements PipeTransform {

    transform(item: TourItem): string {
        return `${this.positionToTime(item.x / 4)} - ${this.positionToTime((item.x + item.cols) / 4)}`;
    }

    positionToTime(hours: number): string {
        const hoursFloor = Math.floor(hours);
        const minutes = (hours - Math.floor(hours)) * 60;
        const hoursString = hoursFloor > 9 ? hoursFloor.toString() : `0${hoursFloor.toString()}`;
        const minutesString = minutes > 9 ? minutes.toString() : `0${minutes.toString()}`;
        return `${hoursString}:${minutesString}`;
    }

}
