import {Component, Input, OnInit} from '@angular/core';
import {Material} from '../../model/old/Material';

@Component({
    selector: 'app-material-display',
    templateUrl: './material-display.component.html',
    styleUrls: ['./material-display.component.scss']
})
export class MaterialDisplayComponent implements OnInit {

    @Input() material: Material;

    @Input() disableTooltip = false;

    @Input() disableText = false;

    constructor() {
    }

    ngOnInit() {
    }

    display(): string {
        if (this.disableText) {
            return '';
        }
        return this.material.name;
    }

    tooltip(): string {
        return this.material.name;
    }

}
