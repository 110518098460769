import {Component, Injector, OnInit} from '@angular/core';
import {BusinessPartner} from '../../../model/old/BusinessPartner';
import {Tour} from '../../../model/old/Tour';
import {BypassFormComponent} from '@byteways/bypass-core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AddressDomainObject} from '../../../model/domain/address.domain-object';
import {BankAccount} from '../../../model/old/BankAccount';

export class TourPriceAndQuantity {
    price: number;
    quantity: number;
}

export class AccountancyIncomingCreditBundle {
    businessPartnerId: number;
    tours: Tour[];
    incomingCreditAmount: number;
    incomingCreditNr: string;
    incomingInvoiceNr: string;
    tourData: { [id: number]: TourPriceAndQuantity };
    discount: number;
    invoiceAddress: AddressDomainObject;
    invoiceBankAccount: BankAccount;
    date: Date;
}


@Component({
    selector: 'app-accountancy-incoming-credit',
    templateUrl: './accountancy-incoming-credit.component.html',
    styleUrls: ['./accountancy-incoming-credit.component.scss']
})
export class AccountancyIncomingCreditComponent extends BypassFormComponent<AccountancyIncomingCreditBundle> implements OnInit {

    businessPartnerFormGroup = new FormGroup({
        businessPartner: new FormControl(undefined, [Validators.required]),
        invoiceAddress: new FormControl(undefined, [Validators.required]),
        invoiceBankAccount: new FormControl(undefined, [Validators.required])
    });

    creditDataFormGroup = new FormGroup({
        incomingCreditNr: new FormControl(undefined, [Validators.required]),
        incomingCreditAmount: new FormControl(undefined, [Validators.required]),
        incomingInvoiceNr: new FormControl(undefined),
        date: new FormControl(new Date(), [Validators.required])
    });

    selectToursFormGroup = new FormGroup({
        tourSelection: new FormControl()
    });

    finalizeFormGroup = new FormGroup({
        businessPartnerId: new FormControl(undefined, [Validators.required]),
        invoiceAddressId: new FormControl(undefined, [Validators.required]),
        invoiceBankAccountId: new FormControl(undefined, [Validators.required]),
        incomingCreditNr: new FormControl(undefined, [Validators.required]),
        incomingCreditAmount: new FormControl(undefined, [Validators.required]),
        incomingInvoiceNr: new FormControl(undefined),
        tourData: new FormControl(undefined, [Validators.required]),
        discount: new FormControl(0, [Validators.required]),
        date: new FormControl(new Date(), [Validators.required])
    });

    tours: Tour[] = [];
    toursFiltered: Tour[] = [];

    constructor(protected injector: Injector, private router: Router) {
        super(injector);
    }

    ngOnInit() {
        this.bpSetup(new AccountancyIncomingCreditBundle());

        this.businessPartnerFormGroup.get('businessPartner').valueChanges.subscribe(valueChanges => {
            this.businessPartnerFormGroup.patchValue({
                invoiceAddress: undefined,
                invoiceBankAccount: undefined
            });
        });

        this.businessPartnerFormGroup.valueChanges.subscribe(valueChanges => {
            const bp = valueChanges.businessPartner;
            if (bp !== undefined) {
                this.data = Object.assign(new AccountancyIncomingCreditBundle(), {
                    businessPartnerId: bp.id
                });
                this.bpRead();

                if (this.businessPartnerFormGroup.value.invoiceAddress) {
                    this.finalizeFormGroup.patchValue({
                        invoiceAddressId: this.businessPartnerFormGroup.value.invoiceAddress.id
                    });
                } else {
                    this.finalizeFormGroup.patchValue({
                        invoiceAddressId: null
                    });
                }
                if (this.businessPartnerFormGroup.value.invoiceBankAccount) {
                    this.finalizeFormGroup.patchValue({
                        invoiceBankAccountId: this.businessPartnerFormGroup.value.invoiceBankAccount.id
                    });
                } else {
                    this.finalizeFormGroup.patchValue({
                        invoiceBankAccountId: null
                    });
                }
                this.finalizeFormGroup.patchValue({
                    businessPartnerId: bp.id
                });
            }
        });

        this.creditDataFormGroup.valueChanges.subscribe(valueChanges => {
            this.finalizeFormGroup.patchValue(this.creditDataFormGroup.value);
        });
    }

    protected bpOnMessageReceived(): void {
        this.tours = this.data.tours.sort((a, b) => a.tourNumber > b.tourNumber ? 1 : -1);
        this.toursFiltered = this.tours;
    }

    businessPartnerToString(data: BusinessPartner) {
        return `${data.name} ${data.nameAdd}${data.debitorNumber ? (' (Debitor ' + data.debitorNumber + ')') : ''}`;
    }

    submitIncomingCredit() {

        // TODO: darf nur submitted / valid sein, sofern die summe aller tonnagen * mengen == incomingCreditAmount ist

        this.data = Object.assign(new AccountancyIncomingCreditBundle(), this.finalizeFormGroup.value);
        this.bpSubmit();
        this.router.navigateByUrl('/accountancy/book');
        // alert('Deaktiviert in Entwicklungsversion');
    }

    getNettoPrice(): number {
        const tourData = this.finalizeFormGroup.value.tourData;
        const tours = this.selectToursFormGroup.value.tourSelection as Tour[];

        if (!tourData || !tours) {
            return 0;
        }

        return tours
            .map(t => {
                const td = tourData[t.id];
                if (!td) {
                    return 0.0;
                }
                const quantity = td.quantity || 0.0;
                const price = td.price || 0.0;
                return quantity * price;
            })
            .reduce((a, b) => a + b, 0);
    }

    getDiscounts(): number {
        const discount = this.finalizeFormGroup.value.discount;
        if (discount === undefined || discount === null) {
            return 0;
        } else {
            return discount;
        }
    }

    getDiscountedPrice(): number {
        return this.getNettoPrice() - this.getDiscounts();
    }

    getPriceDiff(): number | undefined {
        const incoming = this.creditDataFormGroup.value.incomingCreditAmount;
        const total = this.getNettoPrice();
        if (total === undefined || total === null || incoming === undefined || incoming === null) {
            return undefined;
        }
        return this.getDiscountedPrice() * 1.19 - incoming;
    }

    isValidIncomingCredit(): boolean {
        const diff = this.getPriceDiff();
        if (diff === undefined || diff === null) {
            return false;
        }
        return Math.abs(diff) <= 0.01; // TODO: sinnvoller schwellwert für rundungen?
    }

    onTourSelectionChange(event) {

    }


    applyFilter(filter: string) {
        if (filter === undefined) {
            this.toursFiltered = this.tours;
            return;
        }
        this.toursFiltered = this.tours
            .filter(t => JSON.stringify(t).toLowerCase().indexOf(filter) !== -1)
            .sort((a, b) => a.tourNumber > b.tourNumber ? 1 : -1);
    }

}
