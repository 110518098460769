import {Injectable} from '@angular/core';
import {BypassDataService} from '@byteways/bypass-core';

@Injectable({
    providedIn: 'root'
})
export class WindowRefService {

    constructor() {
    }

    getNativeWindow() {
        return window;
    }

    open(path: string) {
        const host = BypassDataService.getHttpHost();
        const url = `${host}${path}`;
        this.getNativeWindow().open(url);
    }

}
