<mat-form-field class="form-field" [appearance]="appearance" [color]="color">
  <mat-label class="label">{{label}}</mat-label>
  <mat-select [disabled]="disabled" (selectionChange)="selectionChanged($event)" [value]="value" [required]="required">
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{option.text}}
    </mat-option>
  </mat-select>
  <mat-icon class="prefix" matPrefix *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>
  <mat-icon class="suffix" matSuffix *ngIf="suffixIcon">{{suffixIcon}}</mat-icon>
  <mat-hint class="hint">{{hint}}</mat-hint>
</mat-form-field>

