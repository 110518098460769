import {Pipe, PipeTransform} from '@angular/core';
import * as modelPackage from '../model/old/model.module';

@Pipe({
    name: 'modelToString'
})
export class ModelToStringPipe implements PipeTransform {

    static initObject<T>(instance: T, props: Partial<T>) {
        return Object.assign(instance, props);
    }

    transform(value: any, args?: any): any {
        if (value == null) {
            return '';
        }

        const instance = ModelToStringPipe.initObject(new (<any>modelPackage)[args](), value);
        return instance.toString();
    }

}
