import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-form-debugger',
    templateUrl: './form-debugger.component.html',
    styleUrls: ['./form-debugger.component.scss']
})
export class FormDebuggerComponent {

    @Input() title: string | null = null;
    @Input() formGroup: FormGroup;

}
