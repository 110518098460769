import {Component, Injector} from '@angular/core';
import {BypassDataService} from '../bypass-data.service';

/**
 * The base component for the framework
 *
 * This abstract class provides a base for the (derived) data- and form-components, which are the central part of this framework.
 * Each of those components have a internal data object, a associated data service and a setup method. The usage of this setup method is
 * crucial, as the connection to the server can only be created by calling the setup function. When using a data- or form-component, the
 * class that inherits from the data- or form-component must call the super and setup methods in its own constructor.
 */
@Component({
  selector: 'lib-base',
  template: ''
})
export class BypassBaseComponent {

  /**
   * injectable data service from this framework
   */
  dataService: BypassDataService = this.injector.get(BypassDataService);

  /**
   * the constructor that is used for dependency injection
   *
   * @param injector - the common angular injector
   */
  constructor(protected injector: Injector) {
  }

}
