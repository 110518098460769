import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ViewsModule} from './views/views.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FeaturesModule} from './features/features.module';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {MatDatetimepickerModule} from '@mat-datetimepicker/core';
import {ContextMenuModule} from 'ngx-contextmenu';
import {HttpClientModule} from '@angular/common/http';
import {DragulaModule} from 'ng2-dragula';
import {BypassCoreModule} from '@byteways/bypass-core';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {MasterdataModule} from './modules/masterdata/masterdata.module';
import {AccountancyEditorModule} from './modules/accountancy-editor/accountancy-editor.module';
import {DispoPlanModule} from './modules/dispo-plan/dispo-plan.module';
import {UserProfileModule} from './modules/user-profile/user-profile.module';
import {MaterialsModule} from './modules/materials/materials.module';
import { DispoStatsModule } from './modules/dispo-stats/dispo-stats.module';
import {DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AccountancyDatevExportModule} from './modules/accountancy-datev-export/accountancy-datev-export.module';
import {DispoPricesModule} from './modules/dispo-prices/dispo-prices.module';

registerLocaleData(localeDe, 'de');

export class CustomDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BypassCoreModule.forRoot(),
        FlexLayoutModule,
        MatDatetimepickerModule,
        MatButtonModule,
        ViewsModule,
        FeaturesModule,
        MasterdataModule,
        MaterialsModule,
        DispoStatsModule,
        AccountancyEditorModule,
        UserProfileModule,
        DispoPlanModule,
        MatDividerModule,
        MatIconModule,
        MatToolbarModule,
        MatListModule,
        MatSidenavModule,
        MatProgressBarModule,
        ContextMenuModule.forRoot(),
        DragulaModule.forRoot(),
        MatTooltipModule,
        AccountancyDatevExportModule,
        DispoPricesModule,
    ],
    exports: [],
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE'
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
