import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {TourDisplay} from '../../partials/invoice-tour-display/invoice-tour-display.component';
import {EditorItemTypeValue} from '../../../model/editor-item-type.value';

export class InvoiceItemFormBundle extends AbstractBypassBundle {
    itemId: number;
    tourId: number;
    tourDisplay: TourDisplay;
}

export interface AdditionalCost {
    type: EditorItemTypeValue;
    vat: number;
    tourNumber: string;
}

@Component({
    selector: 'app-invoice-item-form',
    templateUrl: './invoice-item-form.component.html',
    styleUrls: ['./invoice-item-form.component.scss']
})
export class InvoiceItemFormComponent extends BypassFormComponent<InvoiceItemFormBundle> implements OnInit {

    itemTypes: { label: string; type: EditorItemTypeValue }[] = [
        {label: 'CO2-Zuschlag', type: EditorItemTypeValue.CO2},
        {label: 'Mindermenge', type: EditorItemTypeValue.SHORTFALL},
        {label: 'Standzeit', type: EditorItemTypeValue.WAITING},
        {label: 'Palette', type: EditorItemTypeValue.PALLET},
        {label: 'Energiekostenzuschlag', type: EditorItemTypeValue.ENERGY},
    ];

    static itemCounter = 0;
    itemId = InvoiceItemFormComponent.itemCounter++;

    @Input() invoiceAddressId: number;
    private _invoiceItemForm: FormGroup;
    @Input() set invoiceItemForm(form: FormGroup) {
        this._invoiceItemForm = form;
        if (form.value.tourId) {
            this.data = Object.assign(new InvoiceItemFormBundle(), {
                itemId: this.itemId,
                tourId: form.value.tourId
            });
            this.bpSubmit();
        } else {
            this.data = null;
            this.tourDisplay = null;
        }
        if (this._invoiceItemForm && this.tourDisplay) {
            this._invoiceItemForm.patchValue({
                unitCost: this.tourDisplay.priceVk,
                unit: 'Tonnen'
            });
        }
    }

    get invoiceItemForm(): FormGroup {
        return this._invoiceItemForm;
    }

    @Input() index = 0;
    @Input() numItemsTotal = 0;
    @Input() disableAdditionalCosts = false;
    @Output() deleted = new EventEmitter();
    @Output() movedUp = new EventEmitter();
    @Output() movedDown = new EventEmitter();
    @Output() detailsClicked = new EventEmitter();
    @Output() vatWarning = new EventEmitter();
    @Output() commissionTypeFound = new EventEmitter<string | null>();
    @Output() additionalCostAdded: EventEmitter<AdditionalCost> = new EventEmitter<AdditionalCost>();

    private readonly unitOptions = [
        'Festpreis',
        'Tonnen',
        'Kilogramm',
        'Minuten',
        'Stunden',
        'Tage',
        'Monate',
        'Jahre',
        'Paletten',
        'Big Bags',
        'Liter'
    ];

    unitOptionsFiltered: Observable<string[]> = of([]);

    _tourDisplay: TourDisplay | null;

    get tourDisplay(): TourDisplay | null {
        return this._tourDisplay;
    }

    set tourDisplay(td: TourDisplay | null) {
        this._tourDisplay = td;
        if (td && this.invoiceItemForm) {
            this.invoiceItemForm.patchValue({
                unitCost: td.priceVk,
                unit: 'Tonnen'
            });


            this.checkVatWarning(td);
        }
    }

    constructor(protected injector: Injector) {
        super(injector);
        console.log('form load data', new Date());
        this.bpSetup(new InvoiceItemFormBundle());
    }

    protected bpFilterIncomingData(data: InvoiceItemFormBundle): boolean {
        const accept = data.itemId === this.itemId && (data.tourId === null || data.tourId === this.invoiceItemForm.value.tourId);
        if (accept) {
            console.log('incoming data', new Date());
        }
        return accept;
    }

    protected bpOnMessageReceived(): void {
        console.log('message received', new Date(), this.data);
        this.tourDisplay = this.data.tourDisplay;
        this.commissionTypeFound.emit(this.tourDisplay?.commissionType);
    }

    ngOnInit(): void {
        this.unitOptionsFiltered = this.invoiceItemForm.get('unit').valueChanges.pipe(
            startWith(''),
            map(value => {
                if (value) {
                    const filterValue = value.toLowerCase();
                    return this.unitOptions.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);
                } else {
                    return this.unitOptions;
                }
            })
        );
    }

    updateVat(vat: number) {
        this.invoiceItemForm.patchValue({vat: vat});
    }

    getTourPriceEk(): number | null {
        if (!this.tourDisplay) {
            return null;
        }
        return this.tourDisplay.priceEk;
    }

    getTourPriceVk(): number | null {
        return this.tourDisplay.priceVk;
    }

    getTourPriceFr(): number | null {
        return this.tourDisplay.priceFr;
    }

    getVat(): number {
        return this.invoiceItemForm.value.vat;
    }

    checkVatWarning(td: TourDisplay) {
        const coronaVatStart = new Date('07/01/2020').getTime();
        const coronaVatEnd = new Date('01/01/2022').getTime();

        let warning = false;
        if (td.targetDate.useRange) {
            const compareFrom = new Date(td.targetDate.fromDate).getTime();
            const compareTo = new Date(td.targetDate.toDate).getTime();

            const startsDuringCorona = compareFrom >= coronaVatStart && compareFrom <= coronaVatStart;
            const endsDuringCorona = compareTo >= coronaVatStart && compareTo <= coronaVatStart;
            if (startsDuringCorona || endsDuringCorona) {
                warning = true;
            }
        } else {
            const compare = new Date(td.targetDate.fromDate).getTime();
            const endsDuringCorona = compare >= coronaVatStart && compare <= coronaVatEnd;
            if (endsDuringCorona) {
                warning = true;
            }
        }

        if (warning) {
            this.vatWarning.emit();
        }
    }

    addAdditionalCost(type: EditorItemTypeValue): void {
        this.additionalCostAdded.emit({
            type,
            tourNumber: this.tourDisplay.tourNumber,
            vat: this.invoiceItemForm.value.vat as number
        });
    }

}
