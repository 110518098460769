<mat-form-field fxFlex class="form-field" [appearance]="appearance" [color]="color">
  <mat-label class="label">{{label}}</mat-label>
  <label>
    <input
      matInput
      [type]="showPassword ? '' : 'password'"
      [required]="required"
      (ngModelChange)="change($event)"
      [formControl]="control" />
  </label>
  <mat-icon class="prefix" matPrefix *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>
  <mat-icon class="suffix" matSuffix *ngIf="suffixIcon && !iconPasswordVisible">{{suffixIcon}}</mat-icon>
  <button mat-icon-button matSuffix>
    <mat-icon
      class="suffix"
      (click)="showPassword = !showPassword"
      *ngIf="iconPasswordVisible">
      {{showPassword ? 'visibility' : 'visibility_off'}}
    </mat-icon>
  </button>
  <mat-hint class="hint">{{hint}}</mat-hint>
  <mat-error *ngFor="let error of control | filterActiveErrors">{{error}}</mat-error>
</mat-form-field>
