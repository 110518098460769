import {InvoiceItem} from './InvoiceItem';

export class InvoiceEditorItem {
    id: number;
    label: string;
    quantity: number;
    unit: string;
    unitCost: number;
    tourId: number | null;
    vat: number;

    constructor(invoiceItem: InvoiceItem) {
        this.id = invoiceItem.id;
        this.label = invoiceItem.label;
        this.quantity = invoiceItem.quantity;
        this.unit = invoiceItem.unit;
        this.unitCost = invoiceItem.unitCost;
        if (invoiceItem.tour) {
            this.tourId = invoiceItem.tour.id;
        } else {
            this.tourId = null;
        }
        this.vat = invoiceItem.vat;
    }
}
