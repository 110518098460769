import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {WindowRefService} from '../../../window-ref.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-accountancy-invoicing-new-invoice-dialog',
    templateUrl: './accountancy-invoicing-new-invoice-dialog.component.html',
    styleUrls: ['./accountancy-invoicing-new-invoice-dialog.component.scss']
})
export class AccountancyInvoicingNewInvoiceDialogComponent implements OnInit {

    constructor(
        public router: Router,
        public windowRef: WindowRefService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AccountancyInvoicingNewInvoiceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public input: any
    ) {
    }

    ngOnInit() {
    }

    goToBook() {
        this.dialogRef.close();
        this.router.navigateByUrl('/accountancy/book');
    }

    goToNew() {
        this.dialogRef.close();
        this.router.navigateByUrl('/accountancy/invoicing/new');
    }

}
