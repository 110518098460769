import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {CarrierDomainObject} from '../../../../../model/domain/carrier.domain-object';
import {MatDialog} from '@angular/material/dialog';
import {DialogManageCarrierComponent} from './dialog-manage-carrier/dialog-manage-carrier.component';
import {DialogConfirmCarrierDeleteComponent} from './dialog-confirm-carrier-delete/dialog-confirm-carrier-delete.component';

export class MasterdataCarriersEditorBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    carriers: CarrierDomainObject[];
}

@Component({
    selector: 'app-masterdata-carriers-editor',
    templateUrl: './masterdata-carriers-editor.component.html',
    styleUrls: ['./masterdata-carriers-editor.component.scss']
})
export class MasterdataCarriersEditorComponent extends BypassFormComponent<MasterdataCarriersEditorBundle> {

    tableColumns = ['icon', 'name', 'shortName', 'email', 'comment', 'own', 'active', 'actions'];

    @Output() saved = new EventEmitter();

    businessPartnerId: number;

    carriers: CarrierDomainObject[] = [];

    @Input() set businessPartner(bp: BusinessPartnerDomainObject) {
        this.businessPartnerId = bp.id;
        if (this.businessPartnerId) {
            const bundle = Object.assign(new MasterdataCarriersEditorBundle(), {businessPartnerId: this.businessPartnerId});
            this.bpSetup(bundle);
        }
    }

    constructor(protected injector: Injector, private dialog: MatDialog) {
        super(injector);
        this.disableAutoLoadingOverlay();
    }

    protected bpOnMessageReceived(): void {
        this.carriers = this.data.carriers;
    }

    addCarrier() {
        this.dialog.open(DialogManageCarrierComponent, {
            minWidth: '50%'
        }).afterClosed().subscribe(contact => {
            if (contact) {
                this.carriers.push(contact);
                this.submit();
            }
        });
    }

    editCarrier(index) {
        this.dialog.open(DialogManageCarrierComponent, {
            data: this.carriers[index],
            minWidth: '50%'
        }).afterClosed().subscribe(editedCarrier => {
            if (editedCarrier) {
                this.carriers[index] = editedCarrier;
                this.submit();
            }
        });
    }

    deleteCarrier(index) {
        this.dialog.open(DialogConfirmCarrierDeleteComponent, {
            data: this.carriers[index]
        }).afterClosed().subscribe((response: boolean) => {
            if (response) {
                this.carriers.splice(index, 1);
                this.submit();
            }
        });
    }

    // TODO: Müssen auch bei edit/create alle Daten zum Backend geschickt werden?
    private submit() {
        this.data = Object.assign(new MasterdataCarriersEditorBundle(), {
            businessPartnerId: this.businessPartnerId,
            carriers: this.carriers
        });
        this.bpSubmit();
        this.saved.emit();
    }

}
