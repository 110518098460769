import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { AccountancyDatevExportComponent } from './accountancy-datev-export/accountancy-datev-export.component';
import {FeaturesModule} from '../../features/features.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    declarations: [AccountancyDatevExportComponent],
    imports: [
        CommonModule,
        FeaturesModule,
        ReactiveFormsModule,
        MatInputModule,
        FlexLayoutModule,
        MatButtonModule,
    ],
    exports: [AccountancyDatevExportComponent]
})
export class AccountancyDatevExportModule {
}
