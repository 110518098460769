import {Component, Input} from '@angular/core';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';

@Component({
  selector: 'ui-form-field-time',
  templateUrl: './ui-form-field-time.component.html',
  styleUrls: ['./ui-form-field-time.component.scss']
})

export class UiFormFieldTimeComponent extends FormFieldDataComponent {

  @Input() minTime: string;
  @Input() maxTime: string;

}
