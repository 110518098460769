import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {PageSettings} from '../../../../../model/old/PageSettings';
import {BusinessPartner} from '../../../../../model/old/BusinessPartner';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {WindowRefService} from '../../../../../window-ref.service';
import {Router} from '@angular/router';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {MasterdataDialogCreateComponent} from '../masterdata-dialog-create/masterdata-dialog-create.component';
import {MasterdataDialogGroupCreateComponent} from '../masterdata-dialog-create-group/masterdata-dialog-group-create.component';


export class MasterdataTableBundle extends AbstractBypassBundle {
    pageSettings: PageSettings;
    total: number;
    entries: BusinessPartner[];
}


@Component({
  selector: 'app-masterdata-table',
  templateUrl: './masterdata-table.component.html',
  styleUrls: ['./masterdata-table.component.scss']
})
export class MasterdataTableComponent extends BypassFormComponent<MasterdataTableBundle> {

    paginatorPageSizeOptions = [5, 10, 20, 40];
    paginatorPageSize = this.paginatorPageSizeOptions[2];
    _filter: string = undefined;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    displayedColumns = ['shortName', 'name', 'nameAdd', 'creditorNumber', 'debitorNumber', 'businessPartnerGroup', 'comment'];
    dataSource = new MatTableDataSource([]);

    constructor(protected injector: Injector, private dialog: MatDialog, private windowRef: WindowRefService, private router: Router) {
        super(injector);
        this.bpSetup(new MasterdataTableBundle());
    }

    ngOnInit() {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.paginatorPageSize;

        merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadData())).subscribe();
        this.loadData();
    }

    private loadData() {
        this.data = new MasterdataTableBundle();
        this.data.entries = [];

        let pageIndex = 0;
        if (this.paginator.pageIndex !== undefined) {
            pageIndex = this.paginator.pageIndex;
        }
        let pageSize = this.paginatorPageSize;
        if (this.paginator.pageSize !== undefined) {
            pageSize = this.paginator.pageSize;
        }

        this.data.pageSettings = new PageSettings(
            'BusinessPartner',
            pageIndex,
            pageSize,
            this.sort.active,
            this.sort.direction,
            this._filter
        );

        this.bpSubmit();
    }

    bpOnMessageReceived() {
        this.dataSource.data = this.data.entries;
        this.paginator.length = this.data.total;
    }

    applyFilter(filterValue: string) {
        if (filterValue === '') {
            this._filter = undefined;
        } else {
            this._filter = filterValue.toLowerCase();
        }
        this.loadData();
    }

    onRowClick(businessPartner: BusinessPartner) {
        this.router.navigateByUrl('/masterdata/' + businessPartner.id);
    }

    onNewClick() {
        this.dialog.open(MasterdataDialogCreateComponent, {
            data: null,
            panelClass: 'dialog-mega-panel-class'
        });
    }

    onNewGroupClicked(): void {
        this.dialog.open(MasterdataDialogGroupCreateComponent, {
            data: null,
            panelClass: 'dialog-mega-panel-class'
        });
    }

    onExportClick() {
        alert('Es wird eine Liste mit Namen, Kreditorennr., Debitorennr. und Kürzel der Stammdaten erstellt.');
        this.windowRef.open(`/export/stammdaten.csv`);
    }
}
