import {Component, Input, OnInit} from '@angular/core';
import {RowTitle} from '@byteways/ui-drag-drop-plan';

@Component({
    selector: 'app-dispo-plan-carrier',
    templateUrl: './dispo-plan-carrier.component.html',
    styleUrls: ['./dispo-plan-carrier.component.scss']
})
export class DispoPlanCarrierComponent implements OnInit {

    @Input() row: RowTitle;

    constructor() {
    }

    ngOnInit() {
    }

}
