import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-dispo-stats-field-selection',
    templateUrl: './dispo-stats-field-selection.component.html',
    styleUrls: ['./dispo-stats-field-selection.component.scss']
})
export class DispoStatsFieldSelectionComponent implements AfterViewInit {

    @Input() mode: 'by_invoice' | 'by_tour' = 'by_invoice';
    @Output() dispoStatsFieldSelectionParameter = new EventEmitter<string>();

    values: { name: string, key: string, byInvoice: boolean, byTour: boolean }[] = [
        {
            name: 'Tour Nummer',
            key: 'tourNumber',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Tour Status',
            key: 'tourStatus',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Rechnung Nummer',
            key: 'invoiceNumber',
            byInvoice: true,
            byTour: false
        },
        {
            name: 'Rechnung Buchungsdatum',
            key: 'invoiceBookingDate',
            byInvoice: true,
            byTour: false
        },
        {
            name: 'Rechnung Status',
            key: 'invoiceStatus',
            byInvoice: true,
            byTour: false
        },
        {
            name: 'Material',
            key: 'material',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Spediteur',
            key: 'carrier',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Abrechnung',
            key: 'tourPriceType',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Anlieferdatum',
            key: 'targetDate',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Tour EK',
            key: 'tourEk',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Tour FR',
            key: 'tourFr',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Tour VK',
            key: 'tourVk',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Tour HN',
            key: 'tourHN',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Rechnung Menge',
            key: 'invoiceItemQuantity',
            byInvoice: true,
            byTour: false
        },
        {
            name: 'Rechnung Einheit',
            key: 'invoiceItemUnit',
            byInvoice: true,
            byTour: false
        },
        {
            name: 'Rechnung Preis pro Einheit',
            key: 'invoiceItemUnitCost',
            byInvoice: true,
            byTour: false
        },
        {
            name: 'Rechnung Preis Gesamt',
            key: 'invoiceTotalCost',
            byInvoice: true,
            byTour: false
        },
        {
            name: 'Rechnung Text',
            key: 'invoiceText',
            byInvoice: true,
            byTour: false
        },
        {
            name: 'Ladestelle',
            key: 'loadingLocation',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Entladestelle',
            key: 'unloadingLocation',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Debitor Name',
            key: 'debitorName',
            byInvoice: true,
            byTour: true
        },
        {
            name: 'Debitor Nummer',
            key: 'debitorNumber',
            byInvoice: true,
            byTour: true
        },
    ];

    selectedParams = [];

    constructor() {
    }

    ngAfterViewInit() {
        this.selectedParams = this.values.map(v => v.key);
        this.buildParamString();
    }

    onCheckboxChange(param: string) {
        const index = this.selectedParams.indexOf(param);
        if (index === -1) {
            this.selectedParams.push(param);
        } else {
            this.selectedParams.splice(index, 1);
        }

        this.buildParamString();
    }

    buildParamString() {
        let paramString = '';
        this.selectedParams.forEach((param, index) => {
            paramString += param;
            if ((index + 1) !== this.selectedParams.length) {
                paramString += ',';
            }
        });
        this.dispoStatsFieldSelectionParameter.emit(paramString);
    }

}
