import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WindowRefService} from '../../../window-ref.service';

@Component({
    selector: 'app-accountancy-datev-export',
    templateUrl: './accountancy-datev-export.component.html',
    styleUrls: ['./accountancy-datev-export.component.scss']
})
export class AccountancyDatevExportComponent {

    exportForm = new FormGroup({
        from: new FormControl(undefined, [Validators.required]),
        to: new FormControl(undefined, [Validators.required])
    });

    constructor(private readonly windowRef: WindowRefService) {
    }

    download() {
        const from = this.formatDate(this.getFrom());
        const to = this.formatDate(this.getTo());

        this.windowRef.open(`/export/datev.csv?from=${from}&to=${to}`);
    }

    getFrom(): Date {
        const from = new Date(this.exportForm.value.from);
        from.setHours(0, 0, 0);
        return from;
    }

    getTo(): Date {
        const to = new Date(this.exportForm.value.to);
        to.setHours(23, 59, 59);
        return to;
    }

    formatDate(date: Date): string {
        const deDate = new Date(date).toLocaleDateString('de-DE');
        const split = deDate.split('.');
        let year = split[2];
        while (year.length < 4) {
            year = '0' + year;
        }
        let month = split[1];
        while (month.length < 2) {
            month = '0' + month;
        }
        let day = split[0];
        while (day.length < 2) {
            day = '0' + day;
        }
        return `${year}${month}${day}`;
    }

}
