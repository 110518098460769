import {Pipe, PipeTransform} from '@angular/core';
import {LocationDomainObject} from '../../../model/domain/location.domain-object';

@Pipe({
    name: 'locationTitle'
})
export class LocationTitlePipe implements PipeTransform {

    transform(value: LocationDomainObject, index: number, ...args: any[]): any {
        if (value.name && value.shortName) {
            let ret = `${value.shortName} | ${value.name}`;
            if (value.isLoadingLocation) {
                ret += ' | Ladestelle';
            }
            if (value.isUnloadingLocation) {
                ret += ' | Entladestelle';
            }
            return ret;
        }
        return `Ladestelle ${index + 1}`;
    }

}
