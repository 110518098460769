import {Component, Inject} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MasterdataFormBuilder} from '../../../../services/masterdata-form-builder.service';

@Component({
    selector: 'app-dialog-manage-contact',
    templateUrl: './dialog-manage-contact.component.html',
    styleUrls: ['./dialog-manage-contact.component.scss']
})
export class DialogManageContactComponent {

    formGroup: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<DialogManageContactComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private masterdataFormBuilder: MasterdataFormBuilder
    ) {
        this.formGroup = this.masterdataFormBuilder.createContactFormGroup(data);
    }

    save() {
        if (this.formGroup.valid) {
            this.dialogRef.close(this.formGroup.value);
        }
    }

}
