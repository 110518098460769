import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {DialogTourAddDowntimeBundle} from '../dialog-tour-add-downtime/dialog-tour-add-downtime.component';

@Component({
  selector: 'app-dialog-send-mails',
  templateUrl: './dialog-send-mails.component.html',
  styleUrls: ['./dialog-send-mails.component.scss']
})
export class DialogSendMailsComponent implements OnInit {

    constructor(
        protected injector: Injector,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DialogSendMailsComponent>,
        @Inject(MAT_DIALOG_DATA) public input: any,
    ) {
        // super(injector);
        // this.bpSetup(new ());
    }

    ngOnInit() {
    }

    submit() {
        console.log("submit");
    }

    close() {
        this.dialogRef.close();
    }

}
