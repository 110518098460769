import {Component, Inject, Injector} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export class MasterdataDialogCreateBundle extends AbstractBypassBundle {
    name: string;
    nameAdd: string;
    shortName: string;
    comment: string | undefined;
}

@Component({
    selector: 'app-masterdata-dialog-create',
    templateUrl: './masterdata-dialog-create.component.html',
    styleUrls: ['./masterdata-dialog-create.component.scss']
})
export class MasterdataDialogCreateComponent extends BypassFormComponent<MasterdataDialogCreateBundle> {

    form = new FormGroup({
        name: new FormControl(undefined, [Validators.required]),
        nameAdd: new FormControl(undefined, [Validators.required]),
        shortName: new FormControl(undefined, [Validators.required]),
        comment: new FormControl(undefined, [])
    });

    constructor(protected injector: Injector,
                public dialog: MatDialog,
                public dialogRef: MatDialogRef<MasterdataDialogCreateComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new MasterdataDialogCreateBundle());
        this.disableAutoLoadingOverlay();
    }

    submit() {
        if (this.form.valid) {
            this.data = new MasterdataDialogCreateBundle();
            this.data.name = this.form.value.name;
            this.data.nameAdd = this.form.value.nameAdd;
            this.data.shortName = this.form.value.shortName;
            this.data.comment = this.form.value.comment;
            this.bpSubmit();
            this.dialogRef.close();
        }
    }

}
