import {Component, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {Customer} from '../../../model/old/Customer';
import {PaymentTarget} from '../../../model/old/PaymentTarget';

export class AccountancyInvoicingEditorDiscountsBundle extends AbstractBypassBundle {
    customerId: number;
    discounts: PaymentTarget[];
}

@Component({
    selector: 'app-accountancy-invoicing-editor-discounts',
    templateUrl: './accountancy-invoicing-editor-discounts.component.html',
    styleUrls: ['./accountancy-invoicing-editor-discounts.component.scss']
})
export class AccountancyInvoicingEditorDiscountsComponent
    extends BypassDataComponent<AccountancyInvoicingEditorDiscountsBundle>
    implements ControlValueAccessor, OnInit {

    @Input() customer: Customer;
    @Input() disabled = false;

    constructor(protected injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (this.customer === undefined) {
            return;
        }
        const bundle = new AccountancyInvoicingEditorDiscountsBundle();
        bundle.customerId = this.customer.id;
        this.bpSetup(bundle);
    }

    // =====================================================================================================================================

    propagateChange = (_: any) => {
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: PaymentTarget[]): void {

    }

    // =====================================================================================================================================

    getDescription() {
        return 'Skonto';
    }

}
