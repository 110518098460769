import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateRange} from '../../model/old/DateRange';

@Component({
    selector: 'app-daterange',
    templateUrl: './daterange.component.html',
    styleUrls: ['./daterange.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangeComponent),
            multi: true
        }
    ]
})
export class DateRangeComponent implements ControlValueAccessor {


    private _requireRange = false;

    @Input() textPlaceholderDate = 'Anlieferdatum';
    @Input() textPlaceholderTime = 'Anlieferzeit';
    @Input() textFrom = 'Von';
    @Input() textTo = 'Bis';
    @Input() required = false;
    @Input() useDateOnly = false;

    @Input() set requireRange(requireRange: boolean) {
        this._requireRange = requireRange;
        this.inputUseRange = true;
        this.propagateChange();
    }

    get requireRange() {
        return this._requireRange;
    }

    changeListeners: ((value) => void)[] = [];


    inputDateFrom: Date;
    inputTimeFrom: string;
    inputDateTo: Date;
    inputTimeTo: string;
    inputUseRange: boolean;

    // value: DateRange = new DateRange();

    get value(): DateRange {
        const result = new DateRange();
        result.fromDate = this.inputDateFrom;
        if (result.fromDate !== undefined && this.inputTimeFrom !== undefined && this.inputTimeFrom !== 'NaN:NaN') {
            result.fromDate.setHours(+this.inputTimeFrom.split(':')[0]);
            result.fromDate.setMinutes(+this.inputTimeFrom.split(':')[1]);
        }
        result.toDate = new Date(this.inputDateTo);
        if (result.toDate !== undefined && this.inputTimeTo !== undefined && this.inputTimeTo !== 'NaN:NaN') {
            result.toDate.setHours(+this.inputTimeTo.split(':')[0]);
            result.toDate.setMinutes(+this.inputTimeTo.split(':')[1]);
        }
        result.useRange = this.inputUseRange || this._requireRange;
        return result;
    }

    private propagateChange = () => {
        this.changeListeners.forEach(listener => {
            listener(this.value);
        });
    };

    constructor() {
    }

    public reset() {
        this.inputTimeFrom = undefined;
        this.inputDateFrom = undefined;
        this.inputTimeTo = undefined;
        this.inputDateTo = undefined;
        this.inputUseRange = undefined;
        this.propagateChange();
    }

    registerOnChange(fn: any): void {
        this.changeListeners.push(fn);
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        if (obj) {
            this.inputUseRange = obj.useRange;
            this.inputDateFrom = new Date(obj.fromDate);
            let inputTimeFromHours = `${this.inputDateFrom.getHours()}`;
            if (inputTimeFromHours.length === 1) {
                inputTimeFromHours = `0${inputTimeFromHours}`;
            }
            let inputTimeFromMinutes = `${this.inputDateFrom.getMinutes()}`;
            if (inputTimeFromMinutes.length === 1) {
                inputTimeFromMinutes = `0${inputTimeFromMinutes}`;
            }
            this.inputTimeFrom = `${inputTimeFromHours}:${inputTimeFromMinutes}`;
            if (this.inputUseRange || this._requireRange) {
                this.inputDateTo = new Date(obj.toDate);
                let inputTimeToHours = `${this.inputDateTo.getHours()}`;
                if (inputTimeToHours.length === 1) {
                    inputTimeToHours = `0${inputTimeToHours}`;
                }
                let inputTimeToMinutes = `${this.inputDateTo.getMinutes()}`;
                if (inputTimeToMinutes.length === 1) {
                    inputTimeToMinutes = `0${inputTimeToMinutes}`;
                }
                this.inputTimeTo = `${inputTimeToHours}:${inputTimeToMinutes}`;
            }
            // this.onChange();
        }
    }

    onChange() {
        if (this.inputDateFrom !== undefined) {
            this.inputDateTo = this.inputDateFrom;
        }
        this.propagateChange();
    }

    getDateTextFrom() {
        let txt = this.textPlaceholderDate;
        if (this.value.useRange) {
            txt += ` (${this.textFrom})`;
        }
        return txt;
    }

    getDateTextTo() {
        let txt = this.textPlaceholderDate;
        if (this.value.useRange) {
            txt += ` (${this.textTo})`;
        }
        return txt;
    }

    getTimeTextFrom() {
        let txt = this.textPlaceholderTime;
        if (this.value.useRange) {
            txt += ` (${this.textFrom})`;
        }
        return txt;
    }

    getTimeTextTo() {
        let txt = this.textPlaceholderTime;
        if (this.value.useRange) {
            txt += ` (${this.textTo})`;
        }
        return txt;
    }

    getToggleText() {
        return `${this.textFrom}/${this.textTo}`;
    }

}
