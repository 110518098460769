import {AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {Tour} from '../../../model/old/Tour';

export class AccountancyInvoicingToursListBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    tours: Tour[];
}

@Component({
    selector: 'app-accountancy-invoicing-tours-list',
    templateUrl: './accountancy-invoicing-tours-list.component.html',
    styleUrls: ['./accountancy-invoicing-tours-list.component.scss']
})
export class AccountancyInvoicingToursListComponent
    extends BypassFormComponent<AccountancyInvoicingToursListBundle> implements AfterViewInit {


    _commissionType: string | undefined;
    @Input() set commissionType(commissionType: string | undefined) {
        this._commissionType = commissionType;
        this.onFilterChange();
    }

    get commissionType(): string | undefined {
        return this._commissionType;
    }

    initialized = false;
    isLoading = false;

    tours: Tour[] = [];
    toursFiltered: Tour[] = [];
    toursClicked: Tour[] = [];
    filter = '';

    _businessPartnerId: number;

    @Output() tourClicked = new EventEmitter<Tour>();

    @Input() set businessPartnerId(businessPartnerId: number) {
        this._businessPartnerId = businessPartnerId;
        if (businessPartnerId) {
            this.onBusinessPartnerChange();
        }
    }

    get businessPartnerId(): number {
        return this._businessPartnerId;
    }

    constructor(protected injector: Injector) {
        super(injector);
    }

    ngAfterViewInit() {
        this.bpSetup(new AccountancyInvoicingToursListBundle());
    }

    onBusinessPartnerChange() {
        this.data = Object.assign(new AccountancyInvoicingToursListBundle(), {
            businessPartnerId: this.businessPartnerId
        });
        this.isLoading = true;
        this.bpRead();
    }

    onFilterChange() {
        const filterValue = this.filter.toLowerCase();
        this.toursFiltered = this.tours.filter((value, index, array) => {
            const containsFilterValue = JSON.stringify(value).toLowerCase().indexOf(filterValue) !== -1;
            if (!this.commissionType) {
                return containsFilterValue;
            } else {
                return containsFilterValue && value.commissionType === this.commissionType;
            }
        }).sort((a, b) => a.tourNumber < b.tourNumber ? -1 : 1);
    }

    protected bpOnMessageReceived(): void {
        if (this.data.tours) {
            this.tours = this.data.tours;
            this.filter = '';
            this.toursFiltered = this.tours;
            this.isLoading = false;
        }
    }

    trackToursByFn(index: number, item: Tour): string {
        return item.tourNumber;
    }

    onToursListItemClicked(tour: Tour) {
        this.tourClicked.emit(tour);
        this.tours.splice(this.tours.indexOf(tour), 1);
        this.commissionType = tour.commissionType;
        this.toursClicked.push(tour);
        this.onFilterChange();
    }

    revokeToursListItemClick(tour: Tour) {
        this.tours.push(tour);
        const idx = this.toursClicked.indexOf(tour);
        this.toursClicked.splice(idx, 1);
        if (this.toursClicked.length === 0) {
            this.commissionType = undefined;
        }
        this.onFilterChange();
    }
}
