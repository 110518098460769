import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-accountancy-invoicing-new-incoming-credit',
    templateUrl: './accountancy-invoicing-new-incoming-credit.component.html',
    styleUrls: ['./accountancy-invoicing-new-incoming-credit.component.scss']
})
export class AccountancyInvoicingNewIncomingCreditComponent implements OnInit {

    businessPartnerId: number | null = null;

    constructor(private activatedRoute: ActivatedRoute) {

    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(p => {
            this.businessPartnerId = +p.get('businessPartnerId');
        });
    }

}
