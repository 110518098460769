import {Pipe, PipeTransform} from '@angular/core';
import {FormControl} from '@angular/forms';

@Pipe({
  name: 'filterActiveErrors',
  pure: false
})
export class FilterActiveErrorsPipe implements PipeTransform {

  transform(control: FormControl): any {
    const activeErrors = [];
    if (control && control.errors) {
      const errorKeys = Object.keys(control.errors);
      const activeErrorKeys = errorKeys.filter(key => control.hasError(key));
      activeErrorKeys.forEach((errorKey: string) => {
        const error = control.errors[errorKey];
        if (error instanceof Object) {
          activeErrors.push('');
        } else {
          activeErrors.push(error);
        }
      });
    }
    return activeErrors;
  }

}
