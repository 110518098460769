import { Component, Injector, Input, OnInit, ViewChild, Type, ComponentFactoryResolver, OnDestroy, Injectable } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {BypassFormComponent, AbstractBypassBundle} from '@byteways/bypass-core';

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export class SearchSettings {
  searchTerm = '';
  pageIndex: number | undefined = 0;
  pageSize: number | undefined = 50;
  sortField: string | undefined;
  sortDirection: SortDirection | undefined;
}

export interface DatatableBundle<DataType> extends AbstractBypassBundle {
  searchSettings: SearchSettings;
  page: DataType[];
  total: number;
}

export class ContextMenuItem {

}

export class ContextMenuDivider extends ContextMenuItem {

}

export class ContextMenuButton extends ContextMenuItem {
  text: string;
  onClick: () => void = () => {
  };
}

export class ContextMenuSettings {
  items: ContextMenuItem[] = [];
}

export interface DatatableColumn<DataType> {
  title: string;
  identifier: string;
  accessor: (DataType) => any;
  component: Type<any> | undefined;
  sortable: boolean;
}

export class DatatableConfiguration<DataType, BundleType extends DatatableBundle<DataType>> {
  createBundle: () => BundleType;
  searchSettings: SearchSettings = new SearchSettings();
  page: DataType[];
  total: number;
  columns: DatatableColumn<DataType>[];
  onCreateClick: () => void;
  contextMenuSettings = new ContextMenuSettings();
}

@Injectable()
export class MatPaginatorIntlDE extends MatPaginatorIntl {
  itemsPerPageLabel = 'Einträge pro Seite';
  nextPageLabel = 'Nächste Seite';
  previousPageLabel = 'Vorige Seite';
  getRangeLabel: (page: number, pageSize: number, length: number) => string = (page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return '0 von ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' von ' + length;
  };
}


@Component({
  selector: 'lib-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css'],
  providers: [{
    provide: MatPaginatorIntl,
    useClass: MatPaginatorIntlDE
  }]
})
// @dynamic
export class DatatableComponent<DataType, BundleType extends DatatableBundle<DataType>>
  extends BypassFormComponent<BundleType> implements OnDestroy {

  constructor(protected injector: Injector) {
    super(injector);
    this.componentFactoryResolver = injector.get(ComponentFactoryResolver);
  }

  // =====================================================================================================================================
  // Override these

  datatableColumnDefinition: DatatableColumn<DataType>[] = [];

  protected messageToConfiguration(bundle: DatatableBundle<DataType>): DatatableConfiguration<DataType, BundleType> {
    console.error('Override me!');
    return null;
  }

  // =====================================================================================================================================
  // Dynamic component loader

  componentFactoryResolver: ComponentFactoryResolver;

  // =====================================================================================================================================
  // Datatable configuration property

  _datatableConfiguration: DatatableConfiguration<DataType, BundleType>;

  @Input() set datatableConfiguration(input: DatatableConfiguration<DataType, BundleType>) {
    if (input === undefined || input === null) {
      this._datatableConfiguration = undefined;
      return;
    }
    this.unsubscribeDatatableChanges();
    this._datatableConfiguration = input;
    this.datatableDataSource = new MatTableDataSource(this._datatableConfiguration.page);
    this.datatableDataSource.sort = this.datatableSort;
    if (this.datatableSort) {
      this.datatableDataSource.sort.active = input.searchSettings.sortField;
      this.datatableDataSource.sort.direction = input.searchSettings.sortDirection;
    }
    if (this.datatablePaginator) {
      this.datatablePaginator.pageIndex = input.searchSettings.pageIndex;
      this.datatablePaginator.pageSize = input.searchSettings.pageSize;
    }
    this.datatableDisplayedColumns = this._datatableConfiguration.columns.map(c => c.identifier);

    this.subscribeDatatableChanges();
  }

  get datatableConfiguration(): DatatableConfiguration<DataType, BundleType> {
    return this._datatableConfiguration;
  }

  // =====================================================================================================================================
  // Datatable state

  datatableFilterControl = new FormControl();
  datatableChangeSubscription: Subscription;
  datatableDisplayedColumns: string[];
  datatableDataSource: MatTableDataSource<DataType>;
  @ViewChild(MatPaginator, {static: true}) datatablePaginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) datatableSort: MatSort;

  loadTimeout;

  protected subscribeDatatableChanges(): void {
    this.unsubscribeDatatableChanges();

    if (!this.datatableSort || !this.datatablePaginator || !this.datatableFilterControl) {
      return;
    }

    this.datatableChangeSubscription = merge(
      this.datatableSort.sortChange,
      this.datatablePaginator.page,
      this.datatableFilterControl.valueChanges
    ).pipe(tap(() => {
      if (this.loadTimeout) {
        clearTimeout(this.loadTimeout);
      }
      this.loadTimeout = setTimeout(() => {
        const searchTerm = this.datatableFilterControl.value === null ? '' : this.datatableFilterControl.value;
        const searchSettings = {
          searchTerm: searchTerm,
          pageIndex: this.datatablePaginator.pageIndex,
          pageSize: this.datatablePaginator.pageSize,
          sortField: this.datatableSort.active,
          sortDirection: this.datatableSort.direction === 'asc' ? SortDirection.ASC : SortDirection.DESC
        };
        this.data = this.datatableConfiguration.createBundle();
        this.data.searchSettings = searchSettings;
        this.bpRead();
      }, 250);

    })).subscribe();
  }

  protected unsubscribeDatatableChanges(): void {
    if (this.datatableChangeSubscription !== undefined && this.datatableChangeSubscription !== null) {
      this.datatableChangeSubscription.unsubscribe();
    }
  }

  // =====================================================================================================================================
  // Lifecycle

  protected bpOnMessageReceived() {
    this.datatableConfiguration = this.messageToConfiguration(this.data);
    console.log('datatable message received', this.data);
  }

  ngOnDestroy(): void {
    this.unsubscribeDatatableChanges();
  }

}
