import {Component, Input} from '@angular/core';
import {BypassDataService} from '@byteways/bypass-core';

@Component({
  selector: 'lib-bypass-identicon',
  templateUrl: './bypass-identicon.component.html',
  styleUrls: ['./bypass-identicon.component.css']
})
export class BypassIdenticonComponent {

  @Input() size = 32;
  @Input() identifier: string | undefined = undefined;
  @Input() type: string | undefined = undefined;

  get url(): string {
    const httpHost: string = BypassDataService.getHttpHost();
    return `${httpHost}/identicon/${this.type}/${this.identifier}?size=${this.size}`;
  }

}

// TODO: TS1128
