import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EditorTypeValue, EditorTypeValueParser} from '../../../model/editor-type.value';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {Router} from '@angular/router';

@Component({
    selector: 'app-accountancy-editor-select',
    templateUrl: './accountancy-editor-select.component.html',
    styleUrls: ['./accountancy-editor-select.component.scss']
})
export class AccountancyEditorSelectComponent implements OnInit {

    form = new FormGroup({
        businessPartner: new FormControl(undefined, [Validators.required]),
        type: new FormControl('invoice', [Validators.required])
    });

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    getHeader(): string {
        switch (EditorTypeValueParser.parse(this.form.value.type)) {
            case EditorTypeValue.INVOICE:
                return 'Rechnung';
            case EditorTypeValue.INCOMING_CREDIT:
                return 'Eingangsgutschrift';
            default:
                alert('error');
                break;
        }
    }

    businessPartnerToString(businessPartner: BusinessPartnerDomainObject): string {
        return `${businessPartner.name} ${businessPartner.nameAdd}`;
    }

    onSubmit() {
        this.router.navigateByUrl(`/accountancy/editor/${this.form.value.type}/${this.form.value.businessPartner.id}/new`);
    }

}
