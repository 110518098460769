import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'userActionFormat'
})
export class UserActionFormatPipe implements PipeTransform {

    transform(value: string): string {
        switch (value) {
            case 'Create':
                return 'Erstellen';
            case 'Read':
                return 'Lesen';
            case 'Update':
                return 'Bearbeiten';
            case 'Delete':
                return 'Löschen';
            case 'Login':
                return 'Login';
            case 'Logout':
                return 'Logout';
            default:
                return '—';
        }
    }

}
