import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Input() color: 'primary' | 'accent' | 'warn' | null = 'primary';
    @Input() disabled;
    @Input() type: 'button' | 'submit' = 'button';
    @Output() clicked = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

}
