import {Component, Input, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';

@Component({
  selector: 'ui-form-field-number',
  templateUrl: './ui-form-field-number.component.html',
  styleUrls: ['../ui-form-field-basic-input.scss']
})
export class UiFormFieldNumberComponent extends FormFieldDataComponent {

  @Input() min: number;
  @Input() max: number;

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
    this.type = 'number';
  }

}
