import {Component, forwardRef, Input} from '@angular/core';
import {AbstractControl, FormControl, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors} from '@angular/forms';
import {BypassControlValueAccessor} from '@byteways/bypass-forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {ErrorStateMatcher} from '@angular/material/core';


export class InputPasswordErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    }
}

@Component({
    selector: 'app-input-password',
    templateUrl: './input-password.component.html',
    styleUrls: ['./input-password.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputPasswordComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => InputPasswordComponent)
        }
    ]
})
export class InputPasswordComponent extends BypassControlValueAccessor<string> {

    @Input() errorStateMatcher: ErrorStateMatcher = new InputPasswordErrorStateMatcher();
    @Input() showText = false;
    @Input() placeholder = '';
    @Input() required = false;
    @Input() disableInput = false;

    textModel: string | null = null;

    onModelChange(value: string) {
        this.notifyChange(value);
    }

    writeValue(obj: string | undefined | null): void {
        this.textModel = obj || null;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return undefined;
    }

}
