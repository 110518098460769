<div fxLayout="row" class="selection-list">

    <ui-selection-list-side
        class="left"
        *ngIf="leftSideItems"
        [items]="leftSideItems"
        [title]="leftSideTitle || 'Verfügbar'"
        (sideItemClicked)="leftSideItemClicked($event)"
        [itemsSortComparator]="itemsSortComparator"
        [selectedItems]="selectedFromLeft"
        [dataShowComponent]="dataShowComponent">
    </ui-selection-list-side>

    <div class="buttons-container" fxLayout="column" fxLayoutAlign="start center">
        <button
            mat-icon-button
            [disabled]="selectedFromLeft.length === 0"
            (click)="pushFromLeftToRight()"
            class="arrow-to-selected">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>

        <button
            mat-icon-button
            [disabled]="selectedFromRight.length === 0"
            (click)="pushFromRightToLeft()"
            class="arrow-to-available">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
    </div>

    <ui-selection-list-side
        class="right"
        *ngIf="rightSideItems"
        [items]="rightSideItems"
        [title]="rightSideTitle || 'Ausgewählt'"
        (sideItemClicked)="rightSideItemClicked($event)"
        [itemsSortComparator]="itemsSortComparator"
        [selectedItems]="selectedFromRight"
        [dataShowComponent]="dataShowComponent">
    </ui-selection-list-side>

</div>
