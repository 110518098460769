import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceType'
})
export class PriceTypePipe implements PipeTransform {

    transform(value: string, short: boolean = false, args?: any): any {
        if (short) {
            switch (value) {
                case 'FLAT':
                    return 'Pauschal';
                case 'WEIGHT':
                    return 'Nach Gewicht';
                case 'TIME':
                    return 'Nach Zeit';
                default:
                    return null;
            }
        } else {
            switch (value) {
                case 'FLAT':
                    return 'Pauschalpreis';
                case 'WEIGHT':
                    return 'Abrechnung nach Gewicht';
                case 'TIME':
                    return 'Abrechnung nach Zeit';
                default:
                    return null;
            }
        }

    }

}
