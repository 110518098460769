export class TourStatistics {
    from: Date;
    to: Date;
    carriers: TourStatisticsCarrier[];
    totalSum: number;
    totalQuantity: number;
}

export class TourStatisticsCarrier {
    carrierId: number;
    carrierName: string;
    own: boolean;
    calendarWeeks: TourStatisticsCarrierWeekly[];
    carrierSum: number;
    carrierQuantity: number;
}

export class TourStatisticsCarrierWeekly {
    calendarWeek: number;
    tours: TourStatisticsCarrierTour[];
    weekSum: number;
    weekQuantity: number;
    dailyTurnover: number;
}

export class TourStatisticsCarrierTour {
    tourId: number;
    tourNumber: string;
    loadingLocationId: number;
    loadingLocationName: string;
    unloadingLocationId: number;
    unloadingLocationName: string;
    customerId: number;
    customerName: string;
    fr: number;
    sum: number;
    quantity: number;
}
