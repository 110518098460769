import {Component, Inject, Injector, OnInit} from '@angular/core';
import {TaxGroupDomainObject} from '../../../model/domain/tax-group.domain-object';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class TaxGroupDetailsData {
    taxGroup: TaxGroupDomainObject;
}

@Component({
    selector: 'app-accountancy-taxes-tax-group-dialog-details',
    templateUrl: './accountancy-taxes-tax-group-dialog-details.component.html',
    styleUrls: ['./accountancy-taxes-tax-group-dialog-details.component.scss']
})
export class AccountancyTaxesTaxGroupDialogDetailsComponent {

    taxGroup: TaxGroupDomainObject | undefined;

    constructor(
        private dialogRef: MatDialogRef<AccountancyTaxesTaxGroupDialogDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public input: TaxGroupDetailsData
    ) {
    }

    close() {
        this.dialogRef.close();
    }

}
