import {Component, OnInit} from '@angular/core';
import {BusinessPartner} from '../../../../model/old/BusinessPartner';
import {DatatableCellBase} from '@byteways/bypass-datatable/lib/datatable-cell-base.interface';

@Component({
    selector: 'app-accountancy-invoice-table2-business-partner',
    templateUrl: './accountancy-invoice-table2-business-partner.component.html',
    styleUrls: ['./accountancy-invoice-table2-business-partner.component.scss']
})
export class AccountancyInvoiceTable2BusinessPartnerComponent implements DatatableCellBase<BusinessPartner> {
    data: BusinessPartner;
}
