import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-accountancy-invoicing-add-manual-position',
    templateUrl: './accountancy-invoicing-add-manual-position.component.html',
    styleUrls: ['./accountancy-invoicing-add-manual-position.component.scss']
})
export class AccountancyInvoicingAddManualPositionComponent implements OnInit {

    @Output() clicked = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
    }

}
