import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BypassControlValueAccessor} from '@byteways/bypass-forms';
import {DurationPipe} from '../../../../features/duration.pipe';

@Component({
    selector: 'app-input-duration',
    templateUrl: './input-duration.component.html',
    styleUrls: ['./input-duration.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputDurationComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => InputDurationComponent)
        }
    ]
})
export class InputDurationComponent extends BypassControlValueAccessor<number> {

    @Input() placeholder = '';
    @Input() required = false;
    @Input() disableInput = false;

    durationModel: string | null = null;

    numberToDuration(n: number): string {
        return DurationPipe.numberToDuration(n);
    }

    durationToNumber(d: string): number {
        return DurationPipe.durationToNumber(d);
    }

    writeValue(obj: number | undefined | null): void {
        this.durationModel = obj ? this.numberToDuration(obj) : null;
    }

}
