import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'commissionType'
})
export class CommissionTypePipe implements PipeTransform {

    transform(value: string, args?: any): any {
        switch (value) {
            case 'FRACHT':
                return 'Frachtauftrag';
            case 'HANDEL':
                return 'Handelsauftrag';
            case 'FRANKO':
                return 'Frankoauftrag';
            default:
                return null;
        }
    }

}
