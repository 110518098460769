import {AfterViewInit, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {Tour} from '../../../model/old/Tour';
import {ContextMenuComponent} from 'ngx-contextmenu';
import {DialogDeleteTourComponent} from '../dialogdeletetour/dialogdeletetour.component';
import {DialogDuplicateTourComponent} from '../dialogduplicatetour/dialogduplicatetour.component';
import {DialogTourDetailsComponent} from '../dialogtourdetails/dialogtourdetails.component';
import {DialogGeneratePresetComponent} from '../dialoggeneratepreset/dialoggeneratepreset.component';
import {DialogEditTourComponent} from '../dialogedittour/dialogedittour.component';
import {DialogPlanTourComponent} from '../dialogplantour/dialogplantour.component';
import {DialogSendTourComponent} from '../dialogsendtour/dialogsendtour.component';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {WindowRefService} from '../../../window-ref.service';
import {DialogCancelTourComponent} from '../dialog-cancel-tour/dialog-cancel-tour.component';
import {DialogTourAddDowntimeComponent} from '../dialog-tour-add-downtime/dialog-tour-add-downtime.component';
import {DialogReverseScheduleComponent} from '../dialog-reverse-schedule/dialog-reverse-schedule.component';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {
    DialogSetSubcontractorInvoiceNumberComponent
} from '../dialog-set-subcontractor-invoice-number/dialog-set-subcontractor-invoice-number.component';

export class TourTableBundle extends AbstractBypassBundle {
    pageIndex: number;
    pageSize: number;
    sortField: string;
    sortDirection: string;
    filter: string;

    totalLength: number;
    tours: Tour[];
}

@Component({
    selector: 'app-tourtable',
    templateUrl: './tourtable.component.html',
    styleUrls: ['./tourtable.component.scss']
})
export class TourTableComponent extends BypassFormComponent<TourTableBundle> implements OnInit, AfterViewInit {

    paginatorPageSizeOptions = [10, 25, 50, 75];
    paginatorPageSize = this.paginatorPageSizeOptions[3];
    tourStatusOptions = ['all', 'open', 'sent', 'scheduled'];
    _filter: string = undefined;
    @Input() useExternalFilter = false;
    @Input() showPagination = true;
    @ViewChild(ContextMenuComponent, {static: true}) public basicMenu: ContextMenuComponent;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    displayedColumns =
        !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            ? ['status', 'createdDate', 'tourNumber', 'targetDate', 'loadingLocation', 'unloadingLocation', 'material', 'quantity', 'customer', 'schedule.carrier', 'priceType', 'priceEk', 'priceFr', 'priceVk', 'duration', 'distance', 'message', 'comment', 'orderNumber', 'subcontractorInvoiceNumber']
            : ['status', 'createdDate', 'tourNumber', 'targetDate', 'loadingLocation', 'unloadingLocation', 'material', 'quantity', 'customer', 'schedule.carrier', 'priceType', 'duration', 'distance', 'message', 'comment', 'orderNumber', 'subcontractorInvoiceNumber'];
    dataSource = [];

    constructor(protected injector: Injector, public dialog: MatDialog, private windowRef: WindowRefService) {
        super(injector);
        this.bpSetup(new TourTableBundle());
    }

    private _showFullDate = true;

    @Input() set showFullDate(showFullDate: boolean) {
        this._showFullDate = showFullDate;
    }

    private _showCarrier = true;

    @Input() set showCarrier(showCarrier: boolean) {
        this._showCarrier = showCarrier;
    }

    _tourStatus = this.tourStatusOptions[0];

    @Input() set tourStatus(tourStatus: string) {
        this._tourStatus = tourStatus;
        this.loadData();
    }

    _externalData = undefined;

    @Input() set externalData(tours: Tour[]) {
        this._externalData = tours;
        this.dataSource = this._externalData;
    }

    @Input() set externalFilter(filterValue: string) {
        if (this.useExternalFilter) {
            this.applyFilter(filterValue);
        }
    }

    ngAfterViewInit() {

        merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => {
            this.loadData();
        })).subscribe();
    }

    ngOnInit() {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.paginatorPageSize;

        this.sort.active = 'createdDate';
        this.sort.direction = 'desc';
    }

    bpOnMessageReceived() {
        this.dataSource = this.data.tours;
        this.paginator.length = this.data.totalLength;
    }

    applyFilter(filterValue: string) {
        const oldValue = this._filter;
        this._filter = filterValue?.toLowerCase();
        if (oldValue !== this._filter) {
            this.loadData();
        }
    }

    statusToColor(status: string) {
        switch (status) {
            case 'OPEN':
                return 'red';
            case 'SCHEDULED':
                return 'yellow';
            case 'SENT':
                return 'green';
            case 'DONE':
                return 'blue';
            case 'REVERSED':
                return 'purple';
            case 'INVOICE_DRAFT':
                return 'orange';
            default:
                return 'black';
        }
    }

    statusToString(status: string) {
        switch (status) {
            case 'OPEN':
                return 'Offen';
            case 'SCHEDULED':
                return 'Disponiert';
            case 'SENT':
                return 'Verschickt';
            case 'DONE':
                return 'Abgerechnet';
            case 'REVERSED':
                return 'Storniert';
            case 'INVOICE_DRAFT':
                return 'Rechnung in Bearbeitung';
            default:
                console.warn('unknown status', status);
                return 'Unbekannt';
        }
    }

    deleteTourDialog(tour: Tour): void {
        this.dialog.open(DialogDeleteTourComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    duplicateTourDialog(tour: Tour): void {
        this.dialog.open(DialogDuplicateTourComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    cancelTourDialog(tour: Tour): void {
        this.dialog.open(DialogCancelTourComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    detailsDialog(tour: Tour) {
        this.dialog.open(DialogTourDetailsComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    generatePresetDialog(tour: Tour) {
        this.dialog.open(DialogGeneratePresetComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    editTourDialog(tour: Tour) {
        this.dialog.open(DialogEditTourComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    planTourDialog(tour: Tour) {
        this.dialog.open(DialogPlanTourComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    sendTourDialog(tour: Tour) {
        this.dialog.open(DialogSendTourComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    showCarrierCommissionDialog(tour: Tour) {
        this.windowRef.open(`/document/carriercommission/${tour.tourNumber}`);
    }

    showCarrierReverse(tour: Tour) {
        this.windowRef.open(`/document/carriercommissioncancellation/${tour.tourNumber}`);
    }

    showReverseScheduleDialog(tour: Tour) {
        this.dialog.open(DialogReverseScheduleComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    showInvoice(tour: Tour) {
        alert('TODO');
    }

    contextMenuSendTourIsEnabled(tour: Tour) {
        return tour.status === 'SCHEDULED';
    }

    contextMenuSendTourAgainIsEnabled(tour: Tour) {
        return tour.status === 'SENT';
    }

    contextMenuCancelTourIsEnabled(tour: Tour) {
        return tour.status === 'SCHEDULED';
    }

    contextMenuReplanTourIsEnabled(tour: Tour) {
        return tour.status === 'SCHEDULED';
    }

    contextMenuPlanTourIsEnabled(tour: Tour) {
        return tour.status === 'OPEN' || tour.status === 'REVERSED';
    }

    contextMenuShowCarrierCommissionIsEnabled(tour: Tour) {
        return tour.status === 'SENT' || tour.status === 'DONE';
    }

    contextMenuSetSubcontractorInvoiceNumberIsEnabled(tour: Tour) {
        return true;
    }

    contextMenuShowInvoiceIsEnabled(tour: Tour) {
        return tour.status === 'DONE';
    }

    contextMenuEditTourIsEnabled(tour: Tour) {
        return tour.status === 'OPEN' || tour.status === 'SCHEDULED' || tour.status === 'SENT' || tour.status === 'REVERSED';
    }

    contextMenuDeleteTourIsEnabled(tour: Tour) {
        return tour.status === 'OPEN' || tour.status === 'SCHEDULED' || tour.status === 'REVERSED';
    }

    contextMenuShowCarrierReverseIsEnabled(tour: Tour) {
        return tour.status === 'REVERSED';
    }

    contextMenuAddDowntimeIsEnabled(tour: Tour) {
        return tour.status !== 'OPEN' && tour.status !== 'DONE';
    }

    contextMenuReverseScheduleIsEnabled(tour: Tour) {
        return tour.status === 'SENT';
    }

    addDowntime(tour: Tour) {
        this.dialog.open(DialogTourAddDowntimeComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    setSubcontractorInvoiceNumber(tour: Tour) {
        this.dialog.open(DialogSetSubcontractorInvoiceNumberComponent, {
            data: {
                tour,
                showPrices: !this.dataService.getRoles().includes('DISPOSITION_NO_PRICES')
            }, width: '95vw'
        });
    }

    onRowClick(tour: Tour) {
        switch (tour.status) {
            case 'OPEN':
                this.planTourDialog(tour);
                break;
            case 'SCHEDULED':
                this.sendTourDialog(tour);
                break;
            default:
                this.detailsDialog(tour);
                break;
        }
    }

    formatDate(date: Date): string {
        return new Date(date).toLocaleDateString('de').substring(0, 5).split('-').reverse().join('.');
    }

    formatDateTime(date: Date): string {
        return this.formatDate(date) + ' ' + new Date(date).toLocaleTimeString('de').substr(0, 5);
    }

    private loadData() {
        this.data = new TourTableBundle();

        if (this._externalData !== undefined) {
            return;
        }

        this.data.tours = [];

        let pageIndex = 0;
        if (this.paginator.pageIndex !== undefined) {
            pageIndex = this.paginator.pageIndex;
        }
        let pageSize = this.paginatorPageSize;
        if (this.paginator.pageSize !== undefined) {
            pageSize = this.paginator.pageSize;
        }

        this.data.pageIndex = pageIndex;
        this.data.pageSize = pageSize;
        this.data.sortField = this.sort.active;
        this.data.sortDirection = this.sort.direction;
        this.data.filter = this._filter;

        if (this._tourStatus !== undefined && this._tourStatus !== null && this._tourStatus !== 'all') {
            if (this.data.filter !== undefined && this.data.filter !== null) {
                this.data.filter += ' ' + this._tourStatus;
            } else {
                this.data.filter = this._tourStatus;
            }

        }
        this.bpSubmit();
    }

}
