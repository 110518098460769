import {Component, Input, OnInit} from '@angular/core';
import {Employee} from '../../model/old/Employee';

@Component({
    selector: 'app-employee-display',
    templateUrl: './employee-display.component.html',
    styleUrls: ['./employee-display.component.scss']
})
export class EmployeeDisplayComponent implements OnInit {

    @Input() employee: Employee;

    constructor() {
    }

    ngOnInit() {
    }

    display(): string {
        return this.employee.login;
    }

    tooltip(): string {
        return `${this.employee.firstName} ${this.employee.lastName} | ${this.employee.email}`;
    }

}
