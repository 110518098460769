import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputDatePickerComponent} from './components/input-date-picker/input-date-picker.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {InputTextareaComponent} from './components/input-textarea/input-textarea.component';
import {InputTextComponent} from './components/input-text/input-text.component';
import {InputNumberComponent} from './components/input-number/input-number.component';
import {ButtonComponent} from './components/button/button.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {InputCountryComponent} from './components/input-country/input-country.component';
import {MatSelectModule} from '@angular/material/select';
import {FormDebuggerComponent} from './components/form-debugger/form-debugger.component';
import {DividerComponent} from './components/divider/divider.component';
import {MatDividerModule} from '@angular/material/divider';
import { InputBusinessPartnerAddressSelectorComponent } from './components/input-business-partner-address-selector/input-business-partner-address-selector.component';
import { InputBusinessPartnerBankAccountSelectorComponent } from './components/input-business-partner-bank-account-selector/input-business-partner-bank-account-selector.component';
import {FeaturesModule} from '../../features/features.module';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import { InputBusinessPartnerPaymentTargetsComponent } from './components/input-business-partner-payment-targets/input-business-partner-payment-targets.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FlexLayoutModule} from '@angular/flex-layout';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { InputDurationComponent } from './components/input-duration/input-duration.component';


@NgModule({
    declarations: [
        InputDatePickerComponent,
        InputTextareaComponent,
        InputTextComponent,
        InputNumberComponent,
        ButtonComponent,
        InputCountryComponent,
        FormDebuggerComponent,
        DividerComponent,
        InputBusinessPartnerAddressSelectorComponent,
        InputBusinessPartnerBankAccountSelectorComponent,
        InputBusinessPartnerPaymentTargetsComponent,
        InputPasswordComponent,
        InputDurationComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatDividerModule,
        FeaturesModule,
        BypassIdenticonModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatCheckboxModule,
        FlexLayoutModule
    ],
    exports: [
        InputDatePickerComponent,
        InputTextareaComponent,
        InputTextComponent,
        InputNumberComponent,
        InputCountryComponent,
        ButtonComponent,
        FormDebuggerComponent,
        DividerComponent,
        InputBusinessPartnerAddressSelectorComponent,
        InputBusinessPartnerBankAccountSelectorComponent,
        InputBusinessPartnerPaymentTargetsComponent,
        InputPasswordComponent,
        InputDurationComponent
    ]
})
export class SharedModule {
}
