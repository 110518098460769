import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {TourDisplay} from '../invoice-tour-display/invoice-tour-display.component';

export class InvoiceTourSelectionBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    tourDisplays: TourDisplay[];
}

// TODO: reicht eigentlich wenn nur ids geladen werden

@Component({
    selector: 'app-invoice-tour-selection',
    templateUrl: './invoice-tour-selection.component.html',
    styleUrls: ['./invoice-tour-selection.component.scss']
})
export class InvoiceTourSelectionComponent extends BypassDataComponent<InvoiceTourSelectionBundle> {

    @Input() disabledTourIds: number[] = [];

    @Output() tourClicked = new EventEmitter<TourDisplay>();

    @Input() set businessPartnerId(businessPartnerId: number) {
        const bundle = new InvoiceTourSelectionBundle();
        bundle.businessPartnerId = businessPartnerId;
        this.bpSetup(bundle);
    }

    _commissionType: string | undefined;
    @Input() set commissionType(commissionType: string | undefined) {
        this._commissionType = commissionType;
        this.onFilterChange();
    }

    get commissionType(): string | undefined {
        return this._commissionType;
    }

    tours: TourDisplay[] = [];
    toursFiltered: TourDisplay[] = [];
    filter = '';

    constructor(protected injector: Injector) {
        super(injector);
    }

    trackToursByFn(index: number, item: TourDisplay): string {
        return item.tourNumber;
    }

    protected bpOnMessageReceived(): void {
        this.tours = this.data.tourDisplays;
        this.onFilterChange();
    }

    onFilterChange() {
        const filterValue = this.filter.toLowerCase();
        this.toursFiltered = this.tours.filter((value, index, array) => {
            const containsFilterValue = JSON.stringify(value).toLowerCase().indexOf(filterValue) !== -1;
            if (!this.commissionType) {
                return containsFilterValue;
            } else {
                return containsFilterValue && value.commissionType === this.commissionType;
            }
        }).sort((a, b) => a.tourNumber < b.tourNumber ? -1 : 1);
    }

    isDisabled(tourId: number): boolean {
        return this.disabledTourIds.indexOf(tourId) >= 0;
    }

}
