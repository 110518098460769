import {BusinessPartner} from './BusinessPartner';
import {InvoiceItem} from './InvoiceItem';
import {PaymentTarget} from './PaymentTarget';
import {AddressDomainObject} from '../domain/address.domain-object';
import {Account} from './Account';
import {BankAccount} from './BankAccount';

export class Invoice {
    id: number;
    status: string;
    invoiceNumber: string;
    businessPartner: BusinessPartner;
    invoiceDate: Date;
    bookingDate: Date;
    headerText: string;
    footerText: string;
    invoiceItems: InvoiceItem[];
    paymentTargets: PaymentTarget[];
    netto: number;
    ust: number;
    brutto: number;
    accounts: Account[];
    type: string;
    incomingInvoiceNumber: string;
    invoiceAddress: AddressDomainObject;
    invoiceBankAccount: BankAccount;
    createdDate: Date;

    constructor(id: number) {
        this.id = id;
    }

}
