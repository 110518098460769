import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DispoPlanComponent} from './components/dispo-plan/dispo-plan.component';
import {FeaturesModule} from '../../features/features.module';
import {UiDragDropPlanModule} from '@byteways/ui-drag-drop-plan';
import {MatCardModule} from '@angular/material/card';
import {DispoPlanCarrierComponent} from './components/dispo-plan-carrier/dispo-plan-carrier.component';
import {DispoPlanTourComponent} from './components/dispo-plan-tour/dispo-plan-tour.component';
import {DispoPlanDaypickerComponent} from './components/dispo-plan-daypicker/dispo-plan-daypicker.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import {DispoPlanLoadComponent} from './components/dispo-plan-load/dispo-plan-load.component';
import {DispoPlanTourUnplannedComponent} from './components/dispo-plan-tour-unplanned/dispo-plan-tour-unplanned.component';
import {DispoPlanTourDialogComponent} from './components/dispo-plan-tour-dialog/dispo-plan-tour-dialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TourTooltipPipe} from './pipes/tour-tooltip.pipe';
import {CheckOverflowPipe} from './pipes/check-overflow.pipe';
import {DispoPlanTargetDateSnackbarComponent} from './components/dispo-plan-target-date-snackbar/dispo-plan-target-date-snackbar.component';
import {GridsterModule} from 'angular-gridster2';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatDividerModule} from '@angular/material/divider';
import {UiLoadingOverlayModule} from '@byteways/ui-loading-overlay';
import {MatMenuModule} from '@angular/material/menu';
import {TourItemTimeRangePipe} from './pipes/tour-item-time-range.pipe';

@NgModule({
    declarations: [
        DispoPlanComponent,
        DispoPlanCarrierComponent,
        DispoPlanTourComponent,
        DispoPlanDaypickerComponent,
        DispoPlanLoadComponent,
        DispoPlanTourUnplannedComponent,
        DispoPlanTourDialogComponent,
        TourTooltipPipe,
        CheckOverflowPipe,
        DispoPlanTargetDateSnackbarComponent,
        TourItemTimeRangePipe
    ],
    imports: [
        CommonModule,
        FeaturesModule,
        UiDragDropPlanModule,
        FlexLayoutModule,
        FormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        BypassIdenticonModule,
        MatSnackBarModule,
        MatTooltipModule,
        GridsterModule,
        MatCardModule,
        DragDropModule,
        MatDividerModule,
        UiLoadingOverlayModule,
        MatMenuModule

    ],
    entryComponents: [
        DispoPlanTourDialogComponent,
        DispoPlanTargetDateSnackbarComponent
    ]
})
export class DispoPlanModule {
}
