import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BypassBaseComponent} from './bypass-base.component';
import {BypassDataComponent} from './bypass-data.component';
import {BypassFormComponent} from './bypass-form.component';


@NgModule({
  declarations: [BypassBaseComponent, BypassDataComponent, BypassFormComponent],
  imports: [
    CommonModule
  ],
  exports: [BypassBaseComponent, BypassDataComponent, BypassFormComponent]
})
export class BypassCoreComponentsModule {
}
