import {Pipe, PipeTransform} from '@angular/core';
import {TourStatistics} from '../model/tour-statistics.model';

@Pipe({
    name: 'numOwnCarriers'
})
export class NumOwnCarriersPipe implements PipeTransform {

    transform(value: TourStatistics): number {
        return value.carriers.filter(c => c.own).length;
    }

}
