import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {CustomerDomainObject} from '../../../../../model/domain/customer.domain-object';
import {FormGroup} from '@angular/forms';
import {MasterdataFormBuilder} from '../../../services/masterdata-form-builder.service';


export class MasterdataDispositionEditorBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    customer: CustomerDomainObject;
}

@Component({
    selector: 'app-masterdata-disposition-editor',
    templateUrl: './masterdata-disposition-editor.component.html',
    styleUrls: ['./masterdata-disposition-editor.component.scss']
})
export class MasterdataDispositionEditorComponent extends BypassFormComponent<MasterdataDispositionEditorBundle> {

    @Output() saved = new EventEmitter();

    formGroup: FormGroup;

    businessPartnerId: number;

    @Input() set businessPartner(bp: BusinessPartnerDomainObject) {
        this.businessPartnerId = bp.id;
        if (this.businessPartnerId !== null && this.businessPartnerId !== undefined) {
            const bundle = Object.assign(new MasterdataDispositionEditorBundle(), {businessPartnerId: this.businessPartnerId});
            this.bpSetup(bundle);
        }
    }

    constructor(private masterdataFormBuilder: MasterdataFormBuilder, protected injector: Injector) {
        super(injector);
    }

    protected bpOnMessageReceived(): void {
        this.formGroup = null;
        setTimeout(() => {
            this.formGroup = this.masterdataFormBuilder.createCustomerFormGroup(this.data.customer);
        });
    }

    submit() {
        this.data = Object.assign(new MasterdataDispositionEditorBundle(), {
            businessPartnerId: this.businessPartnerId,
            customer: this.formGroup.value
        });
        this.bpSubmit();
        this.saved.emit();
    }

}
