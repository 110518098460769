import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {CountryOption} from '../../../../model/old/CountryOption';

export class InputCountryBundle extends AbstractBypassBundle {
    countries: CountryOption[];
}

@Component({
    selector: 'app-input-country',
    templateUrl: './input-country.component.html',
    styleUrls: ['./input-country.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputCountryComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => InputCountryComponent)
        }
    ]
})
/**
 * TODO doku
 */
export class InputCountryComponent extends BypassDataComponent<InputCountryBundle> implements ControlValueAccessor, OnInit, Validator {

    @Input() placeholder = 'Land';
    @Input() required = false;
    @Input() transformValue: (country: CountryOption) => any = (country: CountryOption) => {
        if (country !== null && country !== undefined) {
            return country.id;
        } else {
            return null;
        }
    };
    @Input() transformWrite: (input: any, options: CountryOption[]) => CountryOption = (input: any, options: CountryOption[]) => {
        return options.find(o => o.id === input);
    };

    control = new FormControl(undefined, [Validators.required]);
    options: CountryOption[] | undefined;
    private onChangeListeners: ((country: any) => {})[] = [];
    private onTouchedListeners: (() => {})[] = [];

    private writtenValue: any;

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new InputCountryBundle());
    }

    ngOnInit(): void {
        this.control.valueChanges.subscribe(() => {
            this.onChanged();
        });
    }

    protected bpOnMessageReceived(): void {
        this.options = this.data.countries;
        setTimeout(() => this.control.setValue(this.transformWrite(this.writtenValue, this.options)));
        this.onTouched();
    }

    protected bpFilterIncomingData(data: InputCountryBundle): boolean {
        this.onTouched();
        return this.options === undefined;
    }

    onChanged() {
        for (const onChangeListener of this.onChangeListeners) {
            onChangeListener(this.transformValue(this.control.value));
        }
    }

    onTouched() {
        for (const onTouchedListener of this.onTouchedListeners) {
            onTouchedListener();
        }
    }

    registerOnChange(fn: (country: any) => {}): void {
        this.onChangeListeners.push(fn);
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouchedListeners.push(fn);
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    writeValue(country: any): void {
        this.writtenValue = country;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.control.valid ? null : {'country': true};
    }

}
