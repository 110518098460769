import {AfterContentInit, AfterViewChecked, AfterViewInit, Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {FormGroup, Validators, FormControl, FormArray, ValidatorFn} from '@angular/forms';
import {MaterialPrice} from '../../../../../../submodules/bypass-libraries/src/app/material-sorts-price-list/data/MaterialPrice';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class MaterialDialogBundle extends AbstractBypassBundle {
    id: number | undefined;
    name: string;
    comment: string | undefined;
    active: boolean;
}

const checkSorting: ValidatorFn = (fg: FormGroup) => {
    let sorted = true;
    const controls = (fg.get('materialPrices') as FormArray).controls;

    controls.forEach((control, index) => {

        if (index > 0) {
            const last = controls[index - 1].value as MaterialPrice;
            const current = control.value as MaterialPrice;

            if (last && current && last.from && current.from && new Date(last.from).getTime() >= new Date(current.from).getTime()) {
                sorted = false;
            }
        }

    });

    return sorted ? null : {sortedError: true};
};

const checkMaterialPrices: ValidatorFn = (fg: FormGroup) => {
    const prices = fg.get('materialPrices') as FormArray;
    const hasInvalidPrices = prices.controls.map(c => c.valid).filter(v => !v).length > 0;
    if (hasInvalidPrices) {
        return {materialPricesInvalidError: true};
    }
    return null;
};

@Component({
    selector: 'app-material-dialog',
    templateUrl: './material-dialog.component.html',
    styleUrls: ['./material-dialog.component.scss']
})
export class MaterialDialogComponent extends BypassFormComponent<MaterialDialogBundle> implements OnInit {

    submitted = false;

    form = new FormGroup({
        id: new FormControl(undefined),
        name: new FormControl('', [Validators.required]),
        comment: new FormControl(''),
        active: new FormControl(true),
        materialPrices: new FormArray([])
    }, {
        validators: [checkSorting, checkMaterialPrices]
    });

    get materialPrices(): FormArray {
        return this.form.get('materialPrices') as FormArray;
    }

    constructor(protected injector: Injector,
                public dialogRef: MatDialogRef<MaterialDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new MaterialDialogBundle());
    }

    ngOnInit() {
        // setTimeout(() => {
        if (this.input !== null) {
            for (let i = 0; i < this.input.material.materialPrices.length; i++) {
                this.createMaterialPrice();
            }
            this.form.patchValue(this.input.material, {emitEvent: false});
        }
        // });
    }

    createMaterialPrice() {
        const ctr = new FormControl();
        ctr.setValue({from: null, ek: null, fr: null, vk: null});
        this.materialPrices.push(ctr);
    }

    onSubmit() {
        if (this.form.valid) {
            this.submitted = true;
            this.data = Object.assign(new MaterialDialogBundle(), this.form.value);
            if (this.input !== null) {
                Object.assign(this.data, {id: this.input.material.id});
            }
            this.bpSubmit();
            this.close();
        }
    }

    close() {
        this.dialogRef.close();
    }

}
