import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {Carrier} from '../../../model/old/Carrier';
import {Tour} from '../../../model/old/Tour';
import {Location} from '../../../model/old/Location';

export class CarrierRow {
    carrier: Carrier;
    lastUnloadingLocation: [Location, number]; // number = time
    nextLoadingLocation: [Location, number];
    tours: Tour[];
}

export class DispoPlanOwnCarriersBundle extends AbstractBypassBundle {
    date: Date;
    dispoPlan: {
        day: Date;
        carrierRows: CarrierRow[];
    };
}

@Component({
    selector: 'app-dispoplanowncarriers',
    templateUrl: './dispoplanowncarriers.component.html',
    styleUrls: ['./dispoplanowncarriers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispoPlanOwnCarriersComponent extends BypassFormComponent<DispoPlanOwnCarriersBundle> implements OnInit {

    private _date: Date;

    displayedColumns = ['carrier', 'lastUnloadingLocation', 'tours', 'nextLoadingLocation'];
    dataSource = [];

    @Input() set date(date: Date) {
        this._date = date;
        this.data = new DispoPlanOwnCarriersBundle();
        this.data.date = this._date;
        this.bpSubmit();
    }

    constructor(protected injector: Injector, private cdr: ChangeDetectorRef) {
        super(injector);
        this.bpSetup(new DispoPlanOwnCarriersBundle());
    }

    ngOnInit() {
    }

    bpOnMessageReceived() {
        this.dataSource = this.data.dispoPlan.carrierRows.sort((a: CarrierRow, b: CarrierRow) => {
            return a.carrier.shortName.localeCompare(b.carrier.shortName);
        });
        this.cdr.detectChanges();
    }

    millisToDate(millis: number) {
        return this.formatDate(new Date(millis));
    }

    millisToTime(millis: number) {
        return this.formatTime(new Date(millis));
    }

    formatDate(date: Date) {
        return new Date(date).toLocaleString('de').slice(0, -3);
    }

    formatTime(date: Date) {
        return new Date(date).toLocaleTimeString('de').slice(0, -3);
    }

}
