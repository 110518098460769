import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {LocationDomainObject} from '../../../../../../model/domain/location.domain-object';

@Component({
    selector: 'app-dialog-confirm-location-delete',
    templateUrl: './dialog-confirm-location-delete.component.html',
    styleUrls: ['./dialog-confirm-location-delete.component.scss']
})
export class DialogConfirmLocationDeleteComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public location: LocationDomainObject) {
    }

}
