import {ComponentFactoryResolver, Directive, Input, Type, ViewContainerRef} from '@angular/core';
import {DatatableCellBase} from './datatable-cell-base.interface';

@Directive({
  selector: '[libDatatableCell]'
})
export class DatatableCellDirective {

  @Input() datatableCellData: any;

  @Input() set datatableCellComponent(component: Type<any>) {
    this.viewContainerRef.clear();
    if (component === undefined || component === null) {
      return;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    (<DatatableCellBase<any>> componentRef.instance).data = this.datatableCellData;
  }

  constructor(public viewContainerRef: ViewContainerRef, public componentFactoryResolver: ComponentFactoryResolver) {

  }

}
