import {Component, OnDestroy, OnInit} from '@angular/core';
import {DragulaService} from 'ng2-dragula';

@Component({
    selector: 'app-accountancy-invoicing-container',
    templateUrl: './accountancy-invoicing-container.component.html',
    styleUrls: ['./accountancy-invoicing-container.component.scss']
})
export class AccountancyInvoicingContainerComponent {



}
