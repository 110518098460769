import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AccountancyFormBuilder} from '../../../services/accountancy-form-builder.service';
import {TourDisplay} from '../../partials/invoice-tour-display/invoice-tour-display.component';
import {InvoiceItemFormComponent} from '../invoice-item-form/invoice-item-form.component';

@Component({
    selector: 'app-incoming-credit-form',
    templateUrl: './incoming-credit-form.component.html',
    styleUrls: ['./incoming-credit-form.component.scss']
})
export class IncomingCreditFormComponent implements OnInit, OnDestroy {

    @Input() businessPartnerId: number;
    @Output() tourRemoved = new EventEmitter<number>();
    @Output() submitted = new EventEmitter<any>();
    @ViewChildren('invoiceItemForm') invoiceItemForms: QueryList<InvoiceItemFormComponent>;
    incomingCreditForm: FormGroup = this.accountancyFormBuilder.createInvoiceFormGroup();
    @Output() vatWarnings = new EventEmitter<boolean>();

    constructor(public accountancyFormBuilder: AccountancyFormBuilder) {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
    }

    swapInvoiceItems(i: number, j: number) {
        const items = this.accountancyFormBuilder.getInvoiceItems(this.incomingCreditForm);
        const valI = items.controls[i].value;
        const valJ = items.controls[j].value;
        items.controls[i].setValue(valJ);
        items.controls[j].setValue(valI);
    }

    deleteInvoiceItem(index: number) {
        const items = this.accountancyFormBuilder.getInvoiceItems(this.incomingCreditForm);
        if (items.at(index).value.tourId !== null) {
            const tourId = items.at(index).value.tourId;
            this.tourRemoved.emit(tourId);
        }
        items.removeAt(index);
    }


    addAccount() {
        const control = this.incomingCreditForm.get('accounts');
        control.setValue([...control.value, {
            accountId: null,
            sum: null
        }]);
    }


    addTour(tourDisplay: TourDisplay) {
        const invoiceItem = this.accountancyFormBuilder.createInvoiceItemFormGroup();
        invoiceItem.patchValue({
            tourId: tourDisplay.tourId,
            discountable: tourDisplay.commissionType !== 'FRACHT'
        });
        this.accountancyFormBuilder.getInvoiceItems(this.incomingCreditForm).push(invoiceItem);
    }

    onSubmitClick(netto: number, brutto: number) {
        if (confirm(`Bitte bestätigen! Netto = ${this.formatPrice(netto)} €, Brutto = ${this.formatPrice(brutto)} €`)) {
            this.submitted.emit(this.incomingCreditForm.value);
        }
    }


    formatPrice(value: number): string {
        return value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

}
