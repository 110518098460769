import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {MaterialPriceEmbeddedObject} from '../../../model/domain/material-price.embedded-object';
import {Preset} from '../../../model/old/Preset';

export class CreateTourFromPresetPriceWidgetBundle extends AbstractBypassBundle {
    materialId: number;
    date: Date;
    materialPrice: MaterialPriceEmbeddedObject | null;
}

@Component({
    selector: 'app-createtourfrompreset-price-widget',
    templateUrl: './createtourfrompreset-price-widget.component.html',
    styleUrls: ['./createtourfrompreset-price-widget.component.scss']
})
export class CreateTourFromPresetPriceWidgetComponent extends BypassFormComponent<CreateTourFromPresetPriceWidgetBundle> {

    hasResponse = false;

    private _preset: Preset | null = null;

    @Input() set preset(preset: Preset | null) {
        this._preset = preset;
        this.onInputsChange();
    }

    get preset(): Preset | null {
        return this._preset;
    }

    private _date: Date | null = null;

    @Input() set date(date: Date | null) {
        this._date = date;
        this.onInputsChange();
    }

    get date(): Date | null {
        return this._date;
    }

    @Output() priceFound = new EventEmitter<MaterialPriceEmbeddedObject | null>();

    private onInputsChange() {
        this.hasResponse = false;
        if (this.date !== null && this.preset !== null) {
            this.data = Object.assign(new CreateTourFromPresetPriceWidgetBundle(), {
                materialId: this.preset.material.id,
                date: this.date,
                materialPrice: null
            });
            this.bpSubmit();
        } else {
            this.data = null;
        }
    }

    constructor(protected injector: Injector) {
        super(injector);
        this.disableAutoLoadingOverlay();
        this.bpSetup(new CreateTourFromPresetPriceWidgetBundle());
    }

    protected bpOnMessageReceived() {
        this.hasResponse = true;
        if (this.data.materialPrice !== null && this.data.materialPrice !== undefined) {
            this.priceFound.emit(this.data.materialPrice);
        } else {
            this.priceFound.emit(null);
        }
    }
}
