import {Pipe, PipeTransform} from '@angular/core';
import {CarrierDomainObject} from '../../../model/domain/carrier.domain-object';

@Pipe({
    name: 'carrierTitle'
})
export class CarrierTitlePipe implements PipeTransform {

    transform(value: CarrierDomainObject, index: number, ...args: any[]): any {
        if (value.name && value.shortName) {
            if (value.own) {
                return `${value.shortName} | ${value.name} | Eigene Flotte`;
            } else {
                return `${value.shortName} | ${value.name} | Subunternehmer`;
            }
        }
        return `Spediteur ${index + 1}`;
    }

}
