import {Component, Input} from '@angular/core';
import {FormFieldDataComponent} from '../../form-field-data/form-field-data.component';
import {MatSelectChange} from '@angular/material/select';

export class MatSelectOption {
  value: any;
  text: any;

  constructor(value: any, text: any) {
    this.value = value;
    this.text = text;
  }
}

@Component({
  selector: 'ui-form-field-select',
  templateUrl: './ui-form-field-select.component.html',
  styleUrls: ['./ui-form-field-select.component.scss']
})
export class UiFormFieldSelectComponent extends FormFieldDataComponent {

  @Input() options: MatSelectOption[];

  selectionChanged(event: MatSelectChange) {
    this.value = event.value;
  }

}
