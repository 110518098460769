import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BypassCoreModule} from '@byteways/bypass-core';
import {ContainerComponent} from './container/container.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateRangeComponent} from './daterange/daterange.component';
import {ModelToStringPipe} from './model-to-string.pipe';
import {ModelModule} from '../model/old/model.module';
import {ToursummaryComponent} from './toursummary/toursummary.component';
import {PresetSummaryComponent} from './preset-summary/preset-summary.component';
import {SchedulesummaryComponent} from './schedulesummary/schedulesummary.component';
import {CustomerDisplayComponent} from './customer-display/customer-display.component';
import {ContextMenuModule} from 'ngx-contextmenu';
import {CarrierDisplayComponent} from './carrier-display/carrier-display.component';
import {MaterialDisplayComponent} from './material-display/material-display.component';
import {EmployeeDisplayComponent} from './employee-display/employee-display.component';
import {LocationDisplayComponent} from './location-display/location-display.component';
import {DaterangeDisplayComponent} from './daterange-display/daterange-display.component';
import {TextDisplayComponent} from './text-display/text-display.component';
import {ConnectionStatusComponent} from './connection-status/connection-status.component';
import {TourDisplayComponent} from './tour-display/tour-display.component';
import { DemoDialogComponent } from './demo-dialog/demo-dialog.component';
import { BusinessPartnerDisplayComponent } from './business-partner-display/business-partner-display.component';
import { AbbrevPipe } from './abbrev.pipe';
import { DateRangeFormatPipe } from './date-range-format.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { CommissionTypePipe } from './commission-type.pipe';
import { PriceTypePipe } from './price-type.pipe';
import { PriceFormatPipe } from './price-format.pipe';
import { AddressFormatPipe } from './address-format.pipe';
import { BankAccountFormatPipe } from './bank-account-format.pipe';
import { EmployeeFormatPipe } from './employee-format.pipe';
import { EventTypeFormatPipe } from './event-type-format.pipe';
import { UserActionFormatPipe } from './user-action-format.pipe';
import { ErrorComponent } from './error/error.component';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import { DurationPipe } from './duration.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatNativeDateModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    imports: [
        CommonModule,
        BypassCoreModule,
        BypassIdenticonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ContextMenuModule.forRoot(),
        ModelModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatToolbarModule,
        MatInputModule,
    ],
    declarations: [
        ContainerComponent,
        DateRangeComponent,
        ModelToStringPipe,
        ToursummaryComponent,
        PresetSummaryComponent,
        SchedulesummaryComponent,
        CustomerDisplayComponent,
        CarrierDisplayComponent,
        MaterialDisplayComponent,
        EmployeeDisplayComponent,
        LocationDisplayComponent,
        DaterangeDisplayComponent,
        TextDisplayComponent,
        ConnectionStatusComponent,
        TourDisplayComponent,
        DemoDialogComponent,
        BusinessPartnerDisplayComponent,
        AbbrevPipe,
        DateRangeFormatPipe,
        DateFormatPipe,
        CommissionTypePipe,
        PriceTypePipe,
        PriceFormatPipe,
        AddressFormatPipe,
        BankAccountFormatPipe,
        EmployeeFormatPipe,
        EventTypeFormatPipe,
        UserActionFormatPipe,
        ErrorComponent,
        DurationPipe,
    ],
    exports: [
        ContainerComponent,
        DateRangeComponent,
        ModelToStringPipe,
        AbbrevPipe,
        DateRangeFormatPipe,
        DateFormatPipe,
        ToursummaryComponent,
        PresetSummaryComponent,
        SchedulesummaryComponent,
        CustomerDisplayComponent,
        CarrierDisplayComponent,
        MaterialDisplayComponent,
        EmployeeDisplayComponent,
        LocationDisplayComponent,
        DaterangeDisplayComponent,
        TextDisplayComponent,
        ConnectionStatusComponent,
        TourDisplayComponent,
        BusinessPartnerDisplayComponent,
        CommissionTypePipe,
        PriceTypePipe,
        PriceFormatPipe,
        AddressFormatPipe,
        BankAccountFormatPipe,
        EmployeeFormatPipe,
        EventTypeFormatPipe,
        UserActionFormatPipe,
        ErrorComponent,
        DurationPipe
    ],
    entryComponents: [
        DemoDialogComponent
    ]
})
export class FeaturesModule {
}
