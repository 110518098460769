import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-demo-dialog',
    templateUrl: './demo-dialog.component.html',
    styleUrls: ['./demo-dialog.component.scss']
})
export class DemoDialogComponent {

    constructor(public dialog: MatDialog,
                public dialogRef: MatDialogRef<DemoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any) {}

    close() {
        this.dialogRef.close();
    }

}
