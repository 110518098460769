import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-dispobookcontainer',
    templateUrl: './dispobookcontainer.component.html',
    styleUrls: ['./dispobookcontainer.component.scss']
})
export class DispoBookContainerComponent implements AfterViewInit {

    dispoBookFullscreen = false;
    createTourFullscreen = false;

    @ViewChild('dispobook', {static: true}) dispobook;

    constructor(private route: ActivatedRoute) {

    }

    ngAfterViewInit() {
        this.route.queryParamMap.subscribe(qpm => {
            if (qpm.has('filter')) {
                this.dispobook.setFilter(qpm.get('filter'));
            }
        });
    }

    onDispoBookFullscreenToggle(fullscreen: boolean) {
        this.dispoBookFullscreen = fullscreen;
        if (fullscreen) {
            this.createTourFullscreen = false;
        }
    }

    onCreateTourFullscreenToggle(fullscreen: boolean) {
        this.createTourFullscreen = fullscreen;
        if (fullscreen) {
            this.dispoBookFullscreen = false;
        }
    }

    getCreateTourFlexSize() {
        return this.createTourFullscreen ? 'grow' : 20;
    }

}
