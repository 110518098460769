import {Component, Inject, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';

export class DialogSetSubcontractorInvoiceNumberBundle extends AbstractBypassBundle {
    tourId: number;
    subcontractorInvoiceNumber?: string;
}

@Component({
    selector: 'app-dialog-set-subcontractor-invoice-number',
    templateUrl: './dialog-set-subcontractor-invoice-number.component.html',
    styleUrls: ['./dialog-set-subcontractor-invoice-number.component.scss']
})
export class DialogSetSubcontractorInvoiceNumberComponent extends BypassFormComponent<DialogSetSubcontractorInvoiceNumberBundle> implements OnInit {

    form = new FormGroup({
        subcontractorInvoiceNumber: new FormControl()
    });

    constructor(
        protected injector: Injector,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DialogSetSubcontractorInvoiceNumberBundle>,
        @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new DialogSetSubcontractorInvoiceNumberBundle());
    }

    ngOnInit() {
        this.form.get('subcontractorInvoiceNumber').setValue(this.input.tour.subcontractorInvoiceNumber);
    }

    submit() {
        if (this.form.valid) {
            this.data = Object.assign(new DialogSetSubcontractorInvoiceNumberBundle(), this.form.value, {
                tourId: this.input.tour.id
            });
            this.bpSubmit();
            this.close();
        }
    }

    close() {
        this.dialogRef.close();
    }

}
