import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'priceFormat'
})
export class PriceFormatPipe implements PipeTransform {

    private static localeOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    transform(value: number, currencyFormat: string): string {
        if (value === undefined || value === null) {
            return ' — ';
        }
        return value.toLocaleString(undefined, PriceFormatPipe.localeOptions) + ' ' + currencyFormat;
    }
}
