import {ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {UiLoadingOverlayDefaultComponent} from './ui-loading-overlay-default/ui-loading-overlay-default.component';

@Injectable({
  providedIn: 'root'
})
export class UiLoadingOverlayService {

  private static displayWhenVisible = 'flex';
  private static displayWhenHidden = 'none';

  private static setOverlayDimensions(overlay: HTMLElement, hostElement: HTMLElement) {
    const position = getComputedStyle(hostElement).position;

    const positionStatic = position === 'static';

    if (positionStatic) {
      overlay.style.top = hostElement.offsetTop + 'px';
      overlay.style.left = hostElement.offsetLeft + 'px';
    } else {
      overlay.style.top = '0px';
      overlay.style.left = '0px';
    }

    overlay.style.width = hostElement.getBoundingClientRect().width + 'px';
    overlay.style.height = hostElement.getBoundingClientRect().height + 'px';
  }

  constructor(private resolver: ComponentFactoryResolver,
              private injector: Injector, private componentFactoryResolver: ComponentFactoryResolver) {
  }

  init(hostElement: HTMLElement, loading: Observable<boolean>, hide?: Observable<boolean>, component?: any) {
    const componentRef = this.createComponentRef(component || UiLoadingOverlayDefaultComponent);
    const overlay: HTMLElement = this.createOverlay(hostElement, componentRef);
    this.subscribeToEvents(overlay, hostElement, loading, componentRef, hide);
    hostElement.append(overlay);
  }

  private subscribeToEvents(
    overlay: HTMLElement, hostElement: HTMLElement, loading: Observable<boolean>,
    componentRef: ComponentRef<any>, hide?: Observable<boolean>
  ) {
    let hideOverlay = false;
    let overlayLoading = false;
    let hostElementChilds = hostElement.childNodes.length;

    if (hide !== null && typeof hide !== 'undefined') {
      hide.subscribe((value) => {
        hideOverlay = value;
        if (hideOverlay) {
          overlay.style.display = UiLoadingOverlayService.displayWhenHidden;
        } else if (overlayLoading) {
          overlay.style.display = UiLoadingOverlayService.displayWhenVisible;
        }
      });
    }

    loading.subscribe((isLoading) => {
      overlayLoading = isLoading;
      if (isLoading && !hideOverlay) {
        overlay.style.display = UiLoadingOverlayService.displayWhenVisible;
      } else {
        overlay.style.display = UiLoadingOverlayService.displayWhenHidden;
      }
    });

    setInterval(() => {
      const topChanged = overlay.offsetTop !== hostElement.offsetTop;
      const leftChanged = overlay.offsetLeft !== hostElement.offsetLeft;
      const widthChanged = overlay.offsetWidth !== Math.floor(hostElement.getBoundingClientRect().width);
      const heightChanged = overlay.offsetHeight !== Math.floor(hostElement.getBoundingClientRect().height);

      if (topChanged || leftChanged || widthChanged || heightChanged) {
        UiLoadingOverlayService.setOverlayDimensions(overlay, hostElement);
        componentRef.changeDetectorRef.detectChanges();
      }

      if (hostElement.childNodes.length !== hostElementChilds) {
        hostElementChilds = hostElement.childNodes.length;
        overlay.remove();
        hostElement.append(overlay);
      }

    }, 10);
  }

  private createComponentRef(component): ComponentRef<any> {
    let overlayComponent = component;
    if (!overlayComponent) {
      overlayComponent = UiLoadingOverlayDefaultComponent;
    }
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(overlayComponent).create(this.injector);
    componentRef.changeDetectorRef.detectChanges();
    return componentRef;
  }

  private createOverlay(hostElement: HTMLElement, componentRef: ComponentRef<any>): HTMLElement {
    const overlay = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    overlay.id = 'overlay';
    overlay.style.position = 'absolute';
    overlay.style.display = UiLoadingOverlayService.displayWhenVisible;
    UiLoadingOverlayService.setOverlayDimensions(overlay, hostElement);
    return overlay;
  }

}
