import {Component, Input, OnInit} from '@angular/core';
import {TourStatistics} from '../../model/tour-statistics.model';

@Component({
    selector: 'app-dispo-stats-result',
    templateUrl: './dispo-stats-result.component.html',
    styleUrls: ['./dispo-stats-result.component.scss']
})
export class DispoStatsResultComponent implements OnInit {

    private _stats: TourStatistics | null;

    @Input() set stats(value: TourStatistics | null) {
        this._stats = value;
        if (value.carriers.length > 0) {
            this.selectedCarrierId = value.carriers[0].carrierId;
        } else {
            this.selectedCarrierId = null;
        }
    }

    get stats(): TourStatistics | null {
        return this._stats;
    }

    selectedCarrierId: number | null = null;
    showSubcontractors = true;

    constructor() {
    }

    ngOnInit() {
    }

}
