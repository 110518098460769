import {AbstractControl, ControlValueAccessor, ValidationErrors, Validator} from '@angular/forms';

/**
 * Extend this class to get basic control value accessor and validator functionality. Note that you still must add the
 * NG_VALUE_ACCESSOR and NG_VALIDATORS provider to the components providers array.
 *
 * Override any method to implement custom behavior, for example override setDisabledState(...) if you want a custom
 * implementation for the disabled-state.
 *
 * - call notifyChange(value: T) to notify the onChange-Listeners of any changes.
 * - call notifyTouched() to notify the onTouched-Listeners of any changes.
 * - call notifyValidatorChange() to notify the onValidatorChange-Listeners of any changes.
 *
 * Example usage:
 *
 * <code>
 *  @Component({
 *   selector: 'app-my-component',
 *   templateUrl: './app-my-component.component.html',
 *   styleUrls: ['./app-my-component.component.scss'],
 *   providers: [
 *       {
 *           provide: NG_VALUE_ACCESSOR,
 *           multi: true,
 *           useExisting: forwardRef(() => MyComponent)
 *       },
 *       {
 *           provide: NG_VALIDATORS,
 *           multi: true,
 *           useExisting: forwardRef(() => MyComponent)
 *       }
 *   ]
 * })
 * export class MyComponent extends BypassControlValueAccessor {
 *   // implementation ...
 * }
 * </code>
 */
export abstract class BypassControlValueAccessor<ValueType> implements ControlValueAccessor, Validator {

  private isDisabled = false;
  private onChangeListeners: ((ValueType) => void)[] = [];
  private onTouchedListeners: (() => void)[] = [];
  private onValidatorChangeListeners: (() => void)[] = [];

  get disabled(): boolean {
    return this.isDisabled;
  }

  registerOnChange(fn: (ValueType) => void): void {
    this.onChangeListeners.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedListeners.push(fn);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChangeListeners.push(fn);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  abstract writeValue(obj: ValueType | undefined | null): void;

  notifyChange(value: ValueType | undefined | null): void {
    for (const changeListener of this.onChangeListeners) {
      changeListener(value);
    }
  }

  notifyTouched(): void {
    for (const touchedListener of this.onTouchedListeners) {
      touchedListener();
    }
  }

  notifyValidatorChange() {
    for (const validatorChangeListener of this.onValidatorChangeListeners) {
      validatorChangeListener();
    }
  }

}
