import {Component, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {DateRange} from '../../../model/old/DateRange';
import {DateRangeComponent} from '../../../features/daterange/daterange.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {Material} from '../../../model/old/Material';
import {Customer} from '../../../model/old/Customer';
import {Location} from '../../../model/old/Location';
import {BypassSearchableInputComponent} from '@byteways/bypass-searchable-input';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


export class DialogEditTourBundle extends AbstractBypassBundle {
    id: number;
    customer: number;
    targetDate: DateRange;
    material: number;
    quantity: string;
    loadingLocation: number;
    unloadingLocation: number;
    vehicle: string;
    commissionType: string;
    priceEk: number;
    priceFr: number;
    priceVk: number;
    priceType: string;
    comment: string;
    message: string;
    duration: number;
    distance: number;
    orderNumber;
}

const CommissionTypes = ['fracht', 'handel', 'franko'];
const PriceTypes = ['flat', 'weight', 'time'];


@Component({
    selector: 'app-dialogedittour',
    templateUrl: './dialogedittour.component.html',
    styleUrls: ['./dialogedittour.component.scss']
})
export class DialogEditTourComponent extends BypassFormComponent<DialogEditTourBundle> implements OnInit {

    @ViewChild('customer', {static: true}) customerInput: BypassSearchableInputComponent;
    @ViewChild('targetDate', {static: true}) targetDateInput: DateRangeComponent;
    @ViewChild('material', {static: true}) materialInput: BypassSearchableInputComponent;
    @ViewChild('loadingLocation', {static: true}) loadingLocationInput: BypassSearchableInputComponent;
    @ViewChild('unloadingLocation', {static: true}) unloadingLocationInput: BypassSearchableInputComponent;
    @ViewChild('carrier', {static: true}) carrierInput: BypassSearchableInputComponent;

    createForm = new FormGroup({
        customer: new FormControl(undefined, [Validators.required]),
        targetDate: new FormControl(undefined, [Validators.required]),
        material: new FormControl(undefined, [Validators.required]),
        quantity: new FormControl(undefined, [Validators.required]),
        loadingLocation: new FormControl(undefined, [Validators.required]),
        unloadingLocation: new FormControl(undefined, [Validators.required]),
        vehicle: new FormControl(undefined, [Validators.required]),
        commissionType: new FormControl(undefined, [Validators.required]),
        priceEk: new FormControl(),
        priceFr: new FormControl(),
        priceVk: new FormControl(undefined, [Validators.required]),
        priceType: new FormControl(undefined, [Validators.required]),
        message: new FormControl(),
        comment: new FormControl(),
        distance: new FormControl(),
        duration: new FormControl(),
        orderNumber: new FormControl(),
    });

    constructor(protected injector: Injector,
                public dialogRef: MatDialogRef<DialogEditTourComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new DialogEditTourBundle());
        // this.createForm.controls.customer.setValue(input.tour.customer);
        // this.createForm.controls.targetDate.setValue(input.tour.targetDate);
        // this.createForm.controls.material.setValue(input.tour.material);
        // this.createForm.controls.quantity.setValue(input.tour.quantity);
        // this.createForm.controls.loadingLocation.setValue(input.tour.loadingLocation);
        // this.createForm.controls.unloadingLocation.setValue(input.tour.unloadingLocation);
        // this.createForm.controls.priceEk.setValue(input.tour.priceEk);
        // this.createForm.controls.priceFr.setValue(input.tour.priceFr);
        // this.createForm.controls.priceVk.setValue(input.tour.priceVk);
        // this.createForm.controls.priceType.setValue(input.tour.priceType);
        // this.createForm.controls.comment.setValue(input.tour.comment);
        this.createForm.patchValue(input.tour);
    }

    ngOnInit() {
        setTimeout(() => this.createForm.patchValue(this.input.tour));
    }

    getCommissionType(): string {
        if (this.createForm.value.priceEk == null
            && this.createForm.value.priceFr != null && this.createForm.value.priceVk != null) {
            return CommissionTypes[0]; // fracht
        } else if (this.createForm.value.priceEk != null
            && this.createForm.value.priceFr != null && this.createForm.value.priceVk != null) {
            return CommissionTypes[1]; // handel
        } else if (this.createForm.value.priceEk != null
            && this.createForm.value.priceFr == null && this.createForm.value.priceVk != null) {
            return CommissionTypes[2]; // handel/franko
        } else {
            return undefined;
        }
    }

    submit() {
        this.data = new DialogEditTourBundle();
        this.data.id = this.input.tour.id;
        this.data.customer = this.createForm.value.customer.id;
        this.data.targetDate = this.createForm.value.targetDate;
        this.data.material = this.createForm.value.material.id;
        this.data.quantity = this.createForm.value.quantity;
        this.data.loadingLocation = this.createForm.value.loadingLocation.id;
        this.data.unloadingLocation = this.createForm.value.unloadingLocation.id;
        this.data.vehicle = this.createForm.value.vehicle;
        this.data.commissionType = this.getCommissionType();
        this.data.priceEk = this.createForm.value.priceEk;
        this.data.priceFr = this.createForm.value.priceFr;
        this.data.priceVk = this.createForm.value.priceVk;
        this.data.priceType = this.createForm.value.priceType;
        this.data.comment = this.createForm.value.comment;
        this.data.message = this.createForm.value.message;
        this.data.duration = this.createForm.value.duration;
        this.data.distance = this.createForm.value.distance;
        this.data.orderNumber = this.createForm.value.orderNumber;
        this.bpSubmit();
        this.close();
    }

    displayCommissionTypeAndPrice() {
        const commissionType = this.getCommissionType();
        const ek = this.createForm.value.priceEk;
        const fr = this.createForm.value.priceFr;
        const vk = this.createForm.value.priceVk;
        let tradePrice = 0.0;
        switch (commissionType) {
            case 'handel':
                tradePrice = vk - fr - ek;
                return `Handelsauftrag mit ${tradePrice}€ Handelsnutzen`;
            case 'fracht':
                tradePrice = vk - fr;
                return `Frachtauftrag mit ${tradePrice}€ Handelsnutzen`;
            case 'franko':
                tradePrice = vk - ek;
                return `Frankoauftrag mit ${tradePrice}€ Handelsnutzen`;
            default:
                return `Bitte Preise eingeben, um Auftragstyp zu bestimmen.`;
        }
    }

    getTradeValue() {
        const commissionType = this.getCommissionType();
        const ek = this.createForm.value.priceEk;
        const fr = this.createForm.value.priceFr;
        const vk = this.createForm.value.priceVk;
        switch (commissionType) {
            case 'handel':
                return vk - fr - ek;
            case 'fracht':
                return vk - fr;
            case 'franko':
                return vk - ek;
            default:
                return undefined;
        }
    }

    onPriceFound(price: any) {
        this.createForm.patchValue({
            priceEk: price?.priceEk ?? null,
            priceFr: price?.priceFr ?? null,
            priceVk: price?.priceVk ?? null,
            priceType: price?.priceType ?? null,
        });
    }

    close() {
        this.dialogRef.close();
    }

    customerToString(data: any) {
        const customer = (data as Customer);
        return customer.name;
    }

    locationToString(data: any) {
        return (data as Location).name;
    }

    materialToString(data: any) {
        return (data as Material).name;
    }

}
