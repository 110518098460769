import { Component, OnInit } from '@angular/core';
import {DatatableCellBase} from '@byteways/bypass-datatable';

@Component({
  selector: 'app-accountancy-invoice-table2-price',
  templateUrl: './accountancy-invoice-table2-price.component.html',
  styleUrls: ['./accountancy-invoice-table2-price.component.scss']
})
export class AccountancyInvoiceTable2PriceComponent implements DatatableCellBase<number> {
    data: number;
}
