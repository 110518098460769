import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';

export class DialogSendTourBundle extends AbstractBypassBundle {
    tourId: number;
    loadingNumber: string;
    emails: string[];
    emailOptions: { email: string; selectedByDefault: boolean }[];
}

@Component({
    selector: 'app-dialogsendtour',
    templateUrl: './dialogsendtour.component.html',
    styleUrls: ['./dialogsendtour.component.scss']
})
export class DialogSendTourComponent extends BypassFormComponent<DialogSendTourBundle> implements OnInit {


    sendTourForm = new FormGroup({
        emails: new FormArray([this.createEmailFormControl()]),
        files: new FormArray([]),
        loadingNumber: new FormControl(undefined)
    });

    constructor(
        protected injector: Injector,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DialogSendTourComponent>,
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        const bundle = new DialogSendTourBundle();
        bundle.tourId = this.input.tour.id;
        this.bpSetup(bundle);
    }

    get emailsFormArray(): FormArray {
        return this.sendTourForm.get('emails') as FormArray;
    }

    get filesFormArray(): FormArray {
        return this.sendTourForm.get('files') as FormArray;
    }

    ngOnInit() {
    }

    fileChange(event, index: number) {
        const fileList: FileList = event.target.files;
        if (fileList.length === 0) {
            this.filesFormArray.at(index).setValue(null);
            return;
        } else if (fileList.length > 1) {
            throw new Error();
        }
        this.filesFormArray.at(index).setValue({
            file: fileList[0],
            name: fileList[0].name
        });
    }

    submit() {
        const fd = new FormData();
        fd.append('tourId', this.input.tour.id);
        for (const email of this.sendTourForm.value.emails) {
            fd.append('emails', email);
        }
        for (const file of this.sendTourForm.value.files.filter(f => f !== null)) {
            fd.append('files', file.file, file.name);
        }
        fd.append('loadingNumber', this.sendTourForm.value.loadingNumber ?? '');

        this.http.post('/api/send/tour', fd, {
            reportProgress: true,
            observe: 'events'
        }).subscribe(
            response => {
                console.log('response', response);
                this.close();
            },
            error => {
                alert('Beim Versenden der Emails ist ein Fehler aufgetreten');
                console.error(error);
                this.close();
            }
        );
    }

    addFile() {
        this.filesFormArray.push(this.createFileFormControl());
    }

    close() {
        this.dialogRef.close();
    }

    createEmailFormControl(): FormControl {
        return new FormControl(undefined, [Validators.email]);
    }

    createFileFormControl(): FormControl {
        return new FormControl(undefined, []);
    }

    protected bpOnMessageReceived() {
        if (this.data.emailOptions && this.data.emailOptions.length > 0) {
            this.emailsFormArray.clear();
            for (const option of this.data.emailOptions) {
                if (option.selectedByDefault) {
                    const ctrl = this.createEmailFormControl();
                    ctrl.setValue(option.email);
                    this.emailsFormArray.push(ctrl);
                }
            }
        }
    }

}
