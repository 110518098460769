import { Component, OnInit } from '@angular/core';
import {BusinessPartner} from '../../../model/old/BusinessPartner';

@Component({
  selector: 'app-accountancy-outgoing-credit',
  templateUrl: './accountancy-outgoing-credit.component.html',
  styleUrls: ['./accountancy-outgoing-credit.component.scss']
})
export class AccountancyOutgoingCreditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }



}
