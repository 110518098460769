import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accountancy-invoice-book',
  templateUrl: './accountancy-invoice-book.component.html',
  styleUrls: ['./accountancy-invoice-book.component.scss']
})
export class AccountancyInvoiceBookComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
