import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {MasterdataFormBuilder} from '../../../services/masterdata-form-builder.service';
import {FormGroup} from '@angular/forms';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import { BusinessPartnerGroup } from '../../../../../model/old/BusinessPartnerGroup';
import {MatDialog} from '@angular/material/dialog';
import {MasterdataDialogCreateComponent} from '../../table/masterdata-dialog-create/masterdata-dialog-create.component';
import {MasterdataDialogGroupCreateComponent} from '../../table/masterdata-dialog-create-group/masterdata-dialog-group-create.component';

export class MasterdataBusinessPartnerEditorBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    name: string;
    nameAdd: string;
    shortName: string;
    comment: string | undefined;
    businessPartnerGroupId: number | null;
    businessPartnerGroupOptions: BusinessPartnerGroup[];
}

@Component({
    selector: 'app-masterdata-business-partner-editor',
    templateUrl: './masterdata-business-partner-editor.component.html',
    styleUrls: ['./masterdata-business-partner-editor.component.scss']
})
export class MasterdataBusinessPartnerEditorComponent extends BypassFormComponent<MasterdataBusinessPartnerEditorBundle> {

    @Input() set businessPartner(bp: BusinessPartnerDomainObject) {
        this.form = null;
        setTimeout(() => {
            this.form = this.masterdataFormBuilder.createBusinessPartnerFormGroup(bp);
            this.businessPartnerId = bp.id;
        });
    }

    form: FormGroup;
    businessPartnerId: number;

    @Output() saved = new EventEmitter();

    constructor(protected injector: Injector, private masterdataFormBuilder: MasterdataFormBuilder, private dialog: MatDialog) {
        super(injector);
        this.bpSetup(new MasterdataBusinessPartnerEditorBundle());
    }

    submit() {
        this.data = Object.assign(
            new MasterdataBusinessPartnerEditorBundle(),
            {businessPartnerId: this.businessPartnerId},
            this.form.value
        );
        this.bpSubmit();
        this.saved.emit();
    }

}
