import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'eventTypeFormat'
})
export class EventTypeFormatPipe implements PipeTransform {

    transform(value: string): string {
        switch (value) {
            case 'DocumentEvent':
                return 'Dokument';
            case 'InvoiceEvent':
                return 'Rechnung';
            case 'MasterdataEvent':
                return 'Stammdaten';
            case 'MaterialEvent':
                return 'Material';
            case 'PresetEvent':
                return 'Vorlage';
            case 'SystemEvent':
                return 'System';
            case 'TourEvent':
                return 'Tour';
            case 'ServerStart':
                return 'System';
            case 'TaxGroupEvent':
                return 'Steuergruppe';
            case 'PriceEvent':
                return 'Preis';
            default:
                console.error('Unknown event type:', value);
        }
    }

}
