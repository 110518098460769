<ng-template #planItemTemplateDefault let-item="item">
  <span>{{item.title}}{{isInDeadline(item) ? '' : '&nbsp;!'}}</span>
</ng-template>

<div
  #itemEl
  class="drag mat-elevation-z2"
  draggable="true"
  [matTooltip]="isInDeadline(item) ? '' : 'Außerhalb des vorgegebenen Bereichs!'"
  [style.transform]="getPlanItemTransform(item)"
  [style.visibility]="item.visible && getPlanItemTransform(item) !== '' ? 'visible' : 'hidden'"
  [style.width]="(item.length * parent.gridStepSize) + 'px'"
  [style.height]="parent.rowHeight + 'px'"
  [style.background]="'rgb(' + item.currentColor.red + ', ' + item.currentColor.green + ', ' + item.currentColor.blue + ', 0.5)'"
  (contextmenu)="onItemRightClick(item, $event)"
  (dragstart)="onItemDragStart(item, $event)"
  (drag)="onItemDrag(item, $event)"
  (dragend)="onItemDragEnd(item, $event)"
  (mouseover)="onMouseOver(item)">
  <ng-template
    [ngTemplateOutlet]="parent.planItemTemplate || planItemTemplateDefault"
    [ngTemplateOutletContext]="{item: item}">
  </ng-template>
</div>
