import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {BusinessPartnerDomainObject} from '../../../../../model/domain/business-partner.domain-object';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {ContactDomainObject} from '../../../../../model/domain/contact.domain-object';
import {MatDialog} from '@angular/material/dialog';
import {DialogManageContactComponent} from './dialog-manage-contact/dialog-manage-contact.component';
import {DialogConfirmContactDeleteComponent} from './dialog-confirm-contact-delete/dialog-confirm-contact-delete.component';

export class MasterdataContactsEditorBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    contacts: ContactDomainObject[];
}

@Component({
    selector: 'app-masterdata-contacts-editor',
    templateUrl: './masterdata-contacts-editor.component.html',
    styleUrls: ['./masterdata-contacts-editor.component.scss']
})
export class MasterdataContactsEditorComponent extends BypassFormComponent<MasterdataContactsEditorBundle> {

    tableColumns = [
        'icon',
        'name',
        'phone',
        'mobile',
        'email',
        'fax',
        'comment',
        'dispositionEmailReceiver',
        'accountancyEmailReceiver',
        'actions'
    ];

    @Output() saved = new EventEmitter();

    businessPartnerId: number;
    contacts: ContactDomainObject[] = [];

    @Input() set businessPartner(bp: BusinessPartnerDomainObject) {
        this.businessPartnerId = bp.id;
        if (this.businessPartnerId) {
            const bundle = Object.assign(new MasterdataContactsEditorBundle(), {businessPartnerId: this.businessPartnerId});
            this.bpSetup(bundle);
        }
    }

    constructor(protected injector: Injector, private dialog: MatDialog) {
        super(injector);
        this.disableAutoLoadingOverlay();
    }

    protected bpOnMessageReceived(): void {
        this.contacts = this.data.contacts;
    }

    addContact() {
        this.dialog.open(DialogManageContactComponent, {
            minWidth: '50%'
        }).afterClosed().subscribe(contact => {
            if (contact) {
                this.contacts.push(contact);
                this.submit();
            }
        });
    }

    deleteContact(index) {
        this.dialog.open(DialogConfirmContactDeleteComponent, {
            data: this.contacts[index]
        }).afterClosed().subscribe((response: boolean) => {
            if (response) {
                this.contacts.splice(index, 1);
                this.submit();
            }
        });
    }

    editContact(index) {
        this.dialog.open(DialogManageContactComponent, {
            data: this.contacts[index],
            minWidth: '50%'
        }).afterClosed().subscribe(editedContact => {
            if (editedContact) {
                this.contacts[index] = editedContact;
                this.submit();
            }
        });
    }

    // TODO: Müssen auch bei edit/create alle Daten zum Backend geschickt werden?
    private submit() {
        this.data = Object.assign(new MasterdataContactsEditorBundle(), {
            businessPartnerId: this.businessPartnerId,
            contacts: this.contacts
        });
        this.bpSubmit();
        this.saved.emit();
    }
}
