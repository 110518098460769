import {Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {PlanItem} from '../../model/model';
import {DragDropPlanPreviewConfig} from '../../model/DragDropPlanPreviewConfig';
import {DragDropPlanComponent} from '../drag-drop-plan/drag-drop-plan.component';
import {PlanItemService} from '../../services/plan-item.service';

@Component({
  selector: 'ui-drag-drop-plan-item-basic',
  template: ''
})
export class DragDropPlanItemBasicComponent {

  @ViewChild('itemEl') itemEl: ElementRef;

  private _item;

  @Input() set item(item: PlanItem) {
    this._item = item;
  }

  get item(): PlanItem {
    return this._item;
  }

  /**
   * Internal state of the current drag position.
   */
  currentDragPosition = {
    x: undefined,
    y: undefined
  };

  xDifference: number;
  yDifference: number;

  invisibleTimeout;

  constructor(@Inject(DragDropPlanComponent) public parent: DragDropPlanComponent, public planItemService: PlanItemService) {
  }

  /**
   * Updates the preview element for the current dragged item.
   * @param item The plan item.
   * @param event The drag event.
   */
  createItemPreview(item: PlanItem, event: MouseEvent) {
    const position = this.planItemService.getItemDragPosition(item, event, this.parent,
      this.currentDragPosition, this.xDifference, this.yDifference);

    const xPos = position.x;
    const yPos = position.y + this.parent.scrollDifference;

    const width = item.length * this.parent.gridStepSize;

    return new DragDropPlanPreviewConfig(xPos, yPos, width, this.parent.rowHeight);
  }

  /**
   * Callback for right clicking a plan item.
   * @param item The plan item.
   * @param event The mouse event.
   */
  onItemRightClick(item: PlanItem, event: MouseEvent) {
    if (item.onRightClick) {
      item.onRightClick(item, event);
    }
    event.preventDefault();
  }

  /**
   * Check if the left button of the mouse was clicked.
   * @param event The mouse event.
   */
  isLeftButtonMouseClick(event: MouseEvent) {
    return event.button === 0;
  }

  positionBeyondPlanEnd(position: number) {
    return position > this.getPlanEnd();
  }

  getPlanEnd(): number {
    return (this.parent.plan.nativeElement.offsetLeft + this.parent.plan.nativeElement.offsetWidth);
  }

  isMouseOverUnplannedBox(event: MouseEvent): boolean {
    const unplannedTop = this.parent.unplanned.nativeElement.offsetTop;
    return (event.clientY + this.parent.scrollDifference) >= unplannedTop &&
      (event.clientY + this.parent.scrollDifference) <= (unplannedTop + this.parent.unplanned.nativeElement.offsetHeight) &&
      event.screenX >= this.parent.unplanned.nativeElement.offsetLeft &&
      event.screenX <= (this.parent.unplanned.nativeElement.offsetLeft + this.parent.unplanned.nativeElement.offsetWidth);
  }

  /**
   *
   * Returns true, if an item is planned so that it correctly ends within the deadline.
   */
  isInDeadline(item: PlanItem): boolean {
    if (!item.position || !item.deadline) {
      return true;
    }
    const itemEnd = this.planItemService.getItemEnd(item);
    const deadline = item.deadline;
    return itemEnd >= deadline.start && itemEnd <= deadline.end;
  }

}
