import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kmPerEuro'
})
export class KmPerEuroPipe implements PipeTransform {

  transform(km: number, euro: number): number {
    if (euro === 0) {
        return 0;
    }
    return km / euro;
  }

}
