import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputToNumber'
})
export class InputToNumberPipe implements PipeTransform {

  transform(value: any, ...args: any[]): number {
      if (value !== null) {
          return +value;
      }
      return 0;
  }

}
