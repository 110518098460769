import {Component, OnInit} from '@angular/core';
import {Invoice} from '../../../../model/old/Invoice';
import {DatatableCellBase} from '@byteways/bypass-datatable/lib/datatable-cell-base.interface';

@Component({
    selector: 'app-accountancy-invoice-table2-status',
    templateUrl: './accountancy-invoice-table2-status.component.html',
    styleUrls: ['./accountancy-invoice-table2-status.component.scss']
})
export class AccountancyInvoiceTable2StatusComponent implements DatatableCellBase<string> {

    data: string;

    statusToColor(status: string): string {
        switch (status.toLocaleLowerCase()) {
            case 'open':
                return 'purple';
            case 'closed':
                return 'yellow';
            case 'cancelled':
                return 'red';
            case 'sent':
                return 'green';
            default:
                return 'blue';
        }
    }

    statusToString(status: string): string {
        switch (status) {
            case 'OPEN':
                return 'Offen';
            case 'CLOSED':
                return 'Abgeschlossen';
            case 'CANCELLED':
                return 'Storniert';
            case 'CORRECTED':
                return 'Korrigiert';
            case 'SENT':
                return 'Verschickt';
            default:
                return 'Unbekannt';
        }
    }
}
