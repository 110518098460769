import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {AbstractBypassBundle, BypassFormComponent} from '@byteways/bypass-core';
import {PaymentTargetDomainObject} from '../../../../model/domain/payment-target.domain-object';


export class InputBusinessPartnerPaymentTargetsBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    paymentTargets: PaymentTargetDomainObject[];
    newTarget: PaymentTargetDomainObject | undefined;
    defaults: PaymentTargetDomainObject[];
}


@Component({
    selector: 'app-input-business-partner-payment-targets',
    templateUrl: './input-business-partner-payment-targets.component.html',
    styleUrls: ['./input-business-partner-payment-targets.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputBusinessPartnerPaymentTargetsComponent),
        multi: true
    }]
})
export class InputBusinessPartnerPaymentTargetsComponent extends BypassFormComponent<InputBusinessPartnerPaymentTargetsBundle> implements OnInit, ControlValueAccessor {

    @Input() businessPartnerId: number;

    options: PaymentTargetDomainObject[] = [];
    selected: PaymentTargetDomainObject[] = [];
    saveAsDefault = true;

    dirty = false;

    createForm = new FormGroup({
        days: new FormControl(undefined, [Validators.required, Validators.min(0)]),
        discount: new FormControl(undefined, [Validators.required, Validators.min(0), Validators.max(100)]),
        paymentMethod: new FormControl(undefined)
    });

    onChange = (_: any) => {
    };
    onTouched = () => {
    };

    // =====================================================================================================================================

    constructor(protected injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        const bundle = new InputBusinessPartnerPaymentTargetsBundle();
        bundle.businessPartnerId = this.businessPartnerId;
        this.bpSetup(bundle);
    }

    bpOnMessageReceived() {
        this.options = this.data.paymentTargets;
        if (!this.dirty) {
            this.selected = this.data.defaults;
            this.onChange(this.selected.map(pt => pt.id));
        }
    }

    isOptionChecked(option: PaymentTargetDomainObject) {
        return this.selected.map(t => t.id).includes(option.id);
    }

    onOptionClick(checked, target: PaymentTargetDomainObject) {
        this.dirty = true;
        if (!checked) {
            this.selected = this.selected.filter(t => t.id !== target.id);
        } else {
            this.selected.push(target);
        }
        if (this.saveAsDefault) {
            this.data = new InputBusinessPartnerPaymentTargetsBundle();
            this.data.businessPartnerId = this.businessPartnerId;
            this.data.defaults = this.selected;
            this.bpSetLoadingUntilMessageReceived();
            this.bpSubmit();
        }
        this.onChange(this.selected.map(pt => pt.id));
    }

    onCreateFormSubmit() {
        if (this.createForm.valid) {
            const newTarget = Object.assign(new PaymentTargetDomainObject(), this.createForm.value);

            const containsEqual =
                this.options.filter(target => target.days === newTarget.days && target.discount === newTarget.discount && target.paymentMethod === newTarget.paymentMethod).length > 0;

            if (containsEqual) {
                alert('TODO Existiert bereits');
            } else {
                this.data = new InputBusinessPartnerPaymentTargetsBundle();
                this.data.newTarget = newTarget;
                this.data.businessPartnerId = this.businessPartnerId;
                this.bpSetLoadingUntilMessageReceived();
                this.bpSubmit();
                this.createForm.reset();
            }
        }
    }

    getSummary() {
        if (!this.selected) {
            return 'Zahlungsziele';
        }
        if (this.selected.length === 0) {
            return '- keine Zahlungsziele -';
        }
        return this.selected.map(t => this.paymentTargetToShortString(t)).join(', ');
    }

    paymentTargetToString(paymentTarget: PaymentTargetDomainObject): string {
        if (!paymentTarget) {
            return null;
        }
        const str = `${paymentTarget.days} Tage zu ${paymentTarget.discount}% Skonto`;
        if (paymentTarget.paymentMethod === null || paymentTarget.paymentMethod === undefined) {
            return `${str} (kein Zahlungsweg angegeben)`;
        } else {
            return `${str} per ${paymentTarget.paymentMethod}`;
        }
    }

    paymentTargetToShortString(paymentTarget: PaymentTargetDomainObject): string {
        if (!paymentTarget) {
            return null;
        }
        const str = `${paymentTarget.days} Tage zu ${paymentTarget.discount}%`;
        if (paymentTarget.paymentMethod === null || paymentTarget.paymentMethod === undefined) {
            return str;
        } else {
            return `${str} per ${paymentTarget.paymentMethod}`;
        }
    }

    // =====================================================================================================================================

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        // TODO disable accordion
    }

    writeValue(obj: number[]): void {
        this.selected = obj.map(id => this.options.find(o => o.id === id)) || [];
    }

}
