import {AbstractBypassBundle} from '@byteways/bypass-core';
import {DispoPlanTour} from './DispoPlanTour';
import {DispoPlanCarrier} from './DispoPlanCarrier';
import {DispoPlanTourUpdate} from './DispoPlanTourUpdate';

export class DispoPlanBundle extends AbstractBypassBundle {

    constructor(date?: Date) {
        super();
        this.day = date;
    }

    day: Date = new Date();
    tours: DispoPlanTour[] = [];
    carriers: DispoPlanCarrier[] = [];
    lastUpdated: Date = new Date();
    tourUpdate: DispoPlanTourUpdate | null;
}
