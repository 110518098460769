import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MasterdataEditorComponent} from './components/editor/masterdata-editor/masterdata-editor.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {MatCardModule} from '@angular/material/card';
import {FlexModule} from '@angular/flex-layout';
import {MatTabsModule} from '@angular/material/tabs';
import {MasterdataBusinessPartnerEditorComponent} from './components/editor/masterdata-business-partner-editor/masterdata-business-partner-editor.component';
import {MasterdataAddressesEditorComponent} from './components/editor/masterdata-addresses-editor/masterdata-addresses-editor.component';
import {MasterdataContactsEditorComponent} from './components/editor/masterdata-contacts-editor/masterdata-contacts-editor.component';
import {MasterdataAccountancyEditorComponent} from './components/editor/masterdata-accountancy-editor/masterdata-accountancy-editor.component';
import {MasterdataDispositionEditorComponent} from './components/editor/masterdata-disposition-editor/masterdata-disposition-editor.component';
import {MasterdataCarriersEditorComponent} from './components/editor/masterdata-carriers-editor/masterdata-carriers-editor.component';
import {MasterdataLocationsEditorComponent} from './components/editor/masterdata-locations-editor/masterdata-locations-editor.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MasterdataAccountancyEditorBankAccountsComponent} from './components/editor/masterdata-accountancy-editor-bank-accounts/masterdata-accountancy-editor-bank-accounts.component';
import {MasterdataAccountancyEditorNumbersComponent} from './components/editor/masterdata-accountancy-editor-numbers/masterdata-accountancy-editor-numbers.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {FeaturesModule} from '../../features/features.module';
import {AddressTitlePipe} from './pipes/address-title.pipe';
import {BankAccountTitlePipe} from './pipes/bank-account-title.pipe';
import {CarrierTitlePipe} from './pipes/carrier-title.pipe';
import {LocationTitlePipe} from './pipes/location-title.pipe';
import {ContactTitlePipe} from './pipes/contact-title.pipe';
import {BypassIdenticonModule} from '@byteways/bypass-identicon';
import {MasterdataDialogCreateComponent} from './components/table/masterdata-dialog-create/masterdata-dialog-create.component';
import {MasterdataTableComponent} from './components/table/masterdata-table/masterdata-table.component';
import {MasterdataEditorContainerComponent} from './components/editor/masterdata-editor-container/masterdata-editor-container.component';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { DialogManageLocationComponent } from './components/editor/masterdata-locations-editor/dialog-manage-location/dialog-manage-location.component';
import {MatIconModule} from '@angular/material/icon';
import { DialogManageContactComponent } from './components/editor/masterdata-contacts-editor/dialog-manage-contact/dialog-manage-contact.component';
import { DialogManageCarrierComponent } from './components/editor/masterdata-carriers-editor/dialog-manage-carrier/dialog-manage-carrier.component';
import { DialogConfirmCarrierDeleteComponent } from './components/editor/masterdata-carriers-editor/dialog-confirm-carrier-delete/dialog-confirm-carrier-delete.component';
import { DialogConfirmContactDeleteComponent } from './components/editor/masterdata-contacts-editor/dialog-confirm-contact-delete/dialog-confirm-contact-delete.component';
import { DialogConfirmLocationDeleteComponent } from './components/editor/masterdata-locations-editor/dialog-confirm-location-delete/dialog-confirm-location-delete.component';
import { DialogManageAddressComponent } from './components/editor/masterdata-addresses-editor/dialog-manage-address/dialog-manage-address.component';
import { DialogConfirmAddressDeleteComponent } from './components/editor/masterdata-addresses-editor/dialog-confirm-address-delete/dialog-confirm-address-delete.component';
import { DialogConfirmAccountancyBankAccountDeleteComponent } from './components/editor/masterdata-accountancy-editor-bank-accounts/dialog-confirm-accountancy-bank-account-delete/dialog-confirm-accountancy-bank-account-delete.component';
import { DialogManageBankAccountComponent } from './components/editor/masterdata-accountancy-editor-bank-accounts/dialog-manage-bank-account/dialog-manage-bank-account.component';
import {BypassFormsModule} from '@byteways/bypass-forms';
import {MatSelectModule} from '@angular/material/select';
import { MasterdataDialogGroupCreateComponent } from './components/table/masterdata-dialog-create-group/masterdata-dialog-group-create.component';


@NgModule({
    declarations: [
        MasterdataEditorComponent,
        MasterdataEditorContainerComponent,
        MasterdataBusinessPartnerEditorComponent,
        MasterdataAddressesEditorComponent,
        MasterdataContactsEditorComponent,
        MasterdataAccountancyEditorComponent,
        MasterdataDispositionEditorComponent,
        MasterdataCarriersEditorComponent,
        MasterdataLocationsEditorComponent,
        MasterdataAccountancyEditorBankAccountsComponent,
        MasterdataAccountancyEditorNumbersComponent,
        AddressTitlePipe,
        BankAccountTitlePipe,
        CarrierTitlePipe,
        LocationTitlePipe,
        ContactTitlePipe,
        MasterdataDialogCreateComponent,
        MasterdataTableComponent,
        DialogManageLocationComponent,
        DialogManageContactComponent,
        DialogManageCarrierComponent,
        DialogConfirmCarrierDeleteComponent,
        DialogConfirmContactDeleteComponent,
        DialogConfirmLocationDeleteComponent,
        DialogManageAddressComponent,
        DialogConfirmAddressDeleteComponent,
        DialogConfirmAccountancyBankAccountDeleteComponent,
        DialogManageBankAccountComponent,
        MasterdataDialogGroupCreateComponent,
    ],
    entryComponents: [
        MasterdataDialogCreateComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatCardModule,
        FlexModule,
        MatTabsModule,
        MatTableModule,
        MatDialogModule,
        MatPaginatorModule,
        MatInputModule,
        MatSortModule,
        MatButtonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatExpansionModule,
        FeaturesModule,
        BypassIdenticonModule,
        MatIconModule,
        BypassFormsModule,
        MatSelectModule,
    ],
    exports: [
        MasterdataEditorComponent,
        MasterdataEditorContainerComponent,
        MasterdataTableComponent
    ]
})
export class MasterdataModule {
}
