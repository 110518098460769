import {Component, Injector} from '@angular/core';
import {BypassDataComponent} from './bypass-data.component';

/**
 * TODO: doku
 */
@Component({
  template: '',
  selector: 'lib-form'
})
export class BypassFormComponent<T> extends BypassDataComponent<T> {

  /**
   * the constructor that is used for dependency injection
   *
   * @param injector - the common angular injector
   */
  constructor(protected injector: Injector) {
    super(injector);
  }

  /**
   * call this when submitting a form to send the created object to the data service
   *
   * this function has to be called from the derived component when the data should be submitted to the data service.
   */
  bpSubmit() {
    this.bpSendOperator('submit');
  }

}
