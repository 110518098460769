import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {AddressDomainObject} from '../../../../model/domain/address.domain-object';

export class InputBusinessPartnerAddressSelectorBundle extends AbstractBypassBundle {
    businessPartnerId: number;
    addresses: AddressDomainObject[];
}

@Component({
    selector: 'app-input-business-partner-address-selector',
    templateUrl: './input-business-partner-address-selector.component.html',
    styleUrls: ['./input-business-partner-address-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputBusinessPartnerAddressSelectorComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => InputBusinessPartnerAddressSelectorComponent)
        }
    ]
})
export class InputBusinessPartnerAddressSelectorComponent extends BypassDataComponent<InputBusinessPartnerAddressSelectorBundle> implements ControlValueAccessor, OnInit, Validator {

    @Input() set businessPartnerId(id: number) {
        this.bpSetup(Object.assign(new InputBusinessPartnerAddressSelectorBundle(), {
            businessPartnerId: id
        }));
    }

    @Input() placeholder = 'Adresse';
    @Input() required = false;
    @Input() transformValue: (address: AddressDomainObject) => any = (address: AddressDomainObject) => {
        if (address !== null && address !== undefined) {
            return address.id;
        } else {
            return null;
        }
    };
    @Input() transformWrite: (input: any, options: AddressDomainObject[]) => AddressDomainObject = (input: any, options: AddressDomainObject[]) => {
        return options.find(o => o.id === input);
    };

    control = new FormControl(undefined, [Validators.required]);
    options: AddressDomainObject[] | undefined;
    private onChangeListeners: ((address: any) => {})[] = [];
    private onTouchedListeners: (() => {})[] = [];

    private writtenValue: any;

    constructor(protected injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.control.valueChanges.subscribe(() => {
            this.onChanged();
        });
    }

    protected bpOnMessageReceived(): void {
        this.options = this.data.addresses;
        setTimeout(() => this.control.setValue(this.transformWrite(this.writtenValue, this.options)));
        this.onTouched();
    }

    protected bpFilterIncomingData(data: InputBusinessPartnerAddressSelectorBundle): boolean {
        this.onTouched();
        return this.options === undefined;
    }

    onChanged() {
        for (const onChangeListener of this.onChangeListeners) {
            onChangeListener(this.transformValue(this.control.value));
        }
    }

    onTouched() {
        for (const onTouchedListener of this.onTouchedListeners) {
            onTouchedListener();
        }
    }

    registerOnChange(fn: (address: any) => {}): void {
        this.onChangeListeners.push(fn);
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouchedListeners.push(fn);
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    writeValue(address: any): void {
        this.writtenValue = address;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.control.valid ? null : {'address': true};
    }

}
