import {CountryDomainObject} from './country.domain-object';

export class AddressDomainObject {
    id: number;
    street: string;
    city: string;
    cityCode: string;
    residentName: string;
    countryId: number;
}
