import {Component, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BypassFormComponent} from '@byteways/bypass-core';
import {Carrier} from '../../../model/old/Carrier';
import {Customer} from '../../../model/old/Customer';
import {Location} from '../../../model/old/Location';
import {Material} from '../../../model/old/Material';
import {BypassSearchableInputComponent} from '@byteways/bypass-searchable-input';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class PresetDialogBundle {
    id: number;
    name: string;
    customerId: number;
    materialId: number;
    loadingLocationId: number;
    unloadingLocationId: number;
    vehicle: string;
    comment: string;
    orderNumber: string;
    quantity: string;
    duration: number;
    distance: number;
}


@Component({
    selector: 'app-preset-dialog',
    templateUrl: './preset-dialog.component.html',
    styleUrls: ['./preset-dialog.component.scss']
})
export class PresetDialogComponent extends BypassFormComponent<PresetDialogBundle> implements OnInit {


    title = 'Tourvorlage anlegen';
    submitted = false;

    @ViewChild('customer', {static: true}) customerInput: BypassSearchableInputComponent;
    @ViewChild('material', {static: true}) materialInput: BypassSearchableInputComponent;
    @ViewChild('loadingLocation', {static: true}) loadingLocationInput: BypassSearchableInputComponent;
    @ViewChild('unloadingLocation', {static: true}) unloadingLocationInput: BypassSearchableInputComponent;

    form = new FormGroup({
        id: new FormControl(undefined),
        name: new FormControl('', [Validators.required]),
        customer: new FormControl(undefined, [Validators.required]),
        material: new FormControl(undefined, [Validators.required]),
        loadingLocation: new FormControl(undefined, [Validators.required]),
        unloadingLocation: new FormControl(undefined, [Validators.required]),
        vehicle: new FormControl('Silo', [Validators.required]),
        message: new FormControl(),
        comment: new FormControl(),
        orderNumber: new FormControl(),
        quantity: new FormControl(),
        duration: new FormControl(),
        distance: new FormControl()
    });

    constructor(protected injector: Injector,
                public dialogRef: MatDialogRef<PresetDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        super(injector);
        this.bpSetup(new PresetDialogBundle());
    }

    ngOnInit() {
        if (this.input !== null) {
            this.title = 'Tourvorlage bearbeiten';
            this.form.patchValue(this.input.preset);
        }
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            const id = (this.input !== undefined && this.input !== null && this.input.preset !== undefined && this.input.preset !== null) ? this.input.preset.id : null;
            this.data = Object.assign(new PresetDialogBundle(), {
                id: id,
                name: this.form.value.name,
                customerId: this.form.value.customer.id,
                materialId: this.form.value.material.id,
                loadingLocationId: this.form.value.loadingLocation.id,
                unloadingLocationId: this.form.value.unloadingLocation.id,
                vehicle: this.form.value.vehicle,
                message: this.form.value.message,
                comment: this.form.value.comment,
                orderNumber: this.form.value.orderNumber,
                quantity: this.form.value.quantity,
                duration: this.form.value.duration,
                distance: this.form.value.distance
            });
            this.bpSubmit();
            this.close();
        }
    }

    close() {
        this.dialogRef.close();
    }

    carrierToString(data: any) {
        return (data as Carrier).name;
    }

    customerToString(data: any) {
        const customer = (data as Customer);
        return customer.name;
    }

    locationToString(data: Location) {
        return `${data.name} | ${data.address.city}`;
    }

    materialToString(data: any) {
        return (data as Material).name;
    }

}
