import {Injectable} from '@angular/core';
import {PlanItem, PlanItemColor} from '../model/model';
import {DragDropPlanComponent} from '../components/drag-drop-plan/drag-drop-plan.component';

@Injectable({
  providedIn: 'root'
})
export class PlanItemService {

  constructor() {
  }

  public planItemColorToRgbaString(itemColor: PlanItemColor, alpha: number = 1): string {
    return itemColor ? `rgba(${itemColor.red}, ${itemColor.green}, ${itemColor.blue}, ${alpha})` : 'gray';
  }

  /**
   * Returns true, if an item is not planned yet.
   * @param item The plan item.
   * @param planRows: Number of DragDropPlan Rows
   */
  isItemUnplanned(item: PlanItem, planRows: number): boolean {
    return !item?.position?.row || item.position.row < 0
      || item.position.row > planRows || item?.position?.column < 0
      || item.position.column > 96 || (item.position.column + item.length) > 96;
  }

  getItemPositionFromPixelPosition(position: number, gridStepSize: number): number {
    return (position / gridStepSize);
  }

  getPixelPositionFromItemPosition(position: number, gridStepSize: number): number {
    return (position * gridStepSize);
  }

  getItemEnd(item: PlanItem): number {
    return (item.position.column + item.length);
  }

  getItemDragPosition(item: PlanItem, event: MouseEvent, dragDropPlanComponent: DragDropPlanComponent,
                      currentDragPosition: { x, y }, xDifference: number, yDifference: number): { x: number; y: number } {
    const scrollDifference = dragDropPlanComponent.scrollDifference;
    const planTop = dragDropPlanComponent.plan.nativeElement.offsetTop;
    const planStart = dragDropPlanComponent.plan.nativeElement.offsetLeft;
    const planWidth = dragDropPlanComponent.plan.nativeElement.offsetWidth;
    const planHeight = dragDropPlanComponent.plan.nativeElement.offsetHeight;
    const planEnd = planStart + planWidth;
    const gridStepSize = dragDropPlanComponent.gridStepSize;
    const rowHeight = dragDropPlanComponent.rowHeight;

    const pointerPosition = (((event.y + yDifference) + scrollDifference) - planTop);

    let x = Math.floor((event.clientX - xDifference) / gridStepSize) * gridStepSize;
    let y = ((Math.floor(pointerPosition / rowHeight) * rowHeight) - scrollDifference);

    const itemStart = (x + planStart);
    const itemEnd = (itemStart + (item.length * gridStepSize));

    const planBorder = 2;

    if (this.isItemBeyondPlanStart(itemEnd, planStart) || this.isItemBeyondPlanEnd(x, planStart, planEnd, planBorder) ||
      this.isItemAbovePlan(y, scrollDifference) || this.isItemBelowPlan(y, scrollDifference, planHeight, planBorder)) {
      x = currentDragPosition.x;
      y = currentDragPosition.y;
    }

    return {x, y};
  }

  isItemBelowPlan(y: number, scrollDifference: number, planHeight: number, planBorder: number) {
    return (y + scrollDifference) >= planHeight - planBorder;
  }

  isItemBeyondPlanStart(itemEnd: number, planStart: number) {
    return itemEnd <= planStart;
  }

  isItemBeyondPlanEnd(x: number, planStart: number, planEnd: number, planBorder: number) {
    return (x + planStart) >= (planEnd - planBorder);
  }

  /**
   * @param y coordinate in plan
   * @param scrollDifference of the page
   */
  isItemAbovePlan(y: number, scrollDifference: number) {
    return (y + scrollDifference) < 0;
  }

  /**
   * Returns true, if an item is planned so that it correctly ends within the deadline.
   */
  isInDeadline(item: PlanItem): boolean {
    if (!item.position || !item.deadline) {
      return true;
    }
    const itemEnd = this.getItemEnd(item);
    const deadline = item.deadline;
    return itemEnd >= deadline.start && itemEnd <= deadline.end;
  }

}
