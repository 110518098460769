import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {InvoiceFormComponent} from '../../forms/invoice-form/invoice-form.component';
import {InvoiceTourSelectionComponent} from '../../partials/invoice-tour-selection/invoice-tour-selection.component';
import {TourDisplay} from '../../partials/invoice-tour-display/invoice-tour-display.component';
import {IncomingCreditFormComponent} from '../../forms/incoming-credit-form/incoming-credit-form.component';

@Component({
    selector: 'app-incoming-credit-editor',
    templateUrl: './incoming-credit-editor.component.html',
    styleUrls: ['./incoming-credit-editor.component.scss']
})
export class IncomingCreditEditorComponent {

    @Input() businessPartnerId: number;
    @Output() submitted = new EventEmitter<any>();
    @ViewChild('incomingCreditForm', {static: false}) incomingCreditForm: IncomingCreditFormComponent;
    disabledTourIds: number[] = [];
    enableVatWarning = false;

    addTourToIncomingCredit(tourSelection: InvoiceTourSelectionComponent, incomingCreditForm: IncomingCreditFormComponent, tourDisplay: TourDisplay) {
        incomingCreditForm.addTour(tourDisplay);
        this.disabledTourIds.push(tourDisplay.tourId);
    }

    removeTourFromIncomingCredit(tourSelection: InvoiceTourSelectionComponent, incomingCreditForm: IncomingCreditFormComponent, tourId: number) {
        this.disabledTourIds = this.disabledTourIds.filter(tid => tid !== tourId);
        if (this.disabledTourIds.length === 0) {
            tourSelection.commissionType = null;
        }
    }

    onSubmitted(event) {
        if (this.enableVatWarning) {
            if (confirm('Achtung!\n\nDie Gutschrift enthält möglicherweise Rechnungsposten, für die die Steueränderungen zwischen dem 1. Juli und 31. Dezember 2020 beachtet werden müssen!\n\n"OK" klicken, um zu speichern\n\n"Abbrechen" klicken, um weiter zu bearbeiten')) {
                this.submitted.emit(event);
            }
        } else {
            this.submitted.emit(event);
        }
    }

}
