<mat-card class="mat-elevation-z8">

  <div fxLayout="column" class="toolbar">

    <mat-card-title fxLayout="row" fxLayoutGap="auto">
      <span>{{title}}</span>
      <button mat-icon-button type="button" (click)="selectAll()" [disabled]="!items || items.length == 0" matTooltip="Alle auswählen">
        <mat-icon>done_all</mat-icon>
      </button>
    </mat-card-title>

    <mat-form-field>
      <input matInput [formControl]="filterControl" placeholder="Suchen" autocomplete="off"/>
    </mat-form-field>

  </div>

  <mat-card-content>

    <div
      class="itemWrapper"
      *ngFor="let item of itemsFiltered; let i = index"
      (click)="itemClicked(item, i)">
      <ng-template #container></ng-template>
    </div>

    <p *ngIf="itemsFiltered.length === 0" class="not-found">Keine Elemente vorhanden</p>

  </mat-card-content>

</mat-card>
