import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {InvoiceItem} from '../../../model/old/InvoiceItem';
import {DialogTourDetailsComponent} from '../../disposition/dialogtourdetails/dialogtourdetails.component';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-accountancy-invoicing-invoice-item',
    templateUrl: './accountancy-invoicing-invoice-item.component.html',
    styleUrls: ['./accountancy-invoicing-invoice-item.component.scss']
})
export class AccountancyInvoicingInvoiceItemComponent implements OnInit {

    @Input() invoiceItemFormGroup: FormGroup;
    @Input() posNr = 0;
    @Input() numItemsTotal = 0;
    @Input() disabled = false; // TODO implement
    @Input() invoiceAddressId: number;

    @Output() deleted = new EventEmitter<InvoiceItem>();
    @Output() movedUp = new EventEmitter<InvoiceItem>();
    @Output() movedDown = new EventEmitter<InvoiceItem>();

    collapsed = false;

    defaultVat: number | null = null;

    private readonly unitOptions = [
        'Festpreis',
        'Tonnen',
        'Kilogramm',
        'Minuten',
        'Stunden',
        'Tage',
        'Monate',
        'Jahre',
        'Paletten',
        'Big Bags'
    ];

    unitOptionsFiltered: Observable<string[]>;

    constructor(private dialog: MatDialog) {

    }

    ngOnInit(): void {
        this.unitOptionsFiltered = this.invoiceItemFormGroup.get('unit').valueChanges.pipe(
            startWith(''),
            map(value => {
                if (value) {
                    const filterValue = value.toLowerCase();
                    return this.unitOptions.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);
                } else {
                    return this.unitOptions;
                }
            })
        );
    }

    onDetailsClick() {
        this.dialog.open(DialogTourDetailsComponent, {data: {tour: this.invoiceItemFormGroup.value.tour}, width: '1000px'});
    }

    setVat(vat: number) {
        this.defaultVat = vat;
        this.invoiceItemFormGroup.patchValue({vat: vat});
    }

}
