import {Component, Injector} from '@angular/core';
import {AbstractBypassBundle, BypassDataComponent} from '@byteways/bypass-core';
import {Employee} from '../../model/old/Employee';

export class AdminBundle extends AbstractBypassBundle {
    value: Employee;
}

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent extends BypassDataComponent<AdminBundle> {

    constructor(protected injector: Injector) {
        super(injector);
        this.bpSetup(new AdminBundle());
    }

}
