import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {FeaturesModule} from '../../features/features.module';
import {MatListModule} from '@angular/material/list';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
    declarations: [
        ChangePasswordComponent,
        UserProfileComponent
    ],
    imports: [
        CommonModule,
        FeaturesModule,
        MatListModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        UserProfileComponent
    ]
})
export class UserProfileModule {
}
