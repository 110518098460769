import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AddressDomainObject} from '../../../../../../model/domain/address.domain-object';

@Component({
  selector: 'app-dialog-confirm-address-delete',
  templateUrl: './dialog-confirm-address-delete.component.html',
  styleUrls: ['./dialog-confirm-address-delete.component.scss']
})
export class DialogConfirmAddressDeleteComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public address: AddressDomainObject) {
    }

}
